import React, { useLayoutEffect, useState } from 'react'
import Heading from '../../../../Shared/Heading'
import { useNavigate, useParams } from 'react-router'
import closeTicketSchema from './closeTicketSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputError, Required } from './components';
import { closeTicketEndorsement } from './functions';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { URL } from '../../../../env';

const CloseEndorsementTicket = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const {name} = useSelector(({loggedInUser}) => {
    return loggedInUser;
  });

  const [closeEndorsementTiocketStatus, setCloseEndorsementTicketStatus] = useState({
    status: '',
    msg: '',
    hideForm: false
  });

  const onSubmitForm = async (formData) => {
    const { status, msg } = await closeTicketEndorsement(formData, name, id);

    setCloseEndorsementTicketStatus({
      status, msg, hideForm: status === 200 ? true: false
    });

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/endorsements/view')
      }, 3000);
    }

  };

    // Check if ticket already closed
    useLayoutEffect(() => {
      (async () => {
        const { data, status } = await axios.get(`${URL}/ops/endorsements/record/${id}`);
  
        const { closedBy, ticketStatus } = data;
  
        if (ticketStatus === 'closed') {
          setCloseEndorsementTicketStatus({
            status, msg: `Ticket Already Closed by ${closedBy}`, hideForm: status === 200 ? true : false
          });
  
          setTimeout(() => {
            navigate('/ops/endorsements/view');
          }, 2000);
        }
  
      })();
    }, []);


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(closeTicketSchema) });

  return (
    <div className='w-1/2'>
      <Heading title={`Close Ticket #ACPL-EN-2024-${id}`} />

      {
        closeEndorsementTiocketStatus?.status === 200 &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{closeEndorsementTiocketStatus?.msg}</div>
        </div>
      }

      {
        !closeEndorsementTiocketStatus.hideForm &&
        <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
          <div className="mb-5 m-10">
            <label htmlFor="closeTicketComments" className="block mb-2 text-sm font-bold text-gray-900">Final Comments <Required /></label>
            <textarea {...register('closeTicketComments')} id="closeTicketComments" className="block w-full p-2.5 text-sm text-gray-900 rounded-lg border  focus:ring-blue-500  bg-gray-700 border-gray-600 placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:border-blue-500" placeholder="Any Comments..."></textarea>
            {errors.closeTicketComments && <InputError error={errors.closeTicketComments} />}
            <button type="submit" className="text-white my-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Close Ticket <i className="ml-1 fa-solid fa-lock"></i></button>
          </div>
        </form>
      }

      {
        closeEndorsementTiocketStatus?.status === 500 &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">

          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{closeEndorsementTiocketStatus?.msg}</div>
        </div>
      }
    </div>
  )
}

export default CloseEndorsementTicket
