import React, { useEffect, useState } from 'react'
import { InputError, Required } from '../Register Claim Report/components'
import { states } from '../Register Claim Report/constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { insurers } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import editClaimDetailsSchema from './editClaimDetailsSchema';
import { setClaimType, setCustomerName, setDealerName, setInsuranceClaimNumber, setInsurancePolicyNumber, setInsurerName, setLocation, setState, setSurveyorAppointmentDate, setSurveyorContactInfo, setSurveyorName } from '../redux';
import { updateClaimTicket } from './function';
import dayjs from 'dayjs';

const EditClaimDetails = () => {

  const [claimUpdateMessage, setClaimUpdateMessage] = useState(null)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(editClaimDetailsSchema) });

  const { claimid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Claim Details
  const claimDetails = useSelector(({ ClaimsData }) => {
    const { records } = ClaimsData;
    const record = records.filter((record) => {
      return record.id === claimid;
    });

    const claimData = record[0];

    return claimData;
  });

  // Claim Data
  const claimData = useSelector(({ ClaimsData }) => {
    return ClaimsData.editRecordData;
  });

  // Set Claim details in Store
  useEffect(() => {
    dispatch(setCustomerName(claimDetails.customer_name));
    dispatch(setDealerName(claimDetails.dealer_name));
    dispatch(setState(claimDetails.state));
    dispatch(setLocation(claimDetails.city));
    dispatch(setInsurancePolicyNumber(claimDetails.policy_number));
    dispatch(setClaimType(claimDetails.claim_type));
    dispatch(setInsurerName(claimDetails.insurance_company));
    dispatch(setInsuranceClaimNumber(claimDetails.claim_number));
    dispatch(setSurveyorName(claimDetails.surveyor));
    dispatch(setSurveyorContactInfo(claimDetails.surveyor_contact_info));
    if (claimDetails.surveyor_appointment_date) {
      setValue('surveyorAppointmentDate', claimDetails.surveyor_appointment_date);
    }
    setValue('status', claimDetails.status);

  }, [claimDetails]);

  const updateClaimTicketDetails = async (details) => {
    const requestUpdate = await updateClaimTicket(details, claimid);
    setClaimUpdateMessage(requestUpdate.message);
    if (requestUpdate.status === 200) {
      setTimeout(() => {
        navigate('/claims/view');
      }, 3000);
    };
  }

  return (
    <div>
      {/* Form */}
      {
        claimUpdateMessage &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          {
            claimUpdateMessage === 'Updated Successfully' ?
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              </div> :
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                </svg>
                <span className="sr-only">Warning icon</span>
              </div>
          }
          <div className="ms-3 text-sm font-normal">{claimUpdateMessage}</div>
        </div>
      }
      {
        claimUpdateMessage !== 'Updated Successfully' &&
        <form noValidate onSubmit={handleSubmit(updateClaimTicketDetails)}>
          <div className='grid grid-cols-2 gap-2 m-5'>
            {/* Customer Details */}
            <div className="mb-5">
              <label htmlFor="customerName" className="block mb-2 text-sm font-bold text-gray-900">Customer Name <Required /> </label>
              <input {...register('customerName')} value={claimData.customerName} onChange={(e) => {
                dispatch(setCustomerName(e.target.value))
              }} type="text" id="customerName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Kapil Vashist" required />
              {errors.customerName && <InputError error={errors.customerName} />}
              {errors.surveyorAppointmentDate && <InputError error={errors.surveyorAppointmentDate} />}
              {errors.insuranceClaimNumber && <InputError error={errors.insuranceClaimNumber} />}
              {errors.surveyor && <InputError error={errors.surveyor} />}
              {errors.surveyorContactInfo && <InputError error={errors.surveyorContactInfo} />}
            </div>

            {/* Dealer Name */}
            <div className="mb-5">
              <label htmlFor="dealerName" className="block mb-2 text-sm font-bold text-gray-900">Dealer Name <Required /> </label>
              <input {...register('dealerName')} value={claimData.dealerName} onChange={(e) => {
                dispatch(setDealerName(e.target.value));
              }} type="text" id="dealerName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Gaurav BMW Company" required />
              {errors.dealerName && <InputError error={errors.dealerName} />}
            </div>

            {/* Type of Loss */}
            <div>
              <label htmlFor="claimType" className="block mb-2 text-sm font-bold text-gray-900">Claim Type <Required /> </label>
              <select value={claimData.claimType} {...register('claimType')} onChange={(e) => {
                dispatch(setClaimType(e.target.value));
              }} id="claimType" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Claim Type -- </option>
                {
                  ['Accidental', 'Theft', 'Parital Theft'].map((type) => {
                    return <option>{type}</option>
                  })
                }
              </select>
              {errors.claimType && <InputError error={errors.claimType} />}
            </div>

            {/* Insurance Policy Number */}
            <div className="mb-5">
              <label htmlFor="insurancePolicyNumber" className="block mb-2 text-sm font-bold text-gray-900">Insurance Policy Number <Required /> </label>
              <input value={claimData.insurancePolicyNumber} {...register('insurancePolicyNumber')} onChange={(e) => {
                dispatch(setInsurancePolicyNumber(e.target.value));
              }} type="text" id="insurancePolicyNumber" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="OG-25-2081-XXXXXXXX" required />
              {errors.insurancePolicyNumber && <InputError error={errors.insurancePolicyNumber} />}
            </div>

            {/* Insurers */}
            <div>
              <label htmlFor="insurerName" className="block mb-2 text-sm font-bold text-gray-900">Insurer Name <Required /> </label>
              <select value={claimData.insurerName} onInput={(e) => {
                dispatch(setInsurerName(e.target.value))
              }} {...register('insurerName')} id="insurer" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Insurer -- </option>
                {
                  insurers.map((type) => {
                    return <option>{type}</option>
                  })
                }
              </select>
              {errors.insurerName && <InputError error={errors.insurerName} />}
            </div>

            {/* State */}
            <div>
              <label htmlFor="state" className="block mb-2 text-sm font-bold text-gray-900">State <Required /> </label>
              <select value={claimData.state} onInput={(e) => {
                dispatch(setState(e.target.value));
              }} {...register('state')} id="state" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose State -- </option>
                {
                  states.map((type) => {
                    return <option>{type}</option>
                  })
                }
              </select>
              {errors.state && <InputError error={errors.state} />}
            </div>

            {/* Location */}
            <div className="mb-5">
              <label htmlFor="location" className="block mb-2 text-sm font-bold text-gray-900">Location <Required /> </label>
              <input value={claimData.location} {...register('location')} onChange={(e) => {
                dispatch(setLocation(e.target.value));
              }} type="text" id="location" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Noida" required />
              {errors.location && <InputError error={errors.location} />}
            </div>

            {/* Claim Number */}
            {
              claimDetails.claim_number &&
              <div className="mb-5">
                <label htmlFor="insuranceClaimNumber" className="block mb-2 text-sm font-bold text-gray-900">Insurance Claim Number <Required /> </label>
                <input value={claimData.insuranceClaimNumber} {...register('insuranceClaimNumber')} onChange={(e) => {
                  dispatch(setInsuranceClaimNumber(e.target.value));
                }} type="text" id="insuranceClaimNumber" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Insurance Claim Number" required />
                {errors.insuranceClaimNumber && <InputError error={errors.insuranceClaimNumber} />}
              </div>
            }

            {/* Surveyor Name */}
            {
              claimDetails.surveyor &&
              <div className="mb-5">
                <label htmlFor="surveyor" className="block mb-2 text-sm font-bold text-gray-900">Surveyor Name <Required /> </label>
                <input value={claimData.surveyor} {...register('surveyor')} onChange={(e) => {
                  dispatch(setSurveyorName(e.target.value));
                }} type="text" id="surveyor" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Surveyor Name" required />
                {errors.surveyor && <InputError error={errors.surveyor} />}
              </div>
            }

            {/* Surveyor Details */}
            {
              claimDetails.surveyor_contact_info &&
              <div className="mb-5">
                <label htmlFor="surveyorContactInfo" className="block mb-2 text-sm font-bold text-gray-900">Surveyor Mobile <Required /> </label>
                <input value={claimData.surveyorContactInfo} {...register('surveyorContactInfo')} onChange={(e) => {
                  dispatch(setSurveyorContactInfo(e.target.value));
                }} type="text" id="surveyorContactInfo" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Surveyor Mobile Number" required />
                {errors.surveyorContactInfo && <InputError error={errors.surveyorContactInfo} />}
              </div>
            }

          </div>

          <button type="submit" className="text-white ml-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> <i className="fa-solid fa-pen mr-2"></i> Update Claim Ticket</button>
        </form>
      }
    </div>
  )
}

export default EditClaimDetails
