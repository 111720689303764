import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const dealersSlice = createSlice({
  name: 'dealersData',
  initialState,
  reducers: {
    setData : (state, action) => {
      return state = action.payload?.data;
    }
  }
});

export const { setData } = dealersSlice.actions;

export default dealersSlice.reducer;