import { revisePriceListRequest, toBase64, uploadFileToDrive } from '../../../functions.js';
import { resetPriceListData } from './redux.js';

// File Uploads
const fileUpload = async (e) => {
  const file = await (e.target.files[0]);
  const extension = file.type.split('/')[1];
  const fileType = file.type.split('/')[0];
  const base64Data = await toBase64(file);
  const fileId = await uploadFileToDrive(base64Data, extension, fileType);

  return fileId;
};

// Submit
const onSubmit = async (loggedInUser, requestId, priceListData, setMsg, setRequestStatus, navigate, dispatch) => {
  const { msg, status } = await revisePriceListRequest(loggedInUser.name, requestId, priceListData);
  setMsg(msg);
  setRequestStatus(status);

  if (status === 201) {
    setTimeout(() => {
      navigate('/dealer/pricelist');
      dispatch(resetPriceListData());
    }, 3000);
  }
};


export { fileUpload, onSubmit };