import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Chart, Doughnut, Line, Pie} from 'react-chartjs-2';
import "../../../App.css"
import { useEffect, useLayoutEffect } from 'react';
import { URL } from '../../../env.js';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setInsurerData, setInsurerLabels, setInsurerPoliciesCount, setLabels, setMonthlyData, setPoliciesCount, setSalesManagerData, setSalesManagerLabels, setSalesManagerPoliciesCount } from './redux.js';
import Heading from '../../../Shared/Heading.jsx';
import { getLabelsAndPoliciesCount } from './functions.js';
import { checkAuthentication } from '../../../functions.js';
import { useNavigate } from 'react-router';
import InsurerBranchData from '../Insurer Branch Data/index.jsx';

// ChartJS.register(Colors);

const Revenue = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const pathname = window.location.pathname;
    const auth = (async () => {
      const auth = await checkAuthentication(dispatch, navigate, pathname);
      if (!auth) navigate('/aegiscovenant/opsit/login');
    })
    auth();

    // Policies Monthly Count
    (async () => {
      const result = axios.get(`${URL}/revenue/monthly-count`);
      const { data } = (await result).data;
      dispatch(setMonthlyData(data));

     const {labels, policiesCount } = getLabelsAndPoliciesCount(data, 'Month', 'COUNT(UID)');

      dispatch(setLabels(labels));
      dispatch(setPoliciesCount(policiesCount));

    })();

    // Insurer Count
    (async () => {
      const result = axios.get(`${URL}/revenue/insurer-count`);
      const { data } = (await result).data;
      dispatch(setInsurerData(data));

      const {labels, policiesCount } = getLabelsAndPoliciesCount(data, 'Branch', 'COUNT(UID)');

      dispatch(setInsurerPoliciesCount(policiesCount));
      dispatch(setInsurerLabels(labels));

    })();

    // Sales Manager Count
    (async () => {
      const result = axios.get(`${URL}/revenue/sales-manager-count`);
      const { data } = (await result).data;
      dispatch(setSalesManagerData(data));

      const {labels, policiesCount } = getLabelsAndPoliciesCount(data, 'Sales Manager', 'COUNT(UID)');

      dispatch(setSalesManagerPoliciesCount(policiesCount));
      dispatch(setSalesManagerLabels(labels));

    })();

  }, []);

  const revenueData = useSelector(({ Revenue }) => {
    return Revenue;
  });

  return (
    <div>
      {/* Policies Count */}
      <div className='totalPoliciesMonthly' >
        <Heading title={'Total Active Insurance Policies'} />
        <div className='mx-auto mt-10 max-w-[800px]'>
          <Bar
            options={{
              plugins:{
                colors:{
                  forceOverride: true
                }
              }
            }}
            data={{
              labels: revenueData.monthlyData.labels,
              datasets: [
                {
                  label: "Policies Count",
                  data: revenueData.monthlyData.policiesCount
                }
              ]
            }}
          />
        </div>
      </div>

      {/* Insurer Branch Dropdown */}
      <InsurerBranchData/>

      {/* Total Policies Insurer */}
      {/* <div className='mt-10'>
        <Heading title={'Insurer Policies'} />
        <div className='mx-auto mt-10 max-w-[800px]'>
          <Pie
            options={{
              plugins:{
                colors:{
                  forceOverride: true
                }
              }
            }}
            data={{
              labels: revenueData.insurerData.labels,
              datasets: [
                {
                  label: "Policies Count",
                  data: revenueData.insurerData.policiesCount
                }
              ]
            }}
          />
        </div>
      </div> */}

      {/* Sales Manager */}
      {/* <div className='mt-10'>
        <Heading title={'Sales Manager Policies'} />
        <div className='mx-auto mt-10 max-w-[900px]'>
          <Pie
            options={{
              plugins:{
                colors:{
                  forceOverride: true
                }
              }
            }}
            data={{
              labels: revenueData.salesManager.labels,
              datasets: [
                {
                  label: "Policies Count",
                  data: revenueData.salesManager.policiesCount
                }
              ]
            }}
          />
        </div>
      </div> */}
    </div>
  )
};

export default Revenue;
