import axios from 'axios';
import { URL } from '../../../env.js';

const sendDealerOnboardingRequest = async (dealershipDetails, salesManager, requestId, managerTeam) => {
  const options = {
    method: 'POST',
    url: `${URL}/dealer/onboard/request`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealershipDetails, salesManager, requestId, managerTeam })
  };

  try {
    const request = axios.request(options);
    const response = (await request).data;
    return { msg: 'Request Sent Succesfully', type: 'success' };
  } catch (err) {
    return { msg: 'Failed to Complete Request', type: 'failed' }
  }
};

const getMasterDealers = async (salesManager) => {
  const options = {
    method: 'POST',
    url: `${URL}/dealer/rm/master-dealers`,
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ salesManager })
  };

  try {
    const request = axios.request(options);
    const { data } = await request;
    return data.masterDealers;
  } catch (err) {
    return [];
  }
};

export {
  sendDealerOnboardingRequest,
  getMasterDealers
};