import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  records: null,
    filteredRecords: [],
    filterActive: false,
    stats: {pending: 0, active: 0, others: 0, fetching: true}
};
export const InsuranceView = createSlice({
  name: 'Insurance View Records',
  initialState,
  reducers: {
    setInsuranceRecords: (state, action) => {
        state.records = action.payload;
    },
    setFilteredRecords: (state, action) => {
        state.filteredRecords = action.payload;
    },
    setFilterActive: (state, action) => {
      state.filterActive = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    }
  }
});

export const { setInsuranceRecords, setFilteredRecords, setFilterActive, setStats} = InsuranceView.actions;
export default InsuranceView.reducer;