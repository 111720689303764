import axios from 'axios';
import { URL } from '../../../env';

const updateClaimTicket = async (details, claimid) => {
  const options = {
    url: `${URL}/claims/edit`,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ details, claimid })
  };

  try {
    const request = axios.request(options);
    const {status, data} = (await request);
    const { message } = data;
    return {
      status, message
    };
  } catch (err) {
    return {
      status: 500, message: 'Failed to Update'
    }
  }
};

export {
  updateClaimTicket
};