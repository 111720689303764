import * as yup from 'yup';

const schema = yup.object().shape({
  chassisNumber: yup.string()
  .required('Chassis Number is required.')
  .matches(/^(?!\d+$)(?![a-zA-Z]+$)[a-zA-Z\d]+$/, 'Chassis Number must be alphanumberic.')
  .min(17, 'Chassis number cannot be shorter than 17 characters.')
  .max(25, 'Chassis number cannot be longer than 25 characters.')
});

export default schema;
