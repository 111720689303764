import axios from 'axios';
import * as xlsx from 'xlsx';
import { URL } from '../../../env.js';
import { checkAuthentication } from '../../../functions.js';
import { setFilterActive, setPricelistsFilterdView } from './redux.js';

const auth = async (navigate, dispatch, path) => {
  const auth = await checkAuthentication(dispatch, navigate, path);
  if (!auth) {
    navigate('/aegiscovenant/opsit/login');
  }
};

const formatPayout = (query) => {
  const payout = query.split('-');
  const payoutValue = payout[0].trim();
  // % || ₹
  const payoutType = payout[1].substring(payout[1].length - 2, payout[1].length - 1);
  return payoutType === '₹' ? `${payoutType}${payoutValue}` : `${payoutValue}${payoutType}`;
};

// Get User Price lists Requests
const getPriceListRequests = async (email, dept, query = "") => {
  const options = {
    method: 'GET',
    url: `${URL}/dealer/pricelist?dept=${dept}&email=${email}` + ((query === "") ? `` : `&query=${query}`),
    headers: {
      'content-type': 'application/json',
      withCredentials: true,
    },
  };

  try {
    const response = await axios.request(options);
    const { records, msg } = response.data;

    return { msg, records };
  } catch (err) {
    return { msg: 'Unable to complete request', records: [] };
  }
};


const updatePickedBy = async (type, record, user, dispatch, setPriceLists) => {
  const options = {
    method: 'POST',
    url: `${URL}/bst/update/picked-by`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ requestId: record.id, bstMemberName: user, type })
  };

  const request = axios.request(options);
  const { status } = (await request);

  // Reload window;
  if (status) {
    const { records } = await getPriceListRequests(user.email, user.department, '');
    dispatch(setPriceLists(records));
  }
};

// Set Filtered View
const pricelistFilteredView = (searchInput, filter, pricelistData, dispatch) => {

  // Valid Search Inputs
  if (searchInput && filter) {
    const filteredPricelists = pricelistData.filter(pricelist => {
      return pricelist[filter].trim().toLowerCase().includes(searchInput.trim().toLowerCase());
    });

    dispatch(setPricelistsFilterdView(filteredPricelists));
    dispatch(setFilterActive(true));
  }

};


// Generate Report Request
const generatePriceListReport = async (team) => {
  try {
    const options = {
      method: 'POST',
      url: `${URL}/pricelists/download-data`,
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify({team})
    };

    const request = axios.request(options);
    const {data} = (await request).data;

    const wb = xlsx.utils.book_new();

    const pricelistData = xlsx.utils.aoa_to_sheet(data);

    xlsx.utils.book_append_sheet(wb, pricelistData, `Price List Data`);
    const fileName = `Team_North_Price_List_Data.xlsx`;
    xlsx.writeFile(wb, fileName);

  } catch (err) {
    console.log(err);
    return null;
  }


};

export { auth, formatPayout, getPriceListRequests, generatePriceListReport, pricelistFilteredView, updatePickedBy };
