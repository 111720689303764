import React from 'react';

const Heading = ({title}) => {
  return (
    <div className="header mx-auto w-fit m-3">
      <h1 className="text-[35px] font-extrabold text-gray-900 dark:text-white">
        <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          {title}
        </span>
      </h1>
    </div>
  );
};

export default Heading;
