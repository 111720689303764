import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import updateClaimStatusSchema from './updateClaimStatusSchema.jsx';
import { useLayoutEffect, useState } from 'react';
import { updateClaimTicketStatus } from './functions.js';
import { useNavigate } from 'react-router';

const UpdateForm = ({ status, id, user }) => {

  const [requestStatus, setRequestStatus] = useState(null);
  const [finalStatus, setFinalStatus] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statuses = ['pending', 'insurer intimated', 'surveyor appointed', 'report pending', 'report submitted', 'settled', 'rejected', 'closed'];

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(updateClaimStatusSchema) });

  // Claims Store
  const { updatingStatus } = useSelector(({ Claims }) => {
    return Claims;
  });

  // Set Status in Schema
  useLayoutEffect(() => {
    setValue('status', status);
  }, []);

  if (errors) {
    console.log(errors);
  }

  return (
    <div className='border-2 shadow-lg rounded-lg'>
      <h1 className='bg-gray-300 p-2 border-b text-md font-bold'> <span className='p-2'>{status ? status.toUpperCase() : null} </span> <i class="fa-solid fa-arrow-right"></i>  <span className='p-2'>{status === 'settled' ? 'CLOSED' : statuses[statuses.indexOf(status) + 1].toUpperCase()}</span></h1>
      <form className='p-4' noValidate onSubmit={handleSubmit(async (e) => {
        const nextStatus =  getValues('status') === 'settled'? 'closed': getValues('status') === 'rejected' ? 'rejected': statuses[statuses.indexOf(status) + 1];
        const updateRequestStatus = await updateClaimTicketStatus(e, user, id, nextStatus, dispatch);

        if (updateRequestStatus.success) {
          setRequestStatus(updateRequestStatus);
          setTimeout(() => {
            navigate('/claims/view');
          }, 1500);
        } else {
          setRequestStatus(updateRequestStatus);
          setTimeout(() => {
            setRequestStatus(null)
          }, 3000);
        }


      })}>
        {
          status === 'report submitted' && <div className='flex gap-2 my-4'>
            <div class="flex items-center bg-green-600 px-2 border rounded border-gray-700">
              <input checked={finalStatus === 'settled'} onClick={() => {
                setFinalStatus('settled');
                // setValue('status', 'settled');
              }} id="bordered-checkbox-1" type="checkbox" value="settled" name="bordered-checkbox" class="w-4 h-4  border-gray-300 rounded bg-gray-100" />
              <label for="bordered-checkbox-1" class="w-full py-4 ms-2 text-sm  text-white font-bold">Settled</label>
            </div>
            <div class="flex items-center px-2 border bg-red-500 rounded border-gray-700">
              <input checked={finalStatus === 'rejected'} onClick={() => {
                setFinalStatus('rejected');
                // setValue('status', 'rejected');
              }} id="bordered-checkbox-2" type="checkbox" value="rejected" name="bordered-checkbox" class="w-4 h-4  bg-gray-100 border-gray-300 rounded" />
              <label for="bordered-checkbox-2" class="w-full py-4 ms-2 text-sm text-white font-bold">Rejected</label>
            </div>
          </div>
        }
        <div className='grid grid-cols-4 w-3/4 gap-2'>
          {
            status === 'pending' &&
            <LabelInput htmlFor={'insuranceClaimNumber'} placeholder={'Insurance Claim No.'} type={'text'} register={register} errors={errors} />
          }
          {
            status === 'insurer intimated' &&
            <>
              <LabelInput htmlFor={'surveyorName'} placeholder={'Surveyor Name'} type={'text'} register={register} errors={errors} />
              <LabelInput htmlFor={'surveyorMobile'} placeholder={'Surveyor Mobile'} type={'text'} register={register} errors={errors} />
              <LabelInput htmlFor={'surveyorAppointmentDate'} placeholder={'Surveyor Appointment Date'} type={'date'} register={register} errors={errors} />
            </>
          }
          {
            status === 'surveyor appointed' 
            // &&
            // <LabelTextarea htmlFor={'surveyDescription'} placeholder={'Surveyor Description'} type={'text'} register={register} errors={errors} />
          }
          {
            status === 'report pending' 
            &&
            <LabelTextarea htmlFor={'reportComments'} placeholder={'Report Comments'} type={'text'} register={register} errors={errors} />
          }
          {/* {
            status === 'survey done' &&
            <>
              {
                getValues('status') === 'approved' &&
                <LabelInput htmlFor={'claimAmount'} placeholder={'Claim Amount Disbursed'} type={'text'} register={register} errors={errors} />
              }
              <LabelInput htmlFor={'finalClosingComments'} placeholder={'Closing Comments'} type={'text'} register={register} errors={errors} />
            </>
          } */}
          {
            status === 'settled' &&
              <LabelInput htmlFor={'transactionUTR'} placeholder={'Transaction UTR Number'} type={'text'} register={register} errors={errors} />
          }
        </div>
        <div className='flex my-5'>
          <button disabled={updatingStatus} className=' bg-blue-500 text-white block rounded-lg text-sm p-4 font-bold disabled:opacity-55'>Update Status</button>
          {
            updatingStatus &&
            <Oval height={'50px'} width={'60px'} />
          }
        </div>
        {
          requestStatus !== null &&
          <span className={`mt-2  text-white block w-fit text-sm font-bold p-2 bg-${requestStatus.success ? 'green' : 'red'}-500 rounded-lg`}> {
            requestStatus.success ? <i class="fa-solid fa-circle-check"></i> : <i class="fa-solid fa-circle-xmark"></i>
          } {requestStatus.success ? 'Status Updated Successfully' : 'Failed to Update Status'}</span>
        }
      </form>
    </div>
  );
};

// Error on failed validation of any input
const InputError = ({ error }) => {
  return <p className="text-sm text-white my-1 bg-red-500 p-1 rounded-md">{error.message ? error.message : error}</p>;
};

const LabelInput = ({ htmlFor, placeholder, type, register, errors }) => {
  return (
    <div>
      <label htmlFor={htmlFor} className='block text-sm font-bold'>{placeholder} <Required /></label>
      <input {...register(htmlFor)} id={htmlFor} className='text-sm rounded-lg w-full' type={type} placeholder={placeholder} />
      {errors?.[htmlFor] && <InputError error={errors?.[htmlFor]} />}
    </div>
  )
};


// label + textarea
const LabelTextarea = ({ htmlFor, placeholder, type, register, errors }) => {
  return (
    <div>
      <label className='font-bold text-sm' htmlFor={htmlFor}>{placeholder} <Required /></label>
      <textarea {...register(htmlFor)} className='text-sm rounded-md resize-none' id={htmlFor} cols="40" rows="5" placeholder={placeholder}></textarea>
      {errors?.[htmlFor] && <InputError error={errors?.[htmlFor]} />}
    </div>
  )
};


// Required Mark
const Required = () => {
  return (
    <span className='text-red-400'>*</span>
  )
};

export {
  UpdateForm
};