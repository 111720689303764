import React, { useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RevolvingDot } from 'react-loader-spinner';

import viewConfigSchema from './schema.jsx';
import InputError from '../../../../Shared/InputError.jsx';
import { onSubmit, JsonTableWithHeader, reducer } from './functions.jsx';
import { initialState, policyCategory } from './constants.js';

const ViewDealerConfig = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(viewConfigSchema) });

  return (
    <div className="w-2/5 relative">
      <div className="inline-flex flex-col items-start justify-start w-full bg-white p-10 shadow-lg rounded-xl overflow-scroll">
        <p className="text-lg">View Dealer Config</p>
        <form noValidate onSubmit={handleSubmit((d) => onSubmit(d, dispatch))} className="flex items-start mt-2 gap-2">
          <div className="flex flex-col justify-start items-start w-[300px]">
            <input
              {...register('email')}
              placeholder="Dealer Email"
              className="w-[300px] border-gray-300 border-2 rounded-md p-2 px-2"
            />
            {errors.email && <InputError error={errors.email} />}
          </div>
          {state.loading ? (
            <div className="ml-4">
              <RevolvingDot
                visible={true}
                height="32"
                width="32"
                radius="14"
                strokeWidth={1}
                color="#0d55d1"
                ariaLabel="revolving-dot-loading"
              />
            </div>
          ) : (
            <button className="rounded-md bg-blue-500 py-2 px-2 text-white w-[80px]">Search</button>
          )}
        </form>

        {/* View Config */}
        {Object.keys(state.config).length !== 0 && (
          <div className="flex flex-col flex-wrap gap-2 items-start justify-center mt-5">
            <div className="flex gap-2 items-center justify-center">
              {policyCategory.map((category) => {
                return (
                  <button
                    key={category}
                    id={category}
                    onClick={() => dispatch({ type: 'setCategory', payload: category })}
                    className={`${
                      category === state.selectedCategory ? 'bg-orange-500' : 'bg-orange-300'
                    } text-white px-2 py-1 text-xs rounded-md`}
                  >
                    {category}
                  </button>
                );
              })}
            </div>
            <div className="flex flex-wrap gap-2 items-center justify-start">
              {Object.keys(state.vehicleTypeOptions).length !== 0 &&
                Object.keys(state.vehicleTypeOptions).map((vehicleType) => {
                  return (
                    <button
                      key={vehicleType}
                      id={vehicleType}
                      onClick={() => dispatch({ type: 'setVehicleType', payload: vehicleType })}
                      className={`${
                        vehicleType === state.selectedVehicleType ? 'bg-purple-500' : 'bg-purple-300'
                      } text-white px-2 py-1 text-xs rounded-md`}
                    >
                      {vehicleType}
                    </button>
                  );
                })}
            </div>
            <div className="flex gap-2 items-center justify-center">
              {Object.keys(state.policyTypeOptions).length !== 0 &&
                Object.keys(state.policyTypeOptions).map((policyType) => {
                  return (
                    <button
                      key={policyType}
                      id={policyType}
                      onClick={() => dispatch({ type: 'setPolicyType', payload: policyType })}
                      className={`${
                        policyType === state.selectedPolicyType ? 'bg-teal-500' : 'bg-teal-300'
                      } text-white px-2 py-1 text-xs rounded-md`}
                    >
                      {policyType}
                    </button>
                  );
                })}
            </div>
            <div className="flex flex-wrap gap-5 mt-5">
              {state.selectedConfig.discount &&
                Object.keys(state.selectedConfig.discount).length !== 0 &&
                Object.keys(state.selectedConfigDetails).map((key) => {
                  return (
                    <div key={key} className="flex flex-col items-start justify-center">
                      <label className="custom-label text-sm underline">{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                      <p className="rounded-sm text-sm">
                        {state.selectedConfigDetails[key].charAt(0).toUpperCase() +
                          state.selectedConfigDetails[key].slice(1)}
                      </p>
                    </div>
                  );
                })}
            </div>
            {state.selectedConfig.discount && Object.keys(state.selectedConfig.discount).length !== 0 ? (
              <div className="flex flex-col gap-5 mt-2 items-start w-full">
                <div className="flex flex-col justify-start items-start">
                  <p className="text-lg font-bold">Discount</p>
                  <div className="flex gap-5">
                    {state.selectedConfig.discount.withNilDep && (
                      <JsonTableWithHeader
                        label="With Nil Dep"
                        json={state.selectedConfig.discount.withNilDep}
                      />
                    )}
                    <JsonTableWithHeader
                      label="Without Nil Dep"
                      json={state.selectedConfig.discount.withoutNilDep}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                  <p className="text-lg font-bold">Payout</p>
                  <div className="flex gap-5">
                    {state.selectedConfig.payout.withNilDep && (
                      <JsonTableWithHeader
                        label="With Nil Dep"
                        json={state.selectedConfig.payout.withNilDep}
                      />
                    )}
                    <JsonTableWithHeader
                      label="Without Nil Dep"
                      json={state.selectedConfig.payout.withoutNilDep}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <p>Config is not set for this filter</p>
            )}
          </div>
        )}
      </div>
      </div>
  );
};

export default ViewDealerConfig;
