import React, { useEffect, useLayoutEffect, useState } from 'react';
import Heading from '../../../../Shared/Heading';
import { useNavigate, useParams } from 'react-router';
import closeTicketSchema from './closeTicketSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Required } from './components';
import { closeCancellationTicket as closeTicket } from './functions.js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { URL } from '../../../../env.js';
import dayjs from 'dayjs';
import InputError from '../../../../Shared/InputError.jsx';
import { RotatingSquare } from 'react-loader-spinner';

const CloseCancellationTicket = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState([]);

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const [closeCancellationTicket, setCloseCancellationTicket] = useState({
    status: '',
    msg: '',
    hideForm: false,
  });

  const onSubmitForm = async (formData) => {
    setLoading(true);
    if (!file && formData.cancellationReceipt === 'false') {
      setFileError('Please select proof file.');
    } else {
      setFileError('');
      const { status, msg } = await closeTicket(formData, name, id, file);
      setCloseCancellationTicket({
        status,
        msg,
        hideForm: status === 200 ? true : false,
      });

      if (status === 200) {
        setTimeout(() => {
          navigate('/ops/cancellations/view');
        }, 1500);
      }
    }
    setLoading(false);
  };

  // Check if ticket already closed
  useLayoutEffect(() => {
    (async () => {
      const { data, status } = await axios.get(`${URL}/ops/cancellations/record/${id}`);
      setRecord(data.record);

      const { closedBy, ticketStatus } = data;

      if (ticketStatus === 'closed') {
        setCloseCancellationTicket({
          status,
          msg: `Ticket Already Closed by ${closedBy}`,
          hideForm: status === 200 ? true : false,
        });

        setTimeout(() => {
          navigate('/ops/cancellations/view');
        }, 2000);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(closeTicketSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    (async () => {
      if (record?.cancellation_type?.toLowerCase() === 'certificate') setValue('cancellationReceipt', true);
      else setValue('cancellationReceipt', false);
      setValue('amountLocation', 'cd');
      setValue('dateOfTransaction', dayjs().format('YYYY-MM-DD'));
    })();
  }, [record]);

  return (
    <div className="w-screen flex flex-col items-center justify-center">
      <Heading
        title={`Close Ticket #ACPL-C${record.cancellation_type === 'Insurance' ? 'I' : 'C'}N-2024-${id}`}
      />
      {closeCancellationTicket?.status === 200 && (
        <div
          id="toast-success"
          className="w-full mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{closeCancellationTicket?.msg}</div>
        </div>
      )}
      {!closeCancellationTicket.hideForm && (
        <form className="ml-10 flex flex-col items-center" onSubmit={handleSubmit(onSubmitForm)}>
          {record?.cancellation_type?.toLowerCase() === 'insurance' &&
            record?.status?.toLowerCase() !== 'open for reconciliation' && (
              <div className="flex gap-4 items-center my-4">
                <input
                  {...register('cancellationReceipt')}
                  type="checkbox"
                  className="h-[20px] w-[20px] rounded-md"
                />
                <label htmlFor="cancellationReceipt" className="text-lg">
                  Do you want to proceed without cancellation reciept?
                </label>
              </div>
            )}
          <div className="flex flex-col items-center justify-center">
            {watch('cancellationReceipt') === false && (
              <div className="flex flex-col gap-4 mt-4">
                {/* Refunded amount from insurer */}
                <div className="custom-form-group">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Enter Refunded Amount
                  </label>
                  <input
                    {...register('refundedAmount')}
                    type="text"
                    id="first_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="999"
                  />
                  {errors.refundedAmount && <InputError error={errors.refundedAmount} />}
                </div>
                {/* Date Money received */}
                <div className="custom-form-group">
                  <label className="block mb-2 text-sm font-medium text-gray-900">Transaction Date</label>
                  <div className="w-[400px] flex items-center relative">
                    <svg
                      className="w-4 h-4 text-gray-500 absolute right-4 z-50"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                    <input
                      {...register('dateOfTransaction')}
                      datepicker
                      onChange={async (e) => {
                        setValue('dateOfTransaction', e.target.value);
                      }}
                      defaultValue={getValues('dateOfTransaction')}
                      type="date"
                      max={dayjs().format('YYYY-MM-DD')}
                      min={dayjs().subtract(3, 'months').format('YYYY-MM-DD')}
                      className="picker border-2 border-gray-200 cursor-pointer text-gray-900 text-sm rounded-lg block w-full"
                      placeholder="Select date"
                    />
                    {errors.refundedAmount && <InputError error={errors.dateOfTransaction} />}
                  </div>
                </div>
                {/* Where was the amount transferred */}
                <div className="w-[400px]">
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Where was amount transferred?
                  </label>
                  <select
                    {...register('amountLocation')}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="cd">CD Wallet</option>
                    <option value="aegiscovenant">AegisCovenant</option>
                    <option value="customer">Customer</option>
                  </select>
                </div>
                {/* Proof */}
                <div className="w-[400px]">
                  <label className="block mb-2 text-sm font-medium text-gray-900" for="multiple_files">
                    Upload Proof
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50  focus:outline-none"
                    onChange={(e) => {
                      setFile(e.target.files);
                      if (e.target.files.length > 0) setFileError('');
                      else setFileError('Please select proof file.');
                    }}
                    accept=".pdf,.jpg,.png"
                    type="file"
                    multiple
                  />
                  <p className="mt-1 text-sm text-gray-500">PNG, JPG or PDF (MAX. 5MB).</p>
                  {fileError.length > 0 && <InputError error={fileError} />}
                </div>
              </div>
            )}
            {getValues('cancellationReceipt') && getValues('cancellationReceipt') === true && (
              <>
                <label
                  htmlFor="closeTicketComments"
                  className="w-full block mb-2 text-sm font-medium text-gray-900"
                >
                  Final Comments <Required />
                </label>
                <textarea
                  {...register('closeTicketComments')}
                  className="block p-2.5 w-[500px] min-h-[150px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Any Comments..."
                ></textarea>
                {errors.closeTicketComments && <InputError error={errors.closeTicketComments} />}
              </>
            )}
            <button
              type="submit"
              className="text-white my-5 bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={loading}
            >
              Close Ticket <i className="ml-1 fa-solid fa-lock"></i>
            </button>
            {loading && (
              <RotatingSquare
                visible={true}
                height="50"
                width="50"
                color="#3B82F6"
                ariaLabel="rotating-square-loading"
              />
            )} 
          </div>
        </form>
      )}
      {closeCancellationTicket?.status === 500 && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 rounded-lg shadowtext-gray-400 bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{closeCancellationTicket?.msg}</div>
        </div>
      )}
    </div>
  );
};

export default CloseCancellationTicket;
