import axios from 'axios';
import { URL } from '../../../env.js';
import { setPostingFollowup } from '../redux.js';

const postTicketUpdate = async (e, name, ticketId, dispatch) => {

  try {

    dispatch(setPostingFollowup(true));
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify({
        id: ticketId,
        postedBy: name,
        description: e?.description
      }),
      url: `${URL}/claims/followup`
    };

    const request = axios.request(options);
    const response = await request;

    dispatch(setPostingFollowup(false));

    return {
      msg: 'Update Posted Successfully'
    }

  } catch (err) {
    dispatch(setPostingFollowup(false));
    return {
      msg: 'Unable to complete Request'
    }
  }
};


export {
  postTicketUpdate
};