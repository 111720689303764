import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priceLists: [],
  reportGenerating: false,
  filteredPricelists: null,
  isFilterActive: false
};
export const priceListData = createSlice({
  name: 'PriceListView',
  initialState,
  reducers: {
    setPriceLists: (state, action) => {
      state.priceLists = action.payload;
    },
    setGeneratingReport: (state, action) => {
      state.reportGenerating = action.payload;
    },
    setPricelistsFilterdView: (state, action) => {
      state.filteredPricelists = action.payload;
    },
    setFilterActive: (state, action) => {
      state.isFilterActive = action.payload;
    }
  }
});

export const { setPriceLists, setGeneratingReport, setPricelistsFilterdView, setFilterActive } = priceListData.actions;
export default priceListData.reducer;