import React, { useLayoutEffect, useState } from 'react';
import { checkAuthentication } from '../../../functions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Heading from '../../../Shared/Heading';
import { getSalesManagerData, getSalesManagerList } from './functions';
import '../../../App.css';
import { setFetchingData, setSalesManagers, setShowDetails } from './redux';
import { Bar, Pie } from 'react-chartjs-2';
import { AMJAD_RMS, MADHAV_RMS } from '../../PriceList/View/constants';

const RmStatsBst = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [salesManager, setSalesManager] = useState('');


  const user = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });


  const { salesManagers, fetchingData, showDetails, managerStats } = useSelector(({ RmStatsBST }) => {
    return RmStatsBST;
  });

  useLayoutEffect(() => {
    // Check allowed path authentication
    (async () => {
      const path = window.location.pathname;
      const data = await checkAuthentication(dispatch, navigate, path);
      if (data.name === 'Madhav' || data.name === 'Amjad Pathan') {
        dispatch(setSalesManagers(user.name === 'Madhav' ? MADHAV_RMS : AMJAD_RMS));
      } else {
        await getSalesManagerList(dispatch);
      }
    })();

  }, [user.name]);

  return (
    <div>
      {/* Search Bar */}
      <div>
        <Heading title={'View Sales Manager Stats'} />
        {
          salesManagers.length > 0 &&
          <div className='mx-auto w-fit mb-10'>
            <select disabled={fetchingData} onChange={async (e) => {
              const salesManagerName = e.target.value;
              if (salesManagerName.length) {
                setSalesManager(salesManagerName);
                dispatch(setFetchingData(true));
                dispatch(setShowDetails(false));
                getSalesManagerData(dispatch, salesManagerName);
              }
            }} className='outline font-bold p-2 rounded-lg bg-blue-600 text-white'>
              <option>-- Select Sales Manager --</option>
              {
                salesManagers.map((rm) => {
                  return <option>{rm}</option>
                })
              }
            </select>
          </div>
        }
      </div>

      {
        fetchingData && <div className="py-2 px-5 w-fit mx-auto mt-10 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">Preparing Data...</div>
      }

      {/* RM Details Section */}
      {
        showDetails && <>
          <h1 className='mx-auto w-fit font-bold text-[50px]'>{salesManager}</h1>
          {/* Policies Count */}
          <div className='grid grid-cols-2 mx-auto w-fit'>
            <div className="totalCount">

              <span className="m-3 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Overall Policies Count</h5>
                <p className="font-bold text-gray-700 dark:text-gray-400">{managerStats.totalPolicies}</p>
              </span>

            </div>
            <div className="totalMonthCount">

              <span className="m-3 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Monthly Count</h5>
                <p className="font-bold text-gray-700 dark:text-gray-400">{managerStats.currentMonthCount}</p>
              </span>

            </div>
          </div>

          {/* Month Wise Data */}
          <div className='totalPoliciesMonthly' >
            <Heading title={'Monthly Flow'} />
            <div className='mx-auto mt-10 max-w-[800px]'>
              <Bar
                options={{
                  plugins: {
                    colors: {
                      forceOverride: true
                    }
                  }
                }}
                data={{
                  labels: managerStats.monthly.labels,
                  datasets: [
                    {
                      label: "Policies Count",
                      data: managerStats.monthly.count
                    }
                  ]
                }}
              />
            </div>
          </div>

          {/* Dealer Wise Share */}
          <div className='totalPoliciesMonthly' >
            <Heading title={'Dealer Share'} />
            <div className='mx-auto mt-10 max-w-[800px]'>
              <Pie
                options={{
                  plugins: {
                    colors: {
                      forceOverride: true
                    }
                  }
                }}
                data={{
                  labels: managerStats.dealers.labels,
                  datasets: [
                    {
                      label: "Policies Count",
                      data: managerStats.dealers.count
                    }
                  ]
                }}
              />
            </div>
          </div>


          <div className='grid grid-cols-2 m-10'>
            {/* Insurance Wise Count  */}
            <div className=''>
              <Heading title={'Insurance Type'} />
              <div className='mx-auto mt-10 max-w-[500px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: managerStats.insuranceType.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: managerStats.insuranceType.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* Body Type Wise Count  */}
            <div className=''>
              <Heading title={'Body Type'} />
              <div className='mx-auto mt-10 max-w-[500px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: managerStats.bodyType.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: managerStats.bodyType.count
                      }
                    ]
                  }}
                />
              </div>
            </div>
          </div>



        </>
      }
    </div>
  )
}

export default RmStatsBst;
