import { Oval } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import Heading from '../../../../Shared/Heading';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import LinkButton from '../../../../Shared/LinkButton';
import '../../../../App.css';
import { setFilteredRecords, setRecords } from '../redux';
import {
  FilterChip,
  FilterDropdown,
  GenerateReport,
  RegisterCancellationRecord,
  Stats,
} from './component.jsx';
import AuthCheck from '../../../../AuthCheck.jsx';
import { useLocation, useNavigate } from 'react-router';
import {
  acceptCancellationTicket,
  fetchRecords,
  filterRecords,
  removeFilters,
  showRemoveFilter,
} from './functions.js';
import { checkAuthentication } from '../../../../functions.js';
import { FILTEROPTIONS, initialFilters, STATUSES, TYPES } from './constants.js';
import { useLayoutEffect } from 'react';
import LinkButtonWithIcon from '../../../../Shared/LinkButtonWithIcon.jsx';

const CancellationsView = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const [filters, setFilters] = useState(initialFilters);
  const [query, setQuery] = useState('');
  const [department, setDepartment] = useState('');
  const [accepting, setAccepting] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  //? Add filters
  const [selectedFilter, setSelectedFilter] = useState('');

  const state = useSelector(({ Cancellations, loggedInUser }) => {
    return { cancellations: Cancellations, loggedInUser };
  });

  const { records, filteredRecords } = state.cancellations;

  const cancellationsHeaders = [
    'Ticket No.',
    'Raised By',
    'Dealer Name',
    'Policy Number',
    'Insurance Company Name',
    'Customer Name',
    'Cancellation Reason',
    'Alternate Policy Number',
    'Status',
    'Update Ticket',
    'Ticket Created On',
    'Ticket Closed On',
    'File 1',
    'File 2',
    'File 3',
    'Insurer Ticket ID'
  ];

  // POLLING for fresh records
  useLayoutEffect(() => {
    (async () => {
      setLoading(true);
      const user = await checkAuthentication(dispatch, navigate, pathname);
      const { dept, name, team } = user;
      setDepartment(dept.toLowerCase());
      const records = await fetchRecords(dept.toLowerCase(), name.toLowerCase(), team.toLowerCase());

      dispatch(setRecords(records));
      filterRecords(records, filters, dispatch);
      setLoading(false);
    })();

    const POLLING_INTERVAL = 4000;
    const intervalId = setInterval(async () => {
      const user = await checkAuthentication(dispatch, navigate, pathname);
      const { dept, name, team } = user;
      setDepartment(dept.toLowerCase());
      const records = await fetchRecords(dept.toLowerCase(), name.toLowerCase(), team.toLowerCase());

      dispatch(setRecords(records));
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    const filteredRecords = records.filter((record) => {
      const keys = [
        'id',
        'ops_member',
        'dealer_name',
        'policy_number',
        'insurance_company',
        'customer_name',
        'endorsement_reason',
        'alternate_policy_number',
        'file1_id',
        'file2_id',
        'file3_id',
        'insurer_ticket_number'
      ];

      for (let key of keys) {
        if (record?.[key]?.toString().toLowerCase().includes(query.toLowerCase())) return true;
      }
      return false;
    });

    dispatch(setFilteredRecords(query.length === 0 ? records : filteredRecords));
  }, [query]);

  useEffect(() => {
    filterRecords(records, filters, dispatch);
  }, [filters]);

  return (
    <div className="h-screen relative">
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title={'Cancellations'} />
      <div className="w-screen flex flex-auto gap-2 items-center justify-between px-10">
        <Stats records={records} filteredRecords={filteredRecords} />
        <div className="flex gap-2">
          {showRemoveFilter(filters) && (
            <button
              onClick={() => {
                removeFilters(setFilters, setSelectedFilter);
              }}
              className="text-sm bg-red-400 flex items-center gap-2 hover:bg-red-500 rounded-full py-2 px-4"
            >
              <i className="fa-solid fa-x"></i>
              Remove Filters
            </button>
          )}
          {['ops', 'admin'].includes(department) && <RegisterCancellationRecord />}
          <GenerateReport />
        </div>
      </div>
      <div className="flex flex-wrap gap-4 items-center justify-start mt-4 pl-10">
        <FilterDropdown
          id="filterQuery"
          options={FILTEROPTIONS}
          setter={(e) => {
            if (document.getElementById('dynamic-filter-val')) {
              document.getElementById('dynamic-filter-val').value = '';
            }
            setSelectedFilter(e.target.innerText);
          }}
          value={selectedFilter}
          color="bg-[#75c9eb]"
          placeholder="Apply Filters"
          width="w-[200px]"
        />
        {selectedFilter.length > 0 && FILTEROPTIONS.includes(selectedFilter) && (
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <i className="fa-solid fa-filter"></i>
            </div>
            <input
              type="text"
              id="dynamic-filter-val"
              onKeyDown={(e) => {
                const {
                  target: { value },
                  code,
                } = e;
                if (code === 'Enter' && value?.length > 0) {
                  if (filters[selectedFilter] && !filters[selectedFilter].includes(value.toUpperCase()))
                    setFilters({
                      ...filters,
                      [selectedFilter]: [...filters[selectedFilter], value.toUpperCase().trim()],
                    });
                  else if (!filters[selectedFilter])
                    setFilters({ ...filters, [selectedFilter]: [value.toUpperCase().trim()] });

                  e.target.value = '';
                }
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
              placeholder="Filter Value"
            />
          </div>
        )}
        <div date-rangepicker className="flex items-center">
          <input
            datepicker
            onChange={async (e) => {
              setFilters({
                ...filters,
                from: e.target.value,
              });
            }}
            value={filters['from'] || ''}
            type="date"
            max={filters['to'] || dayjs().format('YYYY-MM-DD')}
            min={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
            className="picker border-2 border-gray-200 cursor-pointer text-gray-900 text-sm rounded-lg block w-full"
            placeholder="Select date"
          />
          <span className="mx-[3px] text-gray-500">to</span>
          <input
            datepicker
            onChange={async (e) => {
              setFilters({
                ...filters,
                to: e.target.value,
              });
            }}
            value={filters['to'] || ''}
            type="date"
            max={dayjs().format('YYYY-MM-DD')}
            min={filters['from'] || dayjs().format('YYYY-MM-DD')}
            className="picker border-2 border-gray-200 cursor-pointer text-gray-900 text-sm rounded-lg block w-full"
            placeholder="Select date"
          />
        </div>
        <FilterDropdown
          id="status"
          options={STATUSES}
          color="bg-[#88f78a]"
          value={filters['status']}
          setter={(e) => {
            setFilters({ ...filters, status: e.target.innerText });
          }}
        />
        <FilterDropdown
          id="cancellationType"
          color="bg-[#f78893]"
          options={TYPES}
          value={filters['cancellationType']}
          setter={(e) => {
            setFilters({ ...filters, cancellationType: e.target.innerText });
          }}
        />
        {['pending', 'all'].includes(filters.status.toLowerCase()) && (
          <label className="inline-flex items-center cursor-pointer">
            <input
              checked={filters['late']}
              onChange={(e) => {
                setFilters({ ...filters, late: e.target.checked });
              }}
              type="checkbox"
              value=""
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">Show only late policies</span>
          </label>
        )}
        <div className="flex w-[250px] items-center gap-2 border-gray-400 border-2 rounded-md relative">
          <i className="ml-2 fa-solid fa-magnifying-glass"></i>
          <input
            id="search"
            type="text"
            autoComplete="off"
            className="text-sm w-full mr-2 border-none focus:border-none"
            placeholder="Search"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
      </div>
      {showRemoveFilter(filters) && (
        <div className="flex flex-wrap gap-4 items-center justify-start mt-4 px-10">
          {Object.keys(filters).map((key, index) => {
            if (typeof filters[key] === 'object' && filters[key].length > 0) {
              return (
                <div className="flex flex-wrap gap-4 items-center justify-start">
                  {filters[key].map((filter, ind) => {
                    return (
                      <FilterChip
                        key={filter + ind}
                        onDelete={() => {
                          const newFilters = filters[key].filter((val) => val != filter);
                          if (newFilters.length !== 0) setFilters({ ...filters, [key]: newFilters });
                          else {
                            filters[key] = [];
                            setFilters({ ...filters });
                          }
                        }}
                      >
                        {key}: {filter}
                      </FilterChip>
                    );
                  })}
                </div>
              );
            } else if (
              !typeof filters[key] !== 'object' &&
              filters[key] !== 'All' &&
              filters[key] !== initialFilters[key]
            ) {
              return (
                <FilterChip
                  key={index}
                  onDelete={() => {
                    setFilters({ ...filters, [key]: initialFilters[key] });
                  }}
                >
                  {filters[key]}
                </FilterChip>
              );
            }
          })}
        </div>
      )}
      {loading && (
        <div className="px-10 py-5">
          <Oval
            visible={true}
            height="25"
            strokeWidth={4}
            width="25"
            color="#3B82F6"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {!loading && (
        <>
          <div className="flex items-start justify-items-start relative">
            <div className="relative ml-10 mt-5 overflow-scroll rounded-md rounded-se-none rounded-ee-none border-[1px] border-r-0 border-[#e1e1e1]">
              <table className="w-max mx-auto text-sm rtl:text-right text-gray-400 bg-[#d695fb]">
                {/* Table Heading */}
                <thead
                  className="text-xs uppercase border-b-[1px] border-b-[#e1e1e1]"
                  style={{ position: 'sticky', top: '0' }}
                >
                  <tr className="text-left bg-[#d695fb]">
                    {cancellationsHeaders.map((header, index) => (
                      <th key={index} scope="col" className="px-4 py-3">
                        <span className="text-white font-semibold text-[14px]">{header}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Table Rows */}
                  {filteredRecords.map((record, index) => {
                    const colorClass =
                      record.status === 'pending'
                        ? 'text-[#D97706] bg-[#FEF3C7]'
                        : record.status === 'rejected'
                        ? 'text-[#EF4444] bg-[#FEE2E2]'
                        : record.status === 'pending - business support'
                        ? 'text-[#728015] bg-[#ebf3b9]'
                        : record.status === 'pending - accounts'
                        ? 'text-[#157780] bg-[#dcfcf6]'
                        : record.status === 'open for reconciliation'
                        ? 'text-[#651580] bg-[#efdcfc]'
                        : 'text-[#15803D] bg-[#DCFCE7]';
                    const difference = dayjs().diff(dayjs(record.created_at), 'days');
                    if (index < pageNumber * 10 && index >= (pageNumber - 1) * 10)
                      return (
                        <tr
                          key={index}
                          onClick={(e) => {
                            if (
                              e.target.innerText !== 'Accept' &&
                              !e.target.className.toLowerCase().includes('fa-solid') &&
                              !e.target.className.toLowerCase().includes('fa-regular') &&
                              e.target.tagName.toLowerCase() !== 'button' &&
                              !['update', 'close', 'reject'].includes(e.target.innerText.toLowerCase().trim())
                            ) {
                              navigate(`/ops/cancellations/details/${record.id}`);
                            }
                          }}
                          className="cursor-pointer text-left text-black border-b-[1px] hover:text-black bg-white hover:bg-[#f7faff] border-b-[#e1e1e1] relative"
                        >
                          <td className={'px-4 py-3 font-semibold relative'}>
                            {difference > 7 &&
                              record.status === 'pending' &&
                              record.status !== 'initiated' && (
                                <p className="absolute top-[2px] left-[10px] text-red-500 -rotate-[30deg] text-xl z-30">
                                  <i className="fa-solid fa-triangle-exclamation"></i>
                                </p>
                              )}
                            {JSON.parse(record.updates.replaceAll('\n', '')).length === 1 &&
                              !['deepika', 'ujjawal', 'shashank shekhar', 'abdul khadir'].includes(
                                record.ops_member.toLowerCase().trim()
                              ) &&
                              record.status !== 'initiated' && (
                                <div className="h-[12px] w-[12px] bg-orange-500 rounded-full z-30 top-[13px] end-[13px] absolute"></div>
                              )}
                            {record.status === 'initiated' && (
                              <img
                                className="absolute z-30 left-0 top-0"
                                src="/fireworks.gif"
                                height="35px"
                                width="35px"
                              />
                            )}

                            <span className={'py-[6px] px-[8px] rounded-[16px] relative z-0 ' + colorClass}>
                              ACPL-C{record.cancellation_type.toLowerCase() === 'insurance' ? 'I' : 'C'}
                              N-2024-
                              {record.id}
                            </span>
                          </td>
                          <td className="px-4 py-3">{record.ops_member}</td>
                          <td className="px-4 py-3">{record.dealer_name}</td>
                          <td className="px-4 py-3">{record.policy_number}</td>
                          <td className="px-4 py-3">{record.insurance_company}</td>
                          <td className="px-4 py-3">{record.customer_name}</td>
                          <td className="px-4 py-3">{record.cancellation_reason}</td>
                          <td className="px-4 py-3">{record.alternate_policy_number}</td>
                          <td>
                            <span className={'py-[6px] px-[8px] rounded-[16px] ' + colorClass}>
                              {record.status.toUpperCase()}
                            </span>
                          </td>
                          <td className="px-4 py-3">
                            {(record.status === 'pending' ||
                              (record.status === 'open for reconciliation' &&
                                ['business support', 'admin'].includes(department))) && (
                              <>
                                <LinkButton
                                  link={`/ops/cancellations/update/${record.id}`}
                                  title={
                                    <div className="flex gap-[8px] items-center">
                                      <i className="fa-solid fa-pen"></i>
                                      <span>Update</span>
                                    </div>
                                  }
                                  type=""
                                  color="bg-[#3B82F6]"
                                  textColor="text-white"
                                />
                                <LinkButton
                                  link={`/ops/cancellations/close/${record.id}`}
                                  title={
                                    <div className="flex gap-[8px] items-center">
                                      <i className="fa-solid fa-lock"></i>
                                      <span>Close</span>
                                    </div>
                                  }
                                  type=""
                                  color="bg-[#1ba64e]"
                                  textColor="text-white"
                                />
                              </>
                            )}
                            {record.status === 'initiated' &&
                              (accepting.length !== 0 && accepting === record.id ? (
                                <div className="w-full flex items-center justify-center">
                                  <Oval
                                    visible={true}
                                    height="25"
                                    strokeWidth={4}
                                    width="25"
                                    color="#3B82F6"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                </div>
                              ) : (
                                <>
                                  <LinkButton
                                    onClick={async () => {
                                      const user = await checkAuthentication(dispatch, navigate, pathname);
                                      await acceptCancellationTicket(record.id, user, setAccepting);
                                      window.location.reload();
                                    }}
                                    title={
                                      <div className="flex gap-[8px] items-center">
                                        <i class="fa-solid fa-check"></i>
                                        <span>Accept</span>
                                      </div>
                                    }
                                    type=""
                                    color="bg-[#1ba64e]"
                                    textColor="text-[#ffffff]"
                                    disabled={accepting.length !== 0}
                                  />
                                  <LinkButton
                                    link={`/ops/cancellations/reject/${record.id}`}
                                    title={
                                      <div className="flex gap-[8px] items-center">
                                        <i className="fa-solid fa-xmark"></i>
                                        <span>Reject</span>
                                      </div>
                                    }
                                    type=""
                                    color="bg-[#EF4444]"
                                    textColor="text-[#ffffff]"
                                    disabled={accepting.length !== 0}
                                  />
                                </>
                              ))}
                            {record.status === 'pending - business support' && (
                              <LinkButton
                                link={`/ops/cancellations/details/${record.id}`}
                                title={
                                  <div className="flex gap-[8px] items-center">
                                    <i className="fa-regular fa-eye"></i>
                                    <span>View</span>
                                  </div>
                                }
                                type=""
                                color="bg-[#3B82F6]"
                                textColor="text-[#ffffff]"
                              />
                            )}
                            {record.status === 'closed' && (
                              <span
                                className={'py-[6px] px-[10px] rounded-[16px] text-[#EF4444] bg-[#FEE2E2]'}
                              >
                                <i className="fa-solid fa-lock"></i> Closed
                              </span>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            {dayjs(record.created_at).format('DD-MM-YYYY HH:mm:ss')}
                          </td>
                          <td className="px-4 py-3">
                            {record.updated_at && record.status === 'closed'
                              ? dayjs(record.updated_at, { utc: true }).format('DD-MM-YYYY HH:mm:ss')
                              : ''}
                          </td>
                          <td className="px-4 py-3 h-[40px]">
                            {record.file1_id ? (
                              <LinkButton
                                link={`https://drive.google.com/file/d/${record.file1_id}`}
                                title={record.file1_name}
                                type="file"
                                color="bg-[#3B82F6]"
                                textColor="text-white"
                              />
                            ) : (
                              <p>-</p>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            {record.file2_id ? (
                              <LinkButton
                                link={`https://drive.google.com/file/d/${record.file2_id}`}
                                title={record.file2_name}
                                type="file"
                                color="bg-[#3B82F6]"
                                textColor="text-white"
                              />
                            ) : (
                              <p>-</p>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            {record.file3_id ? (
                              <LinkButton
                                link={`https://drive.google.com/file/d/${record.file3_id}`}
                                title={record.file3_name}
                                type="file"
                                color="bg-[#3B82F6]"
                                textColor="text-white"
                              />
                            ) : (
                              <p>-</p>
                            )}
                          </td>
                          <td className="px-4 py-3">{record.insurer_ticket_number}</td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
            {/* Quick Actions */}
            <div className="relative mr-10 mt-5 overflow-visible rounded-md rounded-ss-none rounded-es-none border-[1px] border-l-0 border-[#e1e1e1] h-full">
              <table className="w-max mx-auto text-sm rtl:text-right text-gray-400">
                <thead
                  className="text-xs uppercase border-b-[1px] border-b-[#e1e1e1]"
                  style={{ position: 'sticky', top: '0' }}
                >
                  <tr className="text-left bg-[#d695fb]">
                    <th scope="col" className="px-4 py-3">
                      <span className="text-white font-semibold text-[14px]">Quick Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record, index) => {
                    if (index < pageNumber * 10 && index >= (pageNumber - 1) * 10)
                      return (
                        <tr className="text-left text-black hover:text-black bg-white border-b-[#e1e1e1] relative">
                          <td className="px-4 py-3 flex items-center justify-center border-b-[1px]">
                            <LinkButtonWithIcon
                              icon={<i className="fa-solid fa-ticket"></i>}
                              color="bg-[#3B82F6]"
                              textColor="text-white"
                              key={`qa-${index}-1`}
                              link={`/ops/cancellations/qa/add-insurer-ticket/${record.id}`}
                            />
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pagination */}
          <div className="flex gap-2 w-full items-center justify-center py-2 px-10">
            <button
              onClick={() => {
                if (pageNumber !== 1) setPageNumber(pageNumber - 1);
              }}
            >
              <i className="fa-solid fa-circle-chevron-left text-xl text-[#d695fb]"></i>
            </button>
            <p className="font-semibold">{pageNumber}</p>
            <button
              onClick={() => {
                if (pageNumber !== Math.ceil(filteredRecords.length / 10)) setPageNumber(pageNumber + 1);
              }}
            >
              <i className="fa-solid fa-circle-chevron-right text-xl text-[#d695fb]"></i>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CancellationsView;
