import axios from 'axios';
import dayjs from 'dayjs';

import { baseUrl } from '../env';

// Check if cookie is authentic or not
const checkAuthentication = async () => {
  const options = {
    method: 'GET',
    url: `${baseUrl}/auth`,
    withCredentials: true,
  };
  const res = await axios.request(options);

  return res.status === 200 ? res.data : false;
};

const formatDate = (date, format = 'DD-MM-YYYY HH:mm:ss') => dayjs(date).format(format);

// Reload
const reloadPage = (time = 5000) => {
  setTimeout(() => {
    window.location.reload();
  }, time);
};

export { checkAuthentication, formatDate, reloadPage };
