const initialFilters = {
  'Raised By': [],
  'Dealer Name': [],
  'Insurance Company Name': [],
  'Cancellation Reason': [],
  status: 'Pending',
  cancellationType: 'Insurance',
  from: undefined,
  to: undefined,
  late: false,
};

const STATUSES = [
  'All',
  'Pending',
  'Pending - Business Support',
  'Pending - Accounts',
  'Open for Reconciliation',
  'Rejected',
  'Closed',
];

const TYPES = ['All', 'Insurance', 'Certificate'];

const FILTEROPTIONS = [
  'Raised By',
  'Dealer Name',
  // 'Policy Number',
  'Insurance Company Name',
  // 'Customer Name',
  'Cancellation Reason',
];

export { initialFilters, STATUSES, TYPES, FILTEROPTIONS };
