import React, { useState } from 'react';
import Heading from '../../../../Shared/Heading.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import registerEndorsementRecordSchema from './registerEndorsementRecordSchema.jsx';
import { Dropdown, FormInputs, InputError, Required } from './components.jsx';
import { INSURER_OPTIONS, endorsementReasons } from './constants.js';
import { useLocation, useNavigate } from 'react-router';
import { registerEndorsementRecordRequest } from './functions.js';
import AuthCheck from '../../../../AuthCheck.jsx';
import { searchDealerName } from '../../Cancellations/Register/functions.js';
import { Oval } from 'react-loader-spinner';

const EndorsementsRecordRegister = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerEndorsementRecordSchema) });

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [endorsementRegistrationStatus, setEndorsementRegistrationStatus] = useState({
    msg: '',
    type: '',
    hideForm: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Endorsement
  const { particular } = useSelector(({ Endorsements }) => {
    return Endorsements.register;
  });

  // User
  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  // Submission
  const registerEndorsementRecord = async (formData) => {
    setSubmitting(true);
    const { status, msg } = await registerEndorsementRecordRequest(formData, name);

    // Show Notification
    setEndorsementRegistrationStatus({
      msg: msg,
      type: status === 200 ? 'success' : 'failed',
      hideForm: status === 200 ? true : false,
    });

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/endorsements/view');
      }, 2000);
    }
    setSubmitting(false);
  };

  const { pathname } = useLocation();

  return (
    <div className="w-full">
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      {/* Form Heading */}
      <Heading title={'Register Endorsement Record'} />

      {endorsementRegistrationStatus.type === 'success' && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{endorsementRegistrationStatus.msg}</div>
        </div>
      )}

      {/* Form */}
      {!endorsementRegistrationStatus.hideForm && (
        <form
          noValidate
          className="flex flex-col items-center justify-center h-full w-full"
          onSubmit={handleSubmit(registerEndorsementRecord)}
        >
          <div className="flex flex-wrap gap-4 items-start justify-start w-[816px] m-10 relative">
            {/* Dealer Name */}
            <div className="custom-form-group relative">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Dealer Name <Required />
              </label>
              <input
                {...register('dealerName')}
                type="text"
                onChange={(e) => {
                  setValue('dealerName', e.target.value);
                  searchDealerName(e.target.value).then((names) => setAutoCompleteOptions(names));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="MPS"
              />
              <InputError error={errors.dealerName} />
              {getValues('dealerName')?.length >= 3 && autoCompleteOptions?.length > 0 && (
                <div className="absolute top-20 max-h-40 w-[400px] bg-gray-50 z-50 overflow-scroll rounded-lg flex flex-col">
                  {autoCompleteOptions.map((option, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setValue('dealerName', option);
                          setAutoCompleteOptions([]);
                        }}
                        className="border-y-[1px] bg-gray-100 hover:bg-gray-200 cursor-pointer p-2 font-light select-none text-xs"
                      >
                        {option}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* Policy Number */}
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Policy Number <Required />
              </label>
              <input
                {...register('policyNumber')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="OG-AAAA-BBBB-1111"
              />
              <InputError error={errors.policyNumber} />
            </div>

            {/* Particulars */}
            <Dropdown
              options={['Insurance', 'Certificate']}
              label={'Endorsement Particular'}
              id={'endorsementParticular'}
              value={watch('endorsementParticular')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />

            {/* Insurance Company Name */}
            <Dropdown
              options={INSURER_OPTIONS}
              label={'Insurance Company'}
              id={'insuranceCompanyName'}
              value={watch('insuranceCompanyName')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />

            {/* Customer Name */}
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Customer Name <Required />
              </label>
              <input
                {...register('customerName')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="ABC Gupta"
              />
              <InputError error={errors.customerName} />
            </div>

            {/* Endorsement Reason */}
            <Dropdown
              options={endorsementReasons}
              label={'Endorsement Reason'}
              id={'endorsementReason'}
              value={watch('endorsementReason')}
              setter={(id, value) => {
                setValue(id, value);
              }}
            />

            {/* Additional Info */}
            <div className="custom-form-group">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Additional Information <Required />
              </label>
              <input
                {...register('additionalInfo')}
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="....."
              />
              <InputError error={errors.additionalInfo} />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="text-white w-[300px] bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={submitting}
          >
            Register Endorsements Record <i className="fa-solid fa-circle-check"></i>{' '}
          </button>
          {submitting && (
            <Oval
              visible={true}
              height="32"
              width="32"
              color="#3B82F6"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </form>
      )}

      {endorsementRegistrationStatus.type === 'failed' && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{endorsementRegistrationStatus.msg}</div>
        </div>
      )}
    </div>
  );
};

export default EndorsementsRecordRegister;
