import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { checkAuthentication } from '../../Shared/functions';
import Heading from '../../Shared/Heading';
import LinkButton from '../../Shared/LinkButton';
import { useNavigate } from 'react-router';
import {logoutUser} from './functions.js';
import { Link } from 'react-router-dom';

const Profile = () => {
  const user = useSelector(({ loggedInUser }) => loggedInUser);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    (async () => {
      const res = await checkAuthentication();
      if(!res) navigate('/login');
    })();
  }, []);

  return <>
    <div className="mt-10 flex flex-col items-center justify-center align-center w-full">
      <Heading title="My Profile" />
      <div className="custom-form-group mb-5">
        <p className="label">Name</p>
        <p className="text-2xl">{user.name}</p>
      </div>
      <div className="custom-form-group mb-5">
        <p className="label">Email</p>
        <p className="text-2xl">{user.email}</p>
      </div>
      <div className="custom-form-group mb-5">
        <p className="label">Department</p>
        <p className="text-2xl">{user.department}</p>
      </div>
      <div className="w-[400px] flex gap-5 align-start justify-start items-start">
        <button onClick={logoutUser} className="custom-btn">Sign Out</button>
        {/* Reset Password */}
        <Link to="/aegiscovenant/opsit/reset-password" className="text-blue-700">
          <button className="custom-btn">
            Reset Password
          </button>
        </Link>
      </div>
    </div>
  </>
};

export default Profile;
