import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInsurerBranches, getSelectedBranchData } from './functions.js';
import Heading from '../../../Shared/Heading.jsx';
import { resetData, setFetchingData, setShowDetails } from './redux.js';
import { Bar, Doughnut, Line, Pie, PolarArea, Radar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { scales } from 'chart.js';

const InsurerBranchData = () => {

  const dispatch = useDispatch();
  const { branches, fetchingData, data, showDetails } = useSelector(({ InsurerBranchData }) => {
    return InsurerBranchData;
  });

  const { totalPolicies, totalCurrentMonthPolicies, stateWiseData, rtoWiseData, monthWiseData, insurancePolicyTypeWiseData, salesManagerWiseData } = data;

  useLayoutEffect(() => {
    (async () => {
      await getInsurerBranches(dispatch);
    })();
  }, [])


  return (
    <div className='mt-20 mb-20'>

      <Heading title={'View Insurer Branch Data'} />

      <div className='mx-auto w-fit'>
        <select onChange={async (e) => {
          const branchName = e.target.value;
          if (branchName.length) {
            dispatch(setFetchingData(true));
            dispatch(setShowDetails(false));
            await getSelectedBranchData(dispatch, branchName);
          }
        }} disabled={fetchingData} className='outline-none border-none rounded-lg p-2 font-bold text-white bg-blue-400 italic'>
          <option value=''> -- Choose Insurer Branch -- </option>
          {
            branches && branches.map((branch) => {
              return <option>{branch}</option>
            })
          }
        </select>
      </div>
      {
        fetchingData && <div className="py-2 px-5 w-fit mx-auto mt-10 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">Preparing Data...</div>
      }

      {
        showDetails && <>
          <div className='grid grid-cols-2 mx-auto w-fit'>
            <div className="totalCount">

              <span className="m-3 mt-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Overall Policies Count</h5>
                <p className="font-bold text-gray-700 dark:text-gray-400">{totalPolicies}</p>
              </span>

            </div>
            <div className="totalMonthCount">

              <span className="m-3 mt-10 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Monthly Count ( {dayjs().format('MMMM YYYY')} )</h5>
                <p className="font-bold text-gray-700 dark:text-gray-400">{totalCurrentMonthPolicies}</p>
              </span>

            </div>
          </div>
          <div>

            {/* State Wise Insurer Branch Data */}
            <div className='mt-20'>
              <Heading title={'State Wise Insurance Policies'} />
              <div className='mx-auto max-w-[700px]'>
                <Pie
                  options={{
                    maintainAspectRatio: true,
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: stateWiseData.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: stateWiseData.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* RTO Wise Insurer Branch Data */}
            <div className='mt-20'>
              <Heading title={'RTO Wise Insurance Policies'} />
              <div className='mx-auto mt-10 max-w-[700px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: rtoWiseData.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: rtoWiseData.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* Month Wise Insurer Branch Data */}
            <div className='mt-20'>
              <Heading title={'Monthly Data'} />
              <div className='mx-auto max-w-[800px]'>
                <Line
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: monthWiseData.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: monthWiseData.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* Insurance Policy Type data */}
            <div className='mt-20'>
              <Heading title={'Insurance Type Data'} />
              <div className='mx-auto mt-10 max-w-[800px]'>
                <Doughnut
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: insurancePolicyTypeWiseData.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: insurancePolicyTypeWiseData.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* Sales Manager Type Data */}
            <div className='mt-20'>
              <Heading title={'Active Sales Managers'} />
              <div className='mx-auto mt-10 max-w-[700px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: salesManagerWiseData.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: salesManagerWiseData.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

          </div>
        </>
      }

    </div>
  )
}

export default InsurerBranchData;
