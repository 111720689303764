// Cards
const cardsLink = "block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700";
const cardsHeader = "mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white";
const cardsContent = "font-normal text-gray-700 dark:text-gray-400";

// Navbar
const navbarMain = "bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700";
const navbarDropdown = "z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600";
const navbarDropdownUl = "py-2 text-sm text-gray-700 dark:text-gray-400";

export {
  cardsLink,
  cardsHeader,
  cardsContent,
  navbarDropdown,
  navbarDropdownUl,
  navbarMain
};