import * as yup from 'yup';

const registerClaimSchema = yup.object().shape({
  workshopName: yup.string().required('Workshop Name is Mandatory'),
  workshopAddress: yup.string().required('Workshop Address is Mandatory'),
  workshopEstimate: yup.string().required('Workshop Estimate is Mandatory').matches(/^[0-9]*$/, 'Please Enter Valid Amount'),
  claimType: yup.string().required('Claim Type is Mandatory'),
  claimReceiveDate: yup.string().required('Date is Required'),
});

export default registerClaimSchema;