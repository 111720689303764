import axios from 'axios';
import { URL } from '../../../env';
import { setCurrentMonthPolicies, setFetchingData, setInsuranceTypeWiseDetails, setInsurerBranches, setMonthWiseDetails, setRtoWiseDetails, setSalesManagerWiseDetails, setShowDetails, setStateWiseDetails, setTotalPolicies } from './redux.js';

// Get Insurer Branches
const getInsurerBranches = async (dispatch) => {
  const options = {
    method: 'GET',
    url: `${URL}/revenue/insurer-branches`
  };

  try {
    const request = axios.request(options);
    const { data } = (await request);
    dispatch(setInsurerBranches(data));
  } catch (err) {
    dispatch(setInsurerBranches([]));
  }

};

// Get Select Insurer Branch Data
const getSelectedBranchData = async (dispatch, branchName) => {
  const options = {
    method: 'GET',
    url: `${URL}/revenue/insurer-branch/${branchName}`
  };

  try {
    const request = axios.request(options);
    const { data } = await request;

    const {stateData, rtoData, monthWiseData, insurancePolicyTypeData, rmWiseData, totalPoliciesData, currentMonthPoliciesData } = data;

    dispatch(setStateWiseDetails(stateData));
    dispatch(setRtoWiseDetails(rtoData));
    dispatch(setMonthWiseDetails(monthWiseData));
    dispatch(setInsuranceTypeWiseDetails(insurancePolicyTypeData));
    dispatch(setSalesManagerWiseDetails(rmWiseData));
    dispatch(setTotalPolicies(totalPoliciesData[0]?.['COUNT(UID)']));
    dispatch(setCurrentMonthPolicies(currentMonthPoliciesData[0]?.['COUNT(UID)']));
    dispatch(setShowDetails(true))

  } catch (err) {
    throw err;
  }

  dispatch(setFetchingData(false));
};

export {
  getInsurerBranches,
  getSelectedBranchData
};