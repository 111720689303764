import React from 'react';
import { Link } from 'react-router-dom';

const LinkButtonWithIcon = ({ link = '', icon, type, color = "button", textColor = "text-blue-400", onClick = () => {}, disabled = false}) => {
  return (
    <Link
      aria-disabled
      to={link}
      target={type === 'file' ? '_blank' : '_self'}
      className={'px-1 font-bold ' + textColor}
    >
      <button
        onClick={onClick}
        type="button"
        className={`text-xs h-[30px] w-[30px] rounded-full ` + color}
        disabled={disabled}
      >
        {icon}
      </button>
    </Link>
  );
};

export default LinkButtonWithIcon;
