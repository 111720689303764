import { createSlice } from '@reduxjs/toolkit';

export const inspectionDataSlice = createSlice({
  name: 'inspectionData',
  initialState: null,
  reducers: {
    setInspectionData: (state, action) => {
      return state = action.payload;
    }
  }
});

export const { setInspectionData } = inspectionDataSlice.actions;
export default inspectionDataSlice.reducer;