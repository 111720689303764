import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { exportRecordsToExcel } from '../functions';
import dayjs from 'dayjs';

const RegisterCancellationRecord = () => {
  return (
    <Link to="/ops/cancellations/register">
      <button className="text-sm bg-blue-200 flex items-center gap-2 hover:bg-blue-300 rounded-full py-2 px-4">
        <i className="fa-solid fa-plus"></i>
        <span>New</span>
      </button>
    </Link>
  );
};

// Export to XLSX
const GenerateReport = () => {
  const { filteredRecords, records } = useSelector(({ Cancellations }) => {
    return Cancellations;
  });

  return (
    <button
      onClick={() => {
        const reportRecords = filteredRecords.length > 0 ? filteredRecords : records;
        exportRecordsToExcel(Array.from(reportRecords).reverse());
      }}
      className="text-sm bg-green-200 flex items-center gap-2 hover:bg-green-300 rounded-full py-2 px-4"
    >
      <i className="fa-regular fa-file-excel"></i>
      <span>Download</span>
    </button>
  );
};

// Stats
const Stats = ({ records, filteredRecords }) => {
  let pending = 0,
    closed = 0,
    tatCrossed = 0;

  filteredRecords.map((record) => {
    if (['pending', 'pending - business support', 'initiated', 'pending - accounts'].includes(record.status)) pending++;
    else closed++;

    if (record.status === 'pending' && dayjs().diff(dayjs(record.created_at), 'days') > 7) tatCrossed++;
  });

  return (
    <div className="flex gap-2">
      <div className="border-2 border-green-500 px-2 py-1 rounded-lg">
        <p className="font-semibold text-gray-700 py-[1px]">Closed: {closed}</p>
      </div>
      <div className="border-2 border-yellow-500 px-2 py-1 rounded-lg">
        <p className="font-semibold text-gray-700 py-[1px]">Pending: {pending}</p>
      </div>
      <div className="border-2 border-red-500 px-2 py-1 rounded-lg">
        <p className="font-bold text-gray-700 py-[1px]">Late: {tatCrossed}</p>
      </div>
    </div>
  );
};

const FilterDropdown = ({
  id,
  color = 'bg-[#0000FF]',
  value,
  options,
  setter,
  placeholder = '',
  width = 'w-[150px]',
}) => {
  return (
    <div className="relative h-[40px]">
      <button
        className={
          `h-[40px] text-gray-800 ${color} font-semibold rounded-lg text-sm px-5 py-2.5 flex items-center justify-between ` +
          width
        }
        type="button"
        onClick={() => {
          const ele = document.getElementById(id);
          if (ele.style.display === 'none') {
            ele.style.display = 'block';
          } else {
            ele.style.display = 'none';
          }
        }}
      >
        <div className="w-full max-h-[40px] text-left text-ellipsis overflow-hidden text-nowrap">
          {value === '' ? placeholder : value}{' '}
        </div>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        id={id}
        style={{ display: 'none' }}
        className="absolute top-[50px] z-10 bg-white divide-y divide-gray-100 overflow-hidden rounded-lg shadow w-44"
      >
        <ul
          onClick={(e) => {
            setter(e);
            const ele = document.getElementById(id);
            if (ele.style.display === 'none') {
              ele.style.display = 'block';
            } else {
              ele.style.display = 'none';
            }
          }}
          className="text-xs text-gray-700 cursor-pointer"
          aria-labelledby="dropdownDefaultButton"
        >
          {options.map((option, index) => (
            <li key={`li${option}`+index}>
              <p className="block px-4 py-2 hover:bg-gray-100">{option}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const FilterChip = ({ children, onDelete = () => {} }) => {
  return (
    <div onClick={onDelete} className="cursor-pointer flex items-center justify-between text-sm gap-1 py-1 rounded-full bg-gray-200 hover:bg-gray-300 transition-all px-3">
      <p className="text-[12px] text-gray-600">{children}</p>
      <button className="text-[12px]"><i className="fa-solid fa-x"></i></button>
    </div>
  );
};

export { RegisterCancellationRecord, GenerateReport, Stats, FilterDropdown, FilterChip };
