import axios from 'axios';
import { URL } from '../../../../env.js';

const registerEndorsementRecordRequest = async (formData, name) => {
  const options = {
    url: `${URL}/ops/register/endorsement`,
    method: 'POST',
    data: JSON.stringify({ formData, user: name }),
    headers: {
      'content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const { data, status } = (await request);

    const { msg } = data;

    return {msg, status}
  } catch (err) {
    return {
      msg: 'Failed to Register',
      status: 500
    }
  }
};


export {
  registerEndorsementRecordRequest
};