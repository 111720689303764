import axios from 'axios';
import { URL } from '../../../../env.js';
import { postCancellationUpdate } from '../Updates/functions.js';

const approveRequest = async (record, user, calculation) => {
  const path = record.status.toLowerCase().includes('accounts')? 'Accounts': 'BST';
  try {
    postCancellationUpdate(`Approved by ${path}`, record.id, user.name);

    await axios.post(`${URL}/${path.toLowerCase()}/cancellation/approve`, JSON.stringify({ ...record, user, calculation }), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  } catch (err) {
    return false;
  }
};

const rejectRequest = async (record, user) => {
  const path = record.status.toLowerCase().includes('accounts')? 'accounts': 'bst'
    try {
      postCancellationUpdate(`Approved by ${path}`, record.id, user.name);

      await axios.post(`${URL}/${path.toLowerCase()}/cancellation/reject`, JSON.stringify({ ...record, user }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  };

export { approveRequest, rejectRequest };
