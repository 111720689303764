import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealershipDetails: {
    comments: '',
    name: '',
    oem: '',
    mobile: '',
    email: '',
    state: '',
    city: '',
    rto: '',
    address: '',
    type: '',
    masterDealerEmail: '',
    paymentTerms: '',
    paymentTermsToBeUsed: 'Master Dealer',
    paymentBy: 'Master Dealer',
    ownerName: '',
    ownerEmail: '',
    ownerMobile: '',
    gmName: '',
    gmMobile: '',
    gmEmail: '',
    accountsName: '',
    accountsEmail: '',
    accountsMobile: '',
    executiveName: '',
    executiveEmail: '',
    executiveMobile: '',
    firmGstin: '',
    firmGstinFile: '',
    firmPan: '',
    firmPanFile: '',
    firmAccountHolderName: '',
    firmAccountNumber: '',
    firmBankName: '',
    firmIfscCode: '',
    firmChequeFile: '',
    subDealerDocumentUpload: '',
    masterDealerName: '',
    dealerWorking: ''  
  },
  masterDealers: [],
  masterDealersName: [],
  dealerWorkings : [],
  dealersList: [],
  filteredDealersList: null
};

const DealerOnboarding = createSlice({
  name: 'Dealer Onboarding',
  initialState,
  reducers: {
    setFilteredDealersList: (state, action) => {
      state.filteredDealersList = action.payload;
    },
    setDealersList: (state, action) => {
      state.dealersList = action.payload;
    },
    setComments: (state, action) => {
      state.dealershipDetails.comments = action.payload;
    },
    resetStore: (state, action) => {
      return initialState;
    },
    setDealerWorkingsDropdown: (state, action ) => {
      state.dealerWorkings = action.payload;
    },
    setDealerWorking: (state, action) => {
      state.dealershipDetails.dealerWorking = action.payload;
    },
    setMasterDealerName: (state, action) => {
      state.dealershipDetails.masterDealerName = action.payload;
    },
    setMasterDealersList: (state, action) => {
      state.masterDealersName = action.payload;
    },
    setMasterDealers: (state, action) => {
      state.masterDealers = action.payload;
    },
    setDealershipName: (state, action) => {
      state.dealershipDetails.name = action.payload;
    },
    setDealershipOem: (state, action) => {
      state.dealershipDetails.oem = action.payload;
    },
    setDealershipMobile: (state, action) => {
      state.dealershipDetails.mobile = action.payload;
    },
    setDealershipEmail: (state, action) => {
      state.dealershipDetails.email = action.payload;
    },
    setDealershipState: (state, action) => {
      state.dealershipDetails.state = action.payload;
    },
    setDealershipCity: (state, action) => {
      state.dealershipDetails.city = action.payload;
    },
    setDealershipRto: (state, action) => {
      state.dealershipDetails.rto = action.payload;
    },
    setDealershipAddress: (state, action) => {
      state.dealershipDetails.address = action.payload;
    },
    setDealershipType: (state, action) => {
      state.dealershipDetails.type = action.payload;
    },
    setDealershipMasterDealerEmail: (state, action) => {
      state.dealershipDetails.masterDealerEmail = action.payload;
    },
    setPaymentTerms: (state, action) => {
      state.dealershipDetails.paymentTerms = action.payload;
    },
    setPaymentTermsToBeUsed: (state, action) => {
      state.dealershipDetails.paymentTermsToBeUsed = action.payload
    },
    setPaymentBy: (state, action) => {
      state.dealershipDetails.paymentBy = action.payload;
    },
    setOwnerName: (state, action) => {
      state.dealershipDetails.ownerName = action.payload;
    },
    setOwnerEmail: (state, action) => {
      state.dealershipDetails.ownerEmail = action.payload;
    },
    setOwnerMobile: (state, action) => {
      state.dealershipDetails.ownerMobile = action.payload;
    },
    setAccountsName: (state, action) => {
      state.dealershipDetails.accountsName = action.payload;
    },
    setAccountsEmail: (state, action) => {
      state.dealershipDetails.accountsEmail = action.payload;
    },
    setAccountsMobile: (state, action) => {
      state.dealershipDetails.accountsMobile = action.payload;
    },
    setGMName: (state, action) => {
      state.dealershipDetails.gmName = action.payload;
    },
    setGMEmail: (state, action) => {
      state.dealershipDetails.gmEmail = action.payload;
    },
    setGMMobile: (state, action) => {
      state.dealershipDetails.gmMobile = action.payload;
    },
    setExecutiveName: (state, action) => {
      state.dealershipDetails.executiveName = action.payload;
    },
    setExecutiveEmail: (state, action) => {
      state.dealershipDetails.executiveEmail = action.payload;
    },
    setExecutiveMobile: (state, action) => {
      state.dealershipDetails.executiveMobile = action.payload;
    },
    setFirmGstin: (state, action) => {
      state.dealershipDetails.firmGstin = action.payload;
    },
    setFirmGstinFile: (state, action) => {
      state.dealershipDetails.firmGstinFile = action.payload;
    },
    setFirmPan: (state, action) => {
      state.dealershipDetails.firmPan = action.payload;
    },
    setFirmPanFile: (state, action) => {
      state.dealershipDetails.firmPanFile = action.payload;
    },
    setFirmAccountHolderName: (state, action) => {
      state.dealershipDetails.firmAccountHolderName = action.payload;
    },
    setFirmBankName: (state, action) => {
      state.dealershipDetails.firmBankName = action.payload;
    },
    setFirmIfscCode: (state, action) => {
      state.dealershipDetails.firmIfscCode = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.dealershipDetails.firmAccountNumber = action.payload;
    },
    setChequeFile: (state, action) => {
      state.dealershipDetails.firmChequeFile = action.payload;
    },
    setSubDealerDocumentUpload: (state, action) => {
      state.dealershipDetails.subDealerDocumentUpload = action.payload;
    }
  }
});

export const { setFilteredDealersList, resetStore, setDealerWorkingsDropdown, setDealerWorking, setStepperCounter, setDealershipName, setDealershipOem, setDealershipMobile, setDealershipEmail, setDealershipState, setDealershipCity, setDealershipRto, setDealershipAddress, setDealershipType, setDealershipMasterDealerEmail, setPaymentTerms, setPaymentTermsToBeUsed, setPaymentBy, setOwnerName, setOwnerEmail, setOwnerMobile, setAccountsName, setAccountsEmail, setAccountsMobile, setGMEmail, setGMName, setGMMobile, setExecutiveName, setExecutiveMobile, setExecutiveEmail, setFirmGstin, setFirmGstinFile, setFirmPan, setFirmPanFile, setFirmAccountHolderName, setFirmBankName, setFirmIfscCode, setAccountNumber, setChequeFile, setSubDealerDocumentUpload, setMasterDealers, setMasterDealerName, setMasterDealersList, setComments, setDealersList } = DealerOnboarding.actions;

export default DealerOnboarding.reducer;