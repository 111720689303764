import { createSlice } from '@reduxjs/toolkit';
import { getLabelsAndPoliciesCount } from './functions';

const initialState = {
  monthlyData: {
    data: [],
    labels: [],
    count: []
  },
  dealerWiseData: {
    data: [],
    labels: [],
    count: []
  },
  bodyTypeData: {
    data: [],
    labels: [],
    count: []
  },
  insuranceTypeWiseData: {
    data: [],
    labels: [],
    count: []
  },
  dealers: [],
  fetchingDealerData: false,
  showDealerData: false,
  dealerData: {
    bodyType: {
      labels: [],
      count: []
    },
    insuranceType: {
      labels: [],
      count: []
    },
    monthly: {
      labels: [],
      count: []
    }
  },
  insurerWiseData: [],
  totalDealersOnBoarded: '',
  totalActiveDealerMonth: '',
  totalPoliciesCount: '',
  totalCurrentMonthPolicies: ''
};

export const RmStats = createSlice({
  name: 'rmstats',
  initialState,
  reducers: {
    setDealers: (state, action) => {
      state.dealers = action.payload;
    },
    setFetchingDealerData: (state, action) => {
      state.fetchingDealerData = action.payload;
    },
    setDealerBodyTypeData: (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Body Type', 'COUNT(UID)');
      state.dealerData.bodyType.labels = labels;
      state.dealerData.bodyType.count = policiesCount;
    },
    setDealerInsuranceTypeData: (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Insurance Type', 'COUNT(UID)');
      state.dealerData.insuranceType.labels = labels;
      state.dealerData.insuranceType.count = policiesCount;
    },
    setDealerMonthlyData: (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Month', 'COUNT(UID)');
      state.dealerData.monthly.labels = labels;
      state.dealerData.monthly.count = policiesCount;
    },
    setShowDealerData: (state, action) => {
      state.showDealerData = action.payload;
    },
    setMonthlyData: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Month', 'COUNT(UID)');
      state.monthlyData.data = action.payload;
      state.monthlyData.labels = labels;
      state.monthlyData.count = policiesCount;
    },
    setDealerWiseData: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Dealer Name', 'COUNT(UID)');

      state.dealerWiseData.data = action.payload;
      state.dealerWiseData.labels = labels;
      state.dealerWiseData.count = policiesCount;
    },
    setBodyTypeWiseData: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Body Type', 'COUNT(UID)')

      state.bodyTypeData.data = action.payload;
      state.bodyTypeData.labels = labels;
      state.bodyTypeData.count = policiesCount;
    },
    setInsuranceTypeWiseData: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Insurance Type', 'COUNT(UID)')

      state.insuranceTypeWiseData.data = action.payload;
      state.insuranceTypeWiseData.labels = labels;
      state.insuranceTypeWiseData.count = policiesCount;
    },
    setInsurerWiseData: (state, action) => {
      state.insurerWiseData = action.payload;
    },
    setTotalOnBoardedDealers: (state, action) => {
      state.totalDealersOnBoarded = action.payload;
    },
    setTotalActiveDealersMonth: (state, action) => {
      state.totalActiveDealerMonth = action.payload;
    },
    setTotalPoliciesCount: (state, action) => {
      state.totalPoliciesCount = action.payload;
    },
    setTotalCurrentMonthPolicies: (state, action) => {
      state.totalCurrentMonthPolicies = action.payload;
    }
  }
});

export const { setFetchingDealerData, setDealerBodyTypeData, setShowDealerData, setDealerInsuranceTypeData, setDealerMonthlyData, setMonthlyData, setDealerWiseData, setBodyTypeWiseData, setInsurerWiseData, setTotalOnBoardedDealers, setTotalActiveDealersMonth, setInsuranceTypeWiseData, setTotalPoliciesCount, setTotalCurrentMonthPolicies, setDealers } = RmStats.actions;

export default RmStats.reducer;