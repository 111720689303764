import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';

import { forms, statusColor } from '../constants.js';
// import { checkAuthentication } from '../Shared/functions.jsx';
import { getPortalStatus, checkAuthentication } from '../functions.js';
import { setUser } from '../Pages/Login/redux.js';
import logo from '../static/navbar-logo.png';

import '../App.css';

const Navbar = () => {
  // Hide / Display Navbar dropdown
  const [navbarDropdownVisibility, setNavbarDropdownVisibility] = useState(true);
  const [portalStatus, setPortalStatus] = useState('');

  const loggedInUser = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = window.location.pathname;
  const navbarlist = useSelector(({loggedInUser}) => {
    return loggedInUser.paths.navbarList;
  })

  // Fetch Portal Status Polling
  let lastFetchStatusCall = 0,
    requestId;
  const getStatus = async (now) => {
    if (!lastFetchStatusCall || now - lastFetchStatusCall >= 20 * 1000) {
      lastFetchStatusCall = now;
      const status = await getPortalStatus();
      setPortalStatus(status);
    }

    requestId = requestAnimationFrame(getStatus);
  };

  useLayoutEffect(() => {
    (async () => {
      const res = await checkAuthentication(dispatch, navigate, path);
      if (res) {
        dispatch(setUser(res));
        getStatus();
      }
    })();

    return () => cancelAnimationFrame(requestId);
  }, []);

  return (
    <nav onMouseLeave={() => {
      setNavbarDropdownVisibility((value) => {
        if (value !== true) {
          return !value;
        }
        return value;
      });
    }} className="bg-gray-800 border-gray-700">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex gap-10 items-center justify-center">
          <Link to="/aegiscovenant/opsit" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={logo} className="h-8" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
              OPSit
            </span>
          </Link>
          {loggedInUser.email && (
            <div
              className={
                portalStatus === ''
                  ? 'hidden '
                  : 'block ' +
                  statusColor[portalStatus] +
                  ' flex items-center outline p-2 rounded-md text-left '
              }
            >
              Portal Status: {portalStatus}
            </div>
          )}
        </div>

        {loggedInUser.email && (
          <div className="flex items-center justify-center gap-4">
            <Link to="/profile">
              <button>
                <svg className="w-[48px] h-[48px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M12 20a8 8 0 0 1-5-1.8v-.6c0-1.8 1.5-3.3 3.3-3.3h3.4c1.8 0 3.3 1.5 3.3 3.3v.6a8 8 0 0 1-5 1.8ZM2 12a10 10 0 1 1 10 10A10 10 0 0 1 2 12Zm10-5a3.3 3.3 0 0 0-3.3 3.3c0 1.7 1.5 3.2 3.3 3.2 1.8 0 3.3-1.5 3.3-3.3C15.3 8.6 13.8 7 12 7Z" clipRule="evenodd" />
                </svg>
              </button>
            </Link>
            <button
              data-collapse-toggle="navbar-hamburger"
              onClick={() => {
                setNavbarDropdownVisibility((value) => !value);
              }}
              type="button"
              className="outline inline-flex items-center justify-center p-2 w-10 h-10 text-sm rounded-lg focus:outline-none focus:ring-2  text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
              aria-controls="navbar-hamburger"
              aria-expanded="false"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  storelinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        )}
        <div hidden={navbarDropdownVisibility} className="w-full" id="navbar-hamburger">
          <ul className="flex flex-col font-medium mt-4 rounded-lg bg-gray-800 border-gray-700">
            { navbarlist && navbarlist.map((form, id) => {
                return (
                  <li key={id}>
                    <Link
                      className="block py-2 px-3 rounded text-gray-400 md:hover:text-white hover:bg-gray-700"
                      onClick={() => {
                        setNavbarDropdownVisibility(false);
                      }}
                      to={form.url}
                    >
                      {form.name}
                    </Link>
                  </li>
                );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
