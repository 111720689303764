import { createSlice } from '@reduxjs/toolkit';

export const dealerDiscountSlice = createSlice({
  name: 'dealerDiscount',
  initialState: null,
  reducers: {
    setDealerInsuranceDiscount: (state, action) => {
      return state = action.payload;
    }
  }
});

export const { setDealerInsuranceDiscount } = dealerDiscountSlice.actions;

export default dealerDiscountSlice.reducer;