import axios from 'axios';
import { URL } from '../../../env.js';

const sendDealerOnboardingRequest = async (dealershipDetails, salesManager, requestId) => {
  const options = {
    method: 'POST',
    url: `${URL}/dealer/onboard/request/update`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealershipDetails, salesManager, requestId })
  };

  try {
    const request = axios.request(options);
    const response = (await request).data;
    return { msg: 'Request Sent Succesfully', type: 'success' };
  } catch (err) {
    return { msg: 'Failed to Complete Request', type: 'failed' }
  }
};

export {
  sendDealerOnboardingRequest
};