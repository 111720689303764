import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';

import { fetchMakes } from './functions.js';
import Heading from '../../../../Shared/Heading.jsx';

const StandardizeDB = () => {
  const [makes, setMakes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchMakes(setMakes, setLoading);
  }, []);

  return loading ? (
    <div className="h-screen flex mt-10 justify-center">
      <InfinitySpin visible={true} width="200" color="#0d55d1" ariaLabel="infinity-spin-loading" />
    </div>
  ) : (
    <div className="flex flex-col m-10 justify-center items-center">
        <Heading title="Standard DB"/>
      <table className="w-max text-sm text-left">
        <thead className="text-center text-lg text-black uppercase bg-blue-400">
          <tr>
            {Object.keys(makes[0]).map((insurer) => (
              <th key={insurer} scope="col" className="px-6 py-3 uppercase border-2 border-blue-800">
                {insurer}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center text-black uppercase bg-blue-100">
          {makes.map((make) => (
            <tr key={`manufacturer${make} text-clip`}>
              {Object.keys(make).map((key) => (
                <td key={make[key]} onClick={() => {
                    navigator.clipboard.writeText(make[key]);
                }} className="h-[50px] w-[100px] cursor-pointer border-2 border-blue-700 p-2">{make[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StandardizeDB;
