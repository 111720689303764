import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  records: [],
  filteredRecords: [],
  count: {
    pending: 0,
    closed: 0
  }
};

export const Cancellations = createSlice({
  name: 'Cancellations',
  initialState,
  reducers: {
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setFilteredRecords: (state, action) => {
      state.filteredRecords = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    }
  }
});

export const { setRecords, setFilteredRecords, setCount } = Cancellations.actions;
export default Cancellations.reducer;