
const getLabelsAndPoliciesCount = (data, labelId, countId) => {
  const labels = data.map((monthCount) => {
    return monthCount[labelId];
  });
  
  const policiesCount = data.map((monthCount) => {
    return monthCount[countId];
  });

  return {
    labels, policiesCount
  };
};

export {
  getLabelsAndPoliciesCount
};