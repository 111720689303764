const amountLocationKeyToLabel = {
    'customer': 'Customer',
    'cd': 'CD Wallet',
    'aegiscovenant': 'AegisCovenant'
}

const recordDetails = [
    { label: 'Insurance Company', key: 'insurance_company' },
    { label: 'Policy Number', key: 'policy_number' },
    { label: 'Cancellation Reason', key: 'cancellation_reason' },
    { label: 'Cancellation Type', key: 'cancellation_type' },
    { label: 'Alternate Policy No.', key: 'alternate_policy_number' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Dealer Name', key: 'dealer_name' },
]

export { amountLocationKeyToLabel, recordDetails }