import dayjs from 'dayjs';
import { setFilterActive, setFilteredRecords } from '../redux';
import * as xlsx from 'xlsx';

const filterRecordsView = (input, filter, records, dispatch) => {

  const filteredRecords = records.filter((record) => {
    return (record[filter].toLowerCase()).includes(input.trim().toLowerCase());
  });

  if (filteredRecords.length > 0) { dispatch(setFilterActive(true)) };
  dispatch(setFilteredRecords(filteredRecords));

};

const exportRecordsToExcel = (records) => {
  const fileHeaders = ['ACPL TICKET ID', 'DEALER NAME', 'ZONE', 'SALES MANAGER', 'STATE', 'CUSTOMER NAME', 'POLICY NUMBER', 'INSURANCE COMPANY', 'INSURER BRANCH', 'ENDORSEMENT REASON', 'COMMENTS', 'STATUS', 'CREATED AT', 'CLOSED AT'];
  const exportData = [[...fileHeaders]];
  records = Array.from(records).reverse();

  Object.keys(records).map((record, index) => {
    const insuranceCompanyName = records[record].insurance_company === 'AegisCovenant' ? records[record].insurance_company : records[record].insurance_company.split('-')[0].trim();
    const insuranceCompanyBranch = records[record].insurance_company === 'AegisCovenant' ? records[record].insurance_company : records[record].insurance_company.split('-')[1].trim();

    const exportRecord = [`ACPL-EN-2024-${records[record].id}`, records[record].dealer_name, '', records[record].sales_manager, records[record].state, records[record].customer_name, records[record].policy_number, insuranceCompanyName, insuranceCompanyBranch, records[record].endorsement_reason, records[record].additional_info, records[record].status, dayjs(records[record].created_at).format('DD-MM-YYYY HH:mm:ss'), records[record].status === 'closed' ? dayjs(records[record].updated_at).format('DD-MM-YYYY HH:mm:ss') : ''];

    exportData.push(exportRecord);
  });

  const wb = xlsx.utils.book_new();
  const endorsementsData = xlsx.utils.aoa_to_sheet(exportData);
  xlsx.utils.book_append_sheet(wb, endorsementsData, `Endorsement Logs`);

  const fileName = `Endorsement-Record-Logs.xlsx`;
  xlsx.writeFile(wb, fileName);
};

export {
  filterRecordsView,
  exportRecordsToExcel
};