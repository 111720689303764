import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import schema from './schema.jsx';
import { auth, login } from './functions.jsx';
import { Alert, Label } from './components.jsx';
import { setAllowedPaths, setUser } from './redux.js';
import InputError from '../../Shared/InputError.jsx';
import { ACCOUNTS_PATHS, ADMIN_PATHS, BPO_PATHS, BUSINESS_SUPPORT_PATHS, OPS_HO_PATHS, OPS_NON_HO_PATHS, SALES_MANAGER_PATHS, CLAIMS_PATHS, DEV_PATHS } from '../../constants.js';

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // Incorrect credentials
  const [loginFail, setLoginFail] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathName } = useLocation();

  // User already logged in or not
  useEffect(() => {
    auth(navigate, dispatch, pathName);
  }, []);

  const onSubmit = async (formData) => {
    const data = await login(formData.email.toLowerCase(), formData.password);

    if (data.statusCode === 200) {
      dispatch(setUser(data.user));

      if (data.user.dept === 'ADMIN') {
        dispatch(setAllowedPaths(ADMIN_PATHS));
        navigate(ADMIN_PATHS.redirectTo);
      } else if (data.user.dept === 'OPS') {
        dispatch(setAllowedPaths(data.user.team !== 'HO' ? OPS_NON_HO_PATHS : OPS_HO_PATHS));
        navigate(data.user.team === 'HO' ? OPS_HO_PATHS.redirectTo : OPS_NON_HO_PATHS.redirectTo);
      } else if (data.user.dept === 'ACCOUNTS') {
        dispatch(setAllowedPaths(ACCOUNTS_PATHS));
        navigate(ACCOUNTS_PATHS.redirectTo);
      } else if (data.user.dept === 'BPO') {
        dispatch(setAllowedPaths(BPO_PATHS));
        navigate(BPO_PATHS.redirectTo);
      } else if (data.user.dept === 'SALES') {
        dispatch(setAllowedPaths(SALES_MANAGER_PATHS));
        navigate(SALES_MANAGER_PATHS.redirectTo);
      } else if (data.user.dept === 'BUSINESS SUPPORT') {
        dispatch(setAllowedPaths(BUSINESS_SUPPORT_PATHS));
        navigate(BUSINESS_SUPPORT_PATHS.redirectTo);
      } else if (data.user.dept === 'CLAIMS') {
        dispatch(setAllowedPaths(CLAIMS_PATHS));
        navigate(CLAIMS_PATHS.redirectTo);
      } else if (data.user.dept === 'DEV') {
        dispatch(setAllowedPaths(DEV_PATHS));
        navigate(DEV_PATHS.redirectTo);
      }

    } else {
      setLoginFail(true);

      // Make the Alert message disappear
      setTimeout(() => {
        setLoginFail(false);
      }, 5000);
    }
  };

  return (
    <div className="max-w-sm m-10">
      <form noValidate className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        {/* Email Input */}
        <div className="mb-5">
          <Label for="email">Your email</Label>
          <input {...register('email')} type="email" className="input bg-gray-600 text-white rounded-lg" placeholder="name@aegiscovenant.com" />
          {errors.email && <InputError error={errors.email} />}
        </div>
        {/* Password Input */}
        <div className="mb-2">
          <Label for="password">Your password</Label>
          <input {...register('password')} type="password" className="input bg-gray-600 text-white rounded-lg" placeholder="xxxxxx" />
          {errors.password && <InputError error={errors.password} />}
        </div>
        {/* Create Account? */}
        {/* <Link to="/aegiscovenant/opsit/signup" className="text-blue-700 text-xs">
          Create Account?
        </Link> */}
        {/* Submit Button */}
        <button className="button text-sm mt-5" type="submit">
          Submit
        </button>
      </form>
      {/* Invalid Login Error */}
      {loginFail && <Alert title="Username or Password Incorrect" />}
    </div>
  );
};

export default LoginForm;
