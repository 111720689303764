import axios from 'axios';
import { URL } from '../../../../env.js';
import { setDealerCreationRequests } from '../../redux.js';

// Get Dealer Creation Requests
const getDealerCreationRequests = async (dispatch) => {
  const data = (await axios.get(`${URL}/bst/dealer-creation-requests`)).data;
  dispatch(setDealerCreationRequests(data));
};

// Approve Dealer Creation
const approvalDealerCreationRequest = async (id, status, dispatch, record, comments) => {

  // Update in Backend
  const options = {
    url: `${URL}/bst/create-dealer`,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ id, status, record, comments})
  };

  try {
    const request = axios.request(options);
    
    setTimeout(async () => {
      await getDealerCreationRequests(dispatch);
    }, 2000);
    
    const { status, data } = (await request);

    const { msg } = data;

    return {
      status, msg
    };
  } catch (err) {
    return {
      status: 500,
      msg: 'Unable to Complete Request'
    }
  }

};

// Pagination
const updatePagnitor = async (type, setPaginator) => {
  if (type === 'prev') {
    setPaginator((currentValue) => {
      return currentValue - 10;
    })
  } else {
    setPaginator((currentValue) => {
      return currentValue + 10    })
  }
};



export {
  getDealerCreationRequests,
  approvalDealerCreationRequest,
  updatePagnitor
};