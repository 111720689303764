import React, { useEffect, useState } from 'react';
import { checkAuthentication, getDealerConfig } from '../functions.js';
import { useNavigate } from 'react-router';
import DealerCards from './DealerCards.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../Store/features/dealersSlice.js';
import "../App.css";


const Dealers = () => {

  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const [searchDealer, setSearchDealer] = useState('');
  const data = useSelector(state => {
    return state.dealersData;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = checkAuthentication();
    if (!auth) navigate('/aegiscovenant/opsit/login');
  }, []);

  return (
    <div>
      <button
      hidden={data.length ? true : false}
        type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 m-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        disabled={refreshDisabled} onClick={async () => {
          const data = await getDealerConfig();
          dispatch(setData({ data }));
          setTimeout(() => {
            setRefreshDisabled(false);
          }, 15000);
        }}>Get Dealers Data</button>

      {
        data.length <= 0 ?
          null
          : 
          <div className="max-w-md mx-auto mt-5">   
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
              <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                      </svg>
                  </div>
                  <input type="search" onInput={(e) => {
                    setSearchDealer(e.target.value);
                  }} id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Dealer Name" required />
              </div>
          </div>
          
      }
      {data.length > 0 && !searchDealer &&
        <div className='cards grid grid-cols-3'>
          {
            data.map((dealer, id) => {
              return (
                <DealerCards key={id} data={dealer} />
              );
            })
          }
        </div>
      }

      {
        data.length > 0 && searchDealer && 
        <div className='cards grid grid-cols-3'>
        {
          data.map((dealer, id) => {
            const key = Object.keys(dealer);
            if (dealer[key].basicDetails['Dealer Name'].toLowerCase().includes(searchDealer.toLowerCase())) {
              return <DealerCards key={id} data={dealer} />
            }
          })
        }
        </div>
      }
    </div>
  )
}

export default Dealers;
