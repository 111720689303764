import dayjs from "dayjs";
import { useSelector } from "react-redux";

const BasicDetails = () => {
  const { viewDetailsCard } = useSelector(({ Claims }) => {
    return Claims;
  });

  return (
    <>
      {viewDetailsCard && (
        <div className="grid grid-cols-2 m-2 gap-[6px]">
          <div id="customerDetails" className="bg-gray-100 p-2 rounded-lg">
            <h1 className="underline text-[25px] p-2 font-bold">
              Customer Details
            </h1>
            <div className="details">
              <li>
                Customer Name :{" "}
                <Span text={viewDetailsCard?.[0]?.customerDetails?.name} />
              </li>
              <li>
                Customer Mobile :{" "}
                <Span text={viewDetailsCard?.[0]?.customerDetails?.mobile} />
              </li>
              <li>
                Customer RTO :{" "}
                <Span text={viewDetailsCard?.[0]?.customerDetails?.rto} />
              </li>
              <li>
                Customer City :{" "}
                <Span text={viewDetailsCard?.[0]?.customerDetails?.city} />
              </li>
              <li>
                Customer State :{" "}
                <Span text={viewDetailsCard?.[0]?.customerDetails?.state} />
              </li>
            </div>
          </div>
          <div id="claimDetails" className="bg-gray-100 p-2 rounded-lg">
            <h1 className="underline text-[25px] p-2 font-bold">
              Claim Details
            </h1>
            <div className="details">
              <li>
                Type :{" "}
                <Span text={viewDetailsCard?.[0]?.claimDetails?.claimType} />
              </li>
              <li>
                Registration Date :{" "}
                <Span text={viewDetailsCard?.[0]?.claimDetails?.registerDate} />
              </li>
              <li>
                Workshop Name :{" "}
                <Span text={viewDetailsCard?.[0]?.claimDetails?.workshopName} />
              </li>
              <li>
                Workshop Address :{" "}
                <Span
                  text={viewDetailsCard?.[0]?.claimDetails?.workshopAddress}
                />
              </li>
              <li>
                Workshop Estimate :{" "}
                <Span
                  text={viewDetailsCard?.[0]?.claimDetails?.workshopEstimate}
                />
              </li>
              {viewDetailsCard?.[0]?.claimDetails?.insuranceClaimNumber && (
                <li>
                  Insurance Claim Number :{" "}
                  <Span
                    text={
                      viewDetailsCard?.[0]?.claimDetails?.insuranceClaimNumber
                    }
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.surveyorName && (
                <li>
                  Surveyor Name :{" "}
                  <Span
                    text={viewDetailsCard?.[0]?.claimDetails?.surveyorName}
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.surveyorMobile && (
                <li>
                  Surveyor Mobile :{" "}
                  <Span
                    text={viewDetailsCard?.[0]?.claimDetails?.surveyorMobile}
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.surveyorDescription && (
                <li>
                  Surveyor Description :{" "}
                  <Span
                    text={
                      viewDetailsCard?.[0]?.claimDetails?.surveyorDescription
                    }
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.surveyorAppointmentDate && (
                <li>
                  Surveyor Appointment Date :{" "}
                  <Span
                    text={
                      viewDetailsCard?.[0]?.claimDetails
                        ?.surveyorAppointmentDate
                    }
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.transactionUTR && (
                <li>
                  Transaction UTR :{" "}
                  <Span
                    text={
                      viewDetailsCard?.[0]?.claimDetails
                        ?.transactionUTR
                    }
                  />
                </li>
              )}
              {viewDetailsCard?.[0]?.claimDetails?.claimAmount && (
                <li>
                  Claim Amount Disbursed :{" "}
                  <Span
                    text={viewDetailsCard?.[0]?.claimDetails?.claimAmount}
                  />
                </li>
              )}

              {viewDetailsCard?.[0]?.claimDetails?.finalClosingComments && (
                <li>
                  Final Closing Comments :{" "}
                  <Span
                    text={
                      viewDetailsCard?.[0]?.claimDetails?.finalClosingComments
                    }
                  />
                </li>
              )}
            </div>
          </div>
          <div id="vehicleDetails" className="bg-gray-100 p-2 rounded-lg">
            <h1 className="underline text-[25px] p-2 font-bold">
              Vehicle Details
            </h1>
            <div className="details">
              <li>
                Vehicle Name :{" "}
                <Span text={viewDetailsCard?.[0]?.vehicleDetails?.name} />
              </li>
              <li>
                Vehicle Registration Number :{" "}
                <Span text={viewDetailsCard?.[0]?.vehicleDetails?.vehicleRegistrationNumber ? viewDetailsCard?.[0]?.vehicleDetails?.vehicleRegistrationNumber : "NEW"} />
              </li>
            </div>
          </div>
          <div id="policyDetails" className="bg-gray-100 p-2 rounded-lg">
            <h1 className="underline text-[25px] p-2 font-bold">
              Insurance Policy Details
            </h1>
            <div className="details">
              <li>
                Insurer Name :{" "}
                <Span text={viewDetailsCard?.[0]?.policyDetails?.insurer} />
              </li>
              <li>
                Branch Name :{" "}
                <Span text={viewDetailsCard?.[0]?.policyDetails?.branch} />
              </li>
            </div>
            <div className="details"></div>
          </div>
        </div>
      )}
    </>
  );
};

const Timeline = () => {
  const { viewDetailsCard } = useSelector(({ Claims }) => {
    return Claims;
  });

  return (
    <>
      <h1 className="m-10 text-[30px] underline">Updates Timeline</h1>
      <ol className="relative m-10 border-s border-gray-700">
        {viewDetailsCard?.[0]?.updates &&
          viewDetailsCard?.[0]?.updates.map((update, index) => {
            return (
              <li className="mb-10 ms-4">
                <div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700"></div>
                <h3 className="text-lg font-semibold text-black">
                  Update #{index + 1}
                </h3>
                <p className="mb-1 font-semibold text-md text-gray-500">
                  {update?.comment ? update?.comment.replaceAll("~", "\n").toUpperCase(): ""}
                </p>
                <h3 className="text-sm font-normal text-gray-900">
                  Updated By : {update?.created_by.toUpperCase()}
                </h3>
                <time className="mb-4 text-sm text-black font-semibold">
                  {dayjs
                    .unix(update?.created_at)
                    .format("MMMM D, YYYY hh:mm A")}
                </time>
              </li>
            );
          })}
      </ol>
    </>
  );
};

const Span = ({ text }) => {
  return <span className="text-sm font-bold">{text}</span>;
};

export { BasicDetails, Timeline };
