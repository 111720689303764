import React, { useState } from 'react';
import Heading from '../../../Shared/Heading.jsx'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { InputError, Required } from '../Register Claim Report/components.jsx';
import rejectClaimSchema from './rejectClaimSchema.js';
import { URL } from '../../../env.js';
import axios from 'axios';

const RejectClaim = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestMessage, setRequestMessage] = useState();

  const { claimid, type } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(rejectClaimSchema) });

  // Reject Claim
  const rejectClaim = async (formDetails) => {
    const options = {
      url: `${URL}/claims/close-ticket`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify({ formDetails, claimid, type})
    };

    const request = axios.request(options);
    const { status, data } = (await request);

    const { message } = data;
    setRequestMessage(message);

    if (status === 200) {
      setTimeout(() => {
        navigate('/claims/view');
      }, 3000);
    };

  };

  return (
    <div>
      <Heading title={`${type === 'approve' ? 'Approve' : 'Reject'} Claim #${claimid}`} />

      {/* Message TopUp */}
      {
        requestMessage &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          {
            requestMessage === "Rejected Succesfully." || requestMessage === "Approved Successfully." ?
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
              </div> :
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                </svg>
                <span className="sr-only">Warning icon</span>
              </div>
          }
          <div className="ms-3 text-sm font-normal">{requestMessage}</div>
        </div>
      }
      {
        (requestMessage !== "Rejected Succesfully." || requestMessage !== "Approved Successfully.") &&
        <form noValidate onSubmit={handleSubmit(rejectClaim)}>
          <div className='p-2 w-1/2'>
            <label htmlFor="customerName" className="block mb-2 text-sm font-bold text-gray-900">Final Comments <Required /> </label>
            <textarea {...register('comments')} id="message" rows="2" className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Any Comments..."></textarea>
            {errors.comments && <InputError error={errors.comments} />}
          </div>
          <button type="submit" className={`text-white ml-2 bg-${type === 'approve' ? 'green': 'red'}-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}> {type === 'approve' ? <i className="fa-solid fa-thumbs-up mr-2"></i>: <i className="fa-solid fa-trash mr-2"></i>  }  {type === 'approve' ? 'Approve' : 'Reject'} Claim Request</button>
        </form>
      }
    </div>
  )
}

export default RejectClaim
