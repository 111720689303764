import { convertInputButton } from './functions';

// Upload File View
const UploadFile = ({ setFile, setFileData }) => {
    return (
      <div className="file-uploader text-center border shadow-lg py-20 rounded-xl mt-3 active:scale-75 duration-300 active:shadow-sm cursor-pointer" onClick={() => convertInputButton(setFile, setFileData)}>
        <h1><i class="fa-solid fa-circle-plus text-[40px] text-gray-400"></i></h1>
        <p className='mt-4 text-[20px]'>Click to choose file ( CSV Only )</p>
        <p className='mt-2 text-[14px]'><i class="fa-solid fa-circle-info text-gray-400 mr-1"></i> Max File Size : 10 MB</p>
      </div>
    )
  };
  
  // File Uploaded
  const FileUploaded = ({ file, setFile, setFileData, setRecordsFetched, setConvertedRecords }) => {
    const fileSize = (file.size / 1000000);
    const fileTypeExtension = file.type.split('/')[1];
  
    return (
      <div className='flex w-3/5 place-content-between border p-2 rounded-lg shadow-sm'>
        <div className="icon text-[30px]"><i className="fa-solid fa-paperclip text-red-500"></i></div>
        <div className="file-details">
          <p>Bulk Upload Claims</p>
          <div className='flex'>
            <p>.{fileTypeExtension}</p>
            <p className='mx-3'>|</p>
            <p>{fileSize.toFixed(2)} MB</p>
          </div>
        </div>
        <div className="actionables">
          <button onClick={() => {
            setTimeout(() => {
              setFile(null);
              setFileData(null);
              setRecordsFetched(false);
              setConvertedRecords([]);
            }, 300);
          }} className='p-2 rounded-lg shadow-md active:scale-50 duration-500 text-[20px] text-red-500 border'><i class="fa-solid fa-trash"></i></button>
        </div>
  
      </div>
    )
  };


  export {
    UploadFile,
    FileUploaded
  };