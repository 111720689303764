import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Chart, Doughnut, Line, Pie } from 'react-chartjs-2';
import Heading from '../../Shared/Heading.jsx';
import { useLayoutEffect, useState } from 'react';
import { checkAuthentication } from '../../functions';
import { useDispatch, useSelector } from 'react-redux';
import "../../App.css";
import { getRmActiveDealers, rmActiveInsuranceDealers, rmActiveInsuranceDealersMonthly, rmDealerMonthlyCount, rmMonthlyCount, rmPoliciesCountBodyType, rmPoliciesCountInsuranceType, setSelectedDealerData, totalCurrentMonthPolicies, totalPoliciesCount } from './functions.js';
import { useLocation, useNavigate } from 'react-router';
import { setFetchingDealerData, setShowDealerData } from './redux.js';
import AuthCheck from '../../AuthCheck.jsx';

const RmStats = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rmName, setRmName] = useState();

  const RM_STATS = useSelector(({ RmStats }) => {
    return RmStats;
  });

  useLayoutEffect(() => {
    (async () => {
      const path = window.location.pathname;
      const { name } = await checkAuthentication(dispatch, navigate, path);

      // Set RM Name
      setRmName(name);

      await Promise.all([getRmActiveDealers(name, dispatch), rmMonthlyCount(name, dispatch), rmDealerMonthlyCount(name, dispatch), rmActiveInsuranceDealers(name, dispatch), rmActiveInsuranceDealersMonthly(name, dispatch), rmPoliciesCountBodyType(name, dispatch), rmPoliciesCountInsuranceType(name, dispatch), totalPoliciesCount(name, dispatch), totalCurrentMonthPolicies(name, dispatch)]);
    })();
  }, []);

  const { pathname } = useLocation();

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname}/>

      {/* Count */}
      <div className='grid grid-cols-2 mx-auto w-fit'>
        <div className="totalCount">

          <span className="m-3 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Overall Policies Count</h5>
            <p className="font-bold text-gray-700 dark:text-gray-400">{RM_STATS.totalPoliciesCount}</p>
          </span>

        </div>
        <div className="totalMonthCount">

          <span className="m-3 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

            <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Monthly Count</h5>
            <p className="font-bold text-gray-700 dark:text-gray-400">{RM_STATS.totalCurrentMonthPolicies}</p>
          </span>

        </div>
        {/* <div className="totalCountToday">

          <span className="m-3 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

            <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Today's Count</h5>
            <p className="font-bold text-gray-700 dark:text-gray-400">19</p>
          </span>

        </div> */}
      </div>

      {/* Policies Count */}
      {
        RM_STATS.monthlyData.count.length > 0 && <div className='totalPoliciesMonthly' >
          <Heading title={'Total Insurance Policies'} />
          <div className='mx-auto mt-10 max-w-[800px]'>
            <Bar
              options={{
                plugins: {
                  colors: {
                    forceOverride: true
                  }
                }
              }}
              data={{
                labels: RM_STATS.monthlyData.labels,
                datasets: [
                  {
                    label: "Policies Count",
                    data: RM_STATS.monthlyData.count
                  }
                ]
              }}
            />
          </div>
        </div>
      }

      {/* Categorized Data */}
      {
        RM_STATS.insuranceTypeWiseData.count.length > 0 &&
        <div className='grid grid-cols-2 m-10'>
          {/* Insurance Wise Count  */}
          <div className=''>
            <Heading title={'Insurance Policy Type Wise Count'} />
            <div className='mx-auto mt-10 max-w-[500px]'>
              <Pie
                options={{
                  plugins: {
                    colors: {
                      forceOverride: true
                    }
                  }
                }}
                data={{
                  labels: RM_STATS.insuranceTypeWiseData.labels,
                  datasets: [
                    {
                      label: "Policies Count",
                      data: RM_STATS.insuranceTypeWiseData.count
                    }
                  ]
                }}
              />
            </div>
          </div>

          {/* Body Type Wise Count  */}
          <div className=''>
            <Heading title={'Body Type Wise Count'} />
            <div className='mx-auto mt-10 max-w-[500px]'>
              <Pie
                options={{
                  plugins: {
                    colors: {
                      forceOverride: true
                    }
                  }
                }}
                data={{
                  labels: RM_STATS.bodyTypeData.labels,
                  datasets: [
                    {
                      label: "Policies Count",
                      data: RM_STATS.bodyTypeData.count
                    }
                  ]
                }}
              />
            </div>
          </div>
        </div>
      }

      {/* Dealer Wise Count  */}

      {
        RM_STATS.dealers.length > 0 &&
        <div className='mx-auto w-fit m-10'>
          <Heading title={'View Dealer Wise Data'} />
          <select disabled={RM_STATS.fetchingDealerData} onChange={async (e) => {
            const dealerName = e.target.value;
            if (dealerName.length) {
              dispatch(setFetchingDealerData(true));
              dispatch(setShowDealerData(false));
              await setSelectedDealerData(dealerName, rmName, dispatch);
            }
          }} className='rounded-lg font-bold text-white bg-blue-600 p-2' >
            <option value="">-- Select Dealer --</option>
            {
              RM_STATS.dealers.map((dealer) => {
                return <option>{dealer}</option>
              })
            }
          </select>
        </div>
      }

      {
        RM_STATS.showDealerData &&
        <>
          {/* Dealer's Month Data */}
          <div className='m-10'>
            <Heading title={'Dealer Monthly Stats'} />
            <div className='mx-auto mt-10 max-w-[800px]'>
              <Bar
                options={{
                  plugins: {
                    colors: {
                      forceOverride: true
                    }
                  }
                }}
                data={{
                  labels: RM_STATS.dealerData.monthly.labels,
                  datasets: [
                    {
                      label: "Policies Count",
                      data: RM_STATS.dealerData.monthly.count
                    }
                  ]
                }}
              />
            </div>
          </div>

          {/* Dealer's Data Section */}
          <div className='grid grid-cols-2'>

            {/* Dealer's Body Type Data */}
            <div className='m-10'>
              <Heading title={'Dealer Body Type Stats'} />
              <div className='mx-auto mt-10 max-w-[500px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: RM_STATS.dealerData.bodyType.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: RM_STATS.dealerData.bodyType.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

            {/* Dealer's Insurance Type Data */}
            <div className='m-10'>
              <Heading title={'Dealer Insurance Type Stats'} />
              <div className='mx-auto mt-10 max-w-[500px]'>
                <Pie
                  options={{
                    plugins: {
                      colors: {
                        forceOverride: true
                      }
                    }
                  }}
                  data={{
                    labels: RM_STATS.dealerData.insuranceType.labels,
                    datasets: [
                      {
                        label: "Policies Count",
                        data: RM_STATS.dealerData.insuranceType.count
                      }
                    ]
                  }}
                />
              </div>
            </div>

          </div>
        </>
      }
    </div>

  )
};

export default RmStats;