import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../Shared/Heading';
import { checkAuthentication, getPaymentLogs } from '../../functions.js';
import { setPaymentLogs, updatePaymentLogs, setRange } from '../../Store/features/dealerPaymentLogsSlice.js';
import { baseUrl } from '../../env.js';

const DealerPaymentLogs = () => {
  const [searchDealerName, setDealerName] = useState('');
  const [searching, setSearching] = useState(false);
  const [msg, setMsg] = useState('Loading Data....');
  const [filter, setFilter] = useState(['all', 'pending', 'approved', 'rejected']);

  // Redux
  const dispatch = useDispatch();
  const state = useSelector(({ dealerPaymentLogs }) => dealerPaymentLogs);
  const navigate = useNavigate();


  // Polling for LIVE Dealer Logs Update
  const POLLING_INTERVAL = 5000; // Poll every second

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const { paymentLogs } = await getPaymentLogs('', 0);
      dispatch(setPaymentLogs(paymentLogs));
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);



  // Check User authentication
  useEffect(() => {
    const path = window.location.pathname;
    const auth = async () => {
      const auth = await checkAuthentication(dispatch, navigate, path);
      if (!auth) navigate('/aegiscovenant/opsit/login');
    };
    auth();

    (async () =>
      await axios.get(`${baseUrl}/get-payment-logs/0`).then((data) => {
        const { paymentLogs } = data.data;
        dispatch(setPaymentLogs(paymentLogs));
      }))();
  }, []);

  const onSubmit = async () => {
    setSearching(true);
    dispatch(setPaymentLogs(null));
    setMsg('Loading Data....');
    const response = await getPaymentLogs(searchDealerName);
    setDealerName('');
    dispatch(setPaymentLogs(response.paymentLogs));
    setMsg(response.msg);
    setSearching(false);
    setFilter(['all', 'pending', 'approved', 'rejected']);
  };

  const loadMore = async () => {
    setSearching(true);
    const response = await getPaymentLogs(searchDealerName, state.from);
    dispatch(updatePaymentLogs(response.paymentLogs));
    setMsg(response.msg);
    setSearching(false);
    dispatch(setRange(state.from + 101));
  }

  return (
    <div>
      <Heading title="Dealer Payment Logs" />
      {/* Search Bar */}
      <div className="max-w-md mx-auto mt-5">
        <label htmlFor="default-search" className="mb-2 text-sm font-medium sr-only text-white">Search!</label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>

          <input type="search" onChange={(e) => {
            setDealerName(e.currentTarget.value);
          }} value={searchDealerName} id="default-search" className="block w-full p-4 ps-10 text-sm  border rounded-lg focus:ring-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 stext-white dark:focus:ring-blue-500 focus:border-blue-500" placeholder="Search Dealer Name, Transaction ID" required />

          <button
            disabled={searching || !searchDealerName}
            onClick={async () => await onSubmit()} type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">

            {
              searching ? <>
                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                <span>Searching...</span> </> : <p>Search</p>
            }
          </button>

        </div>
      </div>

      {/* Filters */}

      <div className="flex flex-col justify-center items-center">
        <ul className="items-center m-10 p-2 text-sm font-medium   border rounded-lg sm:flex bg-gray-700 border-gray-600 text-white">
          <li className="border-b  sm:border-b-0 sm:border-r border-gray-600">
            <div className="flex items-center ps-3">
              <label className="inline-flex items-center w-full cursor-pointer">
                <input checked={filter[0] === 'pending'} type="radio" onClick={(e) => {
                  setFilter(['pending']);
                }} name='pending' value="pending" className="sr-only peer" />
                <div className="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">Pending</span>
              </label>
            </div>
          </li>
          <li className="border-b  sm:border-b-0 sm:border-r border-gray-600">
            <div className="flex items-center ps-3">
              <label className="inline-flex items-center w-full cursor-pointer">
                <input checked={filter[0] === 'approved'} type="radio" onClick={(e) => {
                  setFilter(['approved']);
                }} name='approved' value="approved" className="sr-only peer" />
                <div className="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer  bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium  text-gray-300">Approved</span>
              </label>
            </div>
          </li>
          <li className="border-b sm:border-b-0 sm:border-r border-gray-600">
            <div className="flex items-center ps-3">
              <label className="inline-flex items-center w-full cursor-pointer">
                <input checked={filter[0] === 'rejected'} type="radio" onClick={(e) => {
                  setFilter(['rejected']);
                }} name='rejected' value="rejected" className="sr-only peer" />
                <div className="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">Rejected</span>
              </label>
            </div>
          </li>
          <li className="dark:border-gray-600">
            <div className="flex items-center ps-3">

              <label className="inline-flex items-center w-full cursor-pointer">
                <input checked={filter[0] === 'all'} type="radio" onClick={(e) => {
                  setFilter(['all', 'pending', 'approved', 'rejected']);
                }} name='all' value="all" className="sr-only peer" />
                <div className="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-300">View All</span>
              </label>
            </div>
          </li>
        </ul>
        {/* <div className="flex gap-2">
          <button onClick={() => reloadPage(0)} type="button" className="custom-btn">
            Refresh Data
          </button>
        </div> */}
      </div>


      <div className="relative m-5 overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-max text-sm text-left rtl:text-right text-gray-400">
          {/* Payment Logs Header */}
          <thead className="text-center text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Payment ID
              </th>
              <th scope="col" className="px-6 py-3">
                Dealer Name
              </th>
              <th scope="col" className="px-6 py-3">
                Transaction Date
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Transaction ID
              </th>
              <th scope="col" className="px-6 py-3">
                Narration / Comment
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Mode
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Proof File
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Requested At
              </th>
              <th scope="col" className="px-6 py-3">
                Updated At
              </th>
            </tr>
          </thead>

          <tbody>
            {
              state.paymentLogs && Object.keys(state.paymentLogs).map((log, index) => {
                const logValue = state.paymentLogs[log];

                if (filter.includes(logValue.status)) {
                  return (
                    <tr id={index} className={`text-center odd:bg-gray-900  even:bg-gray-800 border-b border-gray-700`}>
                      <th scope="row" className={`px-6 py-4 font-medium`} style={{ color: logValue.status === 'approved' ? 'lightgreen' : logValue.status === 'rejected' ? 'red' : 'yellow' }}>
                        {logValue.payment_id.substring(0, 36)}
                      </th>
                      <td className="px-6 py-4">
                        {logValue.dealer_name.trim()}
                      </td>
                      <td className="px-6 py-4">
                        {dayjs(logValue.transaction_date).format('DD-MM-YYYY').trim()}
                      </td>
                      <td className="px-6 py-4">
                        ₹ {logValue.amount}
                      </td>
                      <td className="px-6 py-4">
                        {logValue.transaction_id.trim()}
                      </td>
                      <td className="px-6 py-4">
                        {logValue.narration_comment.trim()}
                      </td>
                      <td className="px-6 py-4">
                        {logValue.payment_mode.trim().toUpperCase()}
                      </td>
                      <td className="px-6 py-4 text-blue-400">
                        {logValue.payment_proof_file ? <Link target='_blank' to={`https://drive.google.com/file/d/${logValue.payment_proof_file}/view`}>View File</Link> : '-'}
                      </td>
                      <td className={`px-6 py-4`} style={{ color: logValue.status === 'approved' ? 'lightgreen' : logValue.status === 'rejected' ? 'red' : 'yellow' }}>
                        {logValue.status.trim().toUpperCase()}
                      </td>
                      <td className="px-6 py-4">
                        {dayjs(logValue.requested_at).format('DD-MM-YYYY HH:mm:ss')}
                      </td>
                      <td className="px-6 py-4">
                        {logValue.approved_rejected_at ? dayjs(logValue.approved_rejected_at).format('DD-MM-YYYY HH:mm:ss') : null}
                      </td>
                    </tr>
                  )
                }
              })
            }
          </tbody>
        </table>
        {
          !state.paymentLogs && <h1 className='text-[20px] p-5'>{msg}</h1>
        }
      </div>

      {
        state.paymentLogs && filter[0] === 'all' &&
        <div className="w-full flex m-2 mb-10 justify-center">
          <button onClick={async () => await loadMore()} type="button" className="custom-btn">
            Load More
          </button>
        </div>
      }
    </div>
  )
}

export default DealerPaymentLogs;
