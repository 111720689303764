import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  searching: false,
  requestId: '',
  error: {
    status: false,
    message: ''
  }
}

export const KycDocumentsDownloadSlice = createSlice({
  name: 'kycDocumentsDownloader',
  initialState,
  reducers: {
    setSearching: (state, action) => {
      state.searching = action.payload;
    },
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setSearching, setRequestId, setData, setError} = KycDocumentsDownloadSlice.actions;
export default KycDocumentsDownloadSlice.reducer;