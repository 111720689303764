import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string().matches(/.*aegiscovenant\.com$/, 'Please enter a valid email').email()
    .required('Please enter a valid email'),
  password: yup.string().required('Password is required to login'),
});

export default schema;
