import { useState } from 'react';
import { resetPassword } from '../functions';
import { useNavigate } from 'react-router';

const ResetPassword = () => {
  const [message, setMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userNewPassword, setUserNewPassword] = useState('');

  const navigate = useNavigate();


  return (
    <div>
      <h1 className="text-[30px] font-extrabold mx-auto w-fit mt-10">Reset Password</h1>
      <div className="max-w-lg mt-10 mx-auto">
        {/* User Email */}
        <div className="mb-5">
          <label for="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
          <input onChange={(e) => {
            setUserEmail(e.target.value)
          }} type="email" id="email" className="text-white shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="name@flowbite.com" required />
        </div>
          {/* User Old Password */}
        <div className="mb-5">
          <label for="password" className="block mb-2 text-sm font-medium text-gray-900">Your password</label>
          <input onChange={(e) => {
            setUserPassword(e.target.value);
          }} type="password" id="password" className="shadow-sm bg-gray-50 border text-white border-gray-300text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
        </div>

          {/* User New Password */}
        <div className="mb-5">
          <label for="new-password" className="block mb-2 text-sm font-medium text-gray-900">New password</label>
          <input onChange={(e) => {
            setUserNewPassword(e.target.value);
          }} type="password" id="new-password" className="shadow-sm text-white bg-gray-50 border border-gray-300text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
        </div>
        <button disabled={!userEmail && !userPassword && !userNewPassword} onClick={async () => {
          const response = await resetPassword(userEmail, userPassword, userNewPassword);
          setMessage(response.msg);
          if (response.status === 200) {
            setTimeout(()  => {
              navigate('/aegiscovenant/opsit/login');
            }, 3000);
          }
        }} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Reset Password</button>
      </div>

      { message && 
        <div id="alert-border-5" className="w-fit mx-auto mt-10 flex items-center p-4 rounded-xl border-t-4 border-gray-300 bg-gray-50 dark:bg-gray-800 dark:border-gray-600" role="alert">
          <svg className="flex-shrink-0 w-4 h-4 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <div className="ms-3 px-3 text-sm font-medium text-gray-800 dark:text-gray-300">
            {message}
          </div>
        </div>
      }

    </div>
  )
}

export default ResetPassword;
