import React, { useEffect, useLayoutEffect, useState } from 'react'
import { checkAuthentication } from '../../../functions.js';
import { useNavigate, useParams } from 'react-router';
import { setCurrentPayoutBikeType, setCurrentPayoutBikeValue, setCurrentPayoutScooterType, setCurrentPayoutScooterValue, setDealerCurrentInsurer, setDealerName, setManufacturerName, setNilDepPreferrences, setPincode, setPolicyCopyId, setPreferredInsurer, setPriceListData, setPriceListId, setRequiredPayoutBikeType, setRequiredPayoutBikeValue, setRequiredPayoutScooterType, setRequiredPayoutScooterValue, setRto, setSecondPolicyId } from './redux.js';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../../env.js';
import axios from 'axios';
import { fileUpload, onSubmit } from './functions.js';
import { insurers } from '../../../constants.js';
import revisePricelistSchema from './revisePricelistSchema.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputError, Required } from './components.jsx';
import { setRequirements } from '../Create Pricelist/redux.js';

const EditPriceListRequest = () => {

  const { requestId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(revisePricelistSchema) });

  // Check authentication
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const path = '/dealer/pricelist/edit';
    const auth = async () => {
      const auth = await checkAuthentication(dispatch, navigate, path);
      if (!auth) navigate('/login');
    };
    auth();
  }, []);

  // Get Request ID Details
  useEffect(() => {


    axios.get(`${baseUrl}/price-list-data/${requestId}`).then((data) => {
      const record = data.data.record[0];

      const currentBikePayout = record.current_dealer_payout_bike.split('-');
      const currentBikePayoutValue = currentBikePayout[0].trim();
      const currentBikePayoutType = currentBikePayout[1].trim();

      const currentScooterPayout = record.current_dealer_payout_scooter.split('-');
      const currentScooterPayoutValue = currentScooterPayout[0].trim();
      const currentScooterPayoutType = currentScooterPayout[1].trim();

      const bikePayout = record.required_payout_bike.split('-');
      const bikePayoutValue = bikePayout[0].trim();
      const bikePayoutType = bikePayout[1].trim();

      const scooterPayout = record.required_payout_scooter.split('-');
      const scooterPayoutValue = scooterPayout[0].trim();
      const scooterPayoutType = scooterPayout[1].trim();

      // Set Values in store
      dispatch(setPriceListData(
        {
          dealerName: record.dealer_name,
          rto: record.dealer_rto,
          pincode: record.dealer_pincode,
          manufacturerName: record.manufacturer_name,
          requiredPayoutBikeValue: bikePayoutValue,
          requiredPayoutBikeType: bikePayoutType,
          requiredPayoutScooterValue: scooterPayoutValue,
          requiredPayoutScooterType: scooterPayoutType,
          nilDepPreferences: record.nil_dep_preferences,
          preferredInsurer: record.preferred_insurer,
          policyCopyFileId: record.policy_copy_1,
          secondPolicyCopyFileId: record.policy_copy_2,
          priceListFileId: record.price_list,
          currentInsurer: record.current_dealer_insurer,
          currentDealerPayoutValueBike: currentBikePayoutValue,
          currentDealerPayoutTypeBike: currentBikePayoutType,
          currentDealerPayoutValueScooter: currentScooterPayoutValue,
          currentDealerPayoutTypeScooter: currentScooterPayoutType,
        }
      ));

      setValue('dealerName', record.dealer_name);
      setValue('rto', record.dealer_rto);
      setValue('pincode', record.dealer_pincode);
      setValue('manufacturer', record.manufacturer_name);
      setValue('requiredPayoutBike', bikePayoutValue);
      setValue('requiredPayoutScooter', scooterPayoutValue);
      setValue('requiredDealerPayoutBikeType', bikePayoutType);
      setValue('requiredDealerPayoutScooterType', scooterPayoutType);
      setValue('nilDepPreferences', record.nil_dep_preferences);
      setValue('preferredInsurer', record.preferred_insurer);
      setValue('currentDealerInsurer', record.current_dealer_insurer);
      setValue('currentDealerPayoutBike', currentBikePayoutValue);
      setValue('currentDealerPayoutScooter', currentScooterPayoutValue);
      setValue('currentDealerPayoutBikeType', currentBikePayoutType);
      setValue('currentDealerPayoutScooterType', currentScooterPayoutType);
      setValue('policyCopyId', record.policy_copy_1);
      setValue('secondPolicyCopyId', record.policy_copy_2);
      setValue('priceListId', record.price_list);

    })
  }, []);


  const [msg, setMsg] = useState('null');
  const [requestStatus, setRequestStatus] = useState(null);
  const [payoutTypeError, setPayoutTypeError] = useState({
    currentBike: '',
    requiredBike: '',
    currentScooter: '',
    requiredScooter: ''
  });

  const priceListData = useSelector(({ pricelist }) => pricelist);
  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser);

  const revisePricelist = async () => {

    // Check for Payout Type Selected
    const { currentDealerPayoutValueBike, currentDealerPayoutTypeBike, requiredPayoutBikeValue, requiredPayoutBikeType, currentDealerPayoutValueScooter, currentDealerPayoutTypeScooter, requiredPayoutScooterValue, requiredPayoutScooterType } = priceListData;

    setPayoutTypeError({
      currentBike: '',
      currentScooter: '',
      requiredBike: '',
      requiredScooter: ''
    })

    const PERCENTAGE_PAYOUT_ASK_ERROR = 'Payout Ask Cannot be over 60%';
    const FIXED_AMOUNT_PAYOUT_ASK_ERROR = 'Payout Ask Cannot be over ₹3000';

    // Current BIKE Payout Ask
    if (currentDealerPayoutTypeBike === 'Percentage (%)' && parseFloat(currentDealerPayoutValueBike) > 60) {
      setPayoutTypeError({
        currentBike: PERCENTAGE_PAYOUT_ASK_ERROR,
        currentScooter: '',
        requiredBike: '',
        requiredScooter: ''
      })

      return;
    } else if (currentDealerPayoutTypeBike === 'Fixed Amount (₹)' && parseFloat(currentDealerPayoutValueBike) > 3000) {
      setPayoutTypeError({
        currentBike: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        currentScooter: '',
        requiredBike: '',
        requiredScooter: ''
      })

      return;
    }

    // Current SCOOTER Payout Ask
    if (currentDealerPayoutTypeScooter === 'Percentage (%)' && parseFloat(currentDealerPayoutValueScooter) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: PERCENTAGE_PAYOUT_ASK_ERROR,
        requiredBike: '',
        requiredScooter: ''
      })
      return;
    } else if (currentDealerPayoutTypeScooter === 'Fixed Amount (₹)' && parseFloat(currentDealerPayoutValueScooter) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        requiredBike: '',
        requiredScooter: ''

      })
      return;
    }

    // Required BIKE payout ask
    if (requiredPayoutBikeType === 'Percentage (%)' && parseFloat(requiredPayoutBikeValue) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: PERCENTAGE_PAYOUT_ASK_ERROR,
        requiredScooter: ''
      });
      return;
    } else if (requiredPayoutBikeType === 'Fixed Amount (₹)' && parseFloat(requiredPayoutBikeValue) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        requiredScooter: ''
      });
      return;
    }

    // Required SCOOTER payout ask
    if (requiredPayoutScooterType === 'Percentage (%)' && parseFloat(requiredPayoutScooterValue) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: '',
        requiredScooter: PERCENTAGE_PAYOUT_ASK_ERROR
      })
      return;
    } else if (requiredPayoutScooterType === 'Fixed Amount (₹)' && parseFloat(requiredPayoutScooterValue) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: '',
        requiredScooter: FIXED_AMOUNT_PAYOUT_ASK_ERROR
      })
      return;
    }

    await onSubmit(loggedInUser, requestId, priceListData, setMsg, setRequestStatus, navigate, dispatch);
  };

  return (

    <div className='mx-auto w-fit'>

      {/* heading */}
      <h1 h1 className="mb-4 mt-10 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">RM Price List</h1>

      {/* Form */}

      {msg && requestStatus !== 201 &&
        <form noValidate onSubmit={handleSubmit(revisePricelist)}>
          <div className='grid grid-cols-2 gap-2'>
            {/* Dealer Name */}
            <div className="mb-5">
              <label for="dealerName" className="block mb-2 text-sm font-bold text-gray-900">Dealer Name <Required /></label>
              <input {...register('dealerName')} value={priceListData.dealerName} onChange={(e) => {
                dispatch(setDealerName(e.target.value));
              }} type="text" id="dealerName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Gaurav BMW Company" required />
              {errors.dealerName && <InputError error={errors.dealerName} />}
            </div>

            {/* Dealer RTO */}
            <div className="mb-5">
              <label for="rto" className="block mb-2 text-sm font-bold text-gray-900">RTO <Required /></label>
              <input {...register('rto')} value={priceListData.rto} onChange={(e) => {
                dispatch(setRto(e.target.value));
              }} type="text" id="rto" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="HR13" required />
              {errors.rto && <InputError error={errors.rto} />}
            </div>

            {/* Dealer Pincode */}
            <div className="mb-5">
              <label for="pincode" className="block mb-2 text-sm font-bold text-gray-900">Pincode <Required /></label>
              <input {...register('pincode')} value={priceListData.pincode} onChange={(e) => {
                dispatch(setPincode(e.target.value));
              }} type="text" id="pincode" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="124507" required />
              {errors.pincode && <InputError error={errors.pincode} />}
            </div>

            {/* Manufacturer */}
            <div className="mb-5">
              <label for="manufacturer" className="block mb-2 text-sm font-bold text-gray-900">Manufacturer Name <Required /></label>
              <input {...register('manufacturer')} value={priceListData.manufacturerName} onChange={(e) => {
                dispatch(setManufacturerName(e.target.value));
              }} type="text" id="manufacturer" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="HERO MOTOCORP LIMITED" required />
              {errors.manufacturer && <InputError error={errors.manufacturer} />}
            </div>

            {/* Current Dealer Payout Bike */}
            <div className="mb-5">
              <label for="requiredpayoutBike" className="block mb-2 text-sm font-bold text-gray-900">Current Dealer Payout BIKE <Required /></label>
              <input {...register('currentDealerPayoutBike')} value={priceListData.currentDealerPayoutValueBike} onChange={(e) => {
                dispatch(setCurrentPayoutBikeValue(e.target.value));
              }} type="text" id="requiredpayoutBike" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="30" required />
              <select {...register('currentDealerPayoutBikeType')} value={priceListData.currentDealerPayoutTypeBike} onChange={(e) => {
                dispatch(setCurrentPayoutBikeType(e.target.value));
              }} id="payoutTypeBike" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.currentDealerPayoutBike && <InputError error={errors.currentDealerPayoutBike} />}
              {errors.currentDealerPayoutBikeType && <InputError error={errors.currentDealerPayoutBikeType} />}
              {payoutTypeError.currentBike && <InputError error={payoutTypeError.currentBike} />}
            </div>

            {/* Required Payout BIKE */}
            <div className="mb-5">
              <label for="requiredpayoutBike" className="block mb-2 text-sm font-bold text-gray-900">Required Payout BIKE <Required /></label>
              <input {...register('requiredPayoutBike')} value={priceListData.requiredPayoutBikeValue} onChange={(e) => {
                dispatch(setRequiredPayoutBikeValue(e.target.value));
              }} type="text" id="requiredpayoutBike" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="30" required />
              <select value={priceListData.requiredPayoutBikeType} onChange={(e) => {
                dispatch(setRequiredPayoutBikeType(e.target.value));
              }} {...register('requiredDealerPayoutBikeType')} id="payoutTypeBike" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.requiredPayoutBike && <InputError error={errors.requiredPayoutBike} />}
              {errors.requiredDealerPayoutBikeType && <InputError error={errors.requiredDealerPayoutBikeType} />}
              {payoutTypeError.requiredBike && <InputError error={payoutTypeError.requiredBike} />}
            </div>

            {/* Current Dealer Payout Scooter */}
            <div className="mb-5">
              <label for="requiredpayoutScooter" className="block mb-2 text-sm font-bold text-gray-900">Current Dealer Payout SCOOTER <Required /></label>
              <input {...register('currentDealerPayoutScooter')} value={priceListData.currentDealerPayoutValueScooter} onChange={(e) => {
                dispatch(setCurrentPayoutScooterValue(e.target.value));
              }} type="text" id="requiredpayoutScooter" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="30" required />
              <select {...register('currentDealerPayoutScooterType')} value={priceListData.currentDealerPayoutTypeScooter} onChange={(e) => {
                dispatch(setCurrentPayoutScooterType(e.target.value));
              }} id="payoutTypeScooter" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.currenDealerPayoutScooter && <InputError error={errors.currenDealerPayoutScooter} />}
              {errors.currentDealerPayoutScooterType && <InputError error={errors.currentDealerPayoutScooterType} />}
              {payoutTypeError.currentScooter && <InputError error={payoutTypeError.currentScooter} />}
            </div>

            {/* Required Payout SCOOTER */}
            <div className="mb-5">
              <label for="requiredpayoutScooter" className="block mb-2 text-sm font-bold text-gray-900">Required Payout SCOOTER <Required /></label>
              <input {...register('requiredPayoutScooter')} value={priceListData.requiredPayoutScooterValue} onChange={(e) => {
                dispatch(setRequiredPayoutScooterValue(e.target.value));
              }} type="text" id="requiredpayoutScooter" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="30" required />
              <select {...register('requiredDealerPayoutScooterType')} value={priceListData.requiredPayoutScooterType} onChange={(e) => {
                dispatch(setRequiredPayoutScooterType(e.target.value));
              }} id="payoutTypeScooter" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.requiredPayoutScooter && <InputError error={errors.requiredPayoutScooter} />}
              {errors.requiredDealerPayoutScooterType && <InputError error={errors.requiredDealerPayoutScooterType} />}
              {payoutTypeError.requiredScooter && <InputError error={payoutTypeError.requiredScooter} />}
            </div>

            {/* Nil Dep Preferrences */}
            <div className="mb-5">
              <label for="nilDepChoice" className="block mb-2 text-sm font-bold text-gray-900">Nil Dep Preferrences <Required /></label>
              <select {...register('nilDepPreferences')} value={priceListData.nilDepPreferences} onChange={(e) => {
                dispatch(setNilDepPreferrences(e.target.value));
              }} id="nilDepChoice" className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Nil Dep Preference -- </option>
                <option>With Nil Dep</option>
                <option>Without Nil Dep</option>
              </select>
              {errors.nilDepPreferences && <InputError error={errors.nilDepPreferences} />}
            </div>

            {/* Dealer Current Insurer */}
            <div>
              <label for="countries" className="block mb-2 text-sm font-bold text-gray-900 dark:text-black">Current Dealer Insurer <Required /></label>
              <select {...register('currentDealerInsurer')} value={priceListData.currentInsurer} onChange={(e) => {
                dispatch(setDealerCurrentInsurer(e.target.value));
              }} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Insurer Name -- </option>
                {
                  insurers.map(insurer => {
                    return <option>{insurer}</option>
                  })
                }
              </select>
              {errors.currentDealerInsurer && <InputError error={errors.currentDealerInsurer} />}
            </div>

            {/* Preferred Insurer */}
            {/* <div>
              <label for="countries" className="block mb-2 text-sm font-bold text-gray-900 dark:text-black">Preferred Insurer <Required/></label>
              <select {...register('preferredInsurer')} value={priceListData.preferredInsurer} onChange={(e) => {
                dispatch(setPreferredInsurer(e.target.value));
              }} id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Insurer Name -- </option>
                <option>United India Insurance Company Limited</option>
                <option>Shriram General Insurance Company</option>
                <option>Bajaj Allianz General Insurance Company Limited</option>
                <option>ICICI Lombard General Insurance Company Limited</option>
                <option>MAGMA HDI GENERAL INSURANCE COMPANY LIMITED</option>
              </select>
              {errors.preferredInsurer && <InputError error={errors.preferredInsurer} />}
            </div> */}

            {/* Policy Copy Upload */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="user_avatar">Upload Policy Copy file <Required /></label>
              {
                !priceListData.policyCopyFileId &&
                <input value={priceListData.policyCopyFileId} onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setPolicyCopyId(fileId));
                  setValue('policyCopyId', fileId);
                }} disabled={priceListData.policyCopyFileId} className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }
              {/* Success Message */}
              {
                priceListData.policyCopyFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                  <button onClick={() => {
                    window.open(`https://drive.google.com/file/d/${priceListData.policyCopyFileId}/view`, '_blank')
                  }} type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setPolicyCopyId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.policyCopyId && <InputError error={errors.policyCopyId} />}

            </div>

            {/* Policy Second Copy Upload */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="user_avatar">Upload Second Policy Copy file <Required /></label>
              {
                !priceListData.secondPolicyCopyFileId &&
                <input value={priceListData.secondPolicyCopyFileId} onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setSecondPolicyId(fileId));
                  setValue('secondPolicyCopyId', fileId);
                }} disabled={priceListData.secondPolicyCopyFileId} className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }


              {/* Success Message */}
              {
                priceListData.secondPolicyCopyFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                  <button onClick={() => {
                    window.open(`https://drive.google.com/file/d/${priceListData.secondPolicyCopyFileId}/view`, '_blank')
                  }} type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setSecondPolicyId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.secondPolicyCopyId && <InputError error={errors.secondPolicyCopyId} />}

            </div>

            {/* Price List */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="user_avatar">Upload Price List file <Required /></label>
              {
                !priceListData.priceListFileId && <input value={priceListData.priceListFileId} onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setPriceListId(fileId));
                  setValue('priceListId', fileId);
                }} disabled={priceListData.priceListFileId} className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }


              {/* Success Message */}
              {
                priceListData.priceListFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                  <button onClick={() => {
                    window.open(`https://drive.google.com/file/d/${priceListData.priceListFileId}/view`, '_blank')
                  }} type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setPriceListId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.priceListId && <InputError error={errors.priceListId} />}
            </div>

          </div>

          {/* Description */}
          <div>
            <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="pricelist-desc">Other Requirements / Description <Required /> </label>
            <textarea {...register('comments')} onChange={(e) => {
              dispatch(setRequirements(e.target.value));
            }} id='pricelist-desc' className='p-2 bg-gray-50 text-black w-full outline rounded-md resize-none' cols="20" rows="5"></textarea>
            {errors.comments && <InputError error={errors.comments} />}
          </div>


          <button type="submit" className="text-white my-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit Price List</button>
        </form>
      }

      {/* Alert Message */}
      {
        msg && requestStatus === 201 &&
        <div id="alert-border-1" className="flex items-center w-fit mx-auto p-4 mt-4 rounded-lg mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
          <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <div className="ms-3 mr-5 text-sm font-medium">
            {msg}
          </div>
          <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-border-1" aria-label="Close">
            <span className="sr-only">Dismiss</span>
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
          </button>
        </div>
      }
    </div >

  );
}

export default EditPriceListRequest
