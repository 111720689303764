import React from 'react'
import { InsuranceTableStats, InsuranceViewHeader, TableFilters, TableView } from './components'

const InsuranceView = () => {
  return (
    <div className='my-2 mx-1 px-5'>
      <InsuranceViewHeader />
      <TableFilters/>
      <InsuranceTableStats />
      <TableView />
    </div>
  )
}

export default InsuranceView