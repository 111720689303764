const INSURER_OPTIONS = [
  'Bajaj - New Delhi, Delhi',
  'Bajaj - Lucknow, Uttar Pradesh',
  'Bajaj - Bangalore, Karnataka',
  'Bajaj - Pune, Maharashtra',
  'Bajaj - Patna, Bihar',
  'Bajaj - Chennai, Tamil Nadu',
  'Bajaj - Coimbatore, Tamil Nadu',
  'Bajaj - Kolkata, West Bengal',
  'Bajaj - Chandigarh, Punjab',
  'Bajaj - Patna2, Bihar',
  'Bajaj - Ahmedabad, Gujarat',
  'Bajaj - Amaravati, Andhra Pradesh',
  'Bajaj - Muzaffarpur, Bihar',
  'Bajaj - Main',
  'United - New Delhi, Delhi',
  'United - Direct HERO, Karnataka',
  'United - Direct, Karnataka',
  'United - Manipal, Karnataka',
  'United - Girnar2, Delhi',
  'United - Landmark, Delhi',
  'Shriram - New Delhi, Delhi',
  'Royal Sundaram - Bangalore, Karnataka',
  'Tata - New Delhi, Delhi',
  'Magma - Hubli, Karnataka',
  'Magma - Girnar, Delhi',
  'ICICI - New Delhi, Delhi',
  'HDFC - Bangalore, Karnataka',
  'Chola - Hubli, Karnataka',
  'Liberty - Mumbai, Maharashtra',
  'United - policyBazaar, Delhi',
  'Reliance - Landmark, Delhi',
  'AegisCovenant - Certficate',
  'Chola - Landmark, Delhi',
  'Bajaj - Landmark84, Delhi',
  'National - Girnar, Delhi'
];

const cancellationsReason = [
  'Vehicle not Delivered',
  'Duplicate Policy',
  'Technical issue in the Vehicle',
  'Alternate Policy',
  'Wrong IDV',
  'Wrong Model & IDV',
  'Hypothecation Rejected',
  'Customer Refused to Buy the Vehicle',
  'Policy Issued by mistake',
  'Loan Rejection',
];

const TYPES = ['Insurance', 'Certificate'];

export { INSURER_OPTIONS, cancellationsReason, TYPES };