import axios from 'axios';
import { URL } from '../../env.js';
import { setBodyTypeWiseData, setDealerBodyTypeData, setDealerInsuranceTypeData, setDealerMonthlyData, setDealerWiseData, setDealers, setFetchingDealerData, setInsuranceTypeWiseData, setMonthlyData, setShowDealerData, setTotalActiveDealersMonth, setTotalCurrentMonthPolicies, setTotalOnBoardedDealers, setTotalPoliciesCount } from './redux.js';

// RM Overall Monthly Count
const rmMonthlyCount = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/monthly/${name}`);
  const monthlyCount = data?.data;
  dispatch(setMonthlyData(monthlyCount));
};

// RM Dealer Wise Overall Count
const rmDealerMonthlyCount = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/dealer/monthly/${name}`);
  const dealerWiseMonthlyCount = data?.data;
  dispatch(setDealerWiseData(dealerWiseMonthlyCount));
};

// Overall Active Dealers
const rmActiveInsuranceDealers = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/active/dealers/${name}`);
  const overallyActiveInsuranceDealers = data?.data;
  dispatch(setTotalOnBoardedDealers(overallyActiveInsuranceDealers))
  return overallyActiveInsuranceDealers;
};

// Overall Monthly Active Dealers
const rmActiveInsuranceDealersMonthly = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/active/dealers/monthly/${name}`);
  const ActiveInsuranceDealersMonthly = data?.data;
  dispatch(setTotalActiveDealersMonth(ActiveInsuranceDealersMonthly));
  return ActiveInsuranceDealersMonthly;
};

// Policies Count by Body Type
const rmPoliciesCountBodyType = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/count/bodyType/${name}`);
  const policiesBodyTypeCount = data?.data;
  dispatch(setBodyTypeWiseData(policiesBodyTypeCount));
  return policiesBodyTypeCount;
};

// Policies Count by Body Type
const rmPoliciesCountInsuranceType = async (name, dispatch) => {
  const { data } = await axios.get(`${URL}/revenue/rm/count/insuranceType/${name}`);
  const policiesInsuranceTypeCount = data?.data;
  dispatch(setInsuranceTypeWiseData(policiesInsuranceTypeCount));
  return policiesInsuranceTypeCount;
};

// Total Policies
const totalPoliciesCount = async (name, dispatch) => {
  const {data} = await axios.get(`${URL}/revenue/rm/total/count/${name}`);
  const policiesCount = data?.data?.[0]?.['COUNT(UID)'];
  dispatch(setTotalPoliciesCount(policiesCount));

  return policiesCount;
}

const totalCurrentMonthPolicies = async (name, dispatch) => {
  const {data} = await axios.get(`${URL}/revenue/rm/current-month/count/${name}`);
  const policiesCount = data?.data?.[0]?.['COUNT(UID)'];
  dispatch(setTotalCurrentMonthPolicies(policiesCount))

}

// Get Labels and Data
const getLabelsAndPoliciesCount = (data, labelId, countId) => {
  const labels = data.map((monthCount) => {
    return monthCount[labelId];
  });

  const policiesCount = data.map((monthCount) => {
    return monthCount[countId];
  });

  return {
    labels, policiesCount
  };
};

// Get RM Active Dealers
const getRmActiveDealers = async (name, dispatch) => {
  const request = axios.get(`${URL}/revenue/rm/active-dealers/${name}`);
  const data = (await request).data;

  dispatch(setDealers(data));
};

// Get Selected Dealer Data
const setSelectedDealerData = async (dealerName, rmName, dispatch) => {
  const options =  {
    method: 'POST',
    data: JSON.stringify({
      dealerName,
      rm: rmName
    }),
    url: `${URL}/revenue/rm/dealer/data`,
    headers: {
      'content-type': 'application/json'
    }
  }
  const request = axios.request(options);
  const data = (await request).data;

  const { bodyTypeData, insuranceTypeData, monthData } = data;

  dispatch(setDealerBodyTypeData(bodyTypeData));
  dispatch(setDealerInsuranceTypeData(insuranceTypeData));
  dispatch(setDealerMonthlyData(monthData));

  dispatch(setShowDealerData(true));
  dispatch(setFetchingDealerData(false));

};


export {
  rmMonthlyCount,
  rmDealerMonthlyCount,
  rmActiveInsuranceDealers,
  rmActiveInsuranceDealersMonthly,
  getLabelsAndPoliciesCount,
  rmPoliciesCountBodyType,
  rmPoliciesCountInsuranceType,
  totalPoliciesCount,
  totalCurrentMonthPolicies,
  getRmActiveDealers,
  setSelectedDealerData
};