import axios from "axios";
import { URL } from "../../../env.js";
import * as xlsx from "xlsx";

const fetchClaimRecords = async () => {
  const { data: records } = await axios.get(`${URL}/claims/records`);
  return records;
};

// Download Claims Data
const downloadClaimsData = async () => {
  try {
    const records = await axios.get(`${URL}/claims/download-records`);
  
    // Convert Records to DB
    const wb = xlsx.utils.book_new();
    const claimsData = xlsx.utils.aoa_to_sheet(records?.data?.records);
    xlsx.utils.book_append_sheet(wb, claimsData, `Claim Records`);
  
    const fileName = `Claim-Records.xlsx`;
    xlsx.writeFile(wb, fileName);
    
  } catch (err) {
    console.log(err);
  }
};

export { fetchClaimRecords, downloadClaimsData };
