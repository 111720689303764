import * as yup from 'yup';

const rejectTicketSchema = yup.object().shape({
  reason: yup.string().required(),
  closeTicketComments: yup
    .string()
    .when('reason', {
      is: (reason) => reason === 'Other',
      then: () => yup.string().required('Please mention a reason'),
    }),
});

export default rejectTicketSchema;
