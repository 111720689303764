import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  records: [],
  editRecordData: {
    dealerName: '',
    customerName: '',
    state: '',
    location: '',
    insurerName: '',
    insurancePolicyNumber: '',
    claimType: '',
    insuranceClaimNumber: '',
    surveyor: '',
    surveyorContactInfo: '',
    surveyorAppointmentDate: ''
  }
};

export const ClaimsData = createSlice({
  name: 'Claims',
  initialState,
  reducers: {
    setClaimRecords: (state, action) => {
      state.records = action.payload;
    },
    setDealerName: (state, action) => {
      state.editRecordData.dealerName = action.payload;
    },
    setCustomerName: (state, action) => {
      state.editRecordData.customerName = action.payload;
    },
    setLocation: (state, action) => {
      state.editRecordData.location = action.payload;
    },
    setState: (state, action) => {
      state.editRecordData.state = action.payload;
    },
    setInsurerName: (state, action) => {
      state.editRecordData.insurerName = action.payload;
    },
    setInsurancePolicyNumber: (state, action) => {
      state.editRecordData.insurancePolicyNumber = action.payload;
    },
    setClaimType: (state, action) => {
      state.editRecordData.claimType = action.payload;
    },
    setInsuranceClaimNumber: (state, action) => {
      state.editRecordData.insuranceClaimNumber = action.payload;
    },
    setSurveyorName: (state, action) => {
      state.editRecordData.surveyor = action.payload;
    },
    setSurveyorContactInfo: (state, action) => {
      state.editRecordData.surveyorContactInfo = action.payload;
    },
    setSurveyorAppointmentDate : (state, action) => {
      state.editRecordData.surveyorAppointmentDate = action.payload;
    }
  }
});

export const { setClaimRecords, setDealerName, setCustomerName, setLocation, setState, setInsurerName, setInsurancePolicyNumber, setClaimType, setInsuranceClaimNumber, setSurveyorName, setSurveyorContactInfo, setSurveyorAppointmentDate } = ClaimsData.actions;
export default ClaimsData.reducer;