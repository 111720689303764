import axios from 'axios';
import { JsonToTable } from 'react-json-to-table';

import { URL } from '../../../../env.js';
import { newPolicyTypes, newVehicleTypes, renewalPolicyTypes, renewalVehicleTypes } from './constants.js';

const onSubmit = async (d, dispatch) => {
  dispatch({
    type: 'toggleLoading',
    payload: true,
  });
  dispatch({
    type: 'setConfig',
    payload: {},
  });

  const { data } = await axios.get(`${URL}/dealer/config/${d.email}`);

  if(data.msg) {
    dispatch({
      type: 'setConfig',
      payload: {}
    });
  } else {
    dispatch({
      type: 'setConfig',
      payload: data,
    });
  }

  dispatch({
    type: 'toggleLoading',
    payload: false,
  });
};

const JsonTableWithHeader = ({ label, json }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm">{label}</p>
      <JsonToTable json={json} />
    </div>
  );
};

const reducer = (state, action) => {
  let newState = {};
  let selectedPolicyType;
  switch (action.type) {
    case 'setCategory':
      const vehicleTypes = action.payload === 'New' ? newVehicleTypes : renewalVehicleTypes;
      const policyTypeOptions = action.payload === 'New' ? newPolicyTypes : renewalPolicyTypes;
      const selectedVehicleType = action.payload === 'New' ? 'Bike' : 'Bike A';
      selectedPolicyType = action.payload === 'New' ? 'OD1 + TP5' : 'OD1';
      newState = {
        ...state,
        selectedCategory: action.payload,
        vehicleTypeOptions: vehicleTypes,
        selectedVehicleType,
        selectedPolicyType,
        policyTypeOptions,
      };
      break;
    case 'setConfig':
      newState = { ...state, config: action.payload };
      break;
    case 'toggleLoading':
      newState = { ...state, loading: action.payload };
      break;
    case 'setVehicleType':
      selectedPolicyType = state.selectedCategory === 'New' ? 'OD1 + TP5' : 'OD1';
      newState = {
        ...state,
        selectedPolicyType,
        selectedVehicleType: action.payload,
      };
      break;
    case 'setPolicyType':
      newState = {
        ...state,
        selectedPolicyType: action.payload,
      };
      break;
    default:
      newState = { ...state };
      break;
  }

  if (Object.keys(newState.config).length !== 0) {
    const policyCategory = newState.selectedCategory.toLowerCase();
    const selectedVehicleType = newState.vehicleTypeOptions[newState.selectedVehicleType];
    const selectedPolicyType = newState.policyTypeOptions[newState.selectedPolicyType];
    if (newState.config.discount[policyCategory][selectedVehicleType]) {
      const selectedConfigDetails = {
        Insurer: newState.config.discount[policyCategory][selectedVehicleType].insurer,
        Branch: newState.config.discount[policyCategory][selectedVehicleType].branch,
        "Make": newState.config.discount[policyCategory][selectedVehicleType].make,
        "Payment Terms": newState.config.payout[policyCategory].paymentTerms,
        "Payment By": newState.config.payout[policyCategory].paymentBy,
        "Payment Terms To Be Used": newState.config.payout[policyCategory].paymentTermsToBeUsed,
      };

      let discount = newState.config.discount[policyCategory][selectedVehicleType];
      let payout = newState.config.payout[policyCategory][selectedVehicleType];
      if(selectedPolicyType === 'hirePurchase.new') {
        discount = discount.hirePurchase.new;
        payout = payout.hirePurchase.new;
      } else {
        discount = discount[selectedPolicyType];
        payout = payout[selectedPolicyType];
      }
      newState = {
        ...newState,
        selectedConfig: {
          discount,
          payout,
        },
        error: undefined,
        selectedConfigDetails,
      };
    } else {
      newState = {
        ...newState,
        selectedConfig: {},
        selectedConfigDetails: {}
      };
    }
  }

  return newState;
};

export { onSubmit, JsonTableWithHeader, reducer };
