import { useDispatch, useSelector } from 'react-redux';
import { setRequestId } from './redux';
import { handleSubmit } from './functions.js';
import "../../../App.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Heading from '../../../Shared/Heading.jsx';
import AuthCheck from '../../../AuthCheck.jsx';

const KycDocumentDownloader = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const KycDocumentsSlice = useSelector(({ KycDocuments }) => {
    return KycDocuments;
  });

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname}/>
      <Heading title={'KYC Documents Downloader'} />
      <div className="max-w-md mx-auto mt-5">
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>


          <input type="search" onChange={(e) => {
            dispatch(setRequestId(e.currentTarget.value.trim()));
          }} value={KycDocumentsSlice.requestId} id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Request ID" required />

          <button
            disabled={KycDocumentsSlice.searching}
            onClick={() => handleSubmit(KycDocumentsSlice.requestId, dispatch)} type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:text-white">
            {
              KycDocumentsSlice.searching ? <>
                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-black animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                <span>Searching...</span> </> : <p>Search</p>
            }
          </button>

        </div>

        {
          KycDocumentsSlice.data &&
          KycDocumentsSlice.data.map((document) => {
            const { documentNumber, documentType, fileId, kycType } = document;
            return (
              <div className='mt-10'>
                <div className="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <h5 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white">KYC Document</h5>

                  <div className=" mt-5 items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
                    <div>
                      <p className='text-black mb-2 font-bold dark:text-white'>KYC Verification Type : {kycType}</p>
                      {
                        documentType !== 'FORM60' &&
                        <p className='text-black mb-2 font-bold dark:text-white'>Document Number : {documentNumber}</p>
                      }
                      {
                        kycType !== 'CKYC' &&
                        <button>
                          <Link to={`https://drive.google.com/file/d/${fileId}/view`} target='_blank' className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center dark:text-white px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <div className="text-left rtl:text-right">
                              <i className="fa-solid fa-download mr-4"></i>
                              <span className="mt font-sans text-sm font-semibold">Download {documentType}</span>
                            </div>
                          </Link>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        {
          KycDocumentsSlice.error.message &&
          <div className='w-fit mx-auto mt-10 text-[30px]'>
            <i className="fa-solid fa-ban text-red-600 mr-2"></i>
            <span>{KycDocumentsSlice.error.message}</span>
          </div>
        }
      </div>
    </div>
  )
};

export default KycDocumentDownloader;