import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { checkAuthentication, reloadPage } from '../../../Shared/functions';
import { fixChassisNumberIssue } from './functions';
import Heading from '../../../Shared/Heading';
import { useDispatch, useSelector } from 'react-redux';
import AuthCheck from '../../../AuthCheck';

const ChassisNumberFixCertificates = () => {
  const [fixFor, setFixFor] = useState('');
  const [chassisNumber, setChassisNumber] = useState('');
  const [requestStatus, setRequestStatus] = useState({ msg: '', status: '' });

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const user = useSelector(({ loggedInUser }) => loggedInUser);

  const onSubmit = async () => {
    setRequestStatus({ msg: '', status: 'processing' });
    const response = await fixChassisNumberIssue({
      chassisNumber,
      fixFor,
      user
    });
    setRequestStatus({ msg: response.msg, status: response.msg });

    if (response.status === 'success') {
      reloadPage();
    }
  };

  const updateChanges = (e) => {
    const event = e.target.id;
    const currentValue = e.target.value;

    switch (event) {
      case 'certificateInsurance':
        setFixFor(currentValue);
        break;
      case 'chassisNumber':
        setChassisNumber(currentValue);
        break;
      default:
        throw Error('No such case');
    }
  };

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title="Fix Already Existing Chassis Number Issue"></Heading>
      <form
        className="flex flex-col justify-center items-center gap-y-5"
        onSubmit={() => onSubmit()}
      >
        <div className="custom-form-group">
          <label htmlFor="certificateInsurance">Fix Chassis Number for: </label>
          <div className="p-4 ps-4 custom-input">
            <select
              className="bg-transparent outline-none w-full"
              id="certificateInsurance" onChange={updateChanges}>
              <option value="">--Select--</option>
              <option value="insurance">Insurance</option>
              <option value="certificate">Certificate</option>
            </select>
          </div>
        </div>
        <div className="custom-form-group">
          <label htmlFor="chassisNumber">Chassis Number</label>
          <input
            className="p-4 ps-4 custom-input"
            type="text"
            id="chassisNumber"
            placeholder="XXXX0000"
            onChange={updateChanges}
          />
        </div>
        <button
          type="submit"
          className="custom-btn text-xl mt-2"
          disabled={requestStatus.status === 'processing' ? true : false}
          hidden={requestStatus.status === 'success' ? true : false}
        >
          Submit
        </button>
      </form>
      {requestStatus.msg ? <div>{requestStatus.msg}</div> : null}
    </div>
  );
};

export default ChassisNumberFixCertificates;
