import * as yup from 'yup';

const newPricelistSubmitSchema = yup.object().shape({
  dealerName: yup.string().required('Dealer Name is mandatory'),
  rto: yup.string().required('RTO is required').matches(/\w\w\d\d/, 'Invalid RTO Code Entered').max(4, 'Invalid RTO Code Entered'),
  pincode: yup.string().required('Pincode is required').matches(/\d\d\d\d\d\d/, 'Invalid Pincode').min(6, 'Invalid Pincode Entered').max(6, 'Invalid Pincode Entered'),
  manufacturer: yup.string().required('Manufacturer Name is required'),
  policyCopyId: yup.string().required('Policy Copy File is required'),
  secondPolicyCopyId: yup.string().required('Second Policy Copy File is required'),
  priceListId: yup.string().required('Dealer Price List File is required'),
  nilDepPreferences: yup.string().required('Nil Dep Preference is required'),
  currentDealerInsurer: yup.string().required('Dealer\'s Current Insurer Is Required'),
  currentDealerPayoutBike: yup.string().required('Current Dealer Payout On Bike is required').matches(/[0-9]+/, 'Invalid Payout Value'),
  currentDealerPayoutScooter: yup.string().required('Current Dealer Payout On Scooter is required').matches(/[0-9]+/, 'Invalid Payout Value'),
  requiredPayoutBike: yup.string().required('Required payout for Scooter not entered').matches(/[0-9]+/, 'Invalid Payout Value'),
  requiredPayoutScooter: yup.string().required('Required payout for Scooter not entered').matches(/[0-9]+/, 'Invalid Payout Value'),
  currentDealerPayoutBikeType: yup.string().required('Current Dealer Payout Type is Required'),
  currentDealerPayoutScooterType: yup.string().required('Current Dealer Payout Scooter Type is required'),
  requiredDealerPayoutBikeType: yup.string().required('Required Payout Bike Type is mandatory'),
  requiredDealerPayoutScooterType: yup.string().required('Required Payout Scooter Type is mandatory'),
  expectedBikes: yup.string().required('Please Enter Expected BIKE Count').matches(/[0-9]+/g, 'Please Enter Valid Count'),
  expectedScooter: yup.string().required('Please Enter Expected Scooter Count').matches(/[0-9]+/g, 'Please Enter Valid Count'),
});

export default newPricelistSubmitSchema;