import React from 'react';
import Heading from '../../../Shared/Heading.jsx';
import { useParams } from 'react-router';
import { PostAnUpdate } from './components';

const ClaimsFollowUp = () => {

  const { id } = useParams();

  return (
    <div>
      <Heading title={'Claims'} />
      <PostAnUpdate id={id} />
    </div>
  )
}

export default ClaimsFollowUp
