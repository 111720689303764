import dayjs from 'dayjs';
import * as xlsx from 'xlsx';

const exportRecordsToExcel = (records) => {
  const fileHeaders = [
    'ACPL TICKET ID',
    'DEALER NAME',
    'ZONE',
    'SALES MANAGER',
    'STATE',
    'CUSTOMER NAME',
    'POLICY NUMBER',
    'INSURANCE COMPANY',
    'INSURER BRANCH',
    'CANCELLATION REASON',
    'ALTERNATE POLICY NUMBER',
    'DESCRIPTION',
    'STATUS',
    'IN TAT',
    'CREATED AT',
    'CLOSED AT',
    'UPDATES',
    'AMOUNT',
    'UPDATED AT',
  ];
  const exportData = [[...fileHeaders]];

  Object.keys(records).map((record, index) => {
    const insuranceCompanyName = records[record].insurance_company?.split('-')[0]?.trim();
    const insuranceCompanyBranch = records[record].insurance_company?.split('-')[1]?.trim();
    const updates = JSON.parse(records[record].updates.replaceAll('\n', '~')).reduce(
      (str, obj) =>
        (str += `[${obj.narration.trim()}-${obj.updatedBy}-${dayjs(obj.created_at).format(
          'DD/MM/YYYY hh:mm:ss'
        )}]`),
      ''
    );

    const stringifiedClosingComments = records[record].closing_comments
      ? records[record].closing_comments.replaceAll('\n', '\\n')
      : JSON.stringify('');
    const closingComments = JSON.parse(stringifiedClosingComments);
    const closedOn = closingComments ? closingComments[closingComments.length - 1].closedOn : '';

    const completeWithinTat =
      closingComments && closedOn ? dayjs.unix(closedOn).diff(dayjs(records[record].created_at), 'days') : '';

    const exportRecord = [
      `ACPL-CN-2024-${records[record].id}`,
      records[record].dealer_name,
      '',
      records[record].sales_manager,
      records[record].state,
      records[record].customer_name,
      records[record].policy_number,
      insuranceCompanyName,
      insuranceCompanyBranch,
      records[record].cancellation_reason,
      records[record].alternate_policy_number,
      records[record].additional_info,
      records[record].status,
      completeWithinTat !== '' ? (completeWithinTat >= 7 ? 'No' : 'Yes') : '',
      dayjs(records[record].created_at).format('DD-MM-YYYY HH:mm:ss'),
      !['initiated', 'pending'].includes(records[record].status) && records[record].updated_at
        ? dayjs(records[record].updated_at).format('DD-MM-YYYY HH:mm:ss')
        : '',
      updates,
      records[record].amount,
      records[record].updated_at && ['initiated', 'pending'].includes(records[record].status)
        ? dayjs(records[record].updated_at).format('DD-MM-YYYY HH:mm:ss')
        : '',
    ];

    exportData.push(exportRecord);
  });

  const wb = xlsx.utils.book_new();
  const endorsementsData = xlsx.utils.aoa_to_sheet(exportData);
  xlsx.utils.book_append_sheet(wb, endorsementsData, `Cancellation Logs`);

  const fileName = `Cancellations-Record-Logs.xlsx`;
  xlsx.writeFile(wb, fileName);
};

export { exportRecordsToExcel };
