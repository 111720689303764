import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { MutatingDots } from 'react-loader-spinner';

import schema from './schema.js';
import Heading from '../../../../Shared/Heading.jsx';
import InputError from '../../../../Shared/InputError.jsx';
import { onSubmit } from './functions.js';
import { DEALER_LOGS, STATUS_COLORS } from '../Shared/constants.js';
import { AWS_URL } from '../../../../env.js';
import { setDealerConfigLogs, setSelectedDealerConfigLog } from './redux.js';
import { checkAuthentication } from '../../../../functions.js';

const DealerConfigRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(true);
  const [error, setError] = useState("");

  const state = useSelector(({ dealerConfigLogs }) => dealerConfigLogs);
  console.log(state);

  useLayoutEffect(() => {
    (async () => {
      const path = window.location.pathname;
      const auth = await checkAuthentication(dispatch, navigate, path);
      if (!auth) navigate('/login');
      else {
        const res = await axios.get(`${AWS_URL}/dealer/config/requests`);
        dispatch(setDealerConfigLogs(res.data));
        setSubmitting(false);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const selectDealerConfigRequest = async (request) => {
    dispatch(setSelectedDealerConfigLog(request));
    navigate('/dealer/config/upload');
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <Heading title="Dealer Config" />
      <form
        onSubmit={handleSubmit((d) => onSubmit(d, setSubmitting, setError))}
        className="mt-10 flex flex-col items-start justify-center"
      >
        <p className="font-bold">Generate Enable/Update Dealer Config Request</p>
        <div className="custom-form-group">
          <label className="custom-label">Enter Dealer Email</label>
          <input
            className="custom-input p-2"
            {...register('dealerEmail', { shouldValidate: true })}
            placeholder="Dealer Email"
          />
          {errors.dealerEmail && <InputError error={errors.dealerEmail} />}
          {error != "" && <InputError error={{message: error}} />}
        </div>
        <button className="custom-btn mt-5" type="submit" disabled={submitting}>
          Submit
        </button>
      </form>
      {submitting ? (
        <div className="mt-10">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0d55d1"
            secondaryColor="#0d37d1"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="w-[1100px] mt-10 flex flex-col items-center justify-center">
          <Heading title="Previous Requests" />
          <div className="relative w-[1100px] m-10 mt-2 overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-max text-center mx-auto text-sm rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs uppercase bg-gray-700 text-[#d9d9d9]">
                <tr>
                  {Object.keys(DEALER_LOGS).map((logHeading, index) => (
                    <th scope="col" className="px-4 py-3" key={index}>
                      {logHeading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-xs text-black bg-gray-100">
                {state.logs.length != 0 &&
                  state.logs.map((row) => (
                    <tr key={row.id} className={STATUS_COLORS[row.status]}>
                      {Object.keys(DEALER_LOGS).map((logHeading) =>
                        logHeading === 'Upload Form' ? (
                          <td scope="col" className="px-4 py-3" key={DEALER_LOGS[logHeading]}>
                            {row.status === 'Pending' && (
                              <button onClick={() => selectDealerConfigRequest(row)} className="custom-btn">
                                Upload
                              </button>
                            )}
                          </td>
                        ) : (
                          <td scope="col" className="px-4 py-3" key={row[DEALER_LOGS[logHeading]]}>
                            {row[DEALER_LOGS[logHeading]]}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealerConfigRequest;
