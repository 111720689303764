import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  monthlyData: {
    data: [],
    labels: [],
    policiesCount: [],
  },
  insurerData: {
    data: [],
    labels: [],
    policiesCount: [],
  },
  salesManager: {
    data: [],
    labels: [],
    policiesCount: []
  }
};

export const RevenueSlice = createSlice({
  name: 'RevenueSlice',
  initialState,
  reducers: {
    setMonthlyData: (state, action) => {
      state.monthlyData.monthlyCount = action.payload;
    },
    setLabels: (state, action) => {
      state.monthlyData.labels = action.payload;
    },
    setPoliciesCount: (state, action) => {
      state.monthlyData.policiesCount = action.payload;
    },
    setInsurerData: (state, action) => {
      state.insurerData.data = action.payload;
    },
    setInsurerLabels: (state, action) => {
      state.insurerData.labels = action.payload;
    },
    setInsurerPoliciesCount: (state, action) => {
      state.insurerData.policiesCount = action.payload;
    },
    setSalesManagerData: (state, action) => {
      state.salesManager.data = action.payload;
    },
    setSalesManagerLabels: (state, action) => {
      state.salesManager.labels = action.payload;
    },
    setSalesManagerPoliciesCount: (state, action) => {
      state.salesManager.policiesCount = action.payload;
    }
  }
});

export const { setMonthlyData, setLabels, setPoliciesCount, setInsurerData, setInsurerLabels, setInsurerPoliciesCount, setSalesManagerData, setSalesManagerLabels, setSalesManagerPoliciesCount } = RevenueSlice.actions;

export default RevenueSlice.reducer;