import { configureStore } from "@reduxjs/toolkit";
import dealersReducer from "./features/dealersSlice.js";
import inspectionReducer from './features/inspectionData.js';
import dealerInsuranceDiscount from './features/dealerDiscountSlice.js';
import dealerPaymentLogs from './features/dealerPaymentLogsSlice.js';
import newUser from './features/newUserSlice.js';
import pricelist from '../Pages/PriceList/Create Pricelist/redux.js';
import loggedInUser from '../Pages/Login/redux.js';
import priceListData from '../Pages/PriceList/View/redux.js';
import PriceListWorkingSlice from '../Pages/PriceList/Upload Working/redux.js';
import DealerReportGenerator from '../Pages/Report Generator/redux.js';
import KycDocumentsDownloadSlice from '../Pages/Bpo/KYC Document Downloader/redux.js';
import chassisNumberDetails from "../Pages/Search Policy/redux.js";
import RevenueSlice from '../Pages/Revenue/Policies Count/redux.js';
import InsurancePoliciesView from '../Pages/Insurance Policies/View/redux.js';
import RmStats from '../Pages/RM Stats/redux.js';
import InsurerBranch from '../Pages/Revenue/Insurer Branch Data/redux.js';
import RmStatsBST from '../Pages/Revenue/RM Stats/redux.js';
import dealerConfigLogsReducer from '../Pages/Ops/Dealer Config/Request/redux.js';
import Claims from '../Pages/Claims ( Renewed )/redux.js';
import DealerOnBoarding from '../Pages/dealers/redux.js';
import Endorsements from '../Pages/Ops/Endorsements/redux.js';
import Cancellations from '../Pages/Ops/Cancellations/redux.js';
import BusinessSupportTeam from '../Pages/BST/redux.js';
import  InsuranceView from "../Pages/Policies/Insurance View/redux.js";

export const store = configureStore({
  reducer: {
    dealersData: dealersReducer,
    inspectionData: inspectionReducer,
    dealerInsuranceDiscount: dealerInsuranceDiscount,
    dealerPaymentLogs: dealerPaymentLogs,
    newUser: newUser,
    pricelist: pricelist,
    loggedInUser: loggedInUser,
    priceListData: priceListData,
    PriceListWorkingSlice: PriceListWorkingSlice,
    DealerReportGenerator: DealerReportGenerator,
    KycDocuments: KycDocumentsDownloadSlice,
    chassisNumberDetails: chassisNumberDetails,
    Revenue: RevenueSlice,
    InsurancePoliciesView: InsurancePoliciesView,
    RmStats: RmStats,
    InsurerBranchData: InsurerBranch,
    RmStatsBST: RmStatsBST,
    dealerConfigLogs: dealerConfigLogsReducer,
    Claims,
    DealerOnBoarding,
    Endorsements,
    Cancellations,
    BusinessSupportTeam,
    InsuranceView
  },
  devTools: process.env.NODE_ENV === 'development'
});