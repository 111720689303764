import React, { useState } from 'react';
import Heading from '../../../../Shared/Heading';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputError, Required } from './components';
import { postCancellationUpdate } from './functions.js';
import cancellationUpdatesSchema from './cancellationUpdatesSchema';
import { useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner';

const PostCancellationUpdate = () => {
  const { id } = useParams();

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const [addCancellationUpdateStatus, setAddCancellationUpdateStatus] = useState({
    status: '',
    msg: '',
    hideForm: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(cancellationUpdatesSchema) });

  const navigate = useNavigate();

  const onSubmitForm = async (formDetail) => {
    setSubmitting(true);
    const { description, notifyDealer } = formDetail;
    const cleanDescription = description.replaceAll('\n', '~');
    const { status, msg } = await postCancellationUpdate(cleanDescription, id, name, notifyDealer);

    setAddCancellationUpdateStatus({
      status,
      msg,
      hideForm: status === 200 ? true : false,
    });

    setSubmitting(false);

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/cancellations/view');
      }, 1500);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen">
      <Heading title={`Post An Update #ACPL-CN-2024-${id}`} />

      {addCancellationUpdateStatus?.status === 200 && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{addCancellationUpdateStatus?.msg}</div>
        </div>
      )}

      {!addCancellationUpdateStatus.hideForm && (
        <form className="flex flex-col items-center justify-center mb-2 m-10 relative" noValidate onSubmit={handleSubmit(onSubmitForm)}>
          <div className="flex gap-4 items-center mb-4">
            <input
              {...register('notifyDealer')}
              type="checkbox"
              className="h-[20px] w-[20px] rounded-md"
            />
            <label htmlFor="cancellationReceipt" className="text-lg">
              Do you want to notify dealer?
            </label>
          </div>
          <label htmlFor="closeTicketComments" className="w-full block mb-2 text-sm font-medium text-gray-900">
            Follow Up Description <Required />
          </label>
          <textarea
            {...register('description')}
            className="block p-2.5 w-[600px] h-[200px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Any Comments..."
          ></textarea>
          <InputError error={errors.description}/>
          <button
            type="submit"
            className="text-white my-5  hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 focus:ring-blue-800"
            disabled={submitting}
          >
            Submit Update
          </button>
          {submitting && (
            <Oval
              visible={true}
              height="32"
              width="32"
              color="#3B82F6"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </form>
      )}

      {addCancellationUpdateStatus?.status === 500 && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{addCancellationUpdateStatus?.msg}</div>
        </div>
      )}
    </div>
  );
};

export default PostCancellationUpdate;
