import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logs: [],
};

const dealerConfigLogs = createSlice({
  name: 'dealerConfigLogs',
  initialState,
  reducers: {
    setDealerConfigLogs: (state, action) => {
      return { logs: [...action.payload] };
    },
    setSelectedDealerConfigLog: (state, action) => {
      return { logs: [...state.logs], selectedLog: { ...action.payload } };
    },
  },
});

export const { setDealerConfigLogs, setSelectedDealerConfigLog } = dealerConfigLogs.actions;
export default dealerConfigLogs.reducer;
