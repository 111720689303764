import { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setStatus, setWorkingFileId, setComments, setUploadStatus, setRequestId, setRequestStatus, setRequestMessage, resetStore, setBikePayoutBelow150cc, setScooterPayoutBelow150cc, setBikePayoutBelow350cc, setScooterPayoutBelow350cc, setScooterPayoutAbove350cc, setBikePayoutAbove350cc, setScooterPayoutTypeBelow150cc, setBikePayoutTypeBelow150cc, setBikePayoutTypeBelow350cc, setScooterPayoutTypeBelow350cc, setBikePayoutTypeAbove350cc, setScooterPayoutTypeAbove350cc } from './redux.js';
import { fileUpload, onSubmit } from './functions.js';
import { checkAuthentication } from '../../../functions.js';
import { InputError, Required } from './components.jsx';
import uploadWorkingSchema from './uploadWorkingSchema.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';


const UploadWorking = () => {

  const { requestId } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {

    dispatch(resetStore());

    const pathname = '/dealer/pricelist/upload-working';
    const auth = (async () => {
      const auth = await checkAuthentication(dispatch, navigate, pathname);
      if (!auth) navigate('/login');
    })
    auth();
    dispatch(setRequestId(requestId));
    setValue('status', 'completed');
  }, [])

  const dispatch = useDispatch();
  const pricelistWorking = useSelector(({ PriceListWorkingSlice }) => {
    return PriceListWorkingSlice;
  });

  // Get Logged In User Data
  const loggedInUserName = useSelector(({ loggedInUser }) => {
    return loggedInUser.name;
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(uploadWorkingSchema) });

  const submitUploadWorking = async (e) => {
    await onSubmit(loggedInUserName, pricelistWorking, dispatch, navigate, resetStore, setRequestMessage, setRequestStatus);
  };

  return (
    <div className='m-10'>
      <form noValidate onSubmit={handleSubmit(submitUploadWorking)}>

        <div className="flex flex-col justify-center items-center">
          <ul className="items-center m-10 p-2 text-sm font-medium  border  rounded-lg sm:flex bg-gray-700 border-gray-600 text-white">
            <li className="border-b sm:border-b-0 sm:border-r border-gray-600">
              <div className="flex items-center ps-3">
                <label className="inline-flex items-center w-full cursor-pointer">
                  <input type="radio" checked={pricelistWorking.status === 'on hold'} onChange={() => {
                    dispatch(setStatus('on hold'))
                    setValue('status', 'on hold');
                  }} name='pending' value="pending" className="sr-only peer" />
                  <div className="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-300">On Hold</span>
                </label>
              </div>
            </li>
            <li className="border-b sm:border-b-0 sm:border-r border-gray-600">
              <div className="flex items-center ps-3">
                <label className="inline-flex items-center w-full cursor-pointer">
                  <input type="radio" checked={pricelistWorking.status === 'completed'} onChange={() => {
                    dispatch(setStatus('completed'));
                    setValue('status', 'completed');
                  }} name='completed' value="completed" className="sr-only peer" />
                  <div className="relative w-9 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-500 peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-300">Completed</span>
                </label>
              </div>
            </li>
          </ul>
        </div>

        {pricelistWorking.status === 'completed' ? <span className='font-bold'>Upload Price List Working <Required /></span> : null}
        {
          !pricelistWorking.workingFileId && pricelistWorking.status === 'completed' && <>
            <div className="flex items-center justify-center w-full">
              <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, PDF or XLSX</p>
                </div>
                <input onChange={async (e) => {
                  const fileId = await fileUpload(e, dispatch, setUploadStatus);
                  dispatch(setWorkingFileId(fileId));
                  setValue('workingFile', fileId);
                }} id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            {errors.workingFile && <InputError error={errors.workingFile} />}
          </>
        }

        {/* Failed Upload */}
        {
          pricelistWorking.uploadStatus === 'Failed'
          &&
          <div className="mt-4 flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <span className="font-medium"></span> Failed to Upload File. Please Try Again.
            </div>
          </div>
        }

        {/* Success Upload */}
        {
          pricelistWorking.uploadStatus === 'Success' && pricelistWorking.status === 'completed'
          &&
          <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <button type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
              </svg>
              File Uploaded Successfully
            </button>
            <button onClick={() => {
              dispatch(setWorkingFileId(null));
              dispatch(setUploadStatus(''));
            }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              New File?
            </button>
          </div>
        }


        {/* BELOW 150 CC */}
        {/* Bike Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="bikePayout" className="block mb-2 text-sm font-bold text-gray-900">Provided BIKE Payout (Below 150cc) <Required /></label>
            <input {...register('bikePayoutBelow150')} onChange={(e) => {
              dispatch(setBikePayoutBelow150cc(e.target.value));
            }} type="text" id="bikePayout" className="shadow-sm focus:ring-blue-300 focus:border-blue-300 border  text-sm rounded-lg block w-full p-2.5 bg-gray-600 text-white border-gray-600 dark:placeholder-gray-400  dark:shadow-sm-light" placeholder="2000 - Fixed" required />
            <select onInput={(e) => {
              dispatch(setBikePayoutTypeBelow150cc(e.target.value))
            }} {...register('bikePayoutTypeBelow150')} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.bikePayoutBelow150 && <InputError error={errors.bikePayoutBelow150} />}
            {errors.bikePayoutTypeBelow150 && <InputError error={errors.bikePayoutTypeBelow150} />}
          </div>
        }

        {/* Scooter Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="scooterPayout" className="block mb-2 text-sm font-bold text-gray-900">Provided SCOOTER Payout (Below 150cc) <Required /></label>
            <input {...register('scooterPayoutBelow150')} onChange={(e) => {
              dispatch(setScooterPayoutBelow150cc(e.target.value));
            }} type="text" id="scooterPayout" className="shadow-sm border text-sm rounded-lg focus:ring-blue-300 focus:border-blue-300 block w-full p-2.5 bg-gray-600 text-white border-gray-600 placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="20 - Percent" required />
            <select {...register('scooterPayoutTypeBelow150')} onInput={(e) => {
              dispatch(setScooterPayoutTypeBelow150cc(e.target.value))
            }} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.scooterPayoutBelow150 && <InputError error={errors.scooterPayoutBelow150} />}
            {errors.scooterPayoutTypeBelow150 && <InputError error={errors.scooterPayoutTypeBelow150} />}
          </div>
        }
        {/* BELOW 150 CC */}

        {/* Below 350 CC */}
        {/* Bike Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="bikePayoutBelow350" className="block mb-2 text-sm font-bold text-gray-900">Provided BIKE Payout (Below 350cc) <Required /></label>
            <input {...register('bikePayoutBelow350')} onChange={(e) => {
              dispatch(setBikePayoutBelow350cc(e.target.value));
            }} type="text" id="bikePayoutBelow350" className="shadow-sm focus:ring-blue-300 focus:border-blue-300 border  text-sm rounded-lg block w-full p-2.5 bg-gray-600 text-white border-gray-600 dark:placeholder-gray-400  dark:shadow-sm-light" placeholder="2000 - Fixed" required />
            <select onInput={(e) => {
              dispatch(setBikePayoutTypeBelow350cc(e.target.value))
            }} {...register('bikePayoutTypeBelow350')} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.bikePayoutBelow150 && <InputError error={errors.bikePayoutBelow150} />}
            {errors.bikePayoutTypeBelow150 && <InputError error={errors.bikePayoutTypeBelow150} />}
          </div>
        }

        {/* Scooter Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="scooterPayoutBelow350" className="block mb-2 text-sm font-bold text-gray-900">Provided SCOOTER Payout (Below 350cc) <Required /></label>
            <input {...register('scooterPayoutBelow350')} onChange={(e) => {
              dispatch(setScooterPayoutBelow350cc(e.target.value));
            }} type="text" id="scooterPayoutBelow350" className="shadow-sm border text-sm rounded-lg focus:ring-blue-300 focus:border-blue-300 block w-full p-2.5 bg-gray-600 text-white border-gray-600 placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="20 - Percent" required />
            <select onInput={(e) => {
              dispatch(setScooterPayoutTypeBelow350cc(e.target.value));
            }} {...register('scooterPayoutTypeBelow350')} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.scooterPayoutBelow150 && <InputError error={errors.scooterPayoutBelow150} />}
            {errors.scooterPayoutTypeBelow150 && <InputError error={errors.scooterPayoutTypeBelow150} />}
          </div>
        }
        {/* Below 350 CC */}

        {/* Above 350cc */}
        {/* Bike Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="bikePayoutAbove350" className="block mb-2 text-sm font-bold text-gray-900">Provided BIKE Payout (Above 350cc) <Required /></label>
            <input {...register('bikePayoutAbove350')} onChange={(e) => {
              dispatch(setBikePayoutAbove350cc(e.target.value));
            }} type="text" id="bikePayoutAbove350" className="shadow-sm focus:ring-blue-300 focus:border-blue-300 border  text-sm rounded-lg block w-full p-2.5 bg-gray-600 text-white border-gray-600 dark:placeholder-gray-400  dark:shadow-sm-light" placeholder="2000 - Fixed" required />
            <select onInput={(e) => {
              dispatch(setBikePayoutTypeAbove350cc(e.target.value));
            }} {...register('bikePayoutTypeAbove350')} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.bikePayoutAbove350 && <InputError error={errors.bikePayoutAbove350} />}
            {errors.bikePayoutTypeAbove350 && <InputError error={errors.bikePayoutTypeAbove350} />}
          </div>
        }

        {/* Scooter Provided Payout */}
        {
          pricelistWorking.status === 'completed' &&
          <div className="my-5 w-1/2">
            <label htmlFor="scooterPayoutAbove350" className="block mb-2 text-sm font-bold text-gray-900">Provided SCOOTER Payout (Above 350cc) <Required /></label>
            <input {...register('scooterPayoutAbove350')} onChange={(e) => {
              dispatch(setScooterPayoutAbove350cc(e.target.value));
            }} type="text" id="scooterPayoutAbove350" className="shadow-sm border text-sm rounded-lg focus:ring-blue-300 focus:border-blue-300 block w-full p-2.5 bg-gray-600 text-white border-gray-600 placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="20 - Percent" required />
            <select onInput={(e) => {
              dispatch(setScooterPayoutTypeAbove350cc(e.target.value));
            }} {...register('scooterPayoutTypeAbove350')} className='block my-1 text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg'>
              <option value=""> -- Select Payout Type -- </option>
              <option>₹</option>
              <option>%</option>
            </select>
            {errors.scooterPayoutAbove350 && <InputError error={errors.scooterPayoutAbove350} />}
            {errors.scooterPayoutTypeAbove350 && <InputError error={errors.scooterPayoutTypeAbove350} />}
          </div>
        }

        {/* Above 350cc */}

        <label for="message" className="block mt-5 mb-2 text-[15px] font-bold text-black">Narration / Comments {pricelistWorking.status === 'on hold' ? <Required /> : '( Optional )'}</label>
        <textarea {...register('comments')} onChange={(e) => dispatch(setComments(e.target.value))} id="message" rows="2" className="block p-2.5 w-full text-sm rounded-lg border  focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Any Comments..."></textarea>
        {errors.comments && <InputError error={errors.comments} />}


        <button type="submit" className="text-white my-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit Price List</button>

        {/* Error / Success Message */}

        {
          pricelistWorking.requestStatus === 200 &&
          <div className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <span className="font-medium"></span> Updated Successfully. <br></br>
            </div>
          </div>
        }

        {
          pricelistWorking.requestStatus === 500 &&
          <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <span className="font-medium"></span> Unable to complete request. Please Try Again
            </div>
          </div>
        }

      </form>
    </div>
  )
}

export default UploadWorking;
