import axios from 'axios';
import { URL } from '../../../../env.js'

const postAnUpdateEndorsement = async (description, id, name) => {

  const options = {
    url: `${URL}/ops/update/record`,
    method: 'POST',
    data: JSON.stringify({ description, id, name }),
    headers: {
      'content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const { status, data } = (await request);

    return {
      status,
      msg: data?.msg
    }

  } catch (err) {
    return {
      status: 500,
      msg: 'Unable to complete request'
    }
  }

};

export {
  postAnUpdateEndorsement
};