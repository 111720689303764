import { Route, Routes } from 'react-router';
import { NextUIProvider } from "@nextui-org/react";


// Routes
import Dealers from './Components/Dealers.jsx';
import LoginForm from './Pages/Login/index.jsx';
import ManufacturerUpdate from './Components/Forms/ManufacturerUpdate.jsx';
import UpdateDealerPassword from './Components/Forms/UpdateDealerPassword.jsx';
import Homepage from './Components/Homepage.jsx';
import ChassisNumberFixCertificates from './Pages/Ops/Fix Chassis Number/index.jsx';
import UpdateDealerPermits from './Components/Forms/UpdateDealerPermits.jsx';
import AddBalanceDealerPermission from './Components/Forms/AddBalanceDealerPermission.jsx';
import HideUnhidePayout from './Components/Forms/HideUnhidePayout.jsx';
import UpdateDealerPaymentRequest from './Components/Forms/UpdateDealerPaymentRequest.jsx';
import RedirectToHome from './Components/RedirectToHome.jsx';
import Navbar from './Components/Navbar.jsx';
import DealerCard from './Components/DealerCard.jsx';
import TransferUnitedToRS from './Components/Forms/TransferUnitedToRS.jsx';
import GetInspectionFiles from './Components/Forms/GetInspectionFiles.jsx';
import GetDealerInsuranceDiscount from './Components/Forms/GetDealerInsuranceDiscount.jsx';
import DealerPaymentLogs from './Components/Forms/DealerPaymentLogs.jsx';
import SignUp from './Components/SignUp.jsx';
import ResetPassword from './Components/ForgotPassword.jsx';
import RmPriceList from './Pages/PriceList/Create Pricelist/index.jsx';
import PriceLists from './Pages/PriceList/View/index.jsx';
import EditPriceListRequest from './Pages/PriceList/Revise/index.jsx';
import UploadWorking from './Pages/PriceList/Upload Working/index.jsx';
import DealerReportGenerator from './Pages/Report Generator/index.jsx';
import Profile from './Pages/Profile/index.js';
import KycDocumentDownloader from './Pages/Bpo/KYC Document Downloader/index.jsx';
import SearchPolicy from './Pages/Search Policy/index.js';
import Revenue from './Pages/Revenue/Policies Count/index.jsx';
import NextUI from './Pages/NextUI/index.jsx';
import { ThemeProvider } from 'next-themes';
import InsurancePoliciesView from './Pages/Insurance Policies/View/index.jsx';
import RmStats from './Pages/RM Stats/index.jsx';
import RmStatsBst from './Pages/Revenue/RM Stats/index.jsx';
import DealerConfigRequest from './Pages/Ops/Dealer Config/Request/index.jsx';
import UploadDealerConfig from './Pages/Ops/Dealer Config/Upload/index.jsx';
// import RegisterClaimReport from './Pages/Claims/Register Claim Report/index.jsx';
// import ViewClaims from './Pages/Claims/View Claims/index.jsx';


// Claims
import ClaimRegistration from './Pages/Claims ( Renewed )/register/index.jsx';
import ViewClaimRecords from './Pages/Claims ( Renewed )/view/index.jsx';
import ViewClaimDetails from './Pages/Claims/View Claim/index.jsx';
import EditClaimDetails from './Pages/Claims/Edit Claim Details/index.js';
import UpdateClaimStatusAndDetails from './Pages/Claims/Update Claim Status/index.jsx';
import RejectClaim from './Pages/Claims/Approve Reject Claim/index.jsx';
// Claims

// Dealer Onboarding
import OnBoardDealer from './Pages/dealers/onboarding/index.js';
import EndorsementsRecordRegister from './Pages/Ops/Endorsements/Register/index.jsx';
import EndorsementsView from './Pages/Ops/Endorsements/View/index.jsx';
import PostEndorsementUpdate from './Pages/Ops/Endorsements/Updates/index.jsx';
import ViewEndorsementDetails from './Pages/Ops/Endorsements/View Details/index.jsx';
import CloseEndorsementTicket from './Pages/Ops/Endorsements/Close Ticket/index.jsx';
import RegisterCancellations from './Pages/Ops/Cancellations/Register/index.jsx';
import CancellationsView from './Pages/Ops/Cancellations/View/index.jsx';
import ViewCancellationDetails from './Pages/Ops/Cancellations/View Details/index.jsx';
import CloseCancellationTicket from './Pages/Ops/Cancellations/Close Ticket/index.jsx';
import PostCancellationUpdate from './Pages/Ops/Cancellations/Updates/index.jsx';
import DealerCreationRequests from './Pages/BST/Dealer Creation Requests/View/index.jsx';
import EditOnBoardingDetails from './Pages/dealers/Edit Onboarding Details/index.js';
import ViewDealerConfig from './Pages/Ops/Dealer Config/View/index.jsx';
import StandardizeDB from './Pages/Ops/Dealer Config/Standardise DB/index.jsx';
import RejectDealerOnboardingRequest from './Pages/BST/Dealer Creation Requests/Rejection/index.jsx';
// Dealer Onboarding

import RejectCancellationTicket from './Pages/Ops/Cancellations/Reject Ticket/index.jsx';
import DealerList from './Pages/dealers/Dealer List/index.jsx';
import ClaimsFollowUp from './Pages/Claims ( Renewed )/followup/index.jsx';
import ViewClaimDetailed from './Pages/Claims ( Renewed )/view details/index.jsx';
import UpdateStatus from './Pages/Claims ( Renewed )/update/index.jsx';
import BulkClaimsUpload from './Pages/Claims ( Renewed )/bulk-upload/index.jsx';
import AddInsurerTicketNumber from './Pages/Ops/Cancellations/Quick Actions/AddInsurerTicketNumber/index.jsx';
import AddInsuranceTicketNumberEndorsement from './Pages/Ops/Endorsements/Quick Actions/AddInsurerTicketNumber/index.jsx'
import InsuranceView from './Pages/Policies/Insurance View/index.jsx';
import DealerDashboard from './Pages/dealers/dashboard/index.jsx';
// Routes

const App = () => {
  return (
    // <NextUIProvider>
    //   <ThemeProvider defaultTheme='dark'>
    //   <main>
      <div>
        <Navbar />
        <Routes className="dark text-foreground bg-background">
          <Route path="*" element={<RedirectToHome />} />
          <Route path="/aegiscovenant/opsit" element={<Homepage />} />
          <Route path="/nextui" element={<NextUI/>} />
          <Route path='/login' element={<LoginForm />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/policy/search' element={<SearchPolicy />} />
          <Route path='/aegiscovenant/opsit/dealers' element={<Dealers />} />
          <Route path='/aegiscovenant/opsit/dealers/:dealerCode' element={<DealerCard />} />

          {/* Makes */}
          <Route path='/insurance/makes' element={<StandardizeDB />} />
          
          {/* Price lists */}
          <Route path='/dealer/upload/pricelist' element={<RmPriceList />} />
          <Route path='/dealer/pricelist' element={<PriceLists />} />
          {/* Price lists */}
          
          <Route path='/dealer/pricelist/edit/:requestId' element={<EditPriceListRequest />} />
          <Route path='/dealer/pricelist/upload-working/:requestId' element={<UploadWorking />} />
          <Route path='/accounts/dealer-report-generator' element={<DealerReportGenerator />} />
          <Route path='/bpo/kyc-documents-downloader' element={<KycDocumentDownloader />} />
          {/* <Route path='/view/insurance/policies' element={<InsurancePoliciesView />} /> */}
          <Route path='/view/stats' element={<RmStats />} />
          <Route path='/revenue' element={<Revenue />} />
          <Route path='/claims/inspection-files' element={<GetInspectionFiles />} />
          <Route path='/dealer/payment-logs' element={<DealerPaymentLogs />} />
          <Route path='/dealer/discount-config' element={<GetDealerInsuranceDiscount />} />
          <Route path='/dealer/config/request' element={<DealerConfigRequest />} />
          <Route path='/dealer/config/upload' element={<UploadDealerConfig />} />
          <Route path='/dealer/config/view' element={<ViewDealerConfig/>} />
          <Route path='/dealer/dashboard' element={<DealerDashboard />} />
          <Route path='/revenue/rm/stats' element={<RmStatsBst />} />

          {/* Dealers list */}
          <Route path='/sales/dealerlist' element={<DealerList />} />
          {/* Dealers list */}
          
          {/* Claims */}
          <Route path='/claims/register' element={<ClaimRegistration />} />
          <Route path='/claims/view' element={<ViewClaimRecords />} />
          <Route path='/claims/post-update/:id' element={<ClaimsFollowUp />} />

          <Route path='/claims/details/:claimid' element={<ViewClaimDetailed />} />
          <Route path='/claims/edit/:claimid' element={<EditClaimDetails />} />
          <Route path='/claims/update/:id' element={<UpdateStatus />} />
          <Route path='/claims/:type/:claimid' element={<RejectClaim />} />
          <Route path='/claims/bulk-upload' element={<BulkClaimsUpload />} />
          {/* Claims */}
          
          {/* Dealer Onboarding  */}
          <Route path='/dealer/onboarding/:id' element={<OnBoardDealer/>} />
          <Route path='/dealer/onboarding/edit/:id' element={<EditOnBoardingDetails/>} />
          {/* Dealer Onboarding  */}

          {/* Endorsements */}
          <Route path='/ops/endorsements/register' element={<EndorsementsRecordRegister />} />
          <Route path='/ops/endorsements/view' element={<EndorsementsView />} />
          <Route path='/ops/endorsements/update/:id' element={<PostEndorsementUpdate />} />
          <Route path='/ops/endorsements/details/:id' element={<ViewEndorsementDetails />} />
          <Route path='/ops/endorsements/close/:id' element={<CloseEndorsementTicket />} />
          <Route path='/ops/endorsements/qa/add-insurer-ticket/:id' element={<AddInsuranceTicketNumberEndorsement />} />
          {/* Endorsements */}

          {/* Cancellations */}
          <Route path='/ops/cancellations/register' element={<RegisterCancellations />} />
          <Route path='/ops/cancellations/view' element={<CancellationsView />} />
          <Route path='/ops/cancellations/details/:id' element={<ViewCancellationDetails />} />
          <Route path='/ops/cancellations/update/:id' element={<PostCancellationUpdate />} />
          <Route path='/ops/cancellations/close/:id' element={<CloseCancellationTicket />} />
          <Route path='/ops/cancellations/reject/:id' element={<RejectCancellationTicket />} />
          <Route path='/ops/cancellations/qa/add-insurer-ticket/:id' element={<AddInsurerTicketNumber />} />
          {/* Cancellations */}

          {/* Business Support Team */}
          <Route path='/bst/dealer/onboarding/requests' element={<DealerCreationRequests />} />
          <Route path='/dealer/onboarding/reject/:id' element={<RejectDealerOnboardingRequest/>} />
          {/* Business Support Team */}

          <Route path='/ops/certificate-insurance-chassis-fix' element={<ChassisNumberFixCertificates />} />
          {/* Cancellations */}
          <Route path='/aegiscovenant/opsit/forms'>
          </Route>
          <Route path='/aegiscovenant/opsit/reset-password' element={<ResetPassword />} />
          <Route path='/aegiscovenant/opsit/signup' element={<SignUp />} />


          {/* Insurance Policies */}
          <Route path='/insurance/policies/view' element={<InsuranceView />} />
        </Routes>
      </div>
    //   </main>
    //   </ThemeProvider>
    // </NextUIProvider>
  )
};

export default App;
