export const VEHICLES_TYPES = {
    'bike': 'BIKE',
    'scooter': 'SCOOTER',
    'ev': 'EV',
    'bikeBackup': 'BIKE BACKUP',
    'scooterBackup': 'SCOOTER BACKUP',
    'evBackup': 'EV BACKUP',
    'bikeA': 'BIKE A',
    'bikeB': 'BIKE B',
    'bikeC': 'BIKE C',
    'scooterA': 'SCOOTER A',
    'scooterB': 'SCOOTER B',
    'scooterC': 'SCOOTER C',
    'evA': 'EV A',
    'evB': 'EV B',
    'evC': 'EV C',
}

export const newVehicleTypes = {
    'Bike': 'bike',
    'Scooter': 'scooter',
    'EV': 'ev',
    'Bike Backup': 'bikeBackup',
    'Scooter Backup': 'scooterBackup',
    'EV Backup': 'evBackup'
}

export const renewalVehicleTypes = {
    'Bike A': 'bikeA',
    'Bike B': 'bikeB',
    'Bike C': 'bikeC',
    'Scooter A': 'scooterA',
    'Scooter B': 'scooterB',
    'Scooter C': 'scooterC',
    'EV A': 'evA',
    'EV B': 'evB',
    'EV C': 'evC'
}

export const newPolicyTypes = {
    'OD1 + TP5': 'od1tp5',
    'Hire / Purchase': 'hirePurchase.new'
};

export const renewalPolicyTypes = {
    'OD1': 'od1',
    'OD1 + TP1': 'od1tp1'
}

export const policyCategory = ['New', 'Renewal']

export const initialState = {
    config: {},
    loading: false,
    vehicleTypeOptions: newVehicleTypes,
    policyTypeOptions: newPolicyTypes,
    selectedVehicleType: 'Bike',
    selectedPolicyType: 'OD1 + TP5',
    selectedCategory: 'New',
    selectedConfig: {},
    selectedConfigDetails: {}
}