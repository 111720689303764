import axios from 'axios';
import { URL } from '../../../env.js';
import { setDealersList } from '../redux.js';
import * as xlsx from 'xlsx';

const getDealerslist = async (username, department, dispatch) => {

  const options = {
    method: 'POST',
    url: `${URL}/rm/dealers-list`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({
      username,
      department
    })
  };

  const request = axios.request(options);
  const { data } = await request;

  dispatch(setDealersList(data.list))

};

// Get Dealer Credit Debit History
const debitCreditHistory = async (dealerId, dealerName) => {

  try {
    const options = {
      method: 'POST',
      url: `${URL}/dealer/credit-debit-history`,
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify({ dealerId, dealerName })
    };

    const request = axios.request(options);
    const { certificatesLogsData, insuranceLogsData, paymentLogsData, recoData } = (await request).data;

    const wb = xlsx.utils.book_new();

    const certificatesData = xlsx.utils.aoa_to_sheet(certificatesLogsData);
    const insuranceData = xlsx.utils.aoa_to_sheet(insuranceLogsData);
    const paymentLogs = xlsx.utils.aoa_to_sheet(paymentLogsData);
    const recoSheet = xlsx.utils.aoa_to_sheet(recoData);

    xlsx.utils.book_append_sheet(wb, recoSheet, `Reconciliation`);
    xlsx.utils.book_append_sheet(wb, insuranceData, `Insurance Policies`);
    xlsx.utils.book_append_sheet(wb, certificatesData, `Ceritificates`);
    xlsx.utils.book_append_sheet(wb, paymentLogs, `Payment Logs`);

    const fileName = `${dealerName}-Debit-Credit.xlsx`;
    xlsx.writeFile(wb, fileName);

    return {
      downloaded: true
    };

  } catch (err) {
    console.log(err);
    return {
      downloaded: false
    };
  }
};

// Pagination
const updatePagnitor = async (type, setPaginator) => {
  if (type === 'prev') {
    setPaginator((currentValue) => {
      return currentValue - 10;
    })
  } else {
    setPaginator((currentValue) => {
      return currentValue + 10;
    })
  }
};

export {
  getDealerslist,
  debitCreditHistory,
  updatePagnitor
};