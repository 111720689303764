import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const Homepage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  })

  return (
    <div>
      <h1>Welcome to OPSit</h1>
    </div>
  )
}

export default Homepage
