import * as yup from 'yup';

const registerEndorsementRecordSchema = yup.object().shape({
  policyNumber: yup.string().required('Policy Number is Mandatory'),
  insuranceCompanyName: yup.string().required('Insurance Company Name is Mandatory'),
  dealerName: yup.string().required('Dealer Name is Mandatory'),
  customerName: yup.string().required('Customer Name is Mandatory'),
  endorsementReason: yup.string().required('Endorsement Reason is Mandatory'),
  additionalInfo: yup.string().required('Additional Info is Mandatory'),
  endorsementParticular: yup.string().required('Endorsement Particular is Mandatory')
});

export default registerEndorsementRecordSchema;