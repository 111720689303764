import axios from 'axios';
import { baseUrl } from '../../../env';

// Fix already existing chassis number ( certificate & insurance )
const fixChassisNumberIssue = async (req) => {
  const { chassisNumber, fixFor, user } = req;

  console.log(user);

  const options = {
    method: 'POST',
    url: `${baseUrl}/fix-chassis-number`,
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ 
      requestedBy: user.name,
      operationPerformed: 'Fix Chassis Number (Insurance/Certificate)',
      payload: {
        chassisNumber, fixFor
      }
    }),
  };

  const request = axios.request(options);
  const res = await request;

  return { msg: res.data.msg, status: res.status === 200 ? 'success' : 'failed' };
};

export { fixChassisNumberIssue };
