// Error on failed validation of any input
const InputError = ({ error }) => {
  console.log(error);
  return <p className="text-sm font-semibold text-white my-1 bg-red-600 p-1 rounded-md">{error.message ? error.message : error}</p>;
};

// Required
const Required = () => {
  return <span className='text-red-400'>*</span>
};


export {
  InputError,
  Required
};