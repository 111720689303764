import * as yup from 'yup';

const editClaimDetailsSchema = yup.object().shape({
  dealerName: yup.string().required('Dealer Name is Mandatory'),
  status: yup.string().required(),
  customerName: yup.string().required('Customer Name is Mandatory'),
  claimType: yup.string().required('Claim Type is Mandatory'),
  insurancePolicyNumber: yup.string().required('Insurance Policy Number is Required'),
  state: yup.string().required('State is Required'),
  location: yup.string().required('Location is Required'),
  insurerName: yup.string().required('Insurer Name is Required'),
  insuranceClaimNumber: yup.string().when('status', (status, schema) => {
    if (status[0] === 'Insurer Intimated') {
      return schema.required('Insurance Claim Number is required.');
    }
  }),
  surveyor: yup.string().when('status', (status, schema) => {
    if (status[0] === 'Surveyor Appointed') {
      return schema.required('Surveyor is required.');
    }
  }),
  surveyorContactInfo: yup.string().when('status', (status, schema) => {
    if (status[0] === 'Surveyor Appointed') {
      return schema.required('Surveyor Contact Info is required.');
    }
  }),
  surveyorAppointmentDate: yup.string().when('status', (status, schema) => {
    if (status[0] === 'Surveyor Appointed') {
      return schema.required('Surveyor Appointment Date is required');
    }
  })
});

export default editClaimDetailsSchema;