import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  workingFileId: '',
  insuranceConfigFileId: '',
  comments: '',
  status: 'completed',
  requestId: '',
  uploadStatus: '',
  insuranceConfigFileUploadStatus: '',
  requestStatus: '',
  requestMessage: '',
  bikePayoutBelow150cc: '',
  bikePayoutTypeBelow150cc: '',
  scooterPayoutBelow150cc: '',
  scooterPayoutTypeBelow150cc: '',
  bikePayoutBelow350cc: '',
  bikePayoutTypeBelow350cc: '',
  scooterPayoutBelow350cc: '',
  scooterPayoutTypeBelow350cc: '',
  bikePayoutAbove350cc: '',
  bikePayoutTypeAbove350cc: '',
  scooterPayoutAbove350cc: '',
  scooterPayoutTypeAbove350cc: ''
};

export const PriceListWorkingSlice = createSlice({
  name: 'pricelistworking',
  initialState,
  reducers: {
    setBikePayoutBelow150cc: (state, action) => {
      state.bikePayoutBelow150cc = action.payload;
    },
    setBikePayoutTypeBelow150cc: (state, action) => {
      state.bikePayoutTypeBelow150cc = action.payload;
    },
    setScooterPayoutBelow150cc: (state, action) => {
      state.scooterPayoutBelow150cc = action.payload;
    },
    setScooterPayoutTypeBelow150cc: (state, action) => {
      state.scooterPayoutTypeBelow150cc = action.payload;
    },
    setBikePayoutBelow350cc: (state, action) => {
      state.bikePayoutBelow350cc = action.payload;
    },
    setBikePayoutTypeBelow350cc: (state, action) => {
      state.bikePayoutTypeBelow350cc = action.payload;
    },
    setScooterPayoutBelow350cc: (state, action) => {
      state.scooterPayoutBelow350cc = action.payload;
    },
    setScooterPayoutTypeBelow350cc: (state, action) => {
      state.scooterPayoutTypeBelow350cc = action.payload;
    },
    setBikePayoutAbove350cc: (state, action) => {
      state.bikePayoutAbove350cc = action.payload;
    },
    setBikePayoutTypeAbove350cc: (state, action) => {
      state.bikePayoutTypeAbove350cc = action.payload;
    },
    setScooterPayoutAbove350cc: (state, action) => {
      state.scooterPayoutAbove350cc = action.payload;
    },
    setScooterPayoutTypeAbove350cc: (state, action) => {
      state.scooterPayoutTypeAbove350cc = action.payload;
    },
    setWorkingFileId: (state, action) => {
      state.workingFileId = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload;
    },
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setRequestMessage: (state, action) => {
      state.requestMessage = action.payload;
    },
    setInsuranceConfigFileId: (state, action) => {
      state.insuranceConfigFileId = action.payload;
    },
    setInsuranceConfigFileUploadStatus: (state, action) => {
      state.insuranceConfigFileUploadStatus = action.payload;
    },
    resetStore: () => {
      return initialState;
    }
  }
});

export const { setBikePayoutBelow150cc, setBikePayoutTypeBelow150cc, setScooterPayoutBelow150cc, setScooterPayoutTypeBelow150cc, setBikePayoutBelow350cc, setBikePayoutTypeBelow350cc, setBikePayoutAbove350cc, setBikePayoutTypeAbove350cc, setScooterPayoutAbove350cc, setScooterPayoutTypeAbove350cc, setScooterPayoutBelow350cc, setScooterPayoutTypeBelow350cc, setWorkingFileId, setComments, setStatus, setUploadStatus, setRequestId, setRequestStatus, setRequestMessage, resetStore, setInsuranceConfigFileId, setInsuranceConfigFileUploadStatus } = PriceListWorkingSlice.actions;
export default PriceListWorkingSlice.reducer;