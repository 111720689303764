import React, { useLayoutEffect } from 'react';
import Heading from '../../../Shared/Heading.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { BasicDetails, Timeline } from './components.jsx';
import { useParams } from 'react-router';
import { setViewDetailsCard } from '../redux.js';

const ViewClaimDetailed = () => {

  const dispatch = useDispatch();
  const { claimid } = useParams();

  const { claimRecords } = useSelector(({ Claims }) => {
    return Claims;
  });

  useLayoutEffect(() => {
    const viewDetailsClaimCard = claimRecords.filter((record) => {
      return record.id === claimid;
    });

    dispatch(setViewDetailsCard(viewDetailsClaimCard));
  }, [claimRecords, dispatch]);

  return (
    <div>
      <Heading title={'Claim Ticket History'} />
      <BasicDetails />
      <Timeline />
    </div>
  )
}

export default ViewClaimDetailed;
