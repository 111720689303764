import React, { useState } from 'react'
import Heading from '../../../../Shared/Heading';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import endorsementUpdatesSchema from './endorsementUpdatesSchema';
import { InputError, Required } from './components';
import { postAnUpdateEndorsement } from './functions';
import { useSelector } from 'react-redux';

const PostEndorsementUpdate = () => {

  const { id } = useParams();

  const [addEndorsementUpdateStatus, setAddEndorsementUpdateStatus] = useState({
    status: '',
    msg: '',
    hideForm: false
  });

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(endorsementUpdatesSchema) });

  const navigate = useNavigate();

  const onSubmitForm = async (formDetail) => {
    const { description } = formDetail;
    const cleanDescription = description.replaceAll('\n', '~');
    const { status, msg } = await postAnUpdateEndorsement(cleanDescription, id, name);

    setAddEndorsementUpdateStatus({
      status, msg, hideForm: status === 200 ? true : false
    });

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/endorsements/view')
      }, 3000);
    }

  };

  return (
    <div className='w-1/2'>
      <Heading title={`Post An Update #ACPL-EN-2024-${id}`} />


      {
        addEndorsementUpdateStatus?.status === 200 &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 rounded-lg shadow text-gray-400 bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-green-800 text-green-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{addEndorsementUpdateStatus?.msg}</div>
        </div>
      }

      {
        !addEndorsementUpdateStatus.hideForm &&
        <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
          <div className="mb-5 m-10">
            <label htmlFor="description" className="block mb-2 text-sm font-bold text-gray-900">Follow Up Description <Required /></label>
            <textarea {...register('description')} id="description" className="block w-full p-2.5 text-sm rounded-lg border focus:ring-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:border-blue-500" placeholder="Any Comments..."></textarea>
            {errors.description && <InputError error={errors.description} />}
            <button type="submit" className="text-white my-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> <i className="fa-solid fa-pen-nib mr-1"></i> Post Follow Up Update</button>
          </div>
        </form>
      }

      {
        addEndorsementUpdateStatus?.status === 500 &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">

          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{addEndorsementUpdateStatus?.msg}</div>
        </div>
      }
    </div>
  )
}

export default PostEndorsementUpdate;
