import axios from 'axios';
import { checkAuthentication, reloadPage } from '../../../../Shared/functions';
import { AWS_URL } from '../../../../env';

const onSubmit = async (data, setSubmitting, setError) => {
  setSubmitting(true);
  setError("");
  const { dealerEmail } = data;
  const user = await checkAuthentication();

  const body = {
    requestedBy: user.name,
    operationPerformed: 'Dealer Config Request Generator',
    payload: {
      user,
      dealerEmail,
    },
  };

  try {
    const res = await axios.post(`${AWS_URL}/dealer/config/request`, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.data.status === 'success') {
      reloadPage();
    } else {
      setError(res.data.message);
      setSubmitting(false);
    }
  } catch (err) {
    setError("Something went wrong! Kindly verify dealer email");
    setSubmitting(false);
    console.log(err);
  }
};

export { onSubmit };
