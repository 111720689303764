import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  department: '',
  email: '',
  paths: {}
};

export const loggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        department: action.payload.dept,
        team: action.payload.team
      };
    },
    removeUser: (state, action) => {
      return { ...state, ...initialState };
    },
    setAllowedPaths: (state, action) => {
      state.paths = action.payload;
    }
  },
});

export const { setUser, removeUser, setAllowedPaths } = loggedInUserSlice.actions;
export default loggedInUserSlice.reducer;
