import { Link } from 'react-router-dom';

import { formatPayout, generatePriceListReport, getPriceListRequests, updatePickedBy } from './functions';
import { formatDate } from '../../../Shared/functions';
import LinkButton from '../../../Shared/LinkButton';
import axios from 'axios';
import { URL } from '../../../env';
import { setFilterActive, setPriceLists, setPricelistsFilterdView } from './redux';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';

const FilterRadioButton = ({ onChange, checked, id, title }) => {
  return (
    <div className="flex items-center ps-3">
      <label className="inline-flex items-center w-full cursor-pointer">
        <input
          checked={checked}
          type="radio"
          onChange={onChange}
          name={id}
          value={id}
          className="sr-only peer"
        />
        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-blue-600"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{title}</span>
      </label>
    </div>
  );
};

const FilterMenu = ({ filter, setFilter }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <ul className="items-center m-10 p-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        {/* Pending */}
        <li className="filter-btn">
          <FilterRadioButton
            checked={filter[0] === 'pending'}
            onChange={(e) => setFilter(['pending'])}
            id="pending"
            title="Pending"
          />
        </li>
        {/* Completed */}
        <li className="filter-btn">
          <FilterRadioButton
            checked={filter[0] === 'completed'}
            onChange={(e) => setFilter(['completed'])}
            id="completed"
            title="Completed"
          />
        </li>
        {/* On Hold */}
        <li className="filter-btn">
          <FilterRadioButton
            checked={filter[0] === 'on hold'}
            onChange={(e) => setFilter(['on hold'])}
            id="on hold"
            title="On Hold"
          />
        </li>
        {/* All */}
        <li className="dark:border-gray-600">
          <FilterRadioButton
            checked={filter[0] === 'all'}
            onChange={(e) => setFilter(['all', 'pending', 'completed', 'on hold'])}
            id="all"
            title="All"
          />
        </li>
      </ul>
    </div>
  );
};

const SubmitPriceListButton = () => {
  return (
    <div className="flex flex-row justify-start px-10">
      {/* Submit Price List Button */}
      <Link to="/dealer/upload/pricelist">
        <button
          type="button"
          className="font-bold text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg text-sm px-5 py-2.5 flex items-center dark:focus:ring-gray-500"
        >
          <svg
            className="w-4 h-4 me-2 -ms-1 text-[#626890]"
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="ethereum"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"
            ></path>
          </svg>
          Submit new price list
        </button>
      </Link>
    </div>
  );
};

const SearchPriceList = ({ pricelistFilteredView, department, user}) => {

  const [filter, setFilter] = useState();
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchInputRef = useRef('');

  const dispatch = useDispatch();
  const { priceLists, isFilterActive } = useSelector(({ priceListData }) => {
    return priceListData;
  })

  return (
    <div className="px-10 my-5">
      <div className="max-w-lg mx-auto">
        <div className="flex">
          <div className="relative w-full">
            <input value={searchInputValue} onChange={(e) => {
              setSearchInputValue(e.target.value)
            }} ref={searchInputRef} type="search" id="search-dropdown" className="block outline-none p-2.5 w-full z-20 text-sm border-s-2 border bg-gray-700 border-s-gray-700  border-gray-600 placeholder-gray-400 text-white rounded-s-lg" placeholder={`Search Dealer Name, Working By...`} required />
            <button onClick={() => {
              const searchInputValue = searchInputRef.current.value;
              pricelistFilteredView(searchInputValue, filter, priceLists, dispatch);
            }} type="submit" className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <select value={filter} onChange={(e) => {
            setFilter(e.target.value);
          }} id="dropdown" className="z-10 text-white text-sm bg-gray-700 px-2 rounded-e-lg outline-none divide-gray-100">
            <option value=""> -- Search -- </option>
            {
              (department !== 'SALES' || user === 'Madhav' || user === 'Amjad Pathan')  && <option value="sales_manager"> Sales Manager </option>
            }
            <option value="dealer_name"> Dealer Name </option>
            <option value="uploaded_by"> Working Done By </option>
            <option value="dealer_rto"> Dealer RTO </option>
          </select>
          {
            isFilterActive &&
            <button onClick={() => {
              dispatch(setPricelistsFilterdView(null));
              dispatch(setFilterActive(false));
              setSearchInputValue('');
              setFilter('');
            }} className='bg-gray-700 content-center m-1 px-2 rounded-lg'>
              <i className="fa-solid fa-trash text-red-600"></i>
            </button>
          }
          {/* <span>Delete Filter</span> */}
        </div>
      </div>

    </div>
  );
};

const TableRow = ({ record, user }) => {
  // Bike
  const payoutPreviewBike = formatPayout(record.required_payout_bike);
  // Scooter
  const payoutPreviewScooter = formatPayout(record.required_payout_scooter);
  // Current Bike Payout
  const currentPayoutPreviewBike = formatPayout(record.current_dealer_payout_bike);
  // Current Scooter Payout
  const currentPayoutPreviewScooter = formatPayout(record.current_dealer_payout_scooter);

  const color = `${record.status === 'pending' ? 'GoldenRod' : record.status === 'on hold' ? 'red' : 'lawngreen'
    }`;


  const dispatch = useDispatch();




  let ops_revenue_sheets;

  try {
    ops_revenue_sheets = JSON.parse(record.ops_revenue_sheet);
  } catch (err) {
    ops_revenue_sheets = record.ops_revenue_sheet;
  }

  const working_file = typeof (ops_revenue_sheets);
  const workings = [];

  if (working_file === 'string') {
    workings.push(ops_revenue_sheets);
  } else {
    workings.push(...ops_revenue_sheets);
  }

  return (
    <tr className="table-row">
      <td className="w-4 p-4">
        <div className="flex items-center"></div>
      </td>
      <th scope="row" className="px-4 py-4 font-bold whitespace-nowrap" style={{ color }}>
        {record.id}
      </th>
      {user.department !== 'SALES' &&
        <td className="px-4 py-4">
          {record.sales_manager}
        </td>
      }
      <td className="px-4 py-4">{record.dealer_name}</td>
      <td className="px-4 py-4">{record.dealer_rto}</td>
      <td className="px-4 py-4">{record.dealer_pincode}</td>
      <td className="px-4 py-4">{record.manufacturer_name}</td>
      <td className="px-4 py-4">{currentPayoutPreviewBike}</td>
      <td className="px-4 py-4">{payoutPreviewBike}</td>
      <td className="px-4 py-4">{currentPayoutPreviewScooter}</td>
      <td className="px-4 py-4">{payoutPreviewScooter}</td>
      <td className="px-4 py-4">{record.nil_dep_preferences}</td>
      <td className="px-4 py-4">{record.current_dealer_insurer}</td>
      {/* <td className="px-4 py-4">{record.preferred_insurer}</td> */}
      <td className="px-4 py-4">{record.other_requirements}</td>
      <td>
        <LinkButton
          link={`https://drive.google.com/file/d/${record.policy_copy_1}/view`}
          title={<><i className="fa-solid fa-eye mr-2"></i> <span>View Policy Copy</span></>}
          type="file"
        />
      </td>
      <td>
        <LinkButton
          link={`https://drive.google.com/file/d/${record.policy_copy_2}/view`}
          title={<><i className="fa-solid fa-eye mr-2"></i> <span>View Policy Copy</span></>}
          type="file"
        />
      </td>
      <td>
        <LinkButton
          link={`https://drive.google.com/file/d/${record.price_list}/view`}
          title={<><i className="fa-solid fa-eye mr-2"></i> <span>View Price List</span></>}
          type="file"
        />
      </td>
      <td className="px-4 py-4" style={{ color }}>
        {record.status.toUpperCase()}
      </td>

      {
        ['ADMIN'].includes(user.department) && (
          <td>
            {record.status === 'completed' && record.onboarding_request === 'pending' ? <LinkButton link={`/dealer/onboarding/${record.id}`} title={<><i className="fa-solid fa-handshake mr-2"></i> <span>On Board Dealer</span></>} /> : record.onboarding_request === 'processing' ? <LinkButton title={<><i className="fa-solid fa-handshake mr-2"></i> <span>OnBoarding In Progress</span></>} /> : record.onboarding_request === 'onboarded' ? <LinkButton title={<><i className="fa-solid fa-handshake mr-2"></i> <span>Dealer On Boarded</span></>} /> : record.onboarding_request === 'rejected' ? <>
              <LinkButton title={<><i className="fa-solid fa-x mr-2 text-red"></i> <span>Request Rejected</span></>} />
              <LinkButton link={`/dealer/onboarding/edit/${record.id}`} title={<><i className="fa-solid fa-pen mr-2"></i> <span>Edit & Request Again</span></>} />
            </> : null}
          </td>
        )
      }

      <td className="px-4 py-4 font-bold" style={{ color }}>
        {record.comments}
      </td>

      {
        ['ADMIN', 'BUSINESS SUPPORT'].includes(user.department) &&
        <td className="px-4 py-4">
          {
            record.status === 'completed' ? <button className='bg-green-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-thumbs-up mr-2"></i>{record.uploaded_by}</button> :
              !record.picked_by ?
                <button onClick={() => updatePickedBy('NP', record, user.name, dispatch, setPriceLists)} className='bg-blue-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-hand mr-2"></i> Pick Up</button> : <button onClick={() => {
                  if (record.picked_by === user.name) {
                    updatePickedBy('P', record, user.name, dispatch, setPriceLists)
                  }
                }} className='bg-green-600 text-white p-2 rounded-lg'> <i className="fa-solid fa-person-digging mr-2"></i> {record.picked_by}</button>
          }
        </td>
      }

      {['ADMIN', 'BUSINESS SUPPORT'].includes(user.department) && (
        <td>
          {
            workings.map((file) => {
              return <span className='mr-2'>
                <LinkButton type='file' link={`https://drive.google.com/file/d/${file}/view`} title={<div><><i className="fa-solid fa-eye mr-2"></i> <span>Working</span></></div>} />
                <button disabled={(record.picked_by !== user.name || record.uploaded_by !== user.name)} id={file} onClick={async (e) => {
                  const fileId = file;
                  const requestId = record.id;

                  const options = {
                    url: `${URL}/bst/delete-file`,
                    headers: {
                      'content-type': 'application/json'
                    },
                    method: 'POST',
                    data: JSON.stringify({ fileId, requestId })
                  };

                  const request = axios.request(options);
                  const { status } = (await request);

                  if (status === 200) {
                    const { records } = await getPriceListRequests(user.email, user.department, '');
                    dispatch(setPriceLists(records));
                  }

                }} key={file} className='hover:text-red-800 p-2 bg-blue-600 rounded-lg'><i className="fa-solid fa-trash text-red-600"></i></button>
              </span>
            })
          }
          {
            record.status !== 'completed' ? <LinkButton type='' link={`/dealer/pricelist/upload-working/${record.id}`} title={<> <i className="fa-solid fa-upload mr-2"></i> <span>Upload Payout Working Sheet </span></>} /> : null
          }

          {
            record.status === 'completed' &&
            <LinkButton
              type={''}
              link={`/dealer/pricelist/upload-working/${record.id}`}
              title={<><i className="fa-solid fa-square-plus"></i></>}
            />
          }
        </td>
      )}

      {
        ['ADMIN', 'BUSINESS SUPPORT'].includes(user.department) && (
          <td className="px-4 py-4">{record.reworkings}</td>
        )
      }
      {['ADMIN', 'SALES'].includes(user.department) && (
        <td>
          <LinkButton
            link={
              `/dealer/pricelist/edit/${record.id}`
            }
            title={<><i className="fa-solid fa-pen-to-square mr-2"></i> <span>Revise Request</span></>}
          />
        </td>
      )}
      {(['ADMIN', 'SALES'].includes(user.department)) && (
        <td className="px-4 py-4">
          {record.ops_revenue_sheet === '-' ? (
            '-'
          ) : (
            workings.map((revenue_sheet) => {
              return (
                <LinkButton
                  link={`https://drive.google.com/file/d/${revenue_sheet}/view`}
                  title={<><i className="fa-solid fa-eye mr-2"></i><span>Revenue Sheet</span></>}
                  type="file"
                />
              )
            })
          )}
        </td>
      )}
      <td className="px-4 py-4">{record.status === 'completed' ? record.uploaded_by.toUpperCase() : ''}</td>
      <td className="px-4 py-4">{formatDate(record.submitted_at)}</td>
      <td className="px-4 py-4">{record.updated_at && formatDate(record.updated_at)}</td>
    </tr>
  );
};

const LinkButtonPricelist = ({ link, title, type }) => {
  return (
    <Link aria-disabled to={link} target={type === 'file' ? '_blank' : '_self'} className="text-blue-400 font-bold">
      <button type="button" className={`button text-[10px]`}>
        {title}
      </button>
    </Link>
  );
};


// Team Wise Data Download
const DownloadReport = ({team, setDownloadingReport, downloadingReport}) => {
  return (
    <div>
      <button onClick={async () => {
        setDownloadingReport(true);
        await generatePriceListReport(team);
        setDownloadingReport(false);
      }} className='bg-green-400 align-middle p-2 rounded-lg ml-2 text-sm font-bold'> { downloadingReport ? <Oval height={'15px'}/> : <i class="fa-solid fa-file-arrow-down mr-2"></i> }{ downloadingReport ? '' : 'Report'}</button>
    </div>
  )
};

export { FilterMenu, FilterRadioButton, SearchPriceList, SubmitPriceListButton, TableRow, LinkButtonPricelist, DownloadReport };
