import { useDispatch, useSelector } from 'react-redux';
import { toBase64, uploadFileToDrive } from '../../../../functions';

// File Uploads
const fileUpload = async (e) => {
  const file = await (e.target.files[0]);
  const extension = file.type.split('/')[1];
  const fileType = file.type.split('/')[0];
  const base64Data = await toBase64(file);
  const fileId = await uploadFileToDrive(base64Data, extension, fileType);

  return fileId;
};


// Error on failed validation of any input
const InputError = ({ error }) => {
  return (
    <p className="text-sm text-red-500 mt-1">
      {error?.message ? error?.message : error}
    </p>
  );
};

// Required
const Required = () => {
  return <span className='text-red-400'>*</span>
};

// Form Input Fields
const FormInputs = ({ className, id, title, placeholder, register, errors, dispatchFn, type}) => {

  const dispatch = useDispatch();

  return <div className={className}>
    <label htmlFor={id} className="block mb-2 text-sm font-bold text-gray-900">{title} <Required /> </label>
    <input onInput={(e) => {
      dispatch(dispatchFn({
        type,
        data: e.target.value}))
    }} {...register(id)} type="text" id={id} className="shadow-sm border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 shadow-sm-light" placeholder={placeholder} required />
    {errors[id] && <InputError error={errors[id]} />}
  </div>
};

// Dropdown
const Dropdown = ({ label, id,setter,options, value }) => {
  return (
    <div className="custom-form-group">
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label} <Required />
      </label>
      <div className="relative h-[40px]">
        <button
          className={`h-[40px] w-[400px] text-gray-900 border border-gray-300 bg-gray-50 font-semibold rounded-lg text-sm px-2.5 py-2.5 flex items-center justify-between`}
          type="button"
          onClick={() => {
            const ele = document.getElementById(id);
            if (ele.style.display === 'none') {
              ele.style.display = 'block';
            } else {
              ele.style.display = 'none';
            }
          }}
        >
          <div className="w-full max-h-[40px] text-left text-ellipsis overflow-hidden text-nowrap">
            {value}{' '}
          </div>
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id={id}
          style={{ display: 'none' }}
          className="absolute top-[50px] z-10 bg-white divide-y divide-gray-100 overflow-hidden rounded-lg shadow w-[300px]"
        >
          <ul
            onClick={(e) => {
              console.log("clicked", e.target)
              setter(id, e.target.innerText);
              const ele = document.getElementById(id);
              if (ele.style.display === 'none') {
                ele.style.display = 'block';
              } else {
                ele.style.display = 'none';
              }
            }}
            className="text-xs text-gray-700 max-h-[300px] overflow-scroll w-[300px] cursor-pointer"
          >
            {options.map((option) => (
              <li>
                <p className="block px-4 py-2 hover:bg-gray-100">{option}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};



// File Uploads
const FileUpload = ({title, dispatchFn, setValue, id, errors}) => {

  const dispatch = useDispatch();

  const { dealershipDetails } = useSelector(({DealerOnBoarding}) => {
    return DealerOnBoarding;
  });

  return (
    <div>
      <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="user_avatar">{title} <Required /></label>
      {
        !dealershipDetails[id] && 
        <input onChange={async (e) => {
          const fileId = await fileUpload(e);
          dispatch(dispatchFn(fileId));
          setValue(id, fileId);
        }} className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
      }
      {/* Success Message */}
      {
        dealershipDetails[id] && 
        <div id="alert-additional-content-3" className="p-1.5 mb-2 border rounded-lg bg-green-50 dark:bg-gray-800 text-green-400 border-green-800" role="alert">
          <button type="button" className="text-white bg-green-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-xs px-3 py-1 me-2 text-center inline-flex items-center dark:bg-green-600 hover:bg-green-700 focus:ring-green-800">
            <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
              <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
            </svg>
            File Uploaded Successfully
          </button>
          <button onClick={() => {
            dispatch(dispatchFn(null));
            setValue(id, null);
          }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1 text-center hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
            New File?
          </button>
        </div>
      }
      {errors[id] && <InputError error={errors[id]} />}

    </div>
  )
}


// Form Sub Heading
const FormHeading = ({ heading }) => {
  return <div>
    <h1>{heading}</h1>
  </div>
};

export {
  InputError,
  Required,
  FormInputs,
  FormHeading,
  Dropdown,
  FileUpload
};