import React, { useLayoutEffect, useState } from 'react';
import Heading from '../../../../Shared/Heading.jsx';
import { useNavigate, useParams } from 'react-router';
import rejectTicketSchema from './rejectTicketSchema.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputError, Required } from './components.jsx';
import { closeCancellationTicket as closeTicket } from './functions.js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { URL } from '../../../../env.js';
import { Oval } from 'react-loader-spinner';
import { REJECTION_REASONS } from './constants.js';
import { FilterDropdown } from '../View/component.jsx';

const RejectCancellationTicket = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const [closeCancellationTicket, setCloseCancellationTicket] = useState({
    status: '',
    msg: '',
    hideForm: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const onSubmitForm = async (formData) => {
    const { status, msg } = await closeTicket(formData, name, id);

    setCloseCancellationTicket({
      status,
      msg,
      hideForm: status === 200 ? true : false,
    });

    if (status === 200) {
      setTimeout(() => {
        navigate('/ops/cancellations/view');
      }, 1500);
    }
  };

  // Check if ticket already closed
  useLayoutEffect(() => {
    (async () => {
      const { data, status } = await axios.get(`${URL}/ops/cancellations/record/${id}`);

      const { closedBy, ticketStatus } = data;

      if (ticketStatus === 'rejected') {
        setCloseCancellationTicket({
          status,
          msg: `Ticket Already Closed by ${closedBy}`,
          hideForm: status === 200 ? true : false,
        });

        setTimeout(() => {
          navigate('/ops/cancellations/view');
        }, 1500);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(rejectTicketSchema) });

  return (
    <div className="w-screen flex flex-col items-center justify-center">
      <Heading title={`Reject Ticket #ACPL-CN-2024-${id}`} />
      {closeCancellationTicket?.status === 200 && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{closeCancellationTicket?.msg}</div>
        </div>
      )}

      {!closeCancellationTicket.hideForm && (
        <form
          className="flex flex-col items-center justify-center relative mb-5 m-10"
          noValidate
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <div className="relative h-[40px]">
            <button
              className="h-[40px] text-gray-800 bg-white font-semibold rounded-lg text-sm px-5 py-2.5 flex items-center justify-between w-[300px] border-2 border-gray-200"
              type="button"
              onClick={() => {
                const ele = document.getElementById('rejection-reason-dd');
                if (ele.style.display === 'none') {
                  ele.style.display = 'block';
                } else {
                  ele.style.display = 'none';
                }
              }}
            >
              <div className="w-full max-h-[40px] text-left text-ellipsis overflow-hidden text-nowrap">
                {watch('reason') || 'Rejection Reason'}
              </div>
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              id={'rejection-reason-dd'}
              style={{ display: 'none' }}
              className="absolute top-[50px] z-10 bg-white divide-y divide-gray-100 overflow-hidden rounded-lg shadow"
            >
              <ul
                onClick={(e) => {
                  setValue('reason', e.target.innerText);
                  const ele = document.getElementById('rejection-reason-dd');
                  if (ele.style.display === 'none') {
                    ele.style.display = 'block';
                  } else {
                    ele.style.display = 'none';
                  }
                }}
                className="text-xs text-gray-700 cursor-pointer"
                aria-labelledby="dropdownDefaultButton"
              >
                {REJECTION_REASONS.map((option) => (
                  <li>
                    <p className="block px-4 py-2 hover:bg-gray-100">{option}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {getValues('reason')?.toLowerCase() === 'other' && (
            <>
              <label
                htmlFor="closeTicketComments"
                className="block mb-2 text-sm font-medium text-gray-900 w-full mt-10"
              >
                Write rejection reason <Required />
              </label>
              <textarea
                {...register('closeTicketComments')}
                className="block p-2.5 w-[450px] h-[200px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Any Comments..."
              ></textarea>
              {errors.closeTicketComments && <InputError error={errors.closeTicketComments}/>}
            </>
          )}
          {getValues('reason')?.length > 0 && (
            <button
              type="submit"
              className="text-white my-5 bg-red-500 hover:bg-red-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={submitting}
            >
              Reject Ticket <i className="ml-1 fa-solid fa-lock"></i>
            </button>
          )}
          {submitting && (
            <Oval
              visible={true}
              height="32"
              width="32"
              color="#3B82F6"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </form>
      )}

      {closeCancellationTicket?.status === 500 && (
        <div
          id="toast-success"
          className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 rounded-lg shadowtext-gray-400 bg-gray-800"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{closeCancellationTicket?.msg}</div>
        </div>
      )}
    </div>
  );
};

export default RejectCancellationTicket;
