import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const RedirectToHome = () => {

  const navigate = useNavigate();
  useEffect(() => {
    navigate('/aegiscovenant/opsit');
  });

}

export default RedirectToHome;
