const AWS_URL = 'https://gvr2gvuug6wpjxy3jqxe5e2tg40oxsgd.lambda-url.ap-south-1.on.aws';

const URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://opsit-aegiscovenant-backend-jiwnc.ondigitalocean.app';

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/aegiscovenant/opsit'
    : 'https://opsit-aegiscovenant-backend-jiwnc.ondigitalocean.app/aegiscovenant/opsit';

const baseUrlFrontend =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/aegiscovenant/opsit'
    : 'https://opsit.aegiscovenant.com/aegiscovenant/opsit';

export { AWS_URL, baseUrl, URL, baseUrlFrontend };
