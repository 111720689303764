import React, { useEffect, useLayoutEffect, useState } from 'react'
import Heading from '../../../Shared/Heading.jsx';
import { auth } from '../../Login/functions.jsx';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown, FileUpload, FormInputs, TextArea } from './components.jsx';
import { makes, states } from './constants.js';
import { setDealershipName, setDealershipOem, setDealershipMobile, setDealershipEmail, setDealershipState, setDealershipCity, setDealershipRto, setDealershipAddress, setDealershipType, setDealershipMasterDealerEmail, setPaymentTerms, setPaymentTermsToBeUsed, setPaymentBy, setOwnerName, setOwnerEmail, setOwnerMobile, setAccountsName, setAccountsEmail, setAccountsMobile, setGMName, setGMEmail, setGMMobile, setExecutiveName, setExecutiveEmail, setExecutiveMobile, setFirmGstinFile, setFirmPanFile, setChequeFile, setFirmGstin, setFirmPan, setFirmAccountHolderName, setAccountNumber, setFirmBankName, setFirmIfscCode, setSubDealerDocumentUpload, setMasterDealers, setMasterDealerName, setMasterDealersList, setDealerWorking, setDealerWorkingsDropdown, resetStore, setComments } from '../redux.js';
import { getMasterDealers, sendDealerOnboardingRequest } from './functions.js';
import axios from 'axios';
import { URL } from '../../../env.js';
import dealerObboardingSchema from './onboardingSchema.js';
import { Link } from 'react-router-dom';

const OnBoardDealer = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onboardingResponse, setOnboardingResponse] = useState({
    msg: 'Checking....',
    type: ''
  });


  const { name, department } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });


  const { id } = useParams();


  useLayoutEffect(() => {

    dispatch(resetStore());

    const checkOnboardingRequest = async () => {
      const checkRecord = axios.get(`${URL}/dealer/onboard/requests/${id}`);
      const { found, record } = (await checkRecord).data;


      if (found) {
        setOnboardingResponse({
          msg: 'Already Requested. Redirecting....',
          type: 'failed'
        });

        setTimeout(() => {
          navigate('/dealer/pricelist');
        }, 3000);
      } else {

        const workingFiles = JSON.parse(record[0].ops_revenue_sheet).map((file, index) => {
          const obj = {};
          obj[`File ${index + 1}`] = file.fileId;
          return obj;
        });

        dispatch(setDealerWorkingsDropdown(workingFiles));

        const files = Object.keys(workingFiles).map((file) => {
          return workingFiles[file]
        });

        console.log('files ', files);

        setOnboardingResponse({
          msg: '',
          type: ''
        });
      }

    };

    checkOnboardingRequest();

    const path = '/dealers/onboarding';
    auth(navigate, dispatch, path)
  }, []);


  // Dealer workings
  const { dealerWorkings } = useSelector(({ DealerOnBoarding }) => {
    return DealerOnBoarding;
  });

  useEffect(() => {
    if (name) {
      (async () => {
        const masterDealers = await getMasterDealers(name);
        dispatch(setMasterDealers(masterDealers));

        const masterDealerNames = Object.keys(masterDealers).map((dealer) => {
          return masterDealers[dealer]?.['Dealer Name'];
        });

        dispatch(setMasterDealersList(masterDealerNames));
      })();
    }

  }, [name]);

  const { dealershipDetails, masterDealers, masterDealersName } = useSelector(({ DealerOnBoarding }) => {
    return DealerOnBoarding;
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(dealerObboardingSchema) });

  const submitRequest = async (e) => {
    const salesManager = name;


    const { msg, type } = await sendDealerOnboardingRequest(dealershipDetails, salesManager, id, department);
    setOnboardingResponse({
      msg, type
    });
  };

  return (
    <div>
      <Heading title={'Dealer Onboarding Details'} />

      {
        onboardingResponse.type === 'success' &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{onboardingResponse.msg}</div>
        </div>
      }

      {
        onboardingResponse.type === '' && onboardingResponse.msg === '' &&
        <form noValidate onSubmit={handleSubmit(submitRequest)}>

          <div className='w-full mx-auto mt-10 mb-10'>
            <h1 className='ml-16 bg-blue-500 w-3/4 text-center p-2 rounded-lg mb-4 text-white'>Basic Details</h1>
            {/* Basic Details */}
            <div className="dealership-details grid grid-cols-3 gap-3 w-3/4 mx-auto ml-16">
              <FormInputs id={'dealerName'} className={'dealership-name'} title={'Dealership Name (As per GST)'} placeholder={'Dealership Name'} register={register} errors={errors} dispatchFn={setDealershipName} />
              <Dropdown id={'dealerOem'} label={'Dealership OEM'} option1={' -- Select Manufacturer -- '} options={makes} register={register} errors={errors} dispatchFn={setDealershipOem} />
              <FormInputs id={'dealershipMobile'} className={'dealership-mobile'} title={'Dealership Mobile'} placeholder={'702720XXXX'} register={register} errors={errors} dispatchFn={setDealershipMobile} />
              <FormInputs id={'dealershipEmail'} className={'dealership-email'} title={'Dealership Email'} placeholder={'dealership.email@gmail.com'} register={register} errors={errors} dispatchFn={setDealershipEmail} />
              <Dropdown id={'dealershipState'} label={'Dealership State'} option1={' -- Select Dealership State -- '} options={states} register={register} errors={errors} dispatchFn={setDealershipState} />
              <FormInputs id={'dealershipCity'} className={'dealership-city'} title={'Dealership City'} placeholder={'City Name'} register={register} errors={errors} dispatchFn={setDealershipCity} />
              <FormInputs id={'dealershipRto'} className={'dealership-rto'} title={'Dealership RTO'} placeholder={'DL01'} register={register} errors={errors} dispatchFn={setDealershipRto} />
              <FormInputs id={'dealershipAddress'} className={'dealership-address'} title={'Dealership Address (As per GST)'} placeholder={'Address'} register={register} errors={errors} dispatchFn={setDealershipAddress} />
              <Dropdown id={'dealershipType'} label={'Dealership Type'} option1={' -- Select Dealership Type -- '} options={['Master Dealer', 'Sub Dealer']} register={register} errors={errors} dispatchFn={setDealershipType} setValue={setValue} />
              {
                dealershipDetails.type === 'Sub Dealer' ?
                  <>
                    <Dropdown id={'masterDealerName'} label={'Master Dealer'} option1={' -- Select Master Dealership -- '} options={masterDealersName} register={register} errors={errors} dispatchFn={setMasterDealerName} setValue={setValue} />
                    <FormInputs id={'masterdealershipEmail'} className={'master-dealership-email'} title={'Master Dealership Email'} placeholder={'Master Dealership Email'} register={register} errors={errors} dispatchFn={setDealershipMasterDealerEmail} />
                  </> : null
              }
              <div>
                <Dropdown id={'dealerWorking'} label={'Dealership Workings'} option1={' -- Select Dealership Working -- '} options={dealerWorkings.map((working) => {
                  return Object.keys(working);
                })} register={register} errors={errors} dispatchFn={setDealerWorking} setValue={setValue} />
                {
                  dealershipDetails.dealerWorking &&
                  <Link to={`https://drive.google.com/file/d/${dealershipDetails.dealerWorking}/view`} target='_blank'><span className='text-sm underline text-blue-700 '>View File</span></Link>
                }
              </div>
            </div>
          </div>

          <div className='w-full mt-10 mb-10 mx-auto'>
            <h1 className='ml-16 bg-blue-500 w-3/4 text-center p-2 rounded-lg mb-4 text-white'>Payment Info</h1>
            {/* Payment Info */}
            <div className="dealership-details grid grid-cols-3 gap-3 w-3/4  mx-auto ml-16">
              <Dropdown id={'paymentTerms'} label={'Dealership Payment Terms'} option1={' -- Select Dealership Payment Terms -- '} options={['Cut & Pay', 'Full Pay']} register={register} errors={errors} dispatchFn={setPaymentTerms} />
              {
                dealershipDetails.type === 'Sub Dealer' &&
                <Dropdown id={'paymentTermsToBeUsed'} label={'Payment Terms To Be Used'} option1={' -- Select Payment Terms To Be Used -- '} options={['Master Dealer', 'Self']} register={register} errors={errors} dispatchFn={setPaymentTermsToBeUsed} />
              }
              {
                dealershipDetails.type === 'Sub Dealer' &&
                <Dropdown id={'paymentBy'} label={'Dealership Payment By'} option1={' -- Select Dealership Payment By -- '} options={['Master Dealer', 'Self']} register={register} errors={errors} dispatchFn={setPaymentBy} />
              }
            </div>
          </div>

          {/* Dealership Info */}
          <div className='w-full mt-10 mb-10 mx-auto'>
            <h1 className='ml-16 bg-blue-500 w-3/4 text-center p-2 rounded-lg mb-4 text-white'>Dealership Info</h1>
            <div className="dealership-details grid grid-cols-3 gap-3 w-3/4 mx-auto ml-16">
              <FormInputs id={'dealershipOwner'} className={'dealership-owner-name'} title={'Dealership Owner Name'} placeholder={'Dealership Owner Name'} register={register} errors={errors} dispatchFn={setOwnerName} />
              <FormInputs id={'dealershipOwnerEmail'} className={'dealership-owner-email'} title={'Dealership Owner Email'} placeholder={'dealership.owner.email@gmail.com'} register={register} errors={errors} dispatchFn={setOwnerEmail} />
              <FormInputs id={'dealershipOwnerMobile'} className={'dealership-owner-mobile'} title={'Dealership Owner Mobile'} placeholder={'999999XXX'} register={register} errors={errors} dispatchFn={setOwnerMobile} />

              <FormInputs id={'dealershipGMName'} className={'dealership-gm-name'} title={'Dealership GM Name'} placeholder={'Dealership GM Name'} register={register} errors={errors} dispatchFn={setGMName} />
              <FormInputs id={'dealershipGMEmail'} className={'dealership-GM-email'} title={'Dealership GM Email'} placeholder={'dealership.gm.email@gmail.com'} register={register} errors={errors} dispatchFn={setGMEmail} />
              <FormInputs id={'dealershipGMMobile'} className={'dealership-gm-mobile'} title={'Dealership GM Mobile'} placeholder={'999999XXX'} register={register} errors={errors} dispatchFn={setGMMobile} />

              <FormInputs id={'dealershipAccountsName'} className={'dealership-accounts-name'} title={'Dealership Accounts Name'} placeholder={'Dealership Accounts Name'} register={register} errors={errors} dispatchFn={setAccountsName} />
              <FormInputs id={'dealershipAccountsEmail'} className={'dealership-Accounts-email'} title={'Dealership Accounts Email'} placeholder={'dealership.accounts.email@gmail.com'} register={register} errors={errors} dispatchFn={setAccountsEmail} />
              <FormInputs id={'dealershipAccountsMobile'} className={'dealership-accounts-mobile'} title={'Dealership Accounts Mobile'} placeholder={'999999XXX'} register={register} errors={errors} dispatchFn={setAccountsMobile} />

              <FormInputs id={'dealershipExecutiveName'} className={'dealership-executive-name'} title={'Dealership Executive Name'} placeholder={'Dealership Executive Name'} register={register} errors={errors} dispatchFn={setExecutiveName} />
              <FormInputs id={'dealershipExecutiveEmail'} className={'dealership-executive-email'} title={'Dealership Executive Email'} placeholder={'dealership.executive.email@gmail.com'} register={register} errors={errors} dispatchFn={setExecutiveEmail} />
              <FormInputs id={'dealershipExecutiveMobile'} className={'dealership-executive-mobile'} title={'Dealership Executive Mobile'} placeholder={'999999XXX'} register={register} errors={errors} dispatchFn={setExecutiveMobile} />
            </div>
          </div>

          {/* Optional Document Upload */}
          {
            dealershipDetails.type === 'Sub Dealer' &&
            <div className='w-full mt-10 mb-5 mx-auto ml-16'>
              <input onClick={(e) => {
                const isChecked = e.currentTarget.checked;
                dispatch(setSubDealerDocumentUpload(isChecked));
                setValue('subDealerDocumentUpload', isChecked);
              }} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 align-middle text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" />
              <label for="checked-checkbox" className="ms-2 text-sm font-medium">Upload Documents for Sub Dealer?</label>
            </div>
          }


          {/* Dealership Documents */}
          {
            (dealershipDetails.type === 'Master Dealer' || dealershipDetails.subDealerDocumentUpload) &&
            <div className='w-full mt-10 mb-10 mx-auto'>
              <h1 className='ml-16 bg-blue-500 w-3/4 text-center p-2 rounded-lg mb-4 text-white'>Dealership Documents</h1>

              <div className='dealership-details grid grid-cols-3 mx-auto gap-3 w-3/4 ml-16'>

                <FormInputs id={'firmGstin'} className={'firm-gstin'} title={'Firm GSTIN No.'} placeholder={'Firm GSTIN'} register={register} errors={errors} dispatchFn={setFirmGstin} />
                <FileUpload title={'Firm GSTIN Upload'} setValue={setValue} dispatchFn={setFirmGstinFile} id={'firmGstinFile'} errors={errors} />

                <FormInputs id={'firmPan'} className={'firm-pan'} title={'Firm PAN No.'} placeholder={'Firm PAN'} register={register} errors={errors} dispatchFn={setFirmPan} />
                <FileUpload title={'Firm PAN Document Upload'} setValue={setValue} dispatchFn={setFirmPanFile} id={'firmPanFile'} errors={errors} />

                <FormInputs id={'firmAccountHolderName'} className={'firm-account-holder-name'} title={'Firm Account Holder Name'} placeholder={'Firm Account Holder Name'} register={register} errors={errors} dispatchFn={setFirmAccountHolderName} />

                <FormInputs id={'firmAccountNumber'} className={'firm-account-number'} title={'Firm Account Number'} placeholder={'Firm Account Number'} register={register} errors={errors} dispatchFn={setAccountNumber} />

                <FormInputs id={'firmAccountBankName'} className={'firm-bank-name'} title={'Firm Bank Name'} placeholder={'Firm Bank Name'} register={register} errors={errors} dispatchFn={setFirmBankName} />

                <FormInputs id={'firmIfscCode'} className={'firm-ifsc-code'} title={'Firm IFSC Code'} placeholder={'Firm IFSC Code'} register={register} errors={errors} dispatchFn={setFirmIfscCode} />

                <FileUpload title={'Firm Cancelled Cheque Document Upload'} setValue={setValue} dispatchFn={setChequeFile} errors={errors} id={'firmChequeFile'} />

              </div>
            </div>
          }


          {/* <TextArea id={'comments'} className={'comments'} title={'Comments'} placeholder={'Please Add Comments'} register={register} errors={errors} dispatchFn={setComments} /> */}

          {/* Submit Button */}
          <button type='submit' className="w-3/4 ml-16 text-white my-5 bg-green-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">Submit <i className="fa-solid fa-circle-check"></i> </button>

        </form>
      }

      {
        onboardingResponse.type === 'failed' &&
        <div id="toast-success" className="w-fit mx-auto flex items-center mt-10 max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">

          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Warning icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">{onboardingResponse.msg}</div>
        </div>
      }

    </div>
  )
}

export default OnBoardDealer;