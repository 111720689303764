import axios from "axios";
import { URL } from "../../../env";
import { setFilterActive, setStats } from "./redux";

// Get Insurance Records ( TODAY )
const getInsuranceRecords = async (team, department, user) => {
    const options = {
        url: `${URL}/policies/insurance/records`,
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        data: JSON.stringify({team, department, user})
    };

    const request = axios.request(options);
    const response = (await request).data;
    return response.records;
};

// Set Filtered Records
const filteredRecords = (filter, records, setFilteredRecords, dispatch) => {
    const filters = JSON.parse(filter);

    const filteredRecords = records.filter((record) => {
        let accept = true;

        Object.keys(filters).forEach(key => {

            const filterValue = filters[key].trim().toLowerCase();

            if (key === 'UID') {
                if (record['UID'] !== filterValue) {
                    accept = false;
                }
            }
            // Filter for Customer Name
            else if (key === 'Customer Name') {
                const customerName = `${record['First Name / Company Name']}  ${record['Last Name']? record['Last Name']: ''}`.toLowerCase();

                if(!customerName.trim().includes(filterValue)) {
                    accept = false;
                }
            } 
            // Filter for Engine No. & Chassis No.
            else if (key === 'Engine No. / Chassis No.') {
                
                if (!(record['Engine No.'].toLowerCase().trim().includes(filterValue)) && !(record['Chassis No.'].toLowerCase().trim().includes(filterValue))) {
                    accept = false;
                }

            } else if (key === 'Insurance Provider') {
                
                if (!record['Branch']) {
                    accept = false;
                } else if (!record['Branch'].trim().toLowerCase().includes(filterValue)) {
                    accept = false;
                }
            } else if (key === 'Insurance Policy No.') {
                if (!record[key]) {
                    accept = false;
                } else if (!record['Insurance Policy No.'].trim().toLowerCase().includes(filterValue)) {
                    accept = false;
                }
            } else if(key === 'Dealer Name') {
                if (!record['Dealer Name'].trim().toLowerCase().includes(filterValue) && !record['Master Dealer Name'].toLowerCase().trim().includes(filterValue)) {
                    accept = false;
                }
            } else if (key === 'Status') {
                if (record['Status'].toLowerCase().trim() !== filterValue && filterValue !== 'all' ) {
                    accept = false;
                }
            }
          })

          return accept;
    });

    dispatch(setFilterActive(true));
    prepareInsuranceStats(filteredRecords, dispatch);
    dispatch(setFilteredRecords(filteredRecords));
    
};

// Get Stats
const prepareInsuranceStats = (records, dispatch) => {
    const stats = {pending: 0, active: 0, others: 0, fetching: false};

    for (const record in records) {
        if (records[record]?.Status === 'Active') {
            stats.active++;
        } else if (records[record]?.Status === 'Pending') {
            stats.pending++;
        } else {
            stats.others++;
        }
    }

    dispatch(setStats(stats));
};

export {
    getInsuranceRecords,
    filteredRecords,
    prepareInsuranceStats
};