const CONFIG_TYPES = {
  new: [
    {
      value: 'bike',
      label: 'Bike',
      options: ['bike', 'bikeBackup'],
    },
    {
      value: 'scooter',
      label: 'Scooter',
      options: ['scooter', 'scooterBackup'],
    },
    {
      value: 'ev',
      label: 'EV',
      options: ['ev', 'evBackup'],
    },
  ],
  renewal: [
    {
      value: 'bike',
      label: 'Bike',
      options: ['bikeA', 'bikeB', 'bikeC'],
    },
    {
      value: 'scooter',
      label: 'Scooter',
      options: ['scooterA', 'scooterB', 'scooterC'],
    },
    {
      value: 'ev',
      label: 'EV',
      options: ['evA', 'evB', 'evC'],
    },
  ],
};

const POLICY_TYPES = [
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Renewal',
    value: 'renewal',
  },
];

const OG_OPTION = {
  'bikeBackup': 'bike',
  'scooterBackup': 'scooter',
  'evBackup': 'ev',
  'bikeB': 'bikeA',
  'bikeC': 'bikeA',
  'scooterB': 'scooterA',
  'scooterC': 'scooterA',
  'evB': 'evA',
  'evC': 'evA',
}

export { CONFIG_TYPES, POLICY_TYPES, OG_OPTION };
