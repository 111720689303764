import axios from 'axios';
import { toBase64 } from '../../../functions.js';
import { URL } from '../../../env.js';

// File Upload to Backend and Bulk Update or Add Records
const fileConverterUpload = async (fileData, setConvertedRecords, setRecordsFetched) => {
  try {
    const options = {
      method: 'POST',
      url: `${URL}/files/csv-to-json`,
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify({ fileData })
    };
  
    const request = await axios.request(options);
    console.log(request.data);

    setRecordsFetched(true);
    setConvertedRecords(request.data?.jsonRecords);
    
    return {
      msg: 'Converted Successfully'
    }
    // console.log(request.data); // Records key in this Data

  } catch (err) {
    return {
      msg: 'Conversion Failed'
    }
  }

};

// Convertible Button
const convertInputButton = async (setFile, setFileData) => {
  try {
    // const element = document.getElementById(event.target.id);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv'
    input.onchange = async () => {

      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);

      // Convert on CSV Files
      if (files[0].type.includes('csv')) {
        const base64Data = await toBase64(files[0]);
        // console.log(files[0]);
        setFile(files[0]);
        setFileData(base64Data);
      } else {
        throw Error('Please Upload CSV Files Only.');
      }

    };
    input.click();

  } catch (err) {
    console.log(err);
  }
};

// Bulk Updation
const bulkUploadRequest = async (records, setBulkUploadStatus, navigate) => {
  try {
    const options = {
      url: `${URL}/claims/bulk-upload`,
      method: 'POST',
      data: JSON.stringify({records}),
      headers: {
        'content-type': 'application/json'
      }
    };

    setBulkUploadStatus('uploading');
  
    const request = axios.request(options);
    const response = (await request).data;

    setBulkUploadStatus('success');
    // Take Back to Claims Dashboard
    setTimeout(() => {
      navigate('/claims/view');
    }, 3000);

    return {
      msg: 'Records Updated Successfully'
    }

  } catch (err) {
    console.log(err);
    setBulkUploadStatus('failed');
    
    setTimeout(() =>{
      setBulkUploadStatus(null);
    }, 3000);

    return {
      msg: 'Failed to Update Records'
    }
  }
};

export {
  fileConverterUpload,
  convertInputButton,
  bulkUploadRequest
};