import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  policies: [],
  headers: '',
  filteredPolicies: []
};

const InsurancePoliciesView = createSlice({
  name: 'insurancePoliciesView',
  initialState,
  reducers: {
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    setHeader: (state, action) => {
      state.headers = action.payload;
    },
    setFilteredPolicies: (state, action) => {
      state.filteredPolicies = action.payload;
    }
  }
});

export const { setPolicies, setHeader, setFilteredPolicies } = InsurancePoliciesView.actions;
export default InsurancePoliciesView.reducer;