import React from 'react';

const Pagination = ({ pageNumber, maxPageNumber, setPageNumber }) => {
  let PAGENUMBERS = [1, 2, 3, 4, 5];

  if (pageNumber >= 4 && maxPageNumber > 5) {
    if (maxPageNumber - pageNumber >= 2) {
      PAGENUMBERS = [pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2];
    } else if (maxPageNumber - pageNumber === 1) {
      PAGENUMBERS = [pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1];
    } else {
      PAGENUMBERS = [pageNumber - 4, pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber];
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        gap: '5px',
      }}
    >
      <button
        style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        className="page-btn"
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber <= 1}
      >
        <i className="text-xs fa-solid fa-chevron-left"></i>
      </button>
      {PAGENUMBERS.map((number, index) => {
        let style = {
          outline: 'none',
          color: 'black',
          backgroundColor: '#f2f2f2',
          borderRadius: '5px',
          border: 'none',
          height: '25px',
          width: '25px',
        };
        if (number === pageNumber) {
          style.backgroundColor = '#272D3A';
          style.fontWeight = 'bold';
          style.color = 'white';
        }

        return number <= maxPageNumber ? (
          <button
            key={`page${index}`}
            style={style}
            onClick={() => {
              setPageNumber(number);
            }}
          >
            {number}
          </button>
        ) : (
          <></>
        );
      })}
      <button
        className="page-btn"
        style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber >= maxPageNumber}
      >
        <i className="text-xs fa-solid fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
