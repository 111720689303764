import { toBase64, uploadFileToDrive, uploadWorking } from '../../../functions.js';

// File Uploads
const fileUpload = async (e, dispatch, setUploadStatus) => {
  const file = await (e.target.files[0]);
  const extension = file.type.split('/')[1];
  const fileType = file.type.split('/')[0];
  const base64Data = await toBase64(file);
  const fileId = await uploadFileToDrive(base64Data, extension, fileType);

  if (!fileId) {
    dispatch(setUploadStatus('Failed'))
  } else {
    dispatch(setUploadStatus('Success'));
  }

  return fileId;
};

// Handle Submit
const onSubmit = async (loggedInUserName, pricelistWorking, dispatch, navigate, resetStore, setRequestMessage, setRequestStatus) => {
  const { msg, status } = await uploadWorking(loggedInUserName, pricelistWorking);
  dispatch(setRequestStatus(status));
  dispatch(setRequestMessage(msg));

  if (status === 200) setTimeout(() => {
    navigate('/dealer/pricelist');
    dispatch(resetStore());
  }, 3000);
};


export {fileUpload, onSubmit};