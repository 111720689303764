import { createSlice } from '@reduxjs/toolkit';
import { getLabelsAndPoliciesCount } from '../Policies Count/functions';

const initialState = {
  branches: [],
  fetchingData: false,
  showDetails: false,
  data: {
    totalPolicies: 0,
    totalCurrentMonthPolicies: 0,
    stateWiseData: {
      labels: [],
      count: []
    },
    rtoWiseData: {
      labels: [],
      count: []
    },
    monthWiseData: {
      labels: [],
      count: []
    },
    insurancePolicyTypeWiseData: {
      labels: [],
      count: []
    },
    salesManagerWiseData: {
      labels: [],
      count: []
    }
  }
};

const InsurerBranchSlice = createSlice({
  name: 'insurerBranchData',
  initialState,
  reducers: {
    setInsurerBranches: (state, action) => {
      state.branches = action.payload;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    setShowDetails: (state, action) => {
      state.showDetails = action.payload;
    },
    setStateWiseDetails : (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'State', 'COUNT(UID)')
      state.data.stateWiseData.labels = labels;
      state.data.stateWiseData.count = policiesCount;
    },
    setRtoWiseDetails : (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'RTO', 'COUNT(UID)');
      state.data.rtoWiseData.labels = labels;
      state.data.rtoWiseData.count = policiesCount;
    },
    setMonthWiseDetails : (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Month', 'COUNT(UID)');
      state.data.monthWiseData.labels = labels;
      state.data.monthWiseData.count = policiesCount;
    },
    setInsuranceTypeWiseDetails: (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Insurance Type', 'COUNT(UID)');
      state.data.insurancePolicyTypeWiseData.labels = labels;
      state.data.insurancePolicyTypeWiseData.count = policiesCount;
    },
    setSalesManagerWiseDetails: (state, action) => {
      const {labels, policiesCount} = getLabelsAndPoliciesCount(action.payload, 'Sales Manager', 'COUNT(UID)');
      state.data.salesManagerWiseData.labels = labels;
      state.data.salesManagerWiseData.count = policiesCount;
    },
    setTotalPolicies: (state, action) => {
      state.data.totalPolicies = action.payload;
    },
    setCurrentMonthPolicies: (state, action) => {
      state.data.totalCurrentMonthPolicies = action.payload;
    }
  }
});

export const {setTotalPolicies, setShowDetails, setCurrentMonthPolicies, setInsurerBranches, setFetchingData, setStateWiseDetails, setRtoWiseDetails, setMonthWiseDetails, setInsuranceTypeWiseDetails, setSalesManagerWiseDetails } = InsurerBranchSlice.actions;
export default InsurerBranchSlice.reducer;