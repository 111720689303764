import axios from 'axios';
import { baseUrl } from '../../env';
import { checkAuthentication } from '../../functions.js';

// Login
const login = async (email, password) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/login`,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ email, password }),
  };

  try {
    const res = await axios.request(options);
    return { statusCode: res.status, user: res.data };
  } catch (err) {
    throw err;
  }
};

// Redirect if user already logged in
const auth = async (navigate, dispatch, path) => {

  const res = await checkAuthentication(dispatch, navigate, path);

  if (res) {
    // navigate(allowedPaths.redirectTo);
  }
  // if (res) {
  //   if (res.dept === 'CLAIMS') {
  //     return navigate('/aegiscovenant/opsit/forms/inspection-files');
  //   } else if(res.dept === 'BPO') {
  //     return navigate('/bpo/kyc-documents-downloader');
  //   } else {
  //     return navigate('/aegiscovenant/opsit/forms/dealer-discount');
  //   }
  // }
};

export { login, auth };
