import axios from 'axios';

import { URL } from '../../../../env.js';

const fetchMakes = async (setMakes, setLoading) => {
  const { data } = await axios.get(`${URL}/insurance/makes`);
  setMakes(data);
  setLoading(false);
};

export { fetchMakes };
