import * as yup from 'yup';

const dealerObboardingSchema = yup.object().shape({
  dealerName: yup.string().required('Dealer Name is mandatory'),
  dealerOem: yup.string().required('Dealer OEM is mandatory'),
  dealershipMobile: yup.string().required('Dealership Mobile is mandatory').length(10, 'Invalid Mobile Number'),
  dealershipEmail: yup.string().required('Email is mandatory').email('Please enter valid Email'),
  dealershipState: yup.string().required('Please Select Dealership location state'),
  dealershipCity: yup.string().required('Dealership City Name is mandatory'),
  dealershipRto: yup.string().required('Dealership RTO is mandatory'),
  dealershipAddress: yup.string().required('Dealership Address is mandatory'),
  dealershipType: yup.string().required('Please Select Dealership Type'),
  masterdealershipEmail: yup.string().when( 'dealershipType', (dealershipType, schema) => {
    if (dealershipType[0] === 'Sub Dealer') {
      return schema.required('Master Dealership Email is mandatory');
    }
  }),
  
  paymentTerms: yup.string().required('Please select Payment Terms'),
  paymentTermsToBeUsed: yup.string().when( 'dealershipType', (dealershipType, schema) => {
    if (dealershipType[0] === 'Sub Dealer') {
      return schema.required('Payment Terms To Be Used is mandatory');
    }
  }),
  paymentBy: yup.string().when( 'dealershipType', (dealershipType, schema) => {
    if (dealershipType[0] === 'Sub Dealer') {
      return schema.required('Payment By is mandatory');
    }
  }),
  
  dealershipOwner: yup.string().required('Please Enter Dealership Owner Name'),
  dealershipOwnerEmail: yup.string().required('Please Enter Dealership Owner Email').email('Please Enter valid Email Id'),
  dealershipOwnerMobile: yup.string().required('Please Enter Mobile No.').length(10, 'Invalid Mobile Number'),
  
  dealershipAccountsName: yup.string().required('Please Enter Dealership Accounts Name'),
  dealershipAccountsEmail: yup.string().required('Please Enter Dealership Accounts Email').email('Please Enter valid Email Id'),
  dealershipAccountsMobile: yup.string().required('Please Enter Mobile No.').length(10, 'Invalid Mobile Number'),
  
  dealershipGMName: yup.string().required('Please Enter Dealership GM Name'),
  dealershipGMEmail: yup.string().required('Please Enter Dealership GM Email').email('Please Enter valid Email Id'),
  dealershipGMMobile: yup.string().required('Please Enter Mobile No.').length(10, 'Invalid Mobile Number'),
  
  dealershipExecutiveName: yup.string().required('Please Enter Dealership Executive Name'),
  dealershipExecutiveEmail: yup.string().required('Please Enter Dealership Executive Email').email('Please Enter valid Email Id'),
  dealershipExecutiveMobile: yup.string().required('Please Enter Mobile No.').length(10, 'Invalid Mobile Number'),

  subDealerDocumentUpload: yup.boolean().default(false),
  
  firmGstin: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Dealership GSTIN');
    }
  }),
  firmPan: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Dealership PAN');
    }
  }),
  firmAccountHolderName: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Account Holder Name')
    }
  }),
  firmAccountNumber: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Account Number');
    }
  }),
  firmAccountBankName: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Bank\'s Name');
    }
  }),
  firmIfscCode: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Enter Bank IFSC Code');
    }
  }),
  firmGstinFile: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Upload Firm GSTIN File');
    }
  }),
  firmPanFile: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Upload Firm PAN File');
    }
  }),
  firmChequeFile: yup.string().when( 'subDealerDocumentUpload', (subDealerDocumentUpload, schema) => {
    if (subDealerDocumentUpload[0]) {
      return schema.required('Please Upload Firm Cancelled Cheque');
    }
  })
});

export default dealerObboardingSchema;