import { checkAuthentication, searchKycDocuments } from '../../../functions.js';
import { setData, setError, setRequestId, setSearching } from './redux';


const handleSubmit = async (requestId, dispatch ) => {
  
  dispatch(setError({
    status: '',
    message: ''
  }));

  dispatch(setSearching(true));
  dispatch(setData(null));

  const request = await searchKycDocuments(requestId);
  
  dispatch(setSearching(false));
  if (request.status < 300) {
    dispatch(setRequestId(''));
    dispatch(setData(request.documents));
    return;
  }

  dispatch(setError({
    status: request.status,
    message: request.msg
  }));
};

const auth = async (navigate, dispatch, path) => {
  const auth = await checkAuthentication(dispatch, navigate, path);
  // if (!auth || !(['ADMIN', 'BPO'].includes(auth.dept))) {
  //   navigate('/login');
  // }
};


export { handleSubmit, auth };