import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealerName: '',
  startMonth: '',
  endMonth: '',
  startYear: '',
  endYear: '',
  generating: false,
  reportType: ''
};

export const reportGenerator = createSlice({
  name: 'reportGenerator',
  initialState,
  reducers: {
    setDealerName: (state, action) => {
      state.dealerName = action.payload;
    },
    setStartMonth: (state, action) => {
      state.startMonth = action.payload;
    },
    setEndMonth: (state, action) => {
      state.endMonth = action.payload;
    },
    setStartYear: (state, action) => {
      state.startYear = action.payload;
    },
    setEndYear: (state, action) => {
      state.endYear = action.payload;
    },
    setGenerating: (state, action) => {
      state.generating = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    resetStore: () => {
      return initialState;
    }
  }  
});

export const { setDealerName, setStartMonth, setStartYear, setEndMonth, setEndYear, resetStore, setGenerating, setReportType } = reportGenerator.actions;
export default reportGenerator.reducer;