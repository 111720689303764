import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredRecords,
  getInsuranceRecords,
  prepareInsuranceStats,
} from "./functions";
import {
  setFilterActive,
  setFilteredRecords,
  setInsuranceRecords,
} from "./redux.js";
import { InsuranceViewHeaders } from "./constants.js";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

// Insurance Table Header
const InsuranceViewHeader = () => {
  return (
    <div className="flex w-full justify-between mb-1">
      <h1 className="text-[25px]">Insurance Policies</h1>
      <h1 className="text-sm">{dayjs().format('dddd, DD MMM YYYY')}</h1>
    </div>
  );
};

// Insurance Table Voew
const TableView = () => {
  const dispatch = useDispatch();

  // LoggedIn User Details
  const { name, department, team } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  // Insurance Records
  const { records, filteredRecords, filterActive } = useSelector(
    ({ InsuranceView }) => {
      return InsuranceView;
    }
  );

  // Get Insurance Records
  useLayoutEffect(() => {
    if (name) {
      setInterval(() => {
        (async () => {
          const records = await getInsuranceRecords(team, department, name);
          prepareInsuranceStats(records, dispatch);
          dispatch(setInsuranceRecords(records));
        })();
      }, 3000);
    }
  }, [name, department, team, dispatch]);

  return (
    <div className="h-[65vh] relative mt-1">
      <div className="h-[100%] overflow-auto rounded-lg border-4">
        <table className="border h-max overflow-scroll w-max">
          <thead className="border-b-4 block sticky top-0 bg-green-500">
            <tr className="text-sm">
              {InsuranceViewHeaders.map((header) => {
                return (
                  <th scope="col" className="px-4 py-3 w-[210px] border-r-2">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="block">
            {(filteredRecords && filterActive ? filteredRecords : records) &&
              (filteredRecords && filterActive ? filteredRecords : records).map(
                (record) => {
                  let statusBg;
                  if (record?.Status.toLowerCase() === "pending") {
                    statusBg = "yellow";
                  } else if (record?.Status.toLowerCase() === "active") {
                    statusBg = "green";
                  } else {
                    statusBg = "red";
                  }

                  return (
                    <tr className="text-center border-b-2 text-xs overflow-scroll">
                      <td className={`px-4 py-3 w-[210px] border-r-2`}>
                        {" "}
                        <span
                          className={`bg-${statusBg}-500 px-2 py-2 rounded-lg text-[12px] text-white font-semibold`}
                        >
                          {record.UID}
                        </span>{" "}
                        <span></span>
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["NeverDown Card Link"] && (
                          <LinkButton
                            to={`${record?.["NeverDown Card Link"]}`}
                            text={'Visit NeverDown Card'}
                          />
                        )}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["ACPL Certificate No."]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Insurance Policy No."]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Chassis No."]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Engine No."]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Insurance Provider"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Customer Type"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["First Name / Company Name"]}
                        {""}{" "}
                        {record?.["Last Name"] ? record?.["Last Name"] : ""}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["State"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Pincode"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["RTO"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Vehicle Manufacturer"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Model"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Variant"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Body Type"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Insurance Type"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Vehicle IDV"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Dealer Name"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Master Dealer Name"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Distributor Name"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Dealer Type"]}
                      </td>
                      <td
                        className={`px-4 py-3 w-[210px] uppercase border-r-2`}
                      >
                        <span
                          className={`bg-${statusBg}-500 px-3 py-3 text-white rounded-lg shadow-lg hover:scale-50 disabled`}
                        >
                          {record?.["Status"]}
                        </span>
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Combined Certificate Link"] && (
                          <LinkButton
                            to={`${record?.["Combined Certificate Link"]}`}
                          />
                        )}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Month"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {dayjs(record?.["Created At"]).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Updated At"]
                          ? dayjs(record?.["Updated At"]).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                          : ""}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Insurance Acknowledgment Link"] && (
                          <LinkButton
                            to={`${record?.["Insurance Acknowledgment Link"]}`}
                          />
                        )}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Branch"]}
                      </td>
                      <td className="px-4 py-3 w-[210px] border-r-2">
                        {record?.["Sales Manager"]}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Link Button
const LinkButton = ({ to, text='View File' }) => {
  return (
    <Link to={to} target="_blank">
      <button className="bg-blue-500 text-white p-2 rounded-lg shadow-lg active:scale-90">
        {text === 'View File' ? <i class="fa-solid fa-eye mr-1"></i> : <i class="fa-brands fa-readme mr-1"></i>} {text}
      </button>
    </Link>
  );
};

// Filters
const TableFilters = () => {
  const [filters, setFilters] = useState("{}");
  const [showFilters, setShowFilters] = useState(false);

  const { records } = useSelector(({ InsuranceView }) => {
    return InsuranceView;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (filters !== "{}") {
      filteredRecords(filters, records, setFilteredRecords, dispatch);
    } else {
      dispatch(setFilteredRecords([]));
      prepareInsuranceStats(records, dispatch);
      dispatch(setFilterActive(false));
    }
  }, [filters, records, dispatch]);

  return (
    <div>
      <button
        onClick={() => {
          setShowFilters((value) => {
            return !value;
          });
        }}
        className="bg-blue-600 p-1 text-[12px] text-white rounded-lg"
      >
        {!showFilters ? (
          <p>
            <i class="fa-solid fa-filter mr-1"></i> Show Filters
          </p>
        ) : (
          <p>
            <i class="fa-solid fa-filter-circle-xmark mr-1"></i> Hide Filters
          </p>
        )}
      </button>
      {showFilters && (
        <div
          className={`${showFilters ? "my-1" : ""
            } flex w-full space-x-3 duration-75`}
        >
          {/* UID */}
          <FilterInput id={"UID"} setFilters={setFilters} />
          <FilterInput id={"Insurance Provider"} setFilters={setFilters} />
          <FilterInput id={"Customer Name"} setFilters={setFilters} />
          <FilterInput
            id={"Engine No. / Chassis No."}
            setFilters={setFilters}
          />
          <FilterInput id={"Dealer Name"} setFilters={setFilters} />
          <FilterInput id={"Insurance Policy No."} setFilters={setFilters} />
          <DropdownFilter id={'Status'} setFilters={setFilters} />

        </div>
      )}
    </div>
  );
};

// Filter Input
const FilterInput = ({ id, setFilters }) => {
  const [clearFilter, setClearFilter] = useState(false);
  const [filterText, setFilterText] = useState("");

  return (
    <div className={`filter`}>
      <input
        type="text"
        className="text-sm rounded-md p-1"
        placeholder={id}
        id={id}
        value={filterText}
        onChange={(e) => {
          const filterName = e.currentTarget.id;
          const filterValue = e.currentTarget.value;

          setFilterText(filterValue);
          setClearFilter(filterValue.length > 0 ? true : false);

          setFilters((t) => {
            const value = JSON.parse(t);
            if (!filterValue.length) {
              delete value[filterName];
              return JSON.stringify(value);
            } else {
              value[filterName] = filterValue;
              return JSON.stringify(value);
            }
          });
        }}
      />
      {filterText.length > 0 && (
        <button
          id={id}
          className="p-1 bg-red-500 text-white ml-2 rounded-md"
          onClick={(e) => {
            const filterName = e.currentTarget.id;
            setFilterText("");
            setFilters((t) => {
              const value = JSON.parse(t);
              delete value[filterName];
              return JSON.stringify(value);
            });
          }}
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      )}
    </div>
  );
};

// Dropdown Filter
const DropdownFilter = ({ id, setFilters }) => {
  const options = ['All', 'Pending', 'Active', 'Vahaan Service Issue', 'IDV Error', 'invalid documents', 'quote mismatch'];
  return (
    <div className='text-sm'>
      <select className='p-1 rounded-md text-sm text-gray-500 capitalize' onChange={(e) => {

        const filterName = e.currentTarget.id;
        const filterValue = e.currentTarget.value;

        setFilters((t) => {
          const value = JSON.parse(t);
          if (!filterValue.length) {
            delete value[filterName];
            return JSON.stringify(value);
          } else {
            value[filterName] = filterValue;
            return JSON.stringify(value);
          }
        });
      }} id={id}>
        {
          options.map((option) => {
            return <option value={option}>{option}</option>
          })
        }
      </select>
    </div>
  )
};

// Stats
const InsuranceTableStats = () => {
  const { stats } = useSelector(({ InsuranceView }) => {
    return InsuranceView;
  });

  // Count by Status
  return (
    <div className="flex space-x-2 text-[12px] mt-1">
      <div className="active px-2 py-1 my-1 bg-green-600 text-white font-semibold rounded-lg">
        <p>Active Policies : {stats.active}</p>
      </div>
      <div className="pending px-2 py-1 my-1 bg-yellow-500 text-white font-semibold rounded-lg">
        Pending Policies : {stats.pending}
      </div>
      <div className="others px-2 py-1 my-1 bg-red-500 text-white font-semibold rounded-lg">
        Others: {stats.others}
      </div>
    </div>
  );
};

export {
  TableView,
  InsuranceViewHeader,
  TableFilters,
  InsuranceTableStats,
  LinkButton,
};
