import React, { useState } from 'react';

import { fetchDealerConfig } from './functions';
import { CONFIG_TYPES, OG_OPTION, POLICY_TYPES } from './constants';
import axios from 'axios';
import { URL } from '../../../../env';
import { useSelector } from 'react-redux';

const InsurerShifter = () => {
  const [dealerEmail, setDealerEmail] = useState('');
  const [config, setConfig] = useState();
  const [policyType, setPolicyType] = useState();
  const [vehicleType, setVehicleType] = useState();
  const [formKey, setFormKey] = useState(0);

  const loggedInUser = useSelector(({ loggedInUser }) => loggedInUser);

  const resetState = () => {
    setDealerEmail('');
    setConfig();
    setPolicyType();
    setVehicleType();
    setFormKey(0);
  }

  return (
    <div className="w-full relative">
      <div className="flex flex-col bg-white items-start p-10 border-b border-gray-200 rounded-xl shadow-md w-full">
        <h1 className="mb-2 text-lg">Quick Insurer Shifter</h1>
        <div className="flex gap-2 items-center">
          <input
            type="text"
            placeholder="Dealer Email"
            className="rounded-md border-gray-300 border-2 p-2 w-[300px]"
            onChange={(e) => setDealerEmail(e.target.value)}
          />
          <button
            className="rounded-md bg-blue-400 hover:bg-blue-500 py-2 px-2 text-white w-[50px]"
            onClick={async () => {
              const data = await fetchDealerConfig(dealerEmail);
              setConfig(data.discount);
              setPolicyType('new');
              setVehicleType(CONFIG_TYPES.new[0]);
            }}
            disabled={dealerEmail?.length === 0}
          >
            Go
          </button>
          {config && (
            <button
              className="rounded-md bg-red-400 hover:bg-red-500 py-2 px-2 text-white w-[70px]"
              onClick={() => {
                resetState();
              }}
              disabled={dealerEmail?.length === 0}
            >
              Cancel
            </button>
          )}
        </div>
        {config && (
          <>
            <div className="flex items-center mt-5 rounded-ss-md rounded-se-md overflow-hidden border-2 border-b-0 border-gray-200">
              {POLICY_TYPES.map((type) => {
                return (
                  <button
                    id={type.value}
                    onClick={() => {
                      setPolicyType(type.value);
                      setVehicleType(CONFIG_TYPES[type.value][0]);
                    }}
                    className={
                      'px-5 py-2 transition-all ' +
                      (type.value === policyType
                        ? 'bg-blue-500 text-white hover:bg-blue-600'
                        : 'bg-gray-100 hover:bg-gray-200')
                    }
                  >
                    {type.label}
                  </button>
                );
              })}
            </div>
            {policyType && CONFIG_TYPES[policyType] && (
              <div className="flex flex-wrap items-center overflow-hidden border-2 border-b-0 border-gray-200 rounded-se-md">
                {CONFIG_TYPES[policyType].map((e) => (
                  <button
                    id={e.value}
                    onClick={() => {
                      setVehicleType(e);
                    }}
                    className={
                      'px-5 py-2 transition-all ' +
                      (e.value === vehicleType?.value
                        ? 'bg-blue-500 text-white hover:bg-blue-600'
                        : 'bg-gray-100 hover:bg-gray-200')
                    }
                  >
                    {e.label}
                  </button>
                ))}
              </div>
            )}
            {policyType && vehicleType && (
              <div
                key={formKey}
                className="bg-gray-100 p-5 rounded-md rounded-ss-none border-2 border-gray-200 flex flex-col gap-2"
              >
                {vehicleType.options.map((option, index) => {
                  if (config[policyType][option]) {
                    return (
                      <div key={index} className="flex flex-col gap-1 items-center">
                        <div className="flex gap-5">
                          <input
                            id={`${policyType}-${option}-insurer`}
                            value={config[policyType][option].insurer}
                            onChange={(e) => {
                              let temp = { ...config };
                              temp[policyType][option].insurer = e.target.value;
                              setConfig(temp);
                            }}
                            placeholder="Insurer"
                            className="w-[150px] border-gray-300 border-2 rounded-md p-1 px-2"
                          />
                          <input
                            id={`${policyType}-${option}-branch`}
                            value={config[policyType][option].branch}
                            onChange={(e) => {
                              let temp = { ...config };
                              temp[policyType][option].branch = e.target.value;
                              setConfig(temp);
                            }}
                            placeholder="Branch"
                            className="w-[150px] border-gray-300 border-2 rounded-md p-1 px-2"
                          />
                          <input
                            id={`${policyType}-${option}-make`}
                            value={config[policyType][option].make}
                            onChange={(e) => {
                              let temp = { ...config };
                              temp[policyType][option].make = e.target.value;
                              setConfig(temp);
                            }}
                            placeholder="Make"
                            className="w-[150px] border-gray-300 border-2 rounded-md p-1 px-2"
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <button
                          onClick={() => {
                            let temp = JSON.parse(JSON.stringify(config[policyType][OG_OPTION[option]]));
                            config[policyType][option] = temp;
                            setConfig(config);
                            console.log('here');
                            setFormKey(Math.random());
                          }}
                          className="bg-blue-200 px-2 py-1 rounded-md text-blue-500"
                        >
                          Enable {option}
                        </button>{' '}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </>
        )}
        {config && policyType && vehicleType && (
          <button
            className="rounded-md bg-blue-500 py-2 px-2 text-white w-[80px] mt-4"
            onClick={async () => {
              const body = {config, user: loggedInUser};
              const res = await axios.post(`${URL}/dealer/config/discount/${dealerEmail}`, body);
              if (res.data.success) {
                resetState();
              }
            }}
            disabled={dealerEmail?.length === 0}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default InsurerShifter;
