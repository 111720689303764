import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { MutatingDots } from 'react-loader-spinner';

import { checkAuthentication } from '../../../../Shared/functions.jsx';
import Heading from '../../../../Shared/Heading';
import { SELECTED_LOG } from '../Shared/constants.js';
import { toBase64 } from '../../../../functions.js';
import { uploadConfigForm } from './constants.js';
import InputError from '../../../../Shared/InputError.jsx';

const UploadDealerConfig = () => {
  const state = useSelector(({ dealerConfigLogs }) => dealerConfigLogs.selectedLog);
  const user = useSelector(({ loggedInUser }) => loggedInUser);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const auth = checkAuthentication();
    if (!auth) navigate('/login');
  }, []);

  useEffect(() => {
    if (!state) navigate('/dealer/config/request');
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    const formFile = document.querySelector('#file-id').files[0];
    const revenueFiles_ = document.querySelector('#revenue-sheet').files;
    const revenueFiles = [];
    for(let i = 0 ; i < revenueFiles_.length ; i++) {
      const temp = revenueFiles_[i];
      revenueFiles.push({
        file: temp,
        type: temp.type,
        buffer: await toBase64(temp)
      });
    }
    console.log(revenueFiles);
    const formBuffer = await toBase64(formFile);
    const res = await uploadConfigForm(user, formBuffer, revenueFiles, state);
    if(!res.success) {
      setError("Something went wrong! Check your email");
    }
    setSubmitting(false);

    if(res.success) navigate('/dealer/config/request');
  };

  return submitting ? (
    <div className="mt-10 flex items-center justify-center">
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#0d55d1"
        secondaryColor="#0d37d1"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) : (
    state && (
      <div className="flex flex-col items-center justify-center">
        <Heading title="Upload Dealer Config" />
        <form
          onSubmit={onSubmit}
          className="w-[1000px] gap-3 flex flex-col flex-wrap items-center justify-center"
        >
          <div className="gap-3 flex flex-wrap items-center justify-center">
            {Object.keys(SELECTED_LOG).map((header) => (
              <div className="custom-form-group">
                <label for={SELECTED_LOG[header]} className="custom-label">
                  {header}
                </label>
                <input
                  id={SELECTED_LOG[header]}
                  className="custom-input p-2 rounded-none"
                  value={state[SELECTED_LOG[header]]}
                  disabled
                />
              </div>
            ))}
            <div className="custom-form-group">
              <label for="file-id" className="custom-label">
                Upload Form
              </label>
              <input id="file-id" className="custom-input p-2 rounded-none" type="file" required/>
            </div>
            <div className="custom-form-group">
              <label for="revenue-sheet" className="custom-label">
                Upload Revenue Sheet
              </label>
              <input multiple="multiple" id="revenue-sheet" className="custom-input p-2 rounded-none" type="file"/>
            </div>
          </div>
          <button type="submit" className="custom-btn">
            Submit
          </button>
          {error !== "" && <InputError error={{message: error}} />}
        </form>
      </div>
    )
  );
};

export default UploadDealerConfig;
