import React, { useEffect, useLayoutEffect, useState } from 'react';
import Heading from '../../../../Shared/Heading.jsx';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AuthCheck from '../../../../AuthCheck.jsx';
import { amountLocationKeyToLabel, recordDetails } from './constants.js';
import { approveRequest, rejectRequest } from './functions.js';
import { URL } from '../../../../env.js';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const ViewCancellationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const state = useSelector(({ Cancellations, loggedInUser }) => {
    return { records: Cancellations.records, user: loggedInUser };
  });
  const records = state.records;

  const { id } = useParams();
  const [updates, setUpdates] = useState();
  const [description, setDescription] = useState();
  const [record, setRecord] = useState([]);
  const [department, setDepartment] = useState(state.user.department.toLowerCase());
  const [closingComments, setClosingComments] = useState();
  const [calculation, setCalculation] = useState();
  const [loading, setLoading] = useState(false);
  const [policyDetails, setPolicyDetails] = useState(undefined);

  useEffect(() => {
    const record = records.filter((record) => {
      return record.id === parseInt(id);
    });

    if (record.length > 0) {
      const record_updates = JSON.parse(record[0]?.updates);
      const description = record[0]?.additional_info;

      axios
        .get(`${URL}/policy/${record[0].cancellation_type}/${record[0].policy_number}`)
        .then((res) => setPolicyDetails(res.data.policyRecord))
        .catch((err) => setPolicyDetails(undefined));

      if (!['pending', 'initiated'].includes(record[0].status)) {
        const closing_comments = record[0].closing_comments? JSON.parse(record[0].closing_comments.replaceAll('\n', '')): '';

        if (closing_comments) {
          const closingComments = closing_comments[closing_comments.length - 1];
          closingComments.amountLocation = amountLocationKeyToLabel[closingComments.amountLocation];
          closingComments.cancellationReceipt =
            closingComments.cancellationReceipt === 'false' ? 'Received' : 'Not Received';
          setClosingComments(closingComments);
        }
      }

      if (['accounts', 'admin'].includes(department) && record[0].status === 'pending - accounts') {
        setLoading(true);
        setCalculation({});
        axios
          .post(`${URL}/accounts/cancellation/calculation`, JSON.stringify(record[0]), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(({ data }) => {
            setCalculation(data);
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      }

      setRecord(record[0]);
      setUpdates(record_updates);
      setDescription(description);
    }
  }, [records, id]);

  return (
    <div>
      {/* <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname}/> */}
      <Heading title={`ACPL-C${record.cancellation_type === 'Insurance' ? 'I' : 'C'}N-2024-${id}`} />
      <div className="grid grid-cols-2 items-start">
        {department !== 'business support' && (
          <div>
            {['ops', 'admin'].includes(department) && (
              <ol className="relative m-10 border-s border-gray-700">
                {updates &&
                  updates.map((update, index) => {
                    if (index === 0) {
                      return (
                        <li key={index} className="mb-4 ms-4">
                          <div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700"></div>
                          <h3 className="text-[14px] font-semibold text-gray-900 dark:text-black">
                            Update #{index + 1}
                          </h3>
                          <div className="flex flex-col items-start justify-end mb-1 mt-2 text-md text-gray-800 relative rounded-md">
                            <div className="flex gap-4 items-center justify-between w-full">
                              <span className="bg-[#25D366] px-4 py-2 rounded-lg rounded-es-none text-start">
                                {update.narration.replaceAll('~', '\n').toUpperCase()}
                              </span>
                              {policyDetails && (
                                <a target="_blank" href={policyDetails.policyLink}>
                                  <button className="bg-blue-400 hover:bg-blue-500 transition-all py-2 px-4 rounded-lg text-white">
                                    View Policy
                                  </button>
                                </a>
                              )}
                            </div>
                            <p className="text-xs text-gray-500">
                              {update.updatedBy} -{' '}
                              {dayjs.unix(update.created_at).format('MMMM D, YYYY hh:mm A')}
                            </p>
                          </div>
                          {/* <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{update.narration}</h3> */}
                        </li>
                      );
                    } else
                      return (
                        <li key={index} className="mb-4 ms-4">
                          <div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700"></div>
                          <h3 className="text-[14px] font-semibold text-gray-900 dark:text-black">
                            Update #{index + 1}
                          </h3>
                          <div className="flex flex-col items-start justify-end mb-1 mt-2 text-md text-gray-800 relative rounded-md">
                            <span className="bg-[#25D366] px-4 py-2 rounded-lg rounded-es-none text-start">
                              {update.narration.replaceAll('~', '\n').toUpperCase()}
                            </span>
                            <p className="text-xs text-gray-500">
                              {update.updatedBy} -{' '}
                              {dayjs.unix(update.created_at).format('MMMM D, YYYY hh:mm A')}
                            </p>
                          </div>
                          {/* <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{update.narration}</h3> */}
                        </li>
                      );
                  })}
              </ol>
            )}
            {['accounts', 'admin'].includes(department) &&
              ['pending - accounts', 'closed'].includes(record.status) && (
                // closingComments?.cancellationReceipt === 'Received' &&
                <div className="m-10">
                  {loading && Object.keys(calculation).length === 0 && (
                    <Oval
                      visible={true}
                      height="32"
                      width="32"
                      color="#3B82F6"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                  {calculation?.type === 1 && (
                    // ? We have the cancellation receipt
                    <div>
                      <h1 className="block mb-2 text-sm font-medium text-gray-900">
                        Cancellation Calculation
                      </h1>
                      <table>
                        <tbody>
                          <tr>
                            <td className="custom-td">Gross Insurance Amount</td>
                            <td className="custom-td">{calculation?.calculation?.insuranceGrossPrice}</td>
                          </tr>
                          <tr className="bg-green-50">
                            <td className="custom-td">Refunded Insurance Amount</td>
                            <td className="custom-td">{calculation?.calculation?.insuranceRefundedPrice}</td>
                          </tr>
                          <tr>
                            <td className="custom-td">Gross ACPL Amount</td>
                            <td className="custom-td">{calculation?.calculation?.acplGrossPrice}</td>
                          </tr>
                          <tr className="bg-green-50">
                            <td className="custom-td">Refunded ACPL Amount</td>
                            <td className="custom-td">{calculation?.calculation?.acplRefundedPrice}</td>
                          </tr>
                          <tr>
                            <td className="custom-td">Total Gross Amount</td>
                            <td className="custom-td">
                              {calculation?.calculation?.acplGrossPrice +
                                calculation?.calculation?.insuranceGrossPrice}
                            </td>
                          </tr>
                          <tr className="bg-green-50">
                            <td className="custom-td">Total Refund Amount</td>
                            <td className="custom-td">
                              {calculation?.calculation?.acplRefundedPrice +
                                calculation?.calculation?.insuranceRefundedPrice}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {calculation?.type === 2 && (
                    // ? We don't have the cancellation receipt
                    <div>
                      <h1 className="block mb-2 text-sm font-medium text-gray-900">
                        Cancellation Calculation
                      </h1>
                      <table>
                        <tbody>
                          <tr>
                            <td className="custom-td">Gross Combined Insurance Amount</td>
                            <td className="custom-td">{calculation?.calculation?.insuranceGrossPrice}</td>
                          </tr>
                          <tr className="bg-green-50">
                            <td className="custom-td">Refunded Gross Combined Insurance Amount</td>
                            <td className="custom-td">{calculation?.calculation?.insuranceRefundPrice}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {calculation?.type === 3 && (
                    <div>
                      <h1 className="block mb-2 text-sm font-medium text-gray-900">
                        Cancellation Calculation
                      </h1>
                      <table>
                        <tbody>
                          <tr>
                            <td className="custom-td">Gross ACPL Amount</td>
                            <td className="custom-td">{calculation?.calculation?.acplGrossPrice}</td>
                          </tr>
                          <tr className="bg-green-50">
                            <td className="custom-td">Refund ACPL Amount</td>
                            <td className="custom-td">{calculation?.calculation?.refundAmount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
        <div>
          <div className="m-10">
            <h1 className="block mb-2 text-sm font-medium text-gray-900">Ticket Details</h1>
            <table>
              <tbody>
                {recordDetails.map((ele, index) => (
                  <tr key={index}>
                    <td className="custom-td text-sm">{ele.label}</td>
                    <td
                      onClick={(e) => {
                        if (e.target.innerText !== 'Copied!') {
                          navigator.clipboard.writeText(e.target.innerText);
                          const text = e.target.innerText;
                          e.target.innerText = 'Copied!';
                          setTimeout(() => {
                            e.target.innerText = text;
                          }, 250);
                        }
                      }}
                      className="custom-td text-sm cursor-pointer"
                    >
                      {record[ele.key]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="block mb-2 mt-2 text-sm font-medium text-gray-900">Description</h1>
            <p className="p-4 rounded-md w-[500px] text-md text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500">
              {description}
            </p>
          </div>
          {['business support', 'accounts', 'admin'].includes(department) && (
            <div className="flex flex-col justify-start items-start ml-10">
              <table>
                <tbody>
                  {closingComments?.cancellationReceipt === 'Received' && (
                    <div className="mb-4">
                      <tr>
                        <td className="custom-td">Cancellation Receipt</td>
                        <td className="custom-td">{closingComments?.cancellationReceipt}</td>
                      </tr>
                      <tr>
                        <td className="custom-td">Policy Number</td>
                        <td className="custom-td">{record?.policy_number}</td>
                      </tr>
                      <tr>
                        <td className="custom-td">Amount</td>
                        <td className="custom-td">{closingComments?.refundedAmount}</td>
                      </tr>
                      <tr>
                        <td className="custom-td">Date of Transaction</td>
                        <td className="custom-td">
                          {dayjs(closingComments?.dateOfTransaction).format('DD/MM/YYYY')}
                        </td>
                      </tr>
                      <tr>
                        <td className="custom-td">Payment received at</td>
                        <td className="custom-td">{closingComments?.amountLocation}</td>
                      </tr>
                      {closingComments?.files?.map((file, index) => (
                        <>
                          <tr>
                            <td className="custom-td">Document {index + 1}</td>
                            <td className="custom-td">
                              <a
                                className="bg-[#3B82F6] text-white py-2 px-4 rounded-md mt-2"
                                href={`https://drive.google.com/file/d/${file}`}
                                target="_blank"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </>
                      ))}
                    </div>
                  )}
                  {closingComments?.cancellationReceipt === 'Not Received' &&
                    record.cancellation_type === 'Insurance' && (
                      <div className="mb-4">
                        <tr>
                          <td className="custom-td">Cancellation Receipt</td>
                          <td className="custom-td">{closingComments?.cancellationReceipt}</td>
                        </tr>
                        <tr>
                          <td className="custom-td">Comments</td>
                          <td className="custom-td">{closingComments?.closeTicketComments}</td>
                        </tr>
                        <tr>
                          <td className="custom-td">Closed By</td>
                          <td className="custom-td">{closingComments?.closedBy}</td>
                        </tr>
                      </div>
                    )}
                </tbody>
              </table>
              {loading && ['pending - business support', 'pending - accounts'].includes(record.status) && (
                <Oval
                  visible={true}
                  height="32"
                  width="32"
                  color="#3B82F6"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
              {!loading && ['pending - business support', 'pending - accounts'].includes(record.status) && (
                <div className="flex items-center gap-2 mb-10">
                  <button
                    onClick={async () => {
                      setLoading(true);
                      const res = await approveRequest(record, state.user, calculation);
                      setLoading(false);
                      if (res) {
                        navigate('/ops/cancellations/view');
                      }
                    }}
                    className="bg-[#2ec342] text-white py-2 px-4 rounded-md mt-2"
                    disabled={loading}
                  >
                    Approve
                  </button>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      const res = await rejectRequest(record, state.user);
                      setLoading(false);
                      if (res) {
                        navigate('/ops/cancellations/view');
                      }
                    }}
                    className="bg-[#f63b3b] text-white py-2 px-4 rounded-md mt-2"
                    disabled={loading}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCancellationDetails;
