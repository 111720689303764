import { baseUrlFrontend } from './env.js';

const forms = [
  // {
  //   name: 'Dealer Add Balance Permission',
  //   dept: ['ops', 'admin'],
  //   url: `${baseUrlFrontend}/forms/add-balance-dealer-permission`
  // },
  // {
  //   name: 'Dealer View Permits',
  //   dept: ['ops','admin'],
  //   url: `${baseUrlFrontend}/forms/update-dealer-permit`
  // },
  {
    name: 'Update Dealer Password',
    dept: ['OPS', 'ADMIN'],
    url: `${baseUrlFrontend}/forms/update-dealer-password`,
  },
  {
    name: 'Fix Chassis Number Issue ( Insurance / Certificate )',
    dept: ['OPS', 'ADMIN'],
    url: `${baseUrlFrontend}/forms/certificate-insurance-chassis-fix`,
  },
  {
    name: 'Search Policy Status',
    dept: ['OPS', 'ADMIN'],
    url: `/policy/search`,
  },
  // {
  //   name: 'Dealer Add Balance Permission',
  //   dept: ['ops', 'admin'],
  //   url: `${baseUrlFrontend}/forms/add-balance-dealer-permission`
  // },
  // {
  //   name: 'Transfer Policy United to Royal Sundaram',
  //   dept: ['ops', 'admin'],
  //   url: `${baseUrlFrontend}/forms/transfer-policy-united-rs`
  // },
  {
    name: 'Get Inspection Files',
    dept: ['CLAIMS', 'ADMIN', 'BPO'],
    url: `${baseUrlFrontend}/forms/inspection-files`,
  },
  {
    name: 'Get Dealer Discount',
    dept: ['OPS', 'ADMIN'],
    url: `${baseUrlFrontend}/forms/dealer-discount`,
  },
  {
    name: 'Dealer Payment Logs',
    dept: ['OPS', 'ADMIN'],
    url: `${baseUrlFrontend}/forms/payment-logs`,
  },
  {
    name: 'RM Price List',
    dept: ['SALES', 'ADMIN', 'OPS'],
    url: `/dealer/pricelist`,
  },
  {
    name: 'Dealer Report Generator',
    dept: ['ACCOUNTS', 'ADMIN', 'BPO'],
    url: `/accounts/report-generator`
  },
  {
    name: 'KYC Documents Downloader',
    dept: ['BPO', 'ADMIN'],
    url: `/bpo/kyc-documents-downloader`
  },
  {
    name: 'Business Details',
    dept: ['BUSINESS SUPPORT', 'ADMIN'],
    url: `/revenue`
  },
  {
    name: 'View Business Stats',
    dept: ['SALES', 'ADMIN'],
    url: `/view/stats`
  }
];

const statusColor = {
  Good: 'text-green-500',
  Okay: 'text-orange-500',
  Bad: 'text-red-500',
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const insurers = [
  'Acko General Insurance Limited',
  'Bajaj Allianz General Insurance Co Ltd.',
  'Bharti AXA General Insurance Company Limited',
  'Cholamandalam MS General Insurance Company Limited.',
  'DHFL General Insurance Limited',
  'Edelweiss General Insurance Company Limited',
  'Future Generali India Insurance Company Limited.',
  'Go Digit General Insurance Limited',
  'HDFC ERGO General Insurance Company Limited.',
  'HDFC General Insurance Company Ltd.',
  'ICICI Lombard General Insurance Company Limited.',
  'IFFCO Tokio General Insurance Company Limited.',
  'KOTAK MAHINDRA GENERAL INSURANCE',
  'L&T General Insurance Company Limited',
  'Liberty Videocon General Insurance Company Limited',
  'Magma HDI General Insurance Company Limited',
  'National Insurance Company Limited',
  'Raheja QBE General Insurance Company Limited',
  'Reliance General Insurance Company Limited.',
  'Royal Sundaram Alliance Insurance Company Limited.',
  'SBI General Insurance Company Limited',
  'Shriram General Insurance Company Limited.',
  'Tata AIG General Insurance Company Limited',
  'The New India Assurance Company Limited',
  'The Oriental Insurance Company Limited',
  'United India Insurance Company Limited',
  'Universal Sompo General Insurance Company Limited ',
];


// Allowed Path ADMIN
const ADMIN_PATHS = {
  redirectTo: '/revenue',
  allowed: ['/revenue', '/dealer/dashboard', '/revenue/rm/stats', '/profile', '/policy/search', '/dealer/upload/pricelist', '/dealer/pricelist', `/dealer/pricelist/edit`, '/dealer/pricelist/upload-working', '/accounts/dealer-report-generator', '/bpo/kyc-documents-downloader', '/dealer/payment-logs', '/view/insurance/policies', '/dealer/discount-config', '/claims/inspection-files', '/aegiscovenant/opsit/reset-password', '/dealer/config/request', '/claims/register', '/claims/view', '/dealers/onboarding', '/ops/endorsements/register', '/ops/endorsements/view', '/ops/endorsements/update', '/ops/endorsements/details', '/ops/endorsements/close', '/ops/cancellations/register', '/ops/cancellations/view', '/ops/cancellations/close-ticket', '/bst/dealer/onboarding/requests', '/dealer/onboarding/edit', '/dealer/onboarding/reject', '/insurance/makes', '/sales/dealerlist', '/claims/post-update', '/claims/bulk-upload', '/insurance/policies/view'],
  navbarList: [
    {
      name: 'Dealer Dashboard',
      url: '/dealer/dashboard'
    },
    {
      name: 'Claims Dashboard',
      url: '/claims/view'
    },
    {
      name: 'View Insurance Policies',
      url: '/insurance/policies/view'
    },
    {
      name: 'Search Policy',
      url: '/policy/search'
    },
    {
      name: 'Dealer Report Generator',
      url: '/accounts/dealer-report-generator'
    },
    {
      name: 'KYC Documents Downloader',
      url: '/bpo/kyc-documents-downloader'
    },
    {
      name: 'Dealer Payment Logs',
      url: '/dealer/payment-logs'
    },
    {
      name: 'View Dealer Discount Config',
      url: 'dealer/discount-config'
    },
    {
      name: 'Get Inspection Files',
      url: '/claims/inspection-files'
    },
    {
      name: 'View Business Details',
      url: '/revenue'
    },
    {
      name: 'View Price Lists',
      url: '/dealer/pricelist'
    },
    {
      name: 'View RM Stats',
      url: '/revenue/rm/stats'
    },
    {
      name: 'Fix Chassis Number',
      url: '/ops/certificate-insurance-chassis-fix'
    },
    {
      name: 'Dealer Config Request Generator',
      url: '/dealer/config/request'
    },
    {
      name: 'Upload Dealer Config Form',
      url: '/dealer/config/upload'
    },
    {
      name: 'Standardise DB',
      url: '/insurance/makes'
    },
    {
      name: 'Endorsements',
      url: '/ops/endorsements/view'
    },
    {
      name: 'Cancellations',
      url: '/ops/cancellations/view'
    },
    {
      name: 'Dealer Creation Requests',
      url: '/bst/dealer/onboarding/requests'
    },
    {
      name: 'Generate Cancellation Ticket',
      url: '/cancellations/generator'
    },
    {
      name: 'View Dealers List',
      url: '/sales/dealerlist'
    },
    {
      name : 'Bulk Claim Upload',
      url: '/claims/bulk-upload'
    }
  ],
  fallback: '/revenue'
};

// Team Accounts
const ACCOUNTS_PATHS = {
  redirectTo: '/accounts/dealer-report-generator',
  allowed: ['/profile', '/accounts/dealer-report-generator', '/ops/cancellations/register', '/ops/cancellations/view', '/ops/cancellations/close-ticket', '/dealer/payment-logs', '/aegiscovenant/opsit/reset-password'],
  navbarList: [
    {
      name: 'Dealer Report Generator',
      url: '/accounts/dealer-report-generator'
    },
    {
      name: 'Dealer Payment Logs',
      url: '/dealer/payment-logs'
    },
    {
      name: 'Cancellations',
      url: '/ops/cancellations/view'
    },
  ],
  fallback: '/dealer/payment-logs'
}

// BPO Paths
const BPO_PATHS = {
  redirectTo: '/bpo/kyc-documents-downloader',
  allowed: ['/profile', '/bpo/kyc-documents-downloader', '/aegiscovenant/opsit/reset-password'],
  navbarList: [
    {
      name: 'KYC Documents Downloader',
      url: '/bpo/kyc-documents-downloader'
    }
  ],
  fallback: '/bpo/kyc-documents-downloader'
};

// RM Paths
const SALES_MANAGER_PATHS = {
  redirectTo: '/dealer/pricelist',
  allowed: ['/profile', '/dealer/pricelist', '/view/stats', '/dealer/upload/pricelist', '/dealer/pricelist', `/dealer/pricelist/edit`, '/view/insurance/policies', '/aegiscovenant/opsit/reset-password', '/sales/dealerlist', '/dealers/onboarding', '/dealer/onboarding/edit', '/insurance/policies/view'],
  navbarList: [
    {
      name: 'View Price lists',
      url: '/dealer/pricelist'
    },
    {
      name: 'View Insurance Policies',
      url: '/insurance/policies/view'
    },
    {
      name: 'View Stats',
      url: '/view/stats'
    },
    {
      name: 'Dealers list',
      url: '/sales/dealerlist'
    }
  ],
  fallback: '/view/stats'
}

// Head Office OPS
const OPS_HO_PATHS = {
  redirectTo: '/dealer/payment-logs',
  allowed: ['/profile', '/dealer/payment-logs',  '/dealer/dashboard', '/view/insurance/policies', '/dealer/discount-config', '/policy/search', '/aegiscovenant/opsit/reset-password', '/ops/endorsements/register', '/insurance/makes', '/dealer/config/request', '/ops/endorsements/register', '/ops/endorsements/view', '/ops/endorsements/update', '/ops/endorsements/details', '/ops/endorsements/close', '/ops/cancellations/register', '/ops/cancellations/view', '/ops/cancellations/close-ticket', '/ops/certificate-insurance-chassis-fix', '/sales/dealerlist', '/insurance/policies/view'],
  navbarList: [
    { 
      name: 'Dealer Dashboard',
      url:  '/dealer/dashboard'
    },
    {
      name: 'View Dealers List',
      url: '/sales/dealerlist'
    },
    {
      name: 'View Insurance Policies',
      url: '/insurance/policies/view'
    },
    {
      name: 'Dealer Payment Logs',
      url: '/dealer/payment-logs'
    },
    {
      name: 'Search Policy',
      url: '/policy/search'
    },
    {
      name: 'View Dealer Discount Config',
      url: '/dealer/discount-config'
    },
    {
      name: 'Fix Chassis Number',
      url: '/ops/certificate-insurance-chassis-fix'
    },
    {
      name: 'Generate Cancellation Ticket',
      url: '/cancellations/generator'
    },
    {
      name: 'Dealer Config Request Generator',
      url: '/dealer/config/request'
    },
    {
      name: 'Upload Dealer Config Form',
      url: '/dealer/config/upload'
    },
    {
      name: 'Standardise DB',
      url: '/insurance/makes'
    },
    {
      name: 'Endorsements',
      url: '/ops/endorsements/view'
    },
    {
      name: 'Cancellations',
      url: '/ops/cancellations/view'
    },
  ],
  fallback: '/dealer/payment-logs'
};

// Zonal OPS
const OPS_NON_HO_PATHS = {
  redirectTo: '/dealer/payment-logs',
  allowed: ['/profile', '/dealer/payment-logs', '/view/insurance/policies', '/aegiscovenant/opsit/reset-password', '/ops/endorsements/register', '/ops/endorsements/view', '/ops/endorsements/update', '/ops/endorsements/details', '/ops/endorsements/close', '/ops/cancellations/register', '/ops/cancellations/view', '/ops/cancellations/close-ticket', '/insurance/policies/view'],
  navbarList: [
    {
      name: 'Dealer Payment Logs',
      url: '/dealer/payment-logs'
    },
    {
      name: 'View Insurance Policies',
      url: '/insurance/policies/view'
    },
    {
      name: 'Endorsements',
      url: '/ops/endorsements/view'
    },
    {
      name: 'Cancellations',
      url: '/ops/cancellations/view'
    }
  ],
  fallback: '/dealer/payment-logs'
};

// Business Support
const BUSINESS_SUPPORT_PATHS = {
  redirectTo: '/revenue',
  allowed: ['/profile', '/revenue', '/aegiscovenant/opsit/reset-password', '/ops/cancellations/register', '/ops/cancellations/view', '/ops/cancellations/close-ticket', '/dealer/pricelist', '/dealer/pricelist/upload-working', '/revenue/rm/stats', '/dealer/onboarding/reject', '/insurance/policies/view'],
  navbarList: [
    {
      name: 'View Business Details',
      url: '/revenue'
    },
    {
      name: 'View Insurance Policies',
      url: '/insurance/policies/view'
    },
    {
      name: 'View Sales Manager Stats',
      url: '/revenue/rm/stats'
    },
    {
      name: 'View Price Lists',
      url: '/dealer/pricelist'
    },
    {
      name: 'Dealer Creation Requests',
      url: '/bst/dealer/onboarding/requests'
    },
    {
      name: 'Cancellations',
      url: '/ops/cancellations/view'
    },
  ],
  fallback: '/revenue'
}

// Claims
const CLAIMS_PATHS = {
  redirectTo: '/dealer/payment-logs',
  allowed: ['/profile', '/claims/inspection-files', '/aegiscovenant/opsit/reset-password', '/claims/view', '/claims/post-update'],
  navbarList: [
    {
      name: 'Claims Inspection Files',
      url: '/claims/inspection-files'
    },
    {
      name: 'Claims Dashboard',
      url: '/claims/view'
    },
  ],
  fallback: '/claims/inspection-files'
};

const DEV_PATHS = {
  redirectTo: '/dealer/dashboard',
  allowed: ['/dealer/dashboard'],
  navbarList: [
    {
      name: 'Dealer Dashboard',
      url: '/dealer/dashboard'
    },
  ],
  fallback: '/dealer/dashboard'
}


export { forms, statusColor, months, insurers, ADMIN_PATHS, OPS_NON_HO_PATHS, OPS_HO_PATHS, ACCOUNTS_PATHS, BPO_PATHS, SALES_MANAGER_PATHS, BUSINESS_SUPPORT_PATHS, CLAIMS_PATHS, DEV_PATHS };
