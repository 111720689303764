import axios from 'axios';

import { URL } from '../../../../env.js';

const fetchDealerConfig = async (dealerEmail) => {
  const response = await axios.get(`${URL}/dealer/config/${dealerEmail}`);

  const { data } = response;

  return data;
};

export { fetchDealerConfig };
