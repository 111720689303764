import { useLayoutEffect } from 'react';
import { checkAuthentication } from './functions';

const AuthCheck = ({dispatch, navigate, pathname}) => {
  // Check authentication
  useLayoutEffect(() => {
    const auth = async () => {
      const auth = await checkAuthentication(dispatch, navigate, pathname);
      if (!auth) navigate('/aegiscovenant/opsit/login');
    };
    auth();
  }, []);
};

export default AuthCheck;