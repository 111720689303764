import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setFilteredClaimRecors } from "../redux";
import dayjs from "dayjs";
import { downloadClaimsData } from "./functions";

// Button to Add New Claim
const RegisterNewClaimButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        className="bg-green-600 p-2 rounded-md font-semibold shadow-lg text-white text-sm"
        onClick={() => {
          navigate("/claims/register");
        }}
      >
        <i class="fa-solid fa-square-plus"></i> New Claim
      </button>
    </div>
  );
};

// View Different Claims
const ClaimStatus = () => {
  const [activeStatus, setActiveStatus] = useState("Pending");
  const dispatch = useDispatch();

  // Claims Store
  const { claimRecords } = useSelector(({ Claims }) => {
    return Claims;
  });

  const changeActiveStatus = (e) => {
    setActiveStatus(e.target.innerText);
  };

  useLayoutEffect(() => {
    if (claimRecords) {
      const filteredRecords = claimRecords.filter((record) => {
        return record.status.toLowerCase() === activeStatus.toLowerCase();
      });

      dispatch(setFilteredClaimRecors(filteredRecords));
    }
  }, [claimRecords, activeStatus, dispatch]);

  return (
    <div className="flex text-md w-3/4">
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Pending" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Pending
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Insurer Intimated"
            ? "border-b-4 border-blue-400"
            : ""
        } hover:cursor-pointer`}
      >
        Insurer Intimated
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Surveyor Appointed"
            ? "border-b-4 border-blue-400"
            : ""
        } hover:cursor-pointer`}
      >
        Surveyor Appointed
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Report Pending" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Report Pending
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Report Submitted" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Report Submitted
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Settled" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Settled
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Rejected" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Rejected
      </span>
      <span
        onClick={changeActiveStatus}
        className={`mr-4 p-1 ${
          activeStatus === "Closed" ? "border-b-4 border-blue-400" : ""
        } hover:cursor-pointer`}
      >
        Closed
      </span>
    </div>
  );
};

// View Claim Cards
const ClaimCard = () => {
  const { filteredClaimRecords } = useSelector(({ Claims }) => {
    return Claims;
  });

  const { name, department } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const navigate = useNavigate();

  // filteredClaimRecords.map((record) => {
  return (
    <>
      {filteredClaimRecords &&
        filteredClaimRecords.map((record) => {
          return (
            <div className=" my-4 w-full p-3 rounded-md shadow-lg text-sm outline border-gray-400">
              <div className="flex">
                <div>
                  <span>Ticket ID: {record?.id}</span>
                </div>
              </div>
              <div className="flex justify-between my-2 text-sm">
                <div>
                  <span>Customer Name : {record?.customerDetails?.name}</span>
                </div>
                <div id="actionables">
                  <button
                    onClick={() => {
                      navigate(`/claims/details/${record?.id}`);
                    }}
                    className="p-1 mr-2 text-sm outline rounded-lg"
                  >
                    {" "}
                    <i class="fa-solid fa-timeline"></i> Activity History
                  </button>
                  {["CLAIMS", "ADMIN", "BUSINESS SUPPORT"].includes(
                    department
                  ) && (
                    <>
                      {!["closed", '', "rejected"].includes(record?.status) && (
                        <button
                          onClick={() => {
                            navigate(`/claims/post-update/${record?.id}`);
                          }}
                          className="p-1 mr-2 text-sm outline rounded-lg"
                        >
                          {" "}
                          <i class="fa-solid fa-pen-to-square"></i> Post An
                          Update
                        </button>
                      )}
                      {!["closed", "rejected"].includes(record?.status) && (
                        <button
                          onClick={() => {
                            navigate(`/claims/update/${record?.id}`);
                          }}
                          className="p-1 mr-2 text-sm outline rounded-lg"
                        >
                          {" "}
                          <i class="fa-solid fa-highlighter"></i> Update Status
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-between text-sm ">
                <div>
                  <span>Dealer Name : {record?.dealerDetails?.name}</span>
                  <br />
                  <span>
                    Insurance Company Name : {record?.policyDetails?.insurer}
                  </span>
                </div>
                <div>
                  <span>
                    Created At :{" "}
                    {dayjs(record?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                  </span>
                  <br />
                  <span>
                    Last Updated At :{" "}
                    {record?.updatedAt
                      ? dayjs(record.updatedAt).format("DD-MM-YYYY HH:mm:ss")
                      : "-- No Updates --"}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

// Download Claims Records Button
const DownloadClaimsData = () => {
  return (
    <div>
      <button
        className="bg-blue-600 p-2 mx-2 rounded-md font-semibold shadow-lg text-white text-sm"
        onClick={async () => {
          await downloadClaimsData();
        }}
      >
        <i class="fa-solid fa-file-arrow-down"></i> Download Records
      </button>
    </div>
  );
};

export { RegisterNewClaimButton, ClaimStatus, ClaimCard, DownloadClaimsData };
