import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  registeringClaim: false,
  searchingRecord: false,
  policyRecord : null,
  claimRecords: null,
  filteredClaimRecords: null,
  postingFollowup: false,
  viewDetailsCard: null,
  updatingStatus: false
};

const Claims = createSlice({
  name: 'Claims',
  initialState,
  reducers: {
    setUpdatingStatus: (state, action) => {
      state.updatingStatus = action.payload;
    },
    setViewDetailsCard: (state, action) => {
      state.viewDetailsCard = action.payload;
    },
    setPostingFollowup: (state, action) => {
      state.postingFollowup = action.payload;
    },
    setRegisteringClaim: (state, action) => {
      state.registeringClaim = action.payload;
    },
    setSearchingRecord: (state, action) => {
      state.searchingRecord = action.payload;
    },
    setPolicyRecord: (state, action) => {
      state.policyRecord = action.payload
    },
    setClaimRecords: (state, action) => {
      state.claimRecords = action.payload?.records;
    },
    setFilteredClaimRecors: (state, action) => {
      state.filteredClaimRecords = action.payload;
    }
  }
});


export const {
  setSearchingRecord,
  setPolicyRecord,
  setClaimRecords,
  setRegisteringClaim,
  setFilteredClaimRecors,
  setPostingFollowup,
  setViewDetailsCard,
  setUpdatingStatus
} = Claims.actions;

export default Claims.reducer;