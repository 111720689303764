import { createSlice } from '@reduxjs/toolkit';

export const dealerPaymentLogsSlice = createSlice({
  name: 'dealerPaymentLogs',
  initialState: {
    from: 101
  },
  reducers: {
    setPaymentLogs: (state, action) => {
      return state = {...state, paymentLogs: action.payload}
    },
    setRange: (state, action) => {
      return state = {...state, from: action.payload}
    },
    updatePaymentLogs: (state, action) => {
      return state = {...state, paymentLogs: [...state.paymentLogs, ...action.payload]}
    }
  }
});

export const { setPaymentLogs, setRange, updatePaymentLogs } = dealerPaymentLogsSlice.actions;
export default dealerPaymentLogsSlice.reducer;