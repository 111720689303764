export const DEALER_LOGS = {
    'ID': 'id', 
    'Dealer Name': 'dealer_name',
    'Dealer Email': 'dealer_email',
    'Status': 'status',
    'Upload Form': 'upload_form',
    'Requested By': 'requested_by',
    'Request Type': 'request_type',
    'Slack Message ID': 'slack_message_id',
    // 'Previous New Discount': 'previous_new_discount',
    // 'Previous Renewal Discount': 'previous_renewal_discount',
    // 'Previous New Payout': 'previous_new_payout',
    // 'Previous Renewal Payout': 'previous_renewal_payout',
    'Created At': 'created_at',
    'Updated At': 'updated_at'
};

export const STATUS_COLORS = {
    'Pending': 'bg-yellow-500',
    'Processing': 'bg-orange-500',
    'Rejected': 'bg-red-500',
    'Approved': 'bg-green-500',
};

export const SELECTED_LOG = {
    'ID': 'id', 
    'Dealer Name': 'dealer_name',
    'Dealer Email': 'dealer_email',
    'Status': 'status',
    'Requested By': 'requested_by',
    'Request Type': 'request_type',
}