import axios from 'axios';
import { reloadPage } from '../../functions';
import { baseUrl } from '../../env';

// Sign out
const logoutUser = async () => {
  const options = {
    method: 'GET',
    url: `${baseUrl}/logout`,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
    },
  };
  await axios.request(options);
  reloadPage(0);
};

export { logoutUser };
