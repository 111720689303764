import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import claimsFollowupSchema from './followupSchema';
import { useDispatch, useSelector } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import { postTicketUpdate } from './functions';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const PostAnUpdate = ({ id }) => {

  const dispatch = useDispatch();

  const { name } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const { postingFollowup } = useSelector(({ Claims }) => {
    return Claims;
  })

  const [posted, setPosted] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(claimsFollowupSchema) });

  return (
    <div className='p-5'>
      <h1 className='text-[30px] font-bold border-b-4 w-fit border-gray-400'>Post Follow Up / Update</h1>
      <span className='block'>Ticket ID : {id}</span>
      <form className='my-10' noValidate onSubmit={handleSubmit(async (e) => {
        const posted = await postTicketUpdate(e, name, id, dispatch);
        
        if (posted?.msg?.includes('Success')) {
          setPosted(true);
          setTimeout(() => {
            navigate('/claims/view');
          }, 2000);
        } else {
          setPosted(false);
          setTimeout(() => {
            setPosted(null);
          },2000);
        }
      })}>
        <label htmlFor="description" className='font-bold'>Description <Required /></label>
        <textarea disabled={postingFollowup} id='description' {...register('description')} className='my-2 shadow-lg text-sm outline-none rounded-md block' cols="50" rows="5" style={{ resize: 'none' }}></textarea>
        {errors.description ? <InputError error={errors.description} /> : null}
        <div className='flex'>
          <button disabled={postingFollowup} className='disabled:opacity-40 p-2 bg-blue-300 text-sm font-bold rounded-lg shadow-lg'>Post Follow Up</button>
          {
            postingFollowup &&
            <Oval height={'32px'} width={'40px'} />
          }
        </div>
        {
          posted !== null &&
          <span className={`mt-2  text-white block w-fit text-sm font-bold p-2 bg-${posted ? 'green': 'red'}-500 rounded-lg`}> {
            posted ? <i class="fa-solid fa-circle-check"></i> : <i class="fa-solid fa-circle-xmark"></i>
          } { posted ? 'Update Posted Successfully': 'Failed to Post Update' }</span>
        }
      </form>
    </div>
  )
};

// Error on failed validation of any input
const InputError = ({ error }) => {
  return (
    <p className="text-sm bg-red-500 text-white rounded-lg w-fit my-1 p-2">
      <span>{error?.message ? error?.message : error}</span>
    </p>
  );
};

const Required = () => {
  return <span className="text-red-400">*</span>;
};

export {
  PostAnUpdate
};