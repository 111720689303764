// Error on failed validation of any input
const InputError = ({ error }) => {
  console.log(error);
  return <p className="w-full text-sm text-red-500 my-1">{error?.message ? error?.message : error}</p>;
};

// Required
const Required = () => {
  return <span className='text-red-400'>*</span>
};


export {
  InputError,
  Required
};