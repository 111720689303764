import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  password: '',
  username: '',
  userDepartment: ''
};

export const newUserSlice = createSlice({
  name: 'newUser',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserDepartment: (state, action) => {
      state.userDepartment = action.payload;
    }
  }
});

export const { createNewUser } = newUserSlice.actions;
export default newUserSlice.reducer;