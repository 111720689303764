import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const chassisNumberDetails = createSlice({
  name: 'chassisNumberDetails',
  initialState,
  reducers: {
    setPolicyDetails: (state, action) => {
      return { ...action.payload };
    },
    resetPolicyDetails: (state, action) => {
      return {};
    }
  },
});

export const { setPolicyDetails, resetPolicyDetails } = chassisNumberDetails.actions;
export default chassisNumberDetails.reducer;
