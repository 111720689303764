import React, { useEffect, useState } from 'react'
import Heading from '../../../../Shared/Heading.jsx';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AuthCheck from '../../../../AuthCheck.jsx';

const ViewEndorsementDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { id } = useParams();
  const [updates, setUpdates] = useState();
  const [description, setDescription] = useState();

  const records = useSelector(({ Endorsements }) => {
    return Endorsements.records;
  });

  useEffect(() => {
    const record = records.filter((record) => {
      return record.id === parseInt(id);
    });

    const record_updates = JSON.parse(record[0].updates);
    const description = record;
    
    setUpdates(record_updates);
    setDescription(description[0]?.additional_info);

  }, [records, id]);

  return (
    <div>
      {/* <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname}/> */}
      <Heading title={`Detailed Updates Timelime ACPL-EN-2024-${id}`} />
      <div className='grid grid-cols-2'>
        <ol className="relative m-10 border-s border-gray-700">
          {
            updates && updates.map((update, index) => {
              return (
                <li className="mb-10 ms-4">
                  <div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700"></div>
                  <h3 className="text-lg font-semibold text-black">Update #{index + 1}</h3>
                  <p className="mb-1 font-semibold text-md text-gray-500">{update.narration.replaceAll('~', '\n').toUpperCase()}</p>
                  <h3 className="text-sm font-normal text-gray-900">Updated By : {update.updatedBy.toUpperCase()}</h3>
                  <time className="mb-4 text-sm text-black font-semibold">{dayjs.unix(update.created_at).format('MMMM D, YYYY hh:mm A')}</time>
                </li>
              )
            })
          }
        </ol>

        <div className='m-10'>
          <h1 className='bg-gray-700 w-fit p-2 mb-2 rounded-lg text-white font-bold'>Description</h1>
          <pre className=' overflow-scroll max-h-[450px] w-full rounded-lg p-5 text-white bg-gray-500'>
            {description}
          </pre>
        </div>
      </div>

    </div>
  )
}

export default ViewEndorsementDetails;
