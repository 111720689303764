import React from 'react';

import InsurerShifter from './components/insurer-shifter';
import DealerStats from './components/DealerStats/index.jsx';
import ViewDealerConfig from '../../Ops/Dealer Config/View/index.jsx';
import { useSelector } from 'react-redux';

const DealerDashboard = () => {
  const state = useSelector(({ loggedInUser }) => loggedInUser);

  return (
    <div className="flex gap-5 p-10 pb-10 bg-[#F2F2F2] h-[90vh] w-screen relative overflow-scroll">
      <div className="flex flex-col w-3/5 gap-5 relative">
        <InsurerShifter />
        {state.department !== 'DEV' &&<DealerStats />}
      </div>
      {state.department !== 'DEV' && <ViewDealerConfig />}
    </div>
  );
};

export default DealerDashboard;
