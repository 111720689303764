import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router';
import Heading from '../../../Shared/Heading.jsx';
import { useSelector } from 'react-redux';
import { UpdateForm } from './components.jsx';

const UpdateStatus = () => {

  const statuses = ['pending', 'insurer intimated', 'surveyor appointed', 'report pending', 'report submitted', 'settled', 'rejected', 'closed'];

  const [ticketStatus, setTicketStatus] = useState();
  const { id } = useParams();

  const { claimRecords } = useSelector(({ Claims }) => {
    return Claims;
  });

  const {name} = useSelector(({loggedInUser}) => {
    return loggedInUser;
  })

  useLayoutEffect(() => {
    if (claimRecords) {
      const record = claimRecords.filter((record) => {
        return record.id === id;
      });

      const ticketStatus = record[0]?.status;
      setTicketStatus(ticketStatus);
    }
  }, [claimRecords]);

  return (
    <div>
      <Heading title={'Claim'} />
      <div className='p-10'>
        {
          ticketStatus &&
          <UpdateForm status={ticketStatus} id={id} user={name}/>
        }
      </div>
    </div>
  )
}

export default UpdateStatus;