import * as yup from 'yup';

const updateClaimStatusSchema = yup.object().shape({
  status: yup.string().required('Current Status is required'),
  claimAmount: yup.string().when('status', (status, schema) => {
    if (status[0] === 'approved') {
      return schema.required('Claim Amount is required').matches(/^[0-9]*$/, 'Enter Claim Amount is Invalid.');
    }
  }),
  finalClosingComments: yup.string().when('status', (status, schema) => {
    if (status[0] === 'approved' || status[0] === 'rejected') {
      return schema.required('Closing Comments are required');
    }
  }),
  insuranceClaimNumber: yup.string().when('status', (status, schema) => {
    if (status[0] === 'pending') {
      return schema.required('Insurance Claim Number is required')
    }
  }),
  surveyorName: yup.string().when('status', (status, schema) => {
    if (status[0] === 'insurer intimated') {
      return schema.required('Surveyor Name is required');
    }
  }),
  surveyorMobile: yup.string().when('status', (status, schema) => {
    if (status[0] === 'insurer intimated') {
      return schema.required('Surveyor Mobile is required').matches(/^[0-9]*$/, 'Surveyor Mobile Number is Invalid').max(10, 'Invalid Mobile Number Entered');
    }
  }),
  surveyorAppointmentDate: yup.string().when('status', (status, schema) => {
    if (status[0] === 'insurer intimated') {
      return schema.required('Surveyor Date is required');
    }
  }),
  reportComments: yup.string().when('status', (status, schema) => {
    if (status[0] === 'report pending') {
      return schema.required('Report Submitted Comments are required');
    }
  }),
  transactionUTR: yup.string().when('status', (status, schema) => {
    if (status[0] === 'settled') {
      return schema.required('UTR Number is Mandatory');
    }
  })
});

export default updateClaimStatusSchema;