const states = [
  'Andaman & Nicobar',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra & Nagar Haveli',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu & Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Pondicherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal'
];

const makes = [
  "HONDA",
  "HERO MOTOCORP",
  "BAJAJ",
  "ROYAL ENFIELD",
  "YAMAHA",
  "TVS",
  "SUZUKI",
  "APRILIA",
  "PIAGGIO",
  "JAWA",
  "OKINAWA",
  "KTM",
  "HERO ELECTRIC",
  "HARLEY DAVIDSON",
  "DUCATI",
  "BMW",
  "ATHER ENERGY",
  "AMPERE",
  "ADMS E BIKES",
  "BGAUSS",
  "BOUNCE",
  "KEEWAY",
  "TRIUMPH",
  "REVOLT",
  "ONZO",
  "JOY E BIKE"
]

export {
  states,
  makes
};