import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setFilterActive, setFilterInput, setFilterValue, setFilteredRecords } from '../redux';
import { exportRecordsToExcel, filterRecordsView } from './functions';

const RegisterEndorsementRecord = () => {
  return (
    <div className="flex flex-row justify-start px-10">
      {/* Submit New Record Button */}
      <Link to="/ops/endorsements/register">
        <button
          type="button"
          className="font-bold text-gray-900 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg text-sm px-5 py-2.5 flex items-center dark:focus:ring-gray-500"
        >
          <svg
            className="w-4 h-4 me-2 -ms-1 text-[#626890]"
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="ethereum"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"
            ></path>
          </svg>
          Register New Record
        </button>
      </Link>
    </div>
  );
};


const SearchRecord = () => {

  const searchInputRef = useRef('');

  const dispatch = useDispatch();
  const { records, filterActive, filterInput, filterValue } = useSelector(({ Endorsements }) => {
    return Endorsements;
  });

  return (
    <div className="px-10 my-5">
      <div className="w-3/5 mx-auto">
        <div className="flex">
          <div className="relative w-full">
            <input value={filterInput} onChange={(e) => {
              dispatch(setFilterInput(e.target.value));
            }} ref={searchInputRef} type="search" id="search-dropdown" className="block outline-none p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border-s-gray-50 border-s-2 border border-gray-300  dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white rounded-s-lg" placeholder={`Search`} required />
            <button onClick={() => {
              const searchInputValue = searchInputRef.current.value;
              filterRecordsView(searchInputValue, filterValue, records, dispatch);
            }} type="submit" className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <select value={filterValue} onChange={(e) => {
            dispatch(setFilterValue(e.target.value));
          }} id="dropdown" className="z-10 text-white text-sm bg-gray-700 px-2 rounded-e-lg outline-none divide-gray-100">
            <option value=""> -- Choose Filter -- </option>
            <option value="insurance_company"> Insurance Company Name </option>
            <option value="policy_number"> Policy Number </option>
            <option value="dealer_name"> Dealer Name </option>
            <option value="customer_name"> Customer Name </option>
          </select>
          {
            filterActive &&
            <button onClick={() => {
              dispatch(setFilteredRecords([]));
              dispatch(setFilterActive(false));
              dispatch(setFilterInput(''));
              dispatch(setFilterValue(''));
            }} className='bg-gray-700 content-center m-1 px-2 rounded-lg'>
              <i className="fa-solid fa-trash text-red-600"></i>
            </button>
          }
          {/* <span>Delete Filter</span> */}
        </div>
      </div>

    </div>
  );
};

// Export to XLSX 
const GenerateReport = () => {

  const { filteredRecords, records } = useSelector(({Endorsements}) => {
    return Endorsements;
  });


  return (
    <div className="flex flex-row justify-start px-10">
      <button onClick={() => {
        const reportRecords = filteredRecords.length > 0 ? filteredRecords: records;
        exportRecordsToExcel(reportRecords);
      }} type="button" className="font-bold bg-green-500 text-white hover:bg-green-700 rounded-lg text-sm px-5 py-2.5 flex items-center">
        <i className="fa-solid fa-file-excel mr-2"></i>
        Export to Excel / CSV
      </button>
    </div>
  )
};

// Stats
const Stats = ({records, filteredRecords}) => {

  let pending = 0, closed = 0;

  if (filteredRecords.length > 0) {
    filteredRecords.map((record) => {
      return record.status === 'pending' ? pending++ : closed++;
    });
  } else {
    records.map((record) => {
      return record.status === 'pending' ? pending++ : closed++;
    })
  }

  return (
    <div className='flex text-center px-10 py-2 justify-center'>
      <button type="button" className="font-bold bg-green-700 text-white rounded-lg text-sm px-5 py-2.5 flex items-center">
        Closed Cases : {closed}
      </button>
      <button type="button" className="font-bold bg-yellow-600 text-white rounded-lg ml-2 text-sm px-5 py-2.5 flex items-center">
        Pending Cases : {pending}
      </button>
    </div>
  )
};

export {
  RegisterEndorsementRecord,
  SearchRecord,
  GenerateReport,
  Stats
};