import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Puff } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Heading from '../../Shared/Heading';
import schema from './schema';
import { getPolicyDetails } from './functions';
import InputError from '../../Shared/InputError';
import { TABLE_HEADERS, VALUE_KEYS } from './constants';
import { setPolicyDetails, resetPolicyDetails } from './redux';
import AuthCheck from '../../AuthCheck.jsx';

const SearchPolicy = () => {
  const [searching, setSearching] = useState(false);

  const state = useSelector(({ chassisNumberDetails }) => chassisNumberDetails);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setSearching(true);
    dispatch(resetPolicyDetails());
    const res = await getPolicyDetails(data.chassisNumber);
    dispatch(setPolicyDetails(res));
    setSearching(false);
  };

  const { pathname } = useLocation();

  return (
    <div className="flex flex-col justify-center items-center">
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title="Search Policy By Chassis Number" />
      <form onSubmit={handleSubmit(onSubmit)} className="w-[400px] flex flex-col gap-4 mt-10">
        <div className="custom-form-group">
          <input
            {...register('chassisNumber')}
            className="input bg-gray-600 text-white"
            placeholder="CHASSIS000000000001"
            disabled={searching}
          ></input>
          {errors.chassisNumber && <InputError error={errors.chassisNumber} />}
        </div>
        <button disabled={searching} className="custom-btn" type="submit">
          {searching ? "Searching" : "Search"}
        </button>
      </form>
      {searching && (
        <div className="mt-20">
          <Puff
            visible={true}
            height="80"
            width="80"
            color="#3680f7"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {!searching && Object.keys(state).length !== 0 && (
        <div>
          <table className="w-max my-10 p-4 text-center mx-auto text-sm rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {TABLE_HEADERS.map((header, index) => {
                  return (
                    <th className="p-4 text-black dark:text-white" key={index}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              {Object.keys(state).map((recordNum, index) => {
                const record = state[recordNum];
                return (
                  <tr key={'R' + index}>
                    {VALUE_KEYS.map((key, index) => {
                      return (
                        <td className="py-2 px-5 text-blue-600 dark:text-blue-300" key={'V' + index}>
                          {['created_at', 'updated_at'].includes(key)? dayjs(record[key]).format('DD/MM/YYYY hh:mm:ss'): record[key]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SearchPolicy;
