import * as yup from 'yup';

const reportGeneratorSchema = yup.object().shape({
  reportType: yup.string().required('Please Select Report Type'),
  dealerName: yup.string().required('Dealer Name is Required'),
  startYear: yup.string().required('Start Year is required'),
  startMonth: yup.string().required('Start Month is required'),
  endYear: yup.string().required('End Year is required'),
  endMonth: yup.string().required('End Month is required'),
});

export default reportGeneratorSchema;