import axios from 'axios';
import * as xlsx from 'xlsx';
import { URL, baseUrl } from './env.js';
import { setAllowedPaths } from './Pages/Login/redux.js';
import { ACCOUNTS_PATHS, ADMIN_PATHS, BPO_PATHS, BUSINESS_SUPPORT_PATHS, CLAIMS_PATHS, OPS_HO_PATHS, OPS_NON_HO_PATHS, SALES_MANAGER_PATHS, DEV_PATHS } from './constants.js';

// Check authentication
const checkAuthentication = async (dispatch, navigate, path) => {
  const options = {
    method: 'GET',
    url: `${baseUrl}/auth`,
    withCredentials: true,
  };
  const res = await axios.request(options);
  const { data } = res;

  if (res.status === 200) {
    if (data.dept === 'ADMIN') {
      dispatch(setAllowedPaths(ADMIN_PATHS));
      if (!(ADMIN_PATHS.allowed.includes(path))) {
        navigate(ADMIN_PATHS.fallback);
      }
    } else if (data.dept === 'OPS') {

      dispatch(setAllowedPaths(data.team !== 'HO' ? OPS_NON_HO_PATHS : OPS_HO_PATHS));

      if (data.team === 'HO') {
        if (!OPS_HO_PATHS.allowed.includes(path)) {
          navigate(OPS_HO_PATHS.fallback);
        }
      } else {
        if (!OPS_NON_HO_PATHS.allowed.includes(path)) {
          navigate(OPS_NON_HO_PATHS.fallback);
        }
      }

    } else if (data.dept === 'ACCOUNTS') {
      dispatch(setAllowedPaths(ACCOUNTS_PATHS));
      if (!(ACCOUNTS_PATHS.allowed.includes(path))) {
        navigate(ACCOUNTS_PATHS.fallback);
      }
    } else if (data.dept === 'BPO') {
      dispatch(setAllowedPaths(BPO_PATHS))
      if (!(BPO_PATHS.allowed.includes(path))) {
        navigate(BPO_PATHS.fallback);
      }
    } else if (data.dept === 'SALES') {
      dispatch(setAllowedPaths(SALES_MANAGER_PATHS))
      if (!(SALES_MANAGER_PATHS.allowed.includes(path))) {
        if (path === '/revenue/rm/stats' && (data.name === 'Madhav' || data.name === 'Amjad Pathan')) {
          // skip
        } else {
          navigate(SALES_MANAGER_PATHS.fallback);
        }
      }
    } else if (data.dept === 'BUSINESS SUPPORT') {
      dispatch(setAllowedPaths(BUSINESS_SUPPORT_PATHS));
      if (!(BUSINESS_SUPPORT_PATHS.allowed.includes(path))) {
        navigate(BUSINESS_SUPPORT_PATHS.fallback);
      }
    } else if (data.dept === 'CLAIMS') {
      dispatch(setAllowedPaths(CLAIMS_PATHS));
      if (!(CLAIMS_PATHS.allowed.includes(path))) {
        navigate(CLAIMS_PATHS.fallback);
      }
    } else if (data.dept === 'DEV') {
      dispatch(setAllowedPaths(DEV_PATHS));
      if (!(DEV_PATHS.allowed.includes(path))) {
        navigate(DEV_PATHS.fallback);
      }
    }
    return res.data;
  }

  return false;
};

// Get Portal Status
const getPortalStatus = async () => {
  const response = await axios.get(`${baseUrl}/status`);
  try {
    return response.data;
  } catch (err) {
    console.log('err');
  }

}

// CleanUp Request Payload
const cleanupRequestPayload = (request) => {
  const cleanPayload = {};

  for (const key in request) {
    if (request[key] !== '') {
      cleanPayload[key] = request[key];
    }
  }

  return cleanPayload;
};

// Update dealer password
const updateDealerPassword = async (req) => {
  const { dealerCode, dealerPassword } = req;

  const options = {
    method: 'POST',
    url: `${baseUrl}/update-dealer-password`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealerCode, dealerPassword })
  };

  const request = axios.request(options);
  const response = await request;
  return { msg: response.data.msg, status: response.status === 200 ? 'success' : 'failed' };
};

// Update dealer insurer manufacturer
const updateManufacturer = (config) => {
};

// Update Dealer View Permits
const updateDealerPermits = async (req) => {

  const { dealerCode, dealerEmail, insurancePermit, certificatePermit } = req;
  const options = {
    method: 'POST',
    url: `${baseUrl}/update-dealer-permit`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealerCode, dealerEmail, insurancePermit, certificatePermit })
  };

  const request = axios.request(options);
  const response = await request;

  return { msg: response.data.msg, status: response.data.status === 404 ? 'failed' : 'success' };

};

// Update dealer add balance permission
const updateDealerAddBalancePermission = async (req) => {

  const { dealerCode, dealerEmail, balancePermission } = req;

  const options = {
    method: 'POST',
    url: `${baseUrl}/add-balance-dealer-permission`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealerCode, dealerEmail, balancePermission })
  };

  const request = axios.request(options);
  const res = await request;
  return { msg: res.data.msg, status: res.status === 200 ? 'success' : 'failed' };
};

// Update Dealer Payment Request
const updateDealerPaymentRequest = async (request) => {
  const cleanupRequest = cleanupRequestPayload(request);

  const finalPayload = request.approvalType === 'Approved' ? {
    'Payment Request ID': cleanupRequest.paymentRequestId,
    'ACPL Transaction ID': cleanupRequest.acplTransactionId,
    'ACPL Narration': cleanupRequest.acplNarration,
    'Amount': cleanupRequest.amount
  } : {
    'Payment Request ID': cleanupRequest.paymentRequestId,
    'Cancellation Reason': cleanupRequest.cancellationReason
  };
};

// Get Dealer Config
const getDealerConfig = async () => {
  const options = {
    method: 'GET',
    url: `${URL}/dealer/config`
  };

  const request = axios.request(options);
  const data = await request;
  return data.data;
};

// Tranfer policy united to royal sundaram
const transferPolicyUnitedRs = async (req) => {
  const { uid, chassisNumber, basicOd, nilDep, odDiscountPercent, odNetPremium, tpNetPremium, policyNumber, pdfFileId } = req;

  const options = {
    method: 'POST',
    url: `${baseUrl}/policy-transfer-united-rs`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ uid, chassisNumber, basicOd, nilDep, odDiscountPercent, odNetPremium, tpNetPremium, policyNumber, pdfFileId })
  };

  const request = axios.request(options);
  const response = await request;

  return { status: response.status < 300 ? 'Created' : 'Failed', msg: response.data.msg };

};

// Upload file to drive
const uploadFileToDrive = async (fileData, extension, fileType) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/upload-file-drive`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ fileData, fileType, extension })
  };


  try {
    const request = axios.request(options);
    const response = await request;
    return response.data.fileId;
  } catch (err) {
    const fileId = '';
    return fileId;
  }

};

// Convert file to Base 64
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve((reader.result)?.split(';base64,')[1]);
  reader.onerror = error => reject(error);
});


// Reload
const reloadPage = (time = 5000) => {
  setTimeout(() => {
    window.location.reload();
  }, time);
};

// Get Inspection files
const getInspectionFiles = async (chassisNumber) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/get-inspection-files`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ chassisNumber })
  };


  try {
    const request = axios.request(options);
    const response = await request;
    const responseMessage = response.data.msg;
    const responseData = response.data.inspectionFiles;

    return { msg: responseMessage, inspectionFiles: responseData };

  } catch (err) {
    return { msg: 'Unable to process request', inspectionFiles: null };
  }
};

// Get Dealer Discount
const getDealerInsuranceDiscount = async (dealerCode) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/get-dealer-insurance-discount`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealerCode })
  };


  try {
    const request = axios.request(options);
    const response = await request;
    const responseMessage = response.data.msg;
    const responseData = response.data.insuranceConfigs;

    return { msg: responseMessage, insuranceConfig: responseData };

  } catch (err) {
    const errorMessage = err.response.data.msg;
    return { msg: errorMessage, insuranceConfig: null };
  }
};


// Get Payment Logs
const getPaymentLogs = async (dealerName = null, from = 1) => {
  const options = {
    method: 'GET',
    url: `${baseUrl}/get-payment-logs/${from}/${dealerName}`,
    headers: {
      'content-type': 'application/json'
    },
  };

  try {
    const request = axios.request(options);
    const response = await request;
    const msg = response.data.msg;
    const paymentLogs = response.data.paymentLogs;

    return { msg, paymentLogs };
  } catch (err) {
    const errorMessage = err.response.data.msg;
    return { msg: errorMessage, paymentLogs: null };
  }
};

// Reset User Password
const resetPassword = async (userEmail, userPassword, userNewPassword) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/reset-password`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ userEmail, userPassword, userNewPassword })
  };

  const request = axios.request(options);
  try {
    const response = await request;
    return { msg: response.data.msg, status: response.status };
  } catch (err) {
    const msg = err.response.data.msg;
    return { msg, status: err.response.status };
  }

};


// Create User
const createOpsItUser = async (email, password, repeatPassword, username, department, team) => {

  const checkemail = email.split(`@`)[1];
  if (password !== repeatPassword) {
    return { msg: 'Passwords do not match. Please Check', type: 'Warning' };
  } else if (checkemail !== 'aegiscovenant.com') {
    return { msg: 'Please use your organization email', type: 'Warning' };
  }

  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    withCredentials: true,
    url: `${baseUrl}/create-user`,
    data: JSON.stringify({ email, password, username, department, team })
  };

  try {
    const request = axios.request(options);
    const response = await request;
    const { msg, type } = response.data;

    return { msg, type };
  } catch (err) {
    const msg = err?.response?.data?.msg;
    const type = err?.response?.data?.type;

    return { msg, type }
  }
};

// Submit RM Price list request
const generateRmPriceListRequest = async (data, username, email) => {
  const options = {
    method: 'POST',
    url: `${baseUrl}/rm-price-list`,
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ data, username, email })
  };

  const request = axios.request(options);
  const { status } = await request;
  const { msg } = (await request).data;

  return { msg, status };
};


// Upload Price List Working
const uploadWorking = async (user, pricelistData) => {

  const requestData = { user, pricelistData };

  const options = {
    method: 'POST',
    url: `${baseUrl}/update-price-list`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(requestData)
  }

  try {
    const request = axios.request(options);
    const response = await request;

    return { msg: 'Updated Successfully', status: response.status };
  } catch (err) {
    return { msg: 'Unable to Complete Request', status: 500 };
  }

};

// Revise Price List
const revisePriceListRequest = async (username, requestId, priceListData) => {

  const data = JSON.stringify({
    username,
    requestId,
    priceListData
  });

  const options = {
    method: 'POST',
    url: `${baseUrl}/revise-price-list`,
    headers: {
      'content-type': 'application/json'
    },
    data
  };

  try {
    const request = axios.request(options);
    const response = await request;

    return { msg: 'Updated Successfully', status: response.status };

  } catch (err) {
    return { msg: 'Unable to complete request', status: 500 };
  }

};

const createRecoSheet = (reco, dealerName, start, end, reportType) => {
  const recoSheet = [
    ['', '', ''],
    ['Dealer Name', dealerName],
    ['From', start],
    ['To', end],
    [],
    [],
    ['Month', 'Policies Count', 'Total Invoice Amount', 'Total Dealer\'s Benefit']
  ];

  for (let record of reco) {

    const policiesCount = reportType === 'Insurance' ? record['COUNT(UID)'] : record['COUNT(`Certificate No.`)'];
    const totalInvoiceAmount = reportType === 'Insurance' ? record['SUM(`Gross Balance Deduction`)'] : record['SUM(`Total Invoice Amount`)'];
    const totalPayoutBeforeTDS = reportType === 'Insurance' ? record['SUM(`Total Payout before TDS`)'] : record['SUM(`Total Dealer(s) Benefit`)'];
    const Month = record['month'];

    if (policiesCount) {
      recoSheet.push([Month, policiesCount, totalInvoiceAmount, totalPayoutBeforeTDS]);
    }
  }

  return recoSheet;
};

// Insurance dealer report generator
const dealerReportGenerator = async (dealerName, start, end, reportType) => {
  const options = {
    'method': 'POST',
    url: `${baseUrl}/dealer-report-generator`,
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify({ dealerName, start, end, reportType })
  };

  try {
    const request = axios.request(options);
    const response = await request;

    if (response.data.msg === 'Records Found') {
      const { policies, paymentLogs, reco } = response.data;
      const recoSheet = createRecoSheet(reco, dealerName, start, end, reportType);

      const wb = xlsx.utils.book_new();

      const policiesData = xlsx.utils.aoa_to_sheet(policies);
      const paymentLogsData = xlsx.utils.aoa_to_sheet(paymentLogs);
      const recoData = xlsx.utils.aoa_to_sheet(recoSheet);

      xlsx.utils.book_append_sheet(wb, recoData, `Reco.`);
      xlsx.utils.book_append_sheet(wb, policiesData, `Policies`);
      xlsx.utils.book_append_sheet(wb, paymentLogsData, `Payment Logs`);

      const fileName = `${dealerName}-Policies-Logs.xlsx`;
      xlsx.writeFile(wb, fileName);

      return { msg: 'Sheet Generated Successfully.', status: 'success' };
    } else {
      return { msg: 'No Records were found.', status: 'not found' };
    }

  } catch (err) {
    return { msg: 'Unable to complete your request', status: 'error' };
  }
};

// Search KYC documents
const searchKycDocuments = async (requestId) => {
  const options = {
    method: 'GET',
    url: `${baseUrl}/kyc-documents/${requestId}`,
    headers: {
      'content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const { data, status } = await request;

    const { documents, msg } = data;

    return { documents, msg, status };
  } catch (err) {
    const { msg } = err.response.data;
    const { status } = err.response;
    return { msg, status };
  }
}


export {
  getPortalStatus,
  getInspectionFiles,
  updateManufacturer,
  updateDealerPassword,
  updateDealerPermits,
  updateDealerAddBalancePermission,
  updateDealerPaymentRequest,
  cleanupRequestPayload,
  checkAuthentication,
  getDealerConfig,
  transferPolicyUnitedRs,
  toBase64,
  uploadFileToDrive,
  reloadPage,
  getDealerInsuranceDiscount,
  getPaymentLogs,
  resetPassword,
  createOpsItUser,
  generateRmPriceListRequest,
  uploadWorking,
  revisePriceListRequest,
  dealerReportGenerator,
  searchKycDocuments
} 