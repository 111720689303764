import axios from 'axios';
import { URL } from '../../../../env.js';

const closeTicketEndorsement = async (claimData, name, id) => {
  const {closeTicketComments} = claimData;

  const options = {
    method: 'POST',
    url: `${URL}/ops/endorsement/close-ticket`,
    data: JSON.stringify({closeTicketComments, closedBy: name, id}),
    headers: {
      'content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const {data, status} = (await request);
    const {msg} = data;

    return {
      msg,
      status
    };

  } catch (err) {
    return {
      status: 500,
      msg: 'Unable to Close Ticket'
    }
  }

};

export {
  closeTicketEndorsement
};