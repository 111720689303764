import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealerName: '',
  rto: '',
  pincode: '',
  manufacturerName: '',
  currentDealerPayoutValueBike: '',
  currentDealerPayoutTypeBike: '',
  requiredPayoutBikeValue: '',
  requiredPayoutBikeType: '',
  currentDealerPayoutValueScooter: '',
  currentDealerPayoutTypeScooter: '',
  requiredPayoutScooterValue: '',
  requiredPayoutScooterType: '',
  nilDepPreferences: '',
  currentInsurer: '',
  preferredInsurer: '',
  policyCopyFileId: '',
  secondPolicyCopyFileId: '',
  priceListFileId: '',
  otherRequirements: '',
  bikesCount: 0,
  scootersCount: 0
};

export const priceListSlice = createSlice({
  name: 'pricelist',
  initialState,
  reducers: {
    setExpectedBikes: (state, action) => {
      state.bikesCount = action.payload;
    },
    setExpectedScooter: (state, action) => {
      state.scootersCount = action.payload;
    },
    setDealerName: (state, action) => {
      state.dealerName = action.payload.toUpperCase();
    },
    setRto: (state, action) => {
      state.rto = action.payload.toUpperCase();
    },
    setPincode: (state, action) => {
      state.pincode = action.payload;
    },
    setManufacturerName: (state, action) => {
      state.manufacturerName = action.payload.toUpperCase();
    },
    setCurrentPayoutBikeValue: (state, action) => {
      state.currentDealerPayoutValueBike = action.payload;
    },
    setCurrentPayoutBikeType: (state, action) => {
      state.currentDealerPayoutTypeBike = action.payload;
    },
    setRequiredPayoutBikeValue: (state, action) => {
      state.requiredPayoutBikeValue = action.payload;
    },
    setRequiredPayoutBikeType: (state, action) => {
      state.requiredPayoutBikeType = action.payload;
    },
    setCurrentPayoutScooterValue: (state, action) => {
      state.currentDealerPayoutValueScooter = action.payload;
    },
    setCurrentPayoutScooterType: (state, action) => {
      state.currentDealerPayoutTypeScooter = action.payload;
    },
    setDealerCurrentInsurer: (state, action) => {
      state.currentInsurer = action.payload;
    },
    setRequiredPayoutScooterValue: (state, action) => {
      state.requiredPayoutScooterValue = action.payload;
    },
    setRequiredPayoutScooterType: (state, action) => {
      state.requiredPayoutScooterType = action.payload;
    },
    setNilDepPreferrences: (state, action) => {
      state.nilDepPreferences = action.payload;
    },
    setPreferredInsurer: (state, action) => {
      state.preferredInsurer = action.payload;
    },
    setPolicyCopyId: (state, action) => {
      state.policyCopyFileId = action.payload;
    },
    setSecondPolicyId: (state, action) => {
      state.secondPolicyCopyFileId = action.payload;
    },
    setPriceListId: (state, action) => {
      state.priceListFileId = action.payload;
    },
    setRequirements: (state, action) => {
      state.otherRequirements = action.payload;
    },
    setPriceListData: (state, action) => {
      return action.payload;
    },
    resetPriceListData: () => {
      return initialState;
    }
  }
});

export const { setExpectedBikes, setExpectedScooter, setDealerName, setRto, setPincode, setManufacturerName, setCurrentPayoutBikeValue, setCurrentPayoutBikeType, setRequiredPayoutBikeType, setRequiredPayoutBikeValue, setCurrentPayoutScooterValue, setCurrentPayoutScooterType, setRequiredPayoutScooterType, setRequiredPayoutScooterValue, setNilDepPreferrences, setPolicyCopyId, setPreferredInsurer, setPriceListId, setSecondPolicyId, setPriceListData, resetPriceListData, setDealerCurrentInsurer, setRequirements } = priceListSlice.actions;

export default priceListSlice.reducer;