import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { resetPriceListData, setCurrentPayoutBikeType, setCurrentPayoutBikeValue, setCurrentPayoutScooterType, setCurrentPayoutScooterValue, setDealerCurrentInsurer, setDealerName, setExpectedBikes, setExpectedScooter, setManufacturerName, setNilDepPreferrences, setPincode, setPolicyCopyId, setPriceListId, setRequiredPayoutBikeType, setRequiredPayoutBikeValue, setRequiredPayoutScooterType, setRequiredPayoutScooterValue, setRequirements, setRto, setSecondPolicyId } from './redux.js';
import { checkAuthentication, generateRmPriceListRequest, toBase64, uploadFileToDrive } from '../../../functions';
import { insurers } from '../../../constants.js';
import newPricelistSubmitSchema from './newPricelistSubmitSchema.jsx';
import { InputError, Required } from './components.jsx';
import AuthCheck from '../../../AuthCheck.jsx';


const RmPriceList = () => {

  const dispatch = useDispatch();
  const [msg, setMsg] = useState('null');
  const [requestStatus, setRequestStatus] = useState(null);
  const [payoutTypeError, setPayoutTypeError] = useState({
    currentBike: '',
    requiredBike: '',
    currentScooter: '',
    requiredScooter: ''
  });

  const priceListData = useSelector(({ pricelist }) => {
    return pricelist;
  });

  const loggedInUser = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(newPricelistSubmitSchema) });


  const navigate = useNavigate();
  // Check User authentication
  useEffect(() => {
    dispatch(resetPriceListData());
    const auth = (async () => {
      const pathName = window.location.pathname;
      const auth = await checkAuthentication(dispatch, navigate, pathName);
      if (!auth) navigate('/login');
    })
    auth();
  }, []);


  // File Uploads
  const fileUpload = async (e) => {
    const file = await (e.target.files[0]);
    const extension = file.type.split('/')[1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : file.type.split('/')[1];
    const fileType = file.type.split('/')[0];
    const base64Data = await toBase64(file);
    const fileId = await uploadFileToDrive(base64Data, extension, fileType);

    return fileId;
  };

  // Submit handle
  const onSubmit = async (e) => {
    // Check for Payout Type Selected
    const { currentDealerPayoutValueBike, currentDealerPayoutTypeBike, requiredPayoutBikeValue, requiredPayoutBikeType, currentDealerPayoutValueScooter, currentDealerPayoutTypeScooter, requiredPayoutScooterValue, requiredPayoutScooterType } = priceListData;

    // Percentage (%)
    // Fixed Amount (₹)

    const PERCENTAGE_PAYOUT_ASK_ERROR = 'Payout Ask Cannot be over 60%';
    const FIXED_AMOUNT_PAYOUT_ASK_ERROR = 'Payout Ask Cannot be over ₹3000';

    setPayoutTypeError({
      currentBike: '',
      requiredBike: '',
      currentScooter: '',
      requiredScooter: ''
    });


    // Current BIKE Payout Ask
    if (currentDealerPayoutTypeBike === 'Percentage (%)' && parseFloat(currentDealerPayoutValueBike) > 60) {
      setPayoutTypeError({
        currentBike: PERCENTAGE_PAYOUT_ASK_ERROR,
        currentScooter: '',
        requiredBike: '',
        requiredScooter: ''
      })

      return;
    } else if (currentDealerPayoutTypeBike === 'Fixed Amount (₹)' && parseFloat(currentDealerPayoutValueBike) > 3000) {
      setPayoutTypeError({
        currentBike: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        currentScooter: '',
        requiredBike: '',
        requiredScooter: ''
      })

      return;
    }

    // Current SCOOTER Payout Ask
    if (currentDealerPayoutTypeScooter === 'Percentage (%)' && parseFloat(currentDealerPayoutValueScooter) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: PERCENTAGE_PAYOUT_ASK_ERROR,
        requiredBike: '',
        requiredScooter: ''
      })
      return;
    } else if (currentDealerPayoutTypeScooter === 'Fixed Amount (₹)' && parseFloat(currentDealerPayoutValueScooter) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        requiredBike: '',
        requiredScooter: ''

      })
      return;
    }

    // Required BIKE payout ask
    if (requiredPayoutBikeType === 'Percentage (%)' && parseFloat(requiredPayoutBikeValue) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: PERCENTAGE_PAYOUT_ASK_ERROR,
        requiredScooter: ''
      });
      return;
    } else if (requiredPayoutBikeType === 'Fixed Amount (₹)' && parseFloat(requiredPayoutBikeValue) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: FIXED_AMOUNT_PAYOUT_ASK_ERROR,
        requiredScooter: ''
      });
      return;
    }

    // Required SCOOTER payout ask
    if (requiredPayoutScooterType === 'Percentage (%)' && parseFloat(requiredPayoutScooterValue) > 60) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: '',
        requiredScooter: PERCENTAGE_PAYOUT_ASK_ERROR
      })
      return;
    } else if (requiredPayoutScooterType === 'Fixed Amount (₹)' && parseFloat(requiredPayoutScooterValue) > 3000) {
      setPayoutTypeError({
        currentBike: '',
        currentScooter: '',
        requiredBike: '',
        requiredScooter: FIXED_AMOUNT_PAYOUT_ASK_ERROR
      })
      return;
    }

    const { name, email } = loggedInUser;
    const { msg, status } = await generateRmPriceListRequest(priceListData, name, email);

    if (status === 200) {
      setTimeout(() => {
        navigate('/dealer/pricelist')
      }, 5000);
    };

    setMsg(msg);
    setRequestStatus(status);
  };

  const { pathname } = useLocation();

  return (
    <div className='mx-auto w-fit'>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />

      {/* heading */}
      <h1 h1 className="mb-4 mt-10 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">RM Price List</h1>

      {/* Form */}

      {msg && requestStatus !== 200 &&
        <div>
          <form noValidate className='grid grid-cols-2 gap-2 m-5' onSubmit={handleSubmit(onSubmit)}>
            {/* Dealer Name */}
            <div className="mb-5">
              <label htmlFor="dealerName" className="block mb-2 text-sm font-bold text-gray-900">Dealer Name <Required /> </label>
              <input {...register('dealerName')} onChange={(e) => {
                dispatch(setDealerName(e.target.value));
              }} type="text" id="dealerName" className="shadow-sm  border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 dark:shadow-sm-light" placeholder="Gaurav BMW Company" required />
              {errors.dealerName && <InputError error={errors.dealerName} />}
            </div>

            {/* Dealer RTO */}
            <div className="mb-5">
              <label htmlFor="rto" className="block mb-2 text-sm font-bold text-gray-900">RTO <Required /></label>
              <input {...register('rto')} onChange={(e) => {
                dispatch(setRto(e.target.value));
              }} type="text" id="rto" className="shadow-sm border text-sm rounded-lg  block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 dark:shadow-sm-light" placeholder="HR13" required />
              {errors.rto && <InputError error={errors.rto} />}
            </div>

            {/* Dealer Pincode */}
            <div className="mb-5">
              <label htmlFor="pincode" className="block mb-2 text-sm font-bold text-gray-900">Pincode <Required /></label>
              <input {...register('pincode')} onChange={(e) => {
                dispatch(setPincode(e.target.value));
              }} type="text" id="pincode" className="shadow-sm  border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 dshadow-sm-light" placeholder="124507" required />
              {errors.pincode && <InputError error={errors.pincode} />}
            </div>

            {/* Manufacturer */}
            <div className="mb-5">
              <label htmlFor="manufacturer" className="block mb-2 text-sm font-bold text-gray-900">Manufacturer Name <Required /></label>
              <input {...register('manufacturer')} onChange={(e) => {
                dispatch(setManufacturerName(e.target.value));
              }} type="text" id="manufacturer" className="shadow-sm border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 shadow-sm-light" placeholder="HERO MOTOCORP LIMITED" required />
              {errors.manufacturer && <InputError error={errors.manufacturer} />}
            </div>


            {/* Current Dealer Payout BIKE  */}
            <div className="mb-5">
              <label htmlFor="currentPayoutValueBike" className="block mb-2 text-sm font-bold text-gray-900">Current Dealer Payout BIKE <Required /></label>
              <input {...register('currentDealerPayoutBike')} onChange={(e) => {
                dispatch(setCurrentPayoutBikeValue(e.target.value));
              }} type="text" id="currentPayoutValueBike" className="shadow-sm border text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 dark:focus:border-blue-500 shadow-sm-light" placeholder="30" required />

              <select {...register('currentDealerPayoutBikeType')} onChange={(e) => {
                dispatch(setCurrentPayoutBikeType(e.target.value));
              }} id="currentPayoutType" className="mt-2 border text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.currentDealerPayoutBike && <InputError error={errors.currentDealerPayoutBike} />}
              {errors.currentDealerPayoutBikeType && <InputError error={errors.currentDealerPayoutBikeType} />}
              {payoutTypeError.currentBike && <InputError error={payoutTypeError.currentBike} />}
            </div>

            {/* Required Payout BIKE */}
            <div className="mb-5">
              <label htmlFor="requiredpayoutBike" className="block mb-2 text-sm font-bold text-gray-900">Required Payout BIKE <Required /></label>
              <input {...register('requiredPayoutBike')} onChange={(e) => {
                dispatch(setRequiredPayoutBikeValue(e.target.value));
              }} type="text" id="requiredpayoutBike" className="shadow-sm border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 shadow-sm-light" placeholder="30" required />

              <select {...register('requiredDealerPayoutBikeType')} onChange={(e) => {
                dispatch(setRequiredPayoutBikeType(e.target.value));
              }} id="payoutTypeBike" className="mt-2  border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.requiredPayoutBike && <InputError error={errors.requiredPayoutBike} />}
              {errors.requiredDealerPayoutBikeType && <InputError error={errors.requiredDealerPayoutBikeType} />}
              {payoutTypeError.requiredBike && <InputError error={payoutTypeError.requiredBike} />}
            </div>

            {/* Current Dealer Payout SCOOTER */}
            <div className="mb-5">
              <label htmlFor="currentPayoutBike" className="block mb-2 text-sm font-bold text-gray-900">Current Dealer Payout SCOOTER <Required /></label>
              <input {...register('currentDealerPayoutScooter')} onChange={(e) => {
                dispatch(setCurrentPayoutScooterValue(e.target.value));
              }} type="text" id="currentPayoutBike" className="shadow-sm border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 shadow-sm-light" placeholder="30" required />

              <select {...register('currentDealerPayoutScooterType')} onChange={(e) => {
                dispatch(setCurrentPayoutScooterType(e.target.value));
              }} id="currentPayoutTypeScooter" className="mt-2 border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.currentDealerPayoutScooter && <InputError error={errors.currentDealerPayoutScooter} />}
              {errors.currentDealerPayoutScooterType && <InputError error={errors.currentDealerPayoutScooterType} />}
              {payoutTypeError.currentScooter && <InputError error={payoutTypeError.currentScooter} />}
            </div>

            {/* Required Payout SCOOTER */}
            <div className="mb-5">
              <label htmlFor="requiredpayoutScooter" className="block mb-2 text-sm font-bold text-gray-900">Required Payout SCOOTER <Required /></label>
              <input {...register('requiredPayoutScooter')} onChange={(e) => {
                dispatch(setRequiredPayoutScooterValue(e.target.value));
              }} type="text" id="requiredpayoutScooter" className="shadow-sm border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-700 text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="30" required />

              <select {...register('requiredDealerPayoutScooterType')} onChange={(e) => {
                dispatch(setRequiredPayoutScooterType(e.target.value));
              }} id="payoutTypeScooter" className="mt-2 border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Payout Type -- </option>
                <option>Percentage (%)</option>
                <option>Fixed Amount (₹)</option>
              </select>
              {errors.requiredPayoutScooter && <InputError error={errors.requiredPayoutScooter} />}
              {errors.requiredDealerPayoutScooterType && <InputError error={errors.requiredDealerPayoutScooterType} />}
              {payoutTypeError.requiredScooter && <InputError error={payoutTypeError.requiredScooter} />}
            </div>


            {/* Dealer Current Insurer  */}
            <div>
              <label htmlFor="currentInsurer" className="block mb-2 text-sm font-bold text-gray-900 dark:text-black">Current Dealer Insurer <Required /></label>
              <select {...register('currentDealerInsurer')} onChange={(e) => {
                dispatch(setDealerCurrentInsurer(e.target.value));
              }} id="currentInsurer" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Insurer Name -- </option>
                {
                  insurers.map((insurer) => {
                    return <option key={insurer}>{insurer}</option>
                  })
                }
              </select>
              {errors.currentDealerInsurer && <InputError error={errors.currentDealerInsurer} />}
            </div>


            {/* Nil Dep Preferrences */}
            <div className="mb-5">
              <label htmlFor="nilDepChoice" className="block mb-2 text-sm font-bold text-gray-900">Nil Dep Preferrences <Required /></label>
              <select {...register('nilDepPreferences')} onChange={(e) => {
                dispatch(setNilDepPreferrences(e.target.value));
              }} id="nilDepChoice" className="mt-2 border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option value=""> -- Choose Nil Dep Preference -- </option>
                <option>With Nil Dep</option>
                <option>Without Nil Dep</option>
              </select>
              {errors.nilDepPreferences && <InputError error={errors.nilDepPreferences} />}
            </div>


            {/* Expected BIKE Count */}
            <div className="mb-5">
              <label htmlFor="bikeCount" className="block mb-2 text-sm font-bold text-gray-900">Expected BIKE Count <Required /></label>
              <input {...register('expectedBikes')} onChange={(e) => {
                dispatch(setExpectedBikes(e.target.value));
              }} type="text" id="bikeCount" className="shadow-sm bg-gray-700 border text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500 shadow-sm-light" placeholder="200" required />
              {errors.expectedBikes && <InputError error={errors.expectedBikes} />}
            </div>

            {/* Expected SCOOTER Count */}
            <div className="mb-5">
              <label htmlFor="scooterCount" className="block mb-2 text-sm font-bold text-gray-900">Expected SCOOTER Count <Required /></label>
              <input {...register('expectedScooter')} onChange={(e) => {
                dispatch(setExpectedScooter(e.target.value));
              }} type="text" id="scooterCount" className="shadow-sm border text-sm rounded-lg block w-full p-2.5 bg-gray-700 text-white border-gray-600 dark:placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 dark:shadow-sm-light" placeholder="200" required />
              {errors.expectedScooter && <InputError error={errors.expectedScooter} />}
            </div>

            {/* Preferred Insurer */}
            {/* <div>
              <label htmlFor="preferredInsurer" className="block mb-2 text-sm font-bold text-gray-900 dark:text-black">Preferred Insurer <Required /></label>
              <select {...register('preferredInsurer')} onChange={(e) => {
                dispatch(setPreferredInsurer(e.target.value));
              }} id="preferredInsurer" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""> -- Choose Insurer Name -- </option>
                <option>United India Insurance Company Limited</option>
                <option>Shriram General Insurance Company</option>
                <option>Bajaj Allianz General Insurance Company Limited</option>
                <option>ICICI Lombard General Insurance Company Limited</option>
                <option>MAGMA HDI GENERAL INSURANCE COMPANY LIMITED</option>
              </select>
              {errors.preferredInsurer && <InputError error={errors.preferredInsurer} />}
            </div> */}


            {/* Policy Copy Upload */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="user_avatar">Upload Policy Copy file <Required /></label>
              {
                !priceListData.policyCopyFileId &&
                <input onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setPolicyCopyId(fileId));
                  setValue('policyCopyId', fileId);
                }} disabled={priceListData.policyCopyFileId} className="block p-2 w-full text-sm border rounded-lg cursor-pointer text-gray-400 focus:outline-none bg-gray-700 border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }
              {/* Success Message */}
              {
                priceListData.policyCopyFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-2 text-green-800 border border-green-300 rounded-lg bg-gray-50 dark:text-green-400 dark:border-green-800" role="alert">
                  <button type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setPolicyCopyId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.policyCopyId && <InputError error={errors.policyCopyId} />}

            </div>

            {/* Policy Second Copy Upload */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="user_avatar">Upload Second Policy Copy file <Required /></label>
              {
                !priceListData.secondPolicyCopyFileId &&
                <input onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setSecondPolicyId(fileId));
                  setValue('secondPolicyCopyId', fileId)
                }} disabled={priceListData.secondPolicyCopyFileId} className="block p-2 w-full text-sm border rounded-lg cursor-pointer bg-gray-700 text-gray-400 focus:outline-none :bg-gray-700 border-gray-600 placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }


              {/* Success Message */}
              {
                priceListData.secondPolicyCopyFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                  <button type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setSecondPolicyId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.secondPolicyCopyId && <InputError error={errors.secondPolicyCopyId} />}


            </div>

            {/* Price List */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="user_avatar">Upload Price List file <Required /></label>
              {
                !priceListData.priceListFileId && <input onChange={async (e) => {
                  const fileId = await fileUpload(e);
                  dispatch(setPriceListId(fileId));
                  setValue('priceListId', fileId)
                }} disabled={priceListData.priceListFileId} className="block p-2 w-full text-sm  border rounded-lg cursor-pointer text-gray-400 focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
              }


              {/* Success Message */}
              {
                priceListData.priceListFileId &&
                <div id="alert-additional-content-3" className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                  <button type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                      <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    File Uploaded Successfully
                  </button>
                  <button onClick={() => {
                    dispatch(setPriceListId(null));
                  }} type="button" className="text-green-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                    New File?
                  </button>
                </div>
              }
              {errors.priceListId && <InputError error={errors.priceListId} />}
            </div>

            {/* Description */}
            <div>
              <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-black" htmlFor="pricelist-desc">Other Requirements / Description</label>
              <textarea onChange={(e) => {
                dispatch(setRequirements(e.target.value));
              }} id='pricelist-desc' className='p-2 bg-gray-700 text-white w-full outline rounded-md resize-none' cols="20" rows="5"></textarea>
            </div>

            {/* Submit Button */}
            <br />
            <button type="submit" className="text-white my-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit Price List</button>

          </form>
        </div>
      }

      {/* Alert Message */}
      {
        msg && requestStatus === 200 &&
        <div id="alert-border-1" className="flex items-center w-fit mx-auto p-4 mt-4 rounded-lg mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
          <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <div className="ms-3 mr-5 text-sm font-medium">
            {msg}
          </div>
        </div>
      }
    </div >
  );
};

export default RmPriceList
