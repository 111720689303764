import React, { useEffect, useLayoutEffect, useState } from 'react';
import Heading from '../../../../Shared/Heading.jsx';
import { dealerOnboardRequestHeaders } from './constant.js';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from '../../../../Shared/LinkButton.jsx';
import dayjs from 'dayjs';
import { approvalDealerCreationRequest, getDealerCreationRequests, updatePagnitor } from './functions.js';
import "../../../../App.css";
import { useLocation, useNavigate } from 'react-router';
import AuthCheck from '../../../../AuthCheck.jsx';

const DealerCreationRequests = () => {

  const dispatch = useDispatch();
  const [modalData, setModalData] = useState();
  const [paginator, setPaginator] = useState(10);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    (async () => {
      await getDealerCreationRequests(dispatch);
    })();
  }, []);

  useEffect(() => {
    const POLLING_INTERVAL = 5000;
    const intervalId = setInterval(async () => {
      await getDealerCreationRequests(dispatch);
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);


  const records = useSelector(({ BusinessSupportTeam }) => {
    return BusinessSupportTeam.requests;
  });


  const navigate = useNavigate();

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title={'Dealer Onboarding Requests'} />
      {
        records &&
        <>
          <div className="relative m-10 overflow-x-auto shadow-md sm:rounded-lg">
            <table className={`${modalData ? 'opacity-40' : ''} text-center mx-auto w-full text-sm rtl:text-right text-gray-400`}>
              {/* Table Heading */}
              <thead className="text-xs uppercase  bg-gray-700 text-gray-400">
                <tr>
                  {dealerOnboardRequestHeaders.map((header, index) => (
                    <th key={index} scope="col" className="px-4 py-3">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                {
                  records && records.map((record, index) => {
                    if (index >= paginator - 10 && index < paginator) {
                      return (
                        <tr className={`text-center odd:bg-gray-900  even:bg-gray-800 border-b border-gray-700`}>
                          <td style={{ color: record.status === 'pending' || record.status === 'processing' ? 'yellow' : record.status === 'onboarded' ? 'lightgreen' : 'red' }} className={`px-6 py-4 text-${record.status === 'pending' ? 'yellow' : 'green'}-500 font-bold`}>{record.id}</td>
                          <td className="px-6 py-4">{record.dealership_name}</td>
                          <td className="px-6 py-4">{record.sales_manager}</td>
                          <td className="px-6 py-4">{record.dealership_type}</td>

                          <td>
                            <button onClick={() => {
                              const modalInfo = records.filter((item) => {
                                return item.id === record.id;
                              });

                              setModalData(modalInfo);
                            }} type="button" className={`button text-xs`}>
                              <i className="fa-solid fa-eye mr-1"></i>  View Details
                            </button>
                          </td>

                          <td className="px-6 py-4">
                            {
                              record.status === 'pending' ?
                                <div>
                                  {/* Approve Button */}
                                  <button onClick={async () => {
                                    const approved = await approvalDealerCreationRequest(record.id, 'approved', dispatch, record);
                                  }} type="button" className={`mr-1 button text-xs outline-none`}>
                                    <i className="fa-solid fa-check-to-slot mr-1 text-green-400"></i>  Approve
                                  </button>

                                  {/* Reject Button */}
                                  <button onClick={async () => {
                                    navigate(`/dealer/onboarding/reject/${record.id}`)
                                  }} type="button" className={`button text-xs`}>
                                    <i className="fa-solid fa-rectangle-xmark text-red-400 mr-1"></i>  Reject
                                  </button>
                                </div> : record.status === 'processing' ? <button type="button" className={`mr-1 button text-xs outline-none`}>
                                  <i className="fa-solid fa-bars-progress text-yellow-500"></i>  Processing
                                </button> : record.status === 'onboarded' ? <button type="button" className={`mr-1 button text-xs outline-none`}>
                                  <i className="fa-solid fa-bars-progress text-red-500 mr-1"></i>  {record.status.toUpperCase()}
                                </button> : <button type="button" className={`mr-1 button text-xs outline-none`}>
                                  <i className="fa-solid fa-x font-extrabold text-red-500 mr-1"></i>  {record.status.toUpperCase()}
                                </button>
                            }
                          </td>

                          <td style={{ color: record.status === 'pending' || record.status === 'processing' ? 'yellow' : record.status === 'onboarded' ? 'lightgreen' : 'red' }} className={`px-6 py-4 font-semibold`}>{record.status.toUpperCase()}</td>

                          <td className="px-6 py-4">{dayjs(record.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                          <td className="px-6 py-4">{record.updated_at ? dayjs(record.updated_at, { utc: true }).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                        </tr>
                      )
                    }
                  })
                }
              </tbody>
            </table>

            {/* Modal */}
            {
              modalData &&
              <div id="large-modal" tabindex="-1" className="fixed z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative w-full max-w-7xl max-h-full">
                  <div className="relative rounded-lg shadow bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                      <h3 className="text-xl font-medium text-white">
                        {modalData[0].dealership_name} ( {modalData[0].id} )
                      </h3>
                      <button onClick={() => {
                        setModalData();
                      }} type="button" className="text-red-500 font-bold bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-red-500" data-modal-hide="large-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="p-4 md:p-5 grid grid-cols-2 gap-y-5">
                      {/* Basic Details */}
                      <div className="text-base leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Basic Dealership Details</h1>
                        <div>
                          <li><span className="text-gray-100">Dealership Name</span> : <span>{modalData[0].dealership_name}</span> </li>
                          <li><span className="text-gray-100">Dealership Company</span> : <span>{modalData[0].dealership_company}</span> </li>
                          <li><span className="text-gray-100">Dealership Mobile</span> : <span>{modalData[0].dealership_mobile}</span> </li>
                          <li><span className="text-gray-100">Dealership Email</span> : <span>{modalData[0].dealership_email}</span> </li>
                          <li><span className="text-gray-100">Dealership State</span> : <span>{modalData[0].state}</span> </li>
                          <li><span className="text-gray-100">Dealership City</span> : <span>{modalData[0].city}</span> </li>
                          <li><span className="text-gray-100">Dealership Address</span> : <span>{modalData[0].address}</span> </li>
                          <li><span className="text-gray-100">Dealership Address</span> : <span>{modalData[0].rto}</span> </li>
                          <li><span className="text-gray-100">Dealership Type</span> : <span>{modalData[0].dealership_type}</span> </li>
                          <li>
                            <span className="text-gray-100">Dealer Working File : </span>
                            <LinkButton
                              link={`https://drive.google.com/file/d/${modalData[0].dealer_working}/view`}
                              title={<><i class="fa-solid fa-file mr-1"></i> <span>Working File</span></>}
                              type="file"
                            />
                          </li>
                        </div>
                      </div>

                      {/* Payment Details */}
                      <div className="text-base leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Dealership Details</h1>
                        <div>
                          <li><span className="text-gray-100">Payment Terms</span> : <span>{modalData[0].payment_terms}</span> </li>
                          <li><span className="text-gray-100">Payment By</span> : <span>{modalData[0].payment_by}</span> </li>
                          <li><span className="text-gray-100">Payment Terms to be Used</span> : <span>{modalData[0].payment_terms_to_be_used}</span> </li>
                        </div>
                      </div>

                      {/* Owner Details */}
                      <div className="text-base  leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Dealership Owner Details</h1>
                        <div>
                          <li><span className="text-gray-100">Owner Name</span> : <span>{modalData[0].owner_name}</span> </li>
                          <li><span className="text-gray-100">Owner Mobile</span> : <span>{modalData[0].owner_mobile}</span> </li>
                          <li><span className="text-gray-100">Owner Email</span> : <span>{modalData[0].owner_email}</span> </li>
                        </div>
                      </div>

                      {/* GM Details */}
                      <div className="text-base  leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>General Manager Details</h1>
                        <div>
                          <li><span className="text-gray-100">General Manager Name</span> : <span>{modalData[0].gm_name}</span> </li>
                          <li><span className="text-gray-100">General Manager Mobile</span> : <span>{modalData[0].gm_mobile}</span> </li>
                          <li><span className="text-gray-100">General Manager Email</span> : <span>{modalData[0].gm_email}</span> </li>
                        </div>
                      </div>

                      <div className="text-base  leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Executive Details</h1>
                        <div>
                          <li><span className="text-gray-100">Executive Name</span> : <span>{modalData[0].executive_name}</span> </li>
                          <li><span className="text-gray-100">Executive Mobile</span> : <span>{modalData[0].executive_mobile}</span> </li>
                          <li><span className="text-gray-100">Executive Email</span> : <span>{modalData[0].executive_email}</span> </li>
                        </div>
                      </div>

                      <div className="text-base  leading-relaxed text-gray-400">
                        <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Accounts Details</h1>
                        <div>
                          <li><span className="text-gray-100">Accountant Name</span> : <span>{modalData[0].accounts_name}</span> </li>
                          <li><span className="text-gray-100">Accountant Mobile</span> : <span>{modalData[0].accounts_mobile}</span> </li>
                          <li><span className="text-gray-100">Accountant Email</span> : <span>{modalData[0].accounts_email}</span> </li>
                        </div>
                      </div>

                      {modalData[0].firm_accounts_holder_name &&
                        <div className="text-base  leading-relaxed text-gray-400">
                          <h1 className='text-[30px] underline text-white font-extrabold mb-2'>Dealership Bank Details</h1>
                          <div>
                            <li><span className="text-gray-100">Bank Account Holder Name</span> : <span>{modalData[0].firm_accounts_holder_name}</span> </li>
                            <li><span className="text-gray-100">Firm GSTIN </span> : <span>{modalData[0].firm_gstin}</span> </li>
                            <li><span className="text-gray-100">Firm PAN</span> : <span>{modalData[0].firm_pan}</span> </li>
                            <li><span className="text-gray-100">Firm Bank Account Number</span> : <span>{modalData[0].firm_bank_account_number}</span> </li>
                            <li><span className="text-gray-100">Firm Bank Name</span> : <span>{modalData[0].firm_bank_name}</span> </li>
                            <li><span className="text-gray-100">Firm IFSC Code</span> : <span>{modalData[0].firm_ifsc_code}</span> </li>
                            <div className="mt-3">
                              <LinkButton
                                link={`https://drive.google.com/file/d/${modalData[0].firm_pan_file}/view`}
                                title={<><i className="fa-solid fa-file mr-1"></i> <span>FIRM PAN</span></>}
                                type="file"
                              />
                              <LinkButton
                                link={`https://drive.google.com/file/d/${modalData[0].firm_gstin_file}/view`}
                                title={<><i className="fa-solid fa-file mr-1"></i> <span>FIRM GSTIN</span></>}
                                type="file"
                              />
                              <LinkButton
                                link={`https://drive.google.com/file/d/${modalData[0].firm_cancelled_cheque_file}/view`}
                                title={<><i className="fa-solid fa-file mr-1"></i> <span>FIRM Cancelled Cheque</span></>}
                                type="file"
                              />
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            }

          </div>

          <div className="pagination mx-auto text-center">
            <button disabled={paginator === 10} onClick={() => {
              updatePagnitor('prev', setPaginator);
            }} className='text-sm px-10 py-1 mx-5 outline-8 rounded-md border shadow-xl active:scale-75 duration-300 disabled:text-gray-300'> <i class="fa-solid fa-angle-left"></i> Previous</button>
            <button disabled={paginator > records?.length} onClick={() => {
              updatePagnitor('next', setPaginator);
            }} className='text-sm px-10 py-1 mx-5 outline-8 rounded-md border shadow-xl active:scale-75 duration-300 disabled:text-gray-200'>Next <i class="fa-solid fa-angle-right"></i></button>
          </div>
        </>
      }
    </div>
  )
}

export default DealerCreationRequests;