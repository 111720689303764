import { createSlice } from '@reduxjs/toolkit';
import { getLabelsAndPoliciesCount } from '../../RM Stats/functions.js';

const initialState = {
  salesManagers: [],
  fetchingData: false,
  showDetails: false,
  managerStats: {
    totalPolicies: '',
    currentMonthCount: '',
    monthly: {
      labels: [],
      count: []
    },
    dealers: {
      labels: [],
      count: []
    },
    bodyType: {
      labels: [],
      count: []
    },
    insuranceType: {
      labels: [],
      count: []
    }
  }
};

export const RmStatsBST = createSlice({
  name: 'RmStatsBST',
  initialState,
  reducers: {
    setSalesManagers: (state, action) => {
      state.salesManagers = action.payload;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    setShowDetails: (state, action) => {
      state.showDetails = action.payload;
    },
    setManagerTotalPolicies: (state, action) => {
      state.managerStats.totalPolicies = action.payload;
    },
    setManagerCurrentMonthPolicies : (state, action) => {
      state.managerStats.currentMonthCount = action.payload;
    },
    setManagerMonthlyDetails: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Month', 'COUNT(UID)');
      state.managerStats.monthly.labels = labels;
      state.managerStats.monthly.count = policiesCount;
    },
    setManagerDealersDetails: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Dealer Name', 'COUNT(UID)');
      state.managerStats.dealers.labels = labels;
      state.managerStats.dealers.count = policiesCount;
    },
    setManagerBodyTypeDetails: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Body Type', 'COUNT(UID)');
      state.managerStats.bodyType.labels = labels;
      state.managerStats.bodyType.count = policiesCount;
    },
    setManagerInsuranceTypeDetails: (state, action) => {
      const { labels, policiesCount } = getLabelsAndPoliciesCount(action.payload, 'Insurance Type', 'COUNT(UID)');
      state.managerStats.insuranceType.labels = labels;
      state.managerStats.insuranceType.count = policiesCount;
    }
  }
});

export const { setSalesManagers, setFetchingData, setShowDetails, setManagerTotalPolicies, setManagerCurrentMonthPolicies, setManagerMonthlyDetails, setManagerDealersDetails, setManagerBodyTypeDetails, setManagerInsuranceTypeDetails } = RmStatsBST.actions;

export default RmStatsBST.reducer;