import React, { useLayoutEffect } from 'react';
import Heading from '../../../Shared/Heading.jsx';
import { ClaimCard, ClaimStatus, DownloadClaimsData, RegisterNewClaimButton } from './components.jsx';
import { fetchClaimRecords } from './functions.js';
import { useDispatch } from 'react-redux';
import { setClaimRecords } from '../redux.js';

const ViewClaimRecords = () => {

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    (async () => {
      const records = await fetchClaimRecords();
      dispatch(setClaimRecords(records));
    })();
  }, [dispatch]);

  return (
    <div className='p-2'>
      <Heading title={'Claims'} />
      <div className='flex flex-row justify-between mt-10'>
        <ClaimStatus />
        <div className='flex flex-row justify-around'>
        <RegisterNewClaimButton />
        <DownloadClaimsData />
        </div>
      </div>
      <ClaimCard />
    </div>
  )
}

export default ViewClaimRecords;
