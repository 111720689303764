import axios from 'axios';
import { URL } from '../../../../env.js';
import { toBase64 } from '../../../../functions.js';

const closeCancellationTicket = async (formData, name, id, _files) => {
  const files = [];
  if (formData.cancellationReceipt === 'false') {
    for (let file of _files) {
      files.push({
        file: file,
        type: file.type,
        buffer: await toBase64(file),
      });
    }
  }

  const options = {
    method: 'POST',
    url: `${URL}/ops/cancellation/close-ticket`,
    data: JSON.stringify({ ...formData, files, closedBy: name, id }),
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const request = axios.request(options);
    const { data, status } = await request;
    const { msg } = data;

    return {
      msg,
      status,
    };
  } catch (err) {
    return {
      status: 500,
      msg: 'Unable to Close Ticket',
    };
  }
};

export { closeCancellationTicket };
