import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  records: [],
  filteredRecords: [],
  filterValue: '',
  filterInput: '',
  filterActive: false,
  register: {
    policyNumber: '',
    dealerName: '',
    insuranceCompanyName: '',
    customerName: '',
    particular: '',
    endorsementReason: '',
    additionalInfo: ''
  },
  edit: {
    referenceNo: '',
    policyNumber: '',
    dealerName: '',
    insuranceCompanyName: '',
    customerName: '',
    particular: '',
    endorsementReason: '',
    additionalInfo: ''
  },
  count: {
    pending: 0,
    closed: 0
  }
};

export const Endorsements = createSlice({
  name: 'Endorsements',
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPolicyNumber: (state, action) => {
      action.payload.type === 'edit' ? state.edit.policyNumber = action.payload.data : state.register.policyNumber = action.payload.data;
    },
    setDealerName: (state, action) => {
      action.payload.type === 'edit' ? state.edit.dealerName = action.payload.data : state.register.dealerName = action.payload.data;
    },
    setInsuranceCompanyName: (state, action) => {
      action.payload.type === 'edit' ? state.edit.insuranceCompanyName = action.payload.data : state.register.insuranceCompanyName = action.payload.data;
    },
    setCustomerName: (state, action) => {
      action.payload.type === 'edit' ? state.edit.customerName = action.payload.data : state.register.customerName = action.payload.data;
    },
    setParticulars: (state, action) => {
      action.payload.type === 'edit' ? state.edit.particular = action.payload.data : state.register.particular = action.payload.data;
    },
    setEndorsementReason: (state, action) => {
      action.payload.type === 'edit' ? state.edit.endorsementReason = action.payload.data : state.register.endorsementReason = action.payload.data;
    },
    setAdditionalInfo: (state, action) => {
      action.payload.type === 'edit' ? state.edit.additionalInfo = action.payload.data : state.register.additionalInfo = action.payload.data;
    },
    setReferenceNo: (state, action) => {
      state.edit.referenceNo = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setFilteredRecords: (state, action) => {
      state.filteredRecords = action.payload;
    },
    setFilterActive: (state, action) => {
      state.filterActive = action.payload;
    },
    setFilterValue : (state, action) => {
      state.filterValue = action.payload;
    },
    setFilterInput: (state, action) => {
      state.filterInput = action.payload;
    }
  }
});

export const { setCount, setPolicyNumber, setFilterInput, setFilterValue, setFilterActive, setAdditionalInfo, setFilteredRecords, setCustomerName, setDealerName, setEndorsementReason, setInsuranceCompanyName, setParticulars, setRecords } = Endorsements.actions;

export default Endorsements.reducer;