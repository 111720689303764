import axios from 'axios';
import { setFilteredRecords } from '../redux.js';
import { initialFilters } from './constants.js';
import { URL } from '../../../../env.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween.js';

const showRemoveFilter = (filters) => {
  return JSON.stringify(filters) !== JSON.stringify(initialFilters);
};

const removeFilters = (setter, setSelectedFilter) => {
  setSelectedFilter('');
  setter(initialFilters);
};

const filterRecords = (records, filters, dispatch) => {
  dayjs.extend(isBetween);

  const filteredRecords = records.filter((record) => {
    let res = true;
    for (let key of Object.keys(filters)) {
      if (filters[key] !== 'All') {
        if (
          key === 'status' &&
          ![filters[key].toLowerCase(), 'initiated'].includes(record?.['status'].toString().toLowerCase())
        ) {
          res = false;
        } else if (
          key === 'cancellationType' &&
          record?.['cancellation_type'].toString().toLowerCase() !== filters[key].toLowerCase()
        ) {
          res = false;
        } else if (
          key === 'to' &&
          filters['from'] &&
          filters['to'] &&
          !dayjs(record.created_at, 'YYYY-MM-DD').isBetween(filters['from'], filters['to'], 'day', '[]')
        ) {
          res = false;
        } else if (key === 'late' && filters[key]) {
          const difference = dayjs().diff(dayjs(record.created_at), 'days');
          if (record.status !== 'pending') res = false;
          if (difference <= 7 && record.status === 'pending') res = false;
        } else if (key === 'Raised By' && filters[key].length > 0) {
          let include = false;
          for (let member of filters[key]) {
            if (record['ops_member'].toLowerCase().trim().includes(member.toLowerCase().trim()))
              include = true;
          }

          res = include;
        } else if (key === 'Dealer Name' && filters[key].length > 0) {
          let include = false;
          for (let dealer of filters[key]) {
            if (record['dealer_name'].toLowerCase().trim().includes(dealer.toLowerCase().trim()))
              include = true;
          }

          res = include;
          // } else if (key === 'Policy Number' && filters[key].length > 0) {
          //   let include = false;
          //   for(let policyNumber of filters[key]) {
          //     if(record['policy_number'].toLowerCase().trim().includes(policyNumber.toLowerCase().trim())) include = true;
          //   }

          //   res = include;
        } else if (key === 'Insurance Company Name' && filters[key].length > 0) {
          let include = false;
          for (let insurer of filters[key]) {
            if (record['insurance_company'].toLowerCase().trim().includes(insurer.toLowerCase().trim()))
              include = true;
          }

          res = include;
          // } else if (key === 'Customer Name' && filters[key].length > 0) {
          //   let include = false;
          //   for(let name of filters[key]) {
          //     if(record['customer_name'].toLowerCase().trim().includes(name.toLowerCase().trim())) include = true;
          //   }

          //   res = include;
        } else if (key === 'Cancellation Reason' && filters[key].length > 0) {
          let include = false;
          for (let reason of filters[key]) {
            if (record['cancellation_reason'].toLowerCase().trim().includes(reason.toLowerCase().trim()))
              include = true;
          }

          res = include;
        }
      }
    }
    return res;
  });

  dispatch(setFilteredRecords(filteredRecords));
};

const fetchRecords = async (_dept, name, team) => {
  let url = `${URL}/ops/cancellation/records`;

  if (team === 'team south') {
    url += `?team=south`;
  } else if (team === 'team north') {
    url += `?team=north`;
  } else if (team === 'team west') {
    url += '?team=west';
  } else if (team === 'team east') {
    url += '?team=east';
  }

  return (await axios.get(url))?.data?.records.filter((record) => {
    if (record.status === 'initiated' && !['ops', 'admin'].includes(_dept)) return false;
    if (
      ['business support', 'accounts'].includes(_dept) &&
      record.status.toLowerCase() === 'open for reconciliation'
    ) {
      return true;
    }
    if (_dept === 'ops' || _dept === 'admin') return true;
    if (
      _dept === 'business support' &&
      (record.status.toLowerCase().includes('business support') ||
        record.status.toLowerCase().includes('accounts'))
    ) {
      return true;
    } else if (_dept === 'accounts' && record.status.toLowerCase().includes('accounts')) {
      return true;
    }
    if (record.status === 'closed') {
      return true;
    }
    return false;
  });
};

const acceptCancellationTicket = async (id, user, setAccepting) => {
  setAccepting(id);

  if(user) {
    console.log(JSON.stringify(user));
    await axios.post(`${URL}/ops/cancellation/accept-ticket/${id}`, JSON.stringify(user), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  setAccepting('');
};

export { acceptCancellationTicket, showRemoveFilter, removeFilters, fetchRecords, filterRecords };
