import axios from 'axios';
import { URL } from '../../../env.js';
import { setFetchingData, setManagerBodyTypeDetails, setManagerCurrentMonthPolicies, setManagerDealersDetails, setManagerInsuranceTypeDetails, setManagerMonthlyDetails, setManagerTotalPolicies, setSalesManagers, setShowDetails } from './redux';

// Get Sales Managers
const getSalesManagerList = async (dispatch) => {

  const request = axios.get(`${URL}/revenue/sales-managers`);
  const salesManagers = (await request).data;

  dispatch(setSalesManagers(salesManagers));
};


// Get Sales Manager Data
const getSalesManagerData = async (dispatch, salesManager) => {
  const request = axios.get(`${URL}/revenue/sales-manager/data/${salesManager}`);
  const { monthlyData, dealerData, bodyTypeData, insuranceTypeData, totalPoliciesData, totalCurrentMonthPolicies } = (await request).data;

  dispatch(setManagerMonthlyDetails(monthlyData));
  dispatch(setManagerBodyTypeDetails(bodyTypeData));
  dispatch(setManagerDealersDetails(dealerData));
  dispatch(setManagerInsuranceTypeDetails(insuranceTypeData));
  dispatch(setManagerTotalPolicies(totalPoliciesData?.[0]?.['COUNT(UID)']));
  dispatch(setManagerCurrentMonthPolicies(totalCurrentMonthPolicies?.[0]?.['COUNT(UID)']));

  dispatch(setFetchingData(false));
  dispatch(setShowDetails(true));

};


export {
  getSalesManagerList,
  getSalesManagerData
};