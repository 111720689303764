import * as yup from 'yup';

const uploadWorkingSchema = yup.object().shape({
  status: yup.string(),
  workingFile: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Working File needs to be uploaded');
    }
  }),
  comments: yup.string().when('status', (status, schema) => {
    if (status[0] === 'on hold') {
      return schema.required('Comments are required.');
    }
  }),

  // Below 150cc
  bikePayoutBelow150: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout (Below 150cc) is Mandatory');
    }
  }),
  bikePayoutTypeBelow150: yup.string().when('status',  (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout Type (Below 150cc) is Mandatory');
    }
  }),
  scooterPayoutBelow150: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout (Below 150cc) is Mandatory');
    }
  }),
  scooterPayoutTypeBelow150: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout Type (Below 150cc) is Mandatory');
    }
  }),

  // Below 350 Cc
  bikePayoutBelow350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout (Below 350 Cc) is Mandatory');
    }
  }),
  bikePayoutTypeBelow350: yup.string().when('status',  (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout Type (Below 350 Cc) is Mandatory');
    }
  }),
  scooterPayoutBelow350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout (Below 350 Cc) is Mandatory').matches(/[0-9]+/, 'Enter Valid Payout Value');
    }
  }),
  scooterPayoutTypeBelow350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout Type (Below 350 Cc) is Mandatory');
    }
  }),

  // Above 350cc
  bikePayoutAbove350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout (Above 350cc) is Mandatory').matches(/[0-9]+/, 'Enter Valid Payout Value');
    }
  }),
  bikePayoutTypeAbove350: yup.string().when('status',  (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Bike Payout Type (Above 350cc) is Mandatory')
    }
  }),
  scooterPayoutAbove350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout (Above 350cc) is Mandatory').matches(/[0-9]+/, 'Enter Valid Payout Value');
    }
  }),
  scooterPayoutTypeAbove350: yup.string().when('status', (status, schema) => {
    if (status[0] === 'completed') {
      return schema.required('Provided Scooter Payout Type (Above 350cc) is Mandatory');
    }
  }),
});

export default uploadWorkingSchema;