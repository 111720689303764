import axios from 'axios';

import { URL } from '../../../../../env';

const onSubmit = async (data) => {
  const { ticketNumber, opsitTicketId, user } = data;

  try {
    const res = await axios.post(
      `${URL}/ops/endorsements/add-insurer-ticket-number/${opsitTicketId}`,
      JSON.stringify({
        ticketNumber,
        user,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return res.data;
  } catch (err) {
    return false;
  }
};

export { onSubmit };
