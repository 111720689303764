import { useDispatch, useSelector } from 'react-redux';
import { toBase64, uploadFileToDrive } from '../../../functions';

// File Uploads
const fileUpload = async (e) => {
  const file = await (e.target.files[0]);
  const extension = file.type.split('/')[1];
  const fileType = file.type.split('/')[0];
  const base64Data = await toBase64(file);
  const fileId = await uploadFileToDrive(base64Data, extension, fileType);

  return fileId;
};


// Error on failed validation of any input
const InputError = ({ error }) => {
  return <p className="text-sm text-white my-1 bg-red-600 p-1 rounded-md">{error.message ? error.message : error}</p>;
};

// Required
const Required = () => {
  return <span className='text-red-400'>*</span>
};

// Form Input Fields
const FormInputs = ({ className, id, title, placeholder, register, errors, dispatchFn, value}) => {

  const dispatch = useDispatch();

  const inputDisabled = id === 'masterdealershipEmail' ? true: false;
  

  return <div className={className}>
    <label htmlFor={id} className="block mb-2 text-sm font-bold text-gray-900">{title} <Required /> </label>
    <input disabled={inputDisabled} value={value} onInput={(e) => {
      dispatch(dispatchFn(e.target.value))
    }} {...register(id)} type="text" id={id} className="shadow-sm border text-sm rounded-lg focus:border-blue-500 block w-full p-2.5 bg-gray-700 text-white border-gray-600 placeholder-gray-400  focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder={placeholder} required />
    {errors[id] && <InputError error={errors[id]} />}
  </div>
};

// Dropdown
const Dropdown = ({ option1, label, id, options, register, errors, dispatchFn, setValue, value }) => {

  const dispatch = useDispatch();

  return <div>
    <label htmlFor={id} className="block mb-2 text-sm font-bold text-gray-900">{label} <Required /> </label>
    <select value={value} onInput={(e) => {
      if (label === 'Dealership Type') {
        const targetValue = e.target.value;
        setValue('subDealerDocumentUpload', targetValue === 'Master Dealer' ? true: false);
      }

      dispatch(dispatchFn(e.target.value));
    }} {...register(id)} className='block text-sm w-full p-2.5 bg-gray-600 text-white rounded-lg' id={id}>
      <option value=""> {option1} </option>
      {
        options.map((state) => {
          return <option>{state}</option>
        })
      }
    </select>
    {errors[id] && <InputError error={errors[id]} />}
  </div>
}


// File Uploads
const FileUpload = ({title, dispatchFn, setValue, id, errors, value}) => {

  const dispatch = useDispatch();

  const { dealershipDetails } = useSelector(({DealerOnBoarding}) => {
    return DealerOnBoarding;
  });

  return (
    <div>
      <label className="block mb-2 text-sm font-bold text-black" htmlFor="user_avatar">{title} <Required /></label>
      {
        !dealershipDetails[id] && 
        <input value={value} onChange={async (e) => {
          const fileId = await fileUpload(e);
          dispatch(dispatchFn(fileId));
          setValue(id, fileId);
        }} className="block p-2 w-full text-sm  borde rounded-lg cursor-pointer text-gray-400 focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
      }
      {/* Success Message */}
      {
        dealershipDetails[id] && 
        <div id="alert-additional-content-3" className="p-1.5 mb-2 text-green-800 border border-green-300 rounded-lg bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <button type="button" className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1 me-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
            <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
              <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
            </svg>
            File Uploaded Successfully
          </button>
          <button onClick={() => {
            dispatch(dispatchFn(null));
            setValue(id, null);
          }} type="button" className="bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1 text-center dark:hover:bg-red-600 dark:border-red-600 text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
            New File?
          </button>
        </div>
      }
      {errors[id] && <InputError error={errors[id]} />}

    </div>
  )
}


// Form Sub Heading
const FormHeading = ({ heading }) => {
  return <div>
    <h1>{heading}</h1>
  </div>
};

export {
  InputError,
  Required,
  FormInputs,
  FormHeading,
  Dropdown,
  FileUpload
};