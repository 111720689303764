import axios from 'axios';
import { AWS_URL } from '../../../../env.js';

const uploadConfigForm = async (user, configForm, revenueFiles, state) => {
  const body = JSON.stringify({
    requestedBy: user.name,
    operationPerform: 'Upload Dealer Config',
    payload: {
      configForm,
      revenueFiles,
      state,
    },
  });
  const res = await axios.post(`${AWS_URL}/dealer/config/upload`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const {data} = res;
  return data;
};

export { uploadConfigForm };
