import React, { useState } from 'react';
import Heading from '../../../Shared/Heading.jsx'
import { bulkUploadRequest, fileConverterUpload } from './functions';
import { FileUploaded, UploadFile } from './components.jsx';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router';

const BulkClaimsUpload = () => {

  const [fileData, setFileData] = useState(null);
  const [file, setFile] = useState(null);
  const [recordsFetched, setRecordsFetched] = useState(false);
  const [convertedRecords, setConvertedRecords] = useState([]);

  const [bulkUploadStatus, setBulkUploadStatus] = useState(null)

  const navigate = useNavigate();

  return (
    <div>
      <Heading title={'Bulk Data Upload - Claims'} />
      {
        bulkUploadStatus === null ?
      <div className='upload-section my-8 border w-2/5 mx-auto py-8 px-5 font-semibold rounded-3xl shadow-lg'>
        <h1 className='text-start text-[20px] my-1'>Upload File <i class="fa-solid fa-paperclip text-red-500 font-bold"></i></h1>
        <div className="upload-file my-2">
          {
            !file ?
              <UploadFile setFile={setFile} setFileData={setFileData} /> : <FileUploaded file={file} setFile={setFile} setFileData={setFileData} setConvertedRecords={setConvertedRecords} setRecordsFetched={setRecordsFetched} />
          }
        </div>
        {
          fileData &&
          <button className='border px-4 py-2 mt-5 shadow-md rounded-md bg-green-400 text-black active:scale-75 duration-300'
            onClick={async () => {
              if (recordsFetched) {   
                await bulkUploadRequest(convertedRecords, setBulkUploadStatus, navigate);
              } else {
                await fileConverterUpload(fileData, setConvertedRecords, setRecordsFetched);
              }
            }}
          >
            {
              recordsFetched ? 'Upload Bulk Data' : 'Submit File'
            }
            </button>
        }
      </div> : bulkUploadStatus === 'uploading' ? <div className='mx-auto py-8 px-5 my-8 w-fit'> <Oval height={'200px'}/></div> : bulkUploadStatus === 'success' ? <div className='w-fit mx-auto  py-8 px-5 my-8 text-[100px]'><i class="fa-solid fa-circle-check text-green-500"></i></div> : <div className='w-fit mx-auto  py-8 px-5 my-8 text-[100px]'><i class="fa-solid fa-circle-xmark text-red-500"></i></div>
      }
    </div>
  )
};


export default BulkClaimsUpload;