import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requests: []
}

export const BusinessSupportTeam = createSlice({
  name: 'Business Support Team',
  initialState,
  reducers: {
    setDealerCreationRequests: (state, action) => {
      state.requests = action.payload;
    }
  }
});

export const { setDealerCreationRequests } = BusinessSupportTeam.actions;

export default BusinessSupportTeam.reducer;
