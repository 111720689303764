// Error on failed validation of any input
const InputError = ({ error }) => {
  return (
    <p className="text-sm bg-red-500 text-white rounded-lg w-3/4 my-1 pl-1">
      {error?.message ? error?.message : error}
    </p>
  );
};

// Required
const Required = () => {
  return <span className="text-red-400">*</span>;
};

export {
  InputError,
  Required
};