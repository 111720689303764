import { Link } from 'react-router-dom';
import { cardsContent, cardsHeader, cardsLink } from '../tailwindClasses.js';

const DealerCards = ({ data }) => {

  const key = Object.keys(data);

  return (
    // <h1>Hello Kapil</h1>
    <div className='card m-10 border-2 p-5 hover:shadow-lg rounded-lg'>
      <Link key={key} to={`/aegiscovenant/opsit/dealers/${key}`} className={cardsLink}>
        <div>
          <h2><span className={cardsHeader}>Dealer Name</span> : <p className={cardsContent}>{data[key].basicDetails['Dealer Name']}</p></h2>
          <h3><span className={cardsHeader}>Dealer Code</span> : <p className={cardsContent}>{key}</p></h3>
          <h4><span className={cardsHeader}>Current Balance</span> : <p className={cardsContent}>{data[key].combinedBalance['Combined Balance']}</p></h4>
        </div>
      </Link>
    </div>
  )
};

export default DealerCards;