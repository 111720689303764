import axios from 'axios';
import { URL } from '../../../env.js';
import { setUpdatingStatus } from '../redux.js';

const updateClaimTicketStatus = async (e, user, ticketId, nextStatus, dispatch) => {

  try {
    const { insuranceClaimNumber, status, surveyDescription, surveyorAppointmentDate, surveyorMobile, surveyorName, claimAmount, finalClosingComments, transactionUTR } = e;

    dispatch(setUpdatingStatus(true));

    const options = {
      url: `${URL}/claims/update`,
      method: 'PATCH',
      data: JSON.stringify({
        insuranceClaimNumber,
        surveyDescription,
        status,
        claimAmount,
        finalClosingComments,
        surveyorName,
        surveyorMobile,
        surveyorAppointmentDate,
        ticketId,
        user,
        nextStatus,
        transactionUTR
      }),
      headers: {
        'content-type': 'application/json'
      }
    };


    const request = axios.request(options);
    const response = await request;

    dispatch(setUpdatingStatus(false));
    return {
      msg: 'Status Updated Successfully',
      success: true
    }
  } catch (err) {
    console.log(err);
    dispatch(setUpdatingStatus(false));
    return {
      msg: 'Failed to Update Ticket',
      success: false
    }
  }

};

export {
  updateClaimTicketStatus
};