import axios from 'axios';
import { URL } from '../../../env';
import { setPolicyRecord, setRegisteringClaim, setSearchingRecord } from '../redux';

// Search Policy Record
const searchClaimPolicyNumber = async (policyNumber, dispatch) => {

  const insurancePolicyNumber = policyNumber.includes('/') ? policyNumber.replaceAll('/', '-') : policyNumber;

  const options = {
    method: 'POST',
    url: `${URL}/claims/policy`,
    data: JSON.stringify({ policyNumber: insurancePolicyNumber }),
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const response = await request;

    dispatch(setSearchingRecord(false));
    dispatch(setPolicyRecord(response?.data?.data));

    return {
      msg: response?.data?.msg
    }

  } catch (err) {
    return {
      msg: 'Unable to complete request'
    }
  }


};


// Register Claim Recor
const registerClaimReport = async (e, policyRecord, name, dispatch) => {
  const { claimReceiveDate, claimType, workshopAddress, workshopEstimate, workshopName } = e;

  dispatch(setRegisteringClaim(true))

  const payload = {
    ...policyRecord,
    claimDetails: {
      registerDate: claimReceiveDate,
      workshopName,
      workshopAddress,
      workshopEstimate,
      claimType
    },
    reportedBy: name
  };

  const options = {
    method: 'POST',
    url: `${URL}/claims/register-report`,
    data: JSON.stringify({ payload }),
    headers: {
      'Content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const response = await request;

    dispatch(setRegisteringClaim(false));
    return {
      msg: response?.data?.msg
    }

  } catch (err) {
    dispatch(setRegisteringClaim(false));
    return {
      msg: 'Failed to Register Claim'
    }
  }
};


export {
  searchClaimPolicyNumber,
  registerClaimReport
};