import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import "../App.css";
import { useState } from 'react';
import { Link } from 'react-router-dom';

const DealerCard = () => {

  const [basicDetails, setBasicDetailsView] = useState(false);
  const [balanceDetails, setBalanceDetails] = useState(false);
  const [bikeDetails, setBikeDetails] = useState(false);
  const [scooterDetails, setScooterDetails] = useState(false);
  const [evDetails, setEvDetails] = useState(false);
  const [bikeBackupDetails, setBikeBackupDetails] = useState(false);
  const [scooterBackupDetails, setScooterBackupDetails] = useState(false);
  const [evBackupDetails, setEvBackupDetails] = useState(false);

  const { dealerCode } = useParams();

  const handleView = (event) => {
    const buttonId = event.target.id;
    switch(buttonId) {
      case 'basicDetails' : setBasicDetailsView(value => !value)
        break;
      case 'balanceDetails': setBalanceDetails(value => !value);
        break;
      case 'bikePayout': setBikeDetails(value => !value);
        break;
      case 'scooterPayout': setScooterDetails(value => !value);
        break;
      case 'evPayout': setEvDetails(value => !value);
        break;
      case 'bikeBackupPayout': setBikeBackupDetails(value => !value);
        break;
      case 'scooterBackupPayout': setScooterBackupDetails(value => !value);
        break;
      case 'evBackupPayout': setEvBackupDetails(value => !value);
        break;
      default : console.log('no case found')
    }
  };

  const dealer = useSelector(({ dealersData }) => {

    const dealerFound = dealersData.filter((data) => {
      return data[dealerCode];
    });

    return dealerFound.length === 0 ? null : dealerFound[0];
  });

  return (
    !dealer ? <Link to='/aegiscovenant/opsit/dealers'>Go Back</Link> :
      <div>

        <div id="payoutButtons" className="grid grid-cols-4 m-2">
          {/* Basic Details Button */}
          <button id="basicDetails" onClick={handleView}
            className = {
              !basicDetails ? 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white' : 'bg-red-400 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
            }>
            {basicDetails ? 'Hide' : 'View'} Basic Details
          </button>

          {/* Balance Details Button */}
          <button id="balanceDetails" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            {balanceDetails ? 'Hide' : 'View'} Balance Details
          </button>

          {/* Bike Payout Details Button */}
          <button id="bikePayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            {bikeDetails ? 'Hide' : 'View'} Bike Payout Details
          </button>

          {/* Scooter Payout Details Button */}
          <button id="scooterPayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            {scooterDetails ? 'Hide' : 'View'} Scooter Payout Details
          </button>

          {/* EV Payout Details Button */}
          <button id="evPayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            {evDetails ? 'Hide' : 'View'} EV Payout Details
          </button>

          {/* Bike Backup Payout Details Button */}
          <button id="bikeBackupPayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            {bikeBackupDetails ? 'Hide' : 'View'} Bike Backup Payout Details
          </button>

          {/* Scooter Backup Payout Details Button */}
          <button id="scooterBackupPayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            { scooterBackupDetails ? 'Hide' : 'View'} Scooter Backup Payout Details
          </button>

          {/* Ev Payout Details Button */}
          <button id="evBackupPayout" onClick={handleView}
            className='bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" text-white'>
            { evBackupDetails ? 'Hide' : 'View'} EV Backup Payout Details
          </button>

        </div>

        {/* Data Sections */}
        <div className="sections">
          {/* Basic Details Section */}
          {
            basicDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>Basic Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].basicDetails).map((data) => {
                    if (dealer[dealerCode].basicDetails[data] && !data.includes('ID'))
                      return <li>{data} : {dealer[dealerCode].basicDetails[data]}</li>
                  })
                }
              </ul>
            </div>
          }

          {/* Balance Details Section */}
          {
            balanceDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>Balance Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].combinedBalance).map((data) => {
                    if (dealer[dealerCode].combinedBalance[data])
                      return <li>{data} : {dealer[dealerCode].combinedBalance[data]}</li>
                  })
                }
              </ul>
            </div>
          }

          {/* Bike Payout Details Section */}
          {
            bikeDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3 hover:shadow-lg">
              <h1 className='text-2xl font-bold'>Bike Payout Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].bikeConfig).map((data) => {
                    if (dealer[dealerCode].bikeConfig[data] && !data.includes('Total Policies'))
                      return <li> <span>{data}</span> : {dealer[dealerCode].bikeConfig[data]}</li>
                  })
                }
              </ul>
            </div>
          }

          {/* Scooter Payout Details Section */}
          {
            scooterDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>Scooter Payout Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].scooterConfig).map((data) => {
                    if (dealer[dealerCode].scooterConfig[data])
                      return <li>{data} : {dealer[dealerCode].scooterConfig[data]}</li>
                  })
                }
              </ul>
            </div>
          }
        </div>

        {/* EV Payout Details Section */}
        {
            evDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>EV Payout Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].evConfigDealer).map((data) => {
                    if (dealer[dealerCode].evConfigDealer[data])
                      return <li>{data} : {dealer[dealerCode].evConfigDealer[data]}</li>
                  })
                }
                {/* } */}
              </ul>
            </div>
          }

          {/* Balance Details Section */}
          {
            bikeBackupDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>Bike Backup Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].bikeBackupConfig).map((data) => {
                    if (dealer[dealerCode].bikeBackupConfig[data])
                      return <li>{data} : {dealer[dealerCode].bikeBackupConfig[data]}</li>
                  })
                }
                {/* } */}
              </ul>
            </div>
          }

          {/* Scooter Backup Details Section */}
          {
            scooterBackupDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>Scooter Backup Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].scooterBackupConfig).map((data) => {
                    if (dealer[dealerCode].scooterBackupConfig[data])
                      return <li>{data} : {dealer[dealerCode].scooterBackupConfig[data]}</li>
                  })
                }
                {/* } */}
              </ul>
            </div>
          }

          {/* EV Backup Details Section */}
          {
            evBackupDetails &&
            <div className="basicDetails w-fit border-2 m-10 p-3">
              <h1 className='text-2xl font-bold'>EV Backup Details</h1>
              <ul>
                {
                  Object.keys(dealer[dealerCode].evBackupConfig).map((data) => {
                    if (dealer[dealerCode].evBackupConfig[data])
                      return <li>{data} : {dealer[dealerCode].evBackupConfig[data]}</li>
                  })
                }
                {/* } */}
              </ul>
            </div>
          }
      </div>

  );
};

export default DealerCard
