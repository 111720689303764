import React, { useState } from 'react'
import Heading from '../../../../Shared/Heading'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dealerCreationRequestRejection from './rejectionSchema';
import { InputError, Required } from './components';
import { approvalDealerCreationRequest } from '../View/functions';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const RejectDealerOnboardingRequest = () => {

  const [status, setStatus] = useState();
  const [msg, setMsg] = useState();


  const dispatch = useDispatch();

  const { requests } = useSelector(({ BusinessSupportTeam }) => {
    return BusinessSupportTeam;
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(dealerCreationRequestRejection) });

  const { id } = useParams();

  const handleFormSubmit = async (formInput) => {
    const { rejectionReason } = formInput;

    // Search for Record
    const record = requests.filter((request) => {
      return request.id === id;
    });

    const { status, msg } = await approvalDealerCreationRequest(id, 'rejected', dispatch, record[0], rejectionReason);
    setMsg(msg);
    setStatus(status);

    if (status === 200) {
      setTimeout(() => {
        navigate('/bst/dealer/onboarding/requests');
      }, 3000);
    }

  };

  return (
    <div className='w-1/2 m-2 mx-auto'>
      <Heading title={'Reject Dealer Onboard Request'} />
      <form className='mt-16' noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mb-5">
          <label htmlFor="rejectionReason" className="block mb-2 text-sm font-bold text-gray-900">Rejection Reason <Required /> </label>
          <input {...register('rejectionReason')} type="text" id="rejectionReason" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Rejection Reason" required />
          {errors.rejectionReason && <InputError error={errors.rejectionReason} />}

          <button type="submit" className="text-white my-5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"> <i className="fa-solid fa-rectangle-xmark mr-1"></i>  Reject Request</button>
        </div>
      </form>
      {
        msg && status &&
        <div className="flex items-center p-4 mb-4 text-sm border border-green-300 rounded-lg bg-gray-800 text-white dark:border-green-800" role="alert">
          <div>
            <span className="font-medium"> <i className="fa-solid fa-circle-check mr-1 text-green-500"></i> {msg} </span>
          </div>
        </div>
      }
    </div>
  )
}

export default RejectDealerOnboardingRequest
