import axios from 'axios';
import { URL } from '../../../../env.js';

const addCancellationRecord = async (claimDetails, name) => {

  const options = {
    url: `${URL}/ops/register/cancellation`,
    method: 'POST',
    data: JSON.stringify({ formData: claimDetails, user: name }),
    headers: {
      'content-type': 'application/json'
    }
  };

  try {
    const request = axios.request(options);
    const { data, status } = await request;
    const { msg } = data;

    return { msg, status };
  } catch (err) {
    return {
      msg: 'Failed to Register Record',
      status: 500
    }
  }
};

const searchDealerName = async (query) => {
  if(query.length >= 3) {
    return await axios.get(`${URL}/dealer/search?name=${query.trim()}`).then(r => {
      return r.data;
    }).catch(err => []);
  } else return [];
}

export {
  addCancellationRecord,
  searchDealerName
};