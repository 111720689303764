import { Link } from 'react-router-dom';

const LinkButton = ({ link = '', title, type, color = "button", textColor = "text-blue-400", onClick = () => {}, disabled = false}) => {
  return (
    <Link aria-disabled to={link} target={type === 'file' ? '_blank': '_self'} className={"px-1 py-2 font-bold " + textColor}>
      <button onClick={onClick} type="button" className={`text-xs py-[7px] px-4 rounded-md ` + color} disabled={disabled}>
        {title}
      </button>
    </Link>
  );
};

export default LinkButton;
