import axios from "axios";

import {URL} from '../../env';

const getPolicyDetails = async (chassisNumber) => {
  const response = await axios.get(`${URL}/policy/status/${chassisNumber}`);

  return response.data;
};

export { getPolicyDetails };
