import React, { useEffect, useLayoutEffect, useState } from 'react';
import { debitCreditHistory, getDealerslist, updatePagnitor } from './functions.js';
import AuthCheck from '../../../AuthCheck.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { DEALER_LIST_HEADERS, distributors, makes, states } from './constants.js';
import { setFilteredDealersList } from '../redux.js';
import { Oval } from 'react-loader-spinner';

const DealerList = () => {

  const [filters, setFilters] = useState();
  const [downloadingFile, setDownloadingFile] = useState({ id: '', status: false });
  const [showStats, setShowStats] = useState(false);
  const [paginator, setPaginator] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { name, department } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  const { dealersList, filteredDealersList } = useSelector(({ DealerOnBoarding }) => {
    return DealerOnBoarding;
  });

  // Get Dealers List
  useLayoutEffect(() => {
    if (name && department) {
      (async () => {
        await getDealerslist(name, department, dispatch);
      })();
    }
  }, [name, department, dispatch]);


  // Filter Values
  useEffect(() => {

    if (filters) {
      const filteredRecords = dealersList.filter((dealer) => {
        let accept = true;

        Object.keys(filters).forEach((key) => {
          if (key === 'nameCodeEmail') {
            if ((dealer['Dealer Name'] && !(dealer['Dealer Name'].toString().toLowerCase()).includes(filters[key].toLowerCase())) && (dealer['Dealer Email'] && !(dealer['Dealer Email'].toString().toLowerCase()).includes(filters[key].toLowerCase())) && (dealer['Dealer Code'] && !(dealer['Dealer Code'].toString().toLowerCase()).includes((filters[key].toLowerCase())))) accept = false;
          } else if (key === 'Dealer Type') {
            if (dealer[key] && (dealer[key].toString().toLowerCase()) !== (filters[key].toLowerCase())) accept = false;
          }
          else {
            if (dealer[key] && !(dealer[key].toString().toLowerCase()).includes(filters[key].toLowerCase())) accept = false;
          }
        });

        return accept;
      });

      dispatch(setFilteredDealersList(filteredRecords));

    } else {
      dispatch(setFilteredDealersList(null))
    }

  }, [filters, dealersList, dispatch]);


  return (
    // !showStats ?
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      {/* Filters */}
      <div className='mx-10 my-5'>
        {/* Heading */}
        <h1 className='text-[40px] text-bold mb-2'>Dealers List</h1>
        {/* Filters */}
        <div className="filters flex justify-between">
          <div className=''>
            <input id='nameCodeEmail' value={filters?.['nameCodeEmail'] || ''} onChange={(e) => {
              const filter = e.target.id;
              const value = e.currentTarget.value;

              setFilters((currentValue) => {
                if (value === '') {
                  delete currentValue[filter];
                  return {
                    ...currentValue
                  }
                } else {
                  return {
                    ...currentValue,
                    [filter]: value
                  }
                }
              })

            }} className={`w-[300px] bg-gray-200 rounded-lg outline-none px-2 py-2 text-xs`} placeholder='Search Dealer Name, Code and Email' type="text" />
            {
              filters?.['nameCodeEmail'] &&
              <span onClick={() => {
                setFilters((currentValue) => {
                  delete currentValue['nameCodeEmail'];
                  return {
                    ...currentValue
                  };
                })
              }} className='hover:cursor-pointer m-1 p-1 text-white bg-red-500 rounded text-xs'><i class="fa-solid fa-circle-xmark"></i></span>
            }
          </div>

          {/* Dropdown filters */}
          <div className='flex justify-around'>
            <select value={filters?.['Dealer Type'] || ''} onChange={(e) => {
              const filter = e.target.id;
              const value = e.currentTarget.value;

              setFilters((currentValue) => {
                if (value === '') {
                  delete currentValue[filter];
                  return {
                    ...currentValue
                  }
                } else {
                  return {
                    ...currentValue,
                    [filter]: value
                  }
                }
              })
            }} id='Dealer Type' className='outline border rounded-lg text-xs mr-2 active:outline-none' name="dealer-type">
              <option value="">Dealer Type</option>
              <option value="Dealer">Master Dealer</option>
              <option value="Sub Dealer">Sub Dealer</option>
            </select>

            <select value={filters?.['Company'] || ''} onChange={(e) => {
              const filter = e.target.id;
              const value = e.currentTarget.value;

              setFilters((currentValue) => {
                if (value === '') {
                  delete currentValue[filter];
                  return {
                    ...currentValue
                  }
                } else {
                  return {
                    ...currentValue,
                    [filter]: value
                  }
                }
              })
            }} id='Company' className='outline rounded-lg text-xs mr-2 active:outline-none' name="company">
              <option value="">Manufacturer</option>
              {
                makes.map((make) => {
                  return <option value={make}>{make}</option>
                })
              }
            </select>

            <select value={filters?.['State'] || ''} onChange={(e) => {
              const filter = e.target.id;
              const value = e.currentTarget.value;

              setFilters((currentValue) => {
                if (value === '') {
                  delete currentValue[filter];
                  return {
                    ...currentValue
                  }
                } else {
                  return {
                    ...currentValue,
                    [filter]: value
                  }
                }
              })
            }} id='State' className='outline rounded-lg text-xs active:outline-none mr-2' name="dealer-type">
              <option value="">State</option>
              {
                states.map((state) => {
                  return <option value={state}>{state}</option>
                })
              }
            </select>

            {
              ['ADMIN', 'BUSINESS SUPPORT'].includes(department) &&
              <select value={filters?.['Distributor Name'] || ''} onChange={(e) => {
                const filter = e.target.id;
                const value = e.currentTarget.value;

                setFilters((currentValue) => {
                  if (value === '') {
                    delete currentValue[filter];
                    return {
                      ...currentValue
                    }
                  } else {
                    return {
                      ...currentValue,
                      [filter]: value
                    }
                  }
                })
              }} id='Distributor Name' className='outline rounded-lg text-xs active:outline-none mr-2' name="company">
                <option value="">Distributor</option>
                {
                  distributors.map((distributor) => {
                    return <option value={distributor}>{distributor}</option>
                  })
                }
              </select>
            }

            {
              (filters?.['Distributor Name'] || filters?.['Dealer Type'] || filters?.['Company'] || filters?.['State']) &&
              <button onClick={(e) => {
                setFilters((currentValue) => {
                  delete currentValue['Distributor Name'];
                  delete currentValue['Dealer Type'];
                  delete currentValue['Company'];
                  delete currentValue['State'];
                  return {
                    ...currentValue
                  }
                })
              }}><i class="fa-solid fa-trash text-red-500"></i></button>
            }
          </div>
        </div>
      </div>

      <div className="relative m-10 overflow-x-auto shadow-md sm:rounded-lg">
        {/* Table */}
        <table className={`text-xs w-full text-left`}>
          {/* Table Heading */}
          <thead className="bg-white text-black uppercase font-semibold rounded-lg">
            <tr className='border-b border-t bg-green-600'>
              {DEALER_LIST_HEADERS.map((header, index) => (
                <th key={index} scope="col" className={`px-4 py-3 ${index === DEALER_LIST_HEADERS.length - 1 ? 'sticky right-0' : ''}`}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Table Rows */}
            {
              (filteredDealersList || dealersList) && (filteredDealersList ? filteredDealersList : dealersList).map((dealer, index) => {
                if ((index >= paginator - 10) && (index < paginator)) {
                  return (
                    <tr className={`hover:bg-gray-300 border-b ${dealer?.['channel'] === 'GREEN' ? 'bg-white' : dealer?.['channel'] === 'PARTIAL INACTIVE' ? 'bg-orange-300' : 'bg-red-500'}`}>
                      <td className='px-4 py-3'>{dealer?.['Dealer Name']}</td>
                      <td className='px-4 py-3'>{dealer?.['Dealer Code']}</td>
                      <td className='px-4 py-3'>{dealer?.['Dealer Email']}</td>
                      <td className='px-4 py-3'>{dealer?.['Dealer Mobile']}</td>
                      <td className='px-4 py-3'>{dealer?.['Dealer Type']}</td>
                      <td className='px-4 py-3'>{dealer?.['Company']}</td>
                      <td className='px-4 py-3'>{dealer?.['City']}</td>
                      <td className='px-4 py-3'>{dealer?.['State']}</td>
                      <td className='px-4 py-3'>{dealer?.['Sales Executive']}</td>
                      <td className='px-4 py-3'>{dealer?.['Distributor Name']}</td>
                      {
                        department === 'ADMIN' &&
                      <td className='px-1 py-1 text-lg text-center'>
                        <button id={`${dealer?.['Dealer ID']}~${dealer?.['Dealer Name']}`} onClick={async (event) => {
                          const keyValue = event.currentTarget.id.split('~');
                          //  console.log( event.target.id);
                          setDownloadingFile({ id: event.currentTarget.id, status: true });
                          const dealerId = keyValue[0].trim();
                          const dealerName = keyValue[1].trim();
                          // Generate Sheet Records
                          await debitCreditHistory(dealerId, dealerName);
                          setDownloadingFile({ id: '', status: false });

                          // setShowStats(true);
                        }} disabled={downloadingFile.status} className='bg-green-500 disabled:bg-green-200 h-[40px] w-[40px] rounded-full hover:cursor-pointer'>{downloadingFile.id === `${dealer?.['Dealer ID']}~${dealer?.['Dealer Name']}` && downloadingFile.status ? <Oval height={'40px'} width={'40px'} /> : <i class="fa-solid fa-indian-rupee-sign"></i>}
                        </button>
                      </td>
                      }
                    </tr>
                  )
                }
              })
            }
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {
        (filteredDealersList || dealersList) &&
        <div className="pagination mx-auto text-center">
          <button disabled={paginator == 10} onClick={() => {
            updatePagnitor('prev', setPaginator);
          }} className='text-sm px-10 py-1 mx-5 outline-8 rounded-md border shadow-xl active:scale-75 duration-300 disabled:text-gray-300'> <i class="fa-solid fa-angle-left"></i> Previous</button>
          <button disabled={paginator > filteredDealersList?.length || paginator > dealersList?.length} onClick={() => {
            updatePagnitor('next', setPaginator);
          }} className='text-sm px-10 py-1 mx-5 outline-8 rounded-md border shadow-xl active:scale-75 duration-300 disabled:text-gray-200'>Next <i class="fa-solid fa-angle-right"></i></button>
        </div>
      }
      {/* Pagination */}

    </div>
    // : <span>Hello Kapil</span>
  )
}

export default DealerList
