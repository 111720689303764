import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import Heading from '../../../../../Shared/Heading';
import addInsurerTicketNumberSchema from './schema';
import { InputError, Required } from '../../Register/components.jsx';
import { onSubmit } from './functions.js';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const AddInsurerTicketNumber = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const  loggedInUser  = useSelector(state => state.loggedInUser);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addInsurerTicketNumberSchema),
    defaultValues: {
      opsitTicketId: id,
      user: loggedInUser
    },
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <Heading title={`Add Insurer Ticket Number ${id}`} />
      <form
        className="w-[500px] flex flex-col gap-5 items-center justify-center"
        onSubmit={handleSubmit((data) => {
          setSubmitting(true);
          const res = onSubmit(data);
          if(res === true) {
            navigate('/ops/cancellations/view');
          }
          setSubmitting(false);
        })}
      >
        <div className="custom-form-group">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Ticket Number <Required />
          </label>
          <input
            {...register('ticketNumber')}
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="OG-AAAA-BBBB-1111"
          />
          <InputError error={errors.ticketNumber} />
        </div>

        <button
          type="submit"
          className="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          disabled={submitting}
        >
          Register Cancellation Record <i className="fa-solid fa-circle-check"></i>{' '}
        </button>
        {submitting && (
          <Oval
            visible={true}
            height="32"
            width="32"
            color="#3B82F6"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        )}
      </form>
    </div>
  );
};

export default AddInsurerTicketNumber;
