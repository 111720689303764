import React, { useEffect, useLayoutEffect, useState } from 'react'
import Heading from '../../../../Shared/Heading'
import axios from 'axios';
import { URL } from '../../../../env';
import { useDispatch, useSelector } from 'react-redux';
import { setCount, setFilteredRecords, setRecords } from '../redux';
import dayjs from 'dayjs';
import LinkButton from '../../../../Shared/LinkButton';
import "../../../../App.css";
import { GenerateReport, RegisterEndorsementRecord, Stats } from './components.jsx';
import AuthCheck from '../../../../AuthCheck.jsx';
import { useLocation, useNavigate } from 'react-router';
import LinkButtonWithIcon from '../../../../Shared/LinkButtonWithIcon.jsx';

const EndorsementsView = () => {

  const [filters, setRecordsFilter] = useState({});
  const dispatch = useDispatch();

  const endorsementTableHeaders = ['Ticket No.', 'Raised By', 'Dealer Name', 'Endorsement Type', 'Policy Number', 'Insurance Company Name', 'Customer Name', 'Endorsement Reason', 'Status', 'Post Update', 'View Details', 'Insurer Ticket ID', 'Ticket Created On', 'Ticket Closed On'];

  useLayoutEffect(() => {
    (async () => {
      const records = (await axios.get(`${URL}/ops/endorsement/records`))?.data?.records;
      dispatch(setRecords(records));
    })();
  }, []);

  const { pathname } = useLocation();

  const { records, filteredRecords } = useSelector(({ Endorsements }) => {
    return Endorsements;
  });

  const { name, department } = useSelector(({ loggedInUser }) => {
    return loggedInUser;
  });

  // POLLING for fresh records
  useEffect(() => {
    const POLLING_INTERVAL = 10000;
    const intervalId = setInterval(async () => {

      const records = (await axios.get(`${URL}/ops/endorsement/records`))?.data?.records;
      dispatch(setRecords(records));

    }, POLLING_INTERVAL);

    // Filter Endorsement Records
    if (filters) {

      let pendingCount = 0, closedCount = 0;

      const freshRecords = records.filter(record => {
        let accept = true;
        const filterMap = {
          'Ticket No.': 'id',
          'Raised By': 'ops_member',
          'Dealer Name': 'dealer_name',
          'Endorsement Type': 'endorsement_type',
          'Policy Number': 'policy_number',
          'Insurance Company Name': 'insurance_company',
          'Customer Name': 'customer_name',
          'Endorsement Reason': 'endorsement_reason',
          'Status': 'status',
        }

        Object.keys(filters).forEach(key => {
          const actualFilter = filterMap[key];
          if (actualFilter === 'id') {
            if (record[actualFilter] && (record[actualFilter].toString()) !== `${filters[key]}`) accept = false;
          } else {
            if (record[actualFilter] && !(record[actualFilter].toString().toLowerCase()).includes(filters[key].toLowerCase())) accept = false;
          }
        })

        return accept;
      });

      freshRecords.map((record) => {
        if (record.status === 'pending') {
          pendingCount++;
        } else {
          closedCount++;
        }
      })

      console.log({
        pendingCount,
        closedCount
      });
      dispatch(setFilteredRecords(freshRecords));
    }

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);


  useEffect(() => {

    // Filter Endorsement Records
    if (filters) {
      const freshRecords = records.filter(record => {
        let accept = true;
        const filterMap = {
          'Ticket No.': 'id',
          'Raised By': 'ops_member',
          'Dealer Name': 'dealer_name',
          'Endorsement Type': 'endorsement_type',
          'Policy Number': 'policy_number',
          'Insurance Company Name': 'insurance_company',
          'Customer Name': 'customer_name',
          'Endorsement Reason': 'endorsement_reason',
          'Status': 'status',
          'from': 'from',
          'to': 'to'
        }

        Object.keys(filters).forEach(key => {
          const actualFilter = filterMap[key];
          if ((actualFilter === 'from' && filters['to']) || (actualFilter === 'to' && filters['from'])) {
            accept = dayjs(record.created_at, 'YYYY-MM-DD').isBefore(dayjs(filters['to'], 'YYYY-MM-DD')) && dayjs(record.created_at, 'YYYY-MM-DD').isAfter(dayjs(filters['from'], 'YYYY-MM-DD'));
          } else if (actualFilter === 'id') {
            if (record[actualFilter] && (record[actualFilter].toString()) !== `${filters[key]}`) accept = false;
          } else {
            if (record[actualFilter] && !(record[actualFilter].toString().toLowerCase()).includes(filters[key].toLowerCase())) accept = false;
          }
        })

        return accept;
      });

      dispatch(setFilteredRecords(freshRecords));
    }

  }, [filters, records, dispatch]);

  const navigate = useNavigate();

  return (
    <div>
      <AuthCheck dispatch={dispatch} navigate={navigate} pathname={pathname} />
      <Heading title={'Endorsements'} />
      <div className='w-screen flex flex-auto justify-between'>
        <RegisterEndorsementRecord />
        <GenerateReport />
      </div>
      <Stats records={records} filteredRecords={filteredRecords} />
      <div date-rangepicker className="flex items-center ml-10">
        <input
          datepicker
          id="FROM"
          onChange={async (e) => {
            setRecordsFilter({
              ...filters,
              from: e.target.value,
            });
          }}
          value={filters['from'] || ''}
          type="date"
          max={filters['to'] || dayjs().format('YYYY-MM-DD')}
          min={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
          className="picker border-2 border-gray-200 cursor-pointer text-gray-900 text-sm rounded-lg block"
          placeholder="Select date"
        />
        <span className="mx-[3px] text-gray-500">to</span>
        <input
          datepicker
          id="TO"
          onChange={async (e) => {
            setRecordsFilter({
              ...filters,
              to: e.target.value,
            });
          }}
          value={filters['to'] || ''}
          type="date"
          max={dayjs().format('YYYY-MM-DD')}
          min={filters['from'] || dayjs().format('YYYY-MM-DD')}
          className="picker border-2 border-gray-200 cursor-pointer text-gray-900 text-sm rounded-lg block"
          placeholder="Select date"
        />
      </div>
      <div className="relative m-10 overflow-x-auto shadow-md sm:rounded-lg">
        {
          filters && Object.keys(filters).length > 0 &&
          <div className='w-screen flex flex-auto justify-between py-2'>
            <button onClick={() => {
              setRecordsFilter({});
              document.querySelectorAll('input').forEach((input) => {
                document.getElementById(input.id).value = '';
              });

            }} type="button" className="font-bold bg-red-500 text-white hover:bg-red-700 rounded-lg text-sm px-5 py-2.5 flex items-center">
              <i className="fa-solid fa-filter-circle-xmark mr-2" />
              Remove Filters
            </button>
          </div>
        }
        <table className="w-max text-center mx-auto text-sm rtl:text-right text-gray-400">
          {/* Table Heading */}
          <thead className="text-xs uppercase bg-gray-700 text-gray-400">
            <tr>
              {endorsementTableHeaders.map((header, index) => (
                <th key={index} scope="col" className="px-4 py-3">
                  {header}
                  {
                    !['Post Update', 'View Details', 'Ticket Created On', 'Ticket Closed On', 'Insurer Ticket ID'].includes(header) ?
                      <div className='my-1 mx-2'><input id={header} onChange={(e) => {
                        const filter = e.target.id;
                        const value = e.currentTarget.value;

                        setRecordsFilter((currentValue) => {
                          if (value === '') {
                            delete currentValue[filter];
                            return {
                              ...currentValue
                            }
                          } else {
                            return {
                              ...currentValue,
                              [filter]: value
                            }
                          }

                        });
                      }} className='p-1 rounded-md outline-none text-black font-normal' type="text" /></div> : null
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Table Rows */}
            {
              ((filteredRecords.length > 0 || filters) ? filteredRecords : records).map((record, index) => {
                return (
                  <tr key={`e${index}`} className={`text-center  odd:bg-gray-900  even:bg-gray-800 border-b border-gray-700`}>
                    <td style={{ color: record.status === 'pending' ? 'yellow' : 'lightgreen' }} className={`px-6 py-4 text-${record.status === 'pending' ? 'yellow' : 'green'}-500 font-bold`}>ACPL-EN-2024-{record.id}</td>
                    <td className="px-6 py-4">{record.ops_member}</td>
                    <td className="px-6 py-4">{record.dealer_name}</td>
                    <td className="px-6 py-4">{record.endorsement_type}</td>
                    <td className="px-6 py-4">{record.policy_number}</td>
                    <td className="px-6 py-4">{record.insurance_company}</td>
                    <td className="px-6 py-4">{record.customer_name}</td>
                    <td className="px-6 py-4">{record.endorsement_reason}</td>
                    {/* <td className="px-6 py-4">{record.additional_info}</td> */}
                    <td style={{ color: record.status === 'pending' ? 'yellow' : 'lightgreen' }} className={`px-6 py-4 font-semibold`}>{record.status.toUpperCase()}</td>
                    <td className="px-6 py-4">
                      {
                        record.status === 'pending' ?
                          <>
                            <LinkButton
                              title={<i className="fa-solid fa-ticket"></i>}
                              link={`/ops/endorsements/qa/add-insurer-ticket/${record.id}`}
                              type=""
                            />
                            <LinkButton
                              link={`/ops/endorsements/update/${record.id}`}
                              title={<><i className="fa-solid fa-pen"></i> </>}
                              type=""
                            />
                            <LinkButton
                              link={`/ops/endorsements/close/${record.id}`}
                              title={<><i className="fa-solid fa-lock"></i></>}
                              type=""
                            />
                          </> : <LinkButton
                            title={<><i className="fa-solid fa-lock mr-2"></i> <span>TICKET CLOSED</span></>}
                            type=""
                          />
                      }
                    </td>
                    <td className="px-6 py-4"><LinkButton
                      link={`/ops/endorsements/details/${record.id}`}
                      title={<><i className="fa-solid fa-eye mr-2"></i> <span>View Details</span></>}
                      type=""
                    /></td>
                    <td className="px-6 py-4">{record.insurer_ticket_number}</td>
                    <td className="px-6 py-4">{dayjs(record.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                    <td className="px-6 py-4">{record.updated_at && record.status === 'closed' ? dayjs(record.updated_at, { utc: true }).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EndorsementsView
