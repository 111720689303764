import * as yup from 'yup';

const closeTicketSchema = yup.object().shape({
  cancellationReceipt: yup.string(),
  refundedAmount: yup.string().when('cancellationReceipt', {
    is: (cancellationReceipt) => cancellationReceipt === 'no',
    then: () => yup.string().required('Please enter the refunded amount.'),
  }),
  dateOfTransaction: yup.string().when('cancellationReceipt', {
    is: (cancellationReceipt) => cancellationReceipt === 'no',
    then: () => yup.string().required('Please select the date when money was transferred.'),
  }),
  amountLocation: yup.string().when('cancellationReceipt', {
    is: (cancellationReceipt) => cancellationReceipt === 'no',
    then: () => yup.string().required('Please select where the money was transferred.'),
  }),
  closeTicketComments: yup.string().when('cancellationReceipt', {
    is: (cancellationReceipt) => cancellationReceipt === 'yes',
    then: () => yup.string().required('Please enter comments.'),
  }),
});

export default closeTicketSchema;
